
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

import {environment} from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ProfileService } from '../services/profile/profile.service';

import {APPCONSTANTS} from './../constants/appconstant';

export function initializeKeycloak(keycloak: KeycloakService,profileService: ProfileService) {
  return (): Promise<Boolean> =>  {
      return new Promise<Boolean>( (resolve,reject)=>{
        keycloak.init({
          config: {
            url: environment.sso_url,
            realm: environment.sso_realm,
            clientId: environment.sso_clientId              
          },
          initOptions: {
            onLoad: 'login-required',
            checkLoginIframe: false,
            redirectUri: environment.sso_redirectUri,
            flow: 'implicit'
          },
          enableBearerInterceptor:false    
        }).then(ssoresponse=>{
           if(ssoresponse){
             profileService.loadProfile().then(presponse=>{
                resolve(true);
             },error=>{
              profileService.appstatus= APPCONSTANTS.APP_INITIALIZATION_ERROR
                resolve(true);
             })
           }
        })
      });
  }
}

export function initializeProfile(profileService: ProfileService){
  return profileService.loadProfile();
}
