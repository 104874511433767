import { Status } from 'src/app/modals/webresponsestatus';

/***********************Prescriber Search & Prescriber Details Common response interfaces************************* */
export interface PrescriberIdentifier {
    id: string;
    qualifier: string;
    state:string;
    renewalDate:string;
    expiredDate:string;
}
interface PrescriberAddress {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip1: string;
    zip2: string;
    zip3: string;
    country: string;
}
interface PrescriberContractPeriod {
    fromDate: string;
    thruDate: string;
}
interface PrescriberNetwork {
    id: string;
    name: string;
    activeIndicator:string;
    contractPeriod: PrescriberContractPeriod;
}
export class PrescriberDemographs{
    identifiers!: PrescriberIdentifier[];
    lastName!: string;
    firstName!: string;
    middleInitial!: string;
    address!: PrescriberAddress;
    instanceId!: string;
}
/************************************Common interfaces Ended*********************************************** */

/**********************Prescriber Search **************** */
export interface PrescriberSearchRootObject {
    response: PrescriberSearchResponse;
    status: Status;
}
interface PrescriberSearchResponse {
    maxRowExceededInd: boolean;
    prescriberSearchItems: PrescriberSearchItem[];
}
export interface PrescriberSearchItem extends PrescriberDemographs {
    isFavorite: boolean;
    phone1: string;
    network: PrescriberNetwork[];
    additionalFields: PrescriberSearchAdditionalField[];
}
interface PrescriberSearchAdditionalField {
    fieldDescription: string;
    fieldStringValue: string;
}

/**********************Prescriber Search Ending****************** */

/**********************Prescriber Detail**************************/
export interface PrescriberDetailWebResponse{
    response:PrescriberDetailResponse;
    status:Status;
}
export class PrescriberDetailItem extends PrescriberDemographs{
    primaryDegree!:string;
    contact!:PrescriberContact;
    alternateAddresses!:PrescriberAlternateAddress[];
    selectedAlternativeAddress!:number;
    network!:PrescriberNetwork[];
    specialty!:PrescriberSpeciatly[];
    audit!:PrescriberAudit;
}
interface PrescriberDetailResponse{
    prescriberDetailItem:PrescriberDetailItem;
}
interface PrescriberContact{
    phone1:string;
    phone1Ext:string;
    phone2:string;
    phone2Ext:string;
    fax:string;
    faxExt:string;
    email:string;
}
interface PrescriberAudit{
    addUser:string;
    addDate:string;
    addTime:string;
    changeUser:string;
    changeDate:string;
    changeTime:string;
}
export class PrescriberAlternateAddress{
    qualifier!:string;
    address!:PrescriberAddress;
    contact!:PrescriberContact;
    audit!:PrescriberAudit;
}
interface PrescriberSpeciatly{
    code:string;
    name:string;
    audit:PrescriberAudit;
}
/**********************Prescriber Detail**************************/

/**************************Prescriber Favorite*******************/

export interface PrescriberFavoriteWebResponse {
    presbrFavId: number;
    lastName:string;
	firstName:string;
	address:string;
    npi: string;
    presbrId: string;
    instcId: string;
} 
export interface PrescriberFavoriteResponse {
    prescriberFavoriteList: PrescriberFavoriteWebResponse[];
    presbrFavId:number;
    status: Status;
}

