import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/utility/app.guard';

import {LayoutComponent} from 'src/app/components/layout/layout.component';
import {HomeComponent} from 'src/app/components/routecontent/home/home.component';
import { ViewaallComponent } from 'src/app/components/routecontent/viewaall/viewaall.component';
import { PrivacyComponent } from 'src/app/components/privacy/privacy.component';
import { TermsofuseComponent } from 'src/app/components/termsofuse/termsofuse.component';
import { ContactusComponent } from 'src/app/components/contactus/contactus.component';
import { ErrorComponent } from 'src/app/components/error/error.component';

import { coverageRoutes } from 'src/app/modules/checkcoverage/checkcoverage.module';
import { ePARoutes } from 'src/app/modules/epa/epa.module';

import { CheckcoverageComponent } from 'src/app/components/routecontent/checkcoverage/checkcoverage.component';
import { SmapclaimsComponent } from 'src/app/components/routecontent/smapclaims/smapclaims.component';
import { EpasummaryComponent } from 'src/app/components/routecontent/epasummary/epasummary.component';
import { ViewAllSubmittedClaimsComponent } from './components/view-all-submitted-claims/view-all-submitted-claims.component';
import { SearchMedicationClaimsComponent } from './components/search-medication-claims/search-medication-claims.component';

import { MemberDetailComponent } from './components/member-detail/member-detail.component';
import { PaidClaimsComponent } from './components/paid-claims/paid-claims.component';
import { searchMediactionClaimsRoutes } from './modules/history/history.module';
import { PaDetailsComponent } from './components/pa-details/pa-details.component';
import { ePAResubmitRoutes } from './modules/resubmit/resubmit.module';
import { ProviderDetailsComponent } from './components/provider-details/provider-details.component';
import { ResubmitPaComponent } from './components/resubmit-pa/resubmit-pa.component';
export const routes: Routes = [
  {
    path: '', 
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data:{breadcrumb:{label:'Home'}},
    children:[
      {path:'', redirectTo:"/home", pathMatch:'full'  },
      {path:'home', component:HomeComponent, data:{breadcrumb:{skip:true}}},
      {path: 'viewall', component:ViewaallComponent, data:{ breadcrumb : {label:'View all PAs in progress'}}},
      {path: 'AllSubmittedClaims', component:ViewAllSubmittedClaimsComponent, data:{ breadcrumb : {label:'View all submitted claims'}}},
      {path: 'smapclaims', component:SmapclaimsComponent, data:{breadcrumb : {label:'Submit a medication claim - saved trial claims'}}},
       {path: 'PriorAuthorizationDetails', component:PaDetailsComponent, data:{ breadcrumb : {label:'Prior authorization history'}}},
      {path: 'claimreversal', component:PaidClaimsComponent, data:{breadcrumb : {label:'Submit a medication claim - paid trial claims'}}},
      
      {
        path:'coverage', 
        data:{breadcrumb:{skip:true}},
        component: CheckcoverageComponent,
        //loadChildren is ideal way of loading children especially child routes are defined in its own module.
        //But here still children array is being used since this loadchildren not working with our custom breadcrum component.
        children:[
          ...coverageRoutes
        ],
        // loadChildren: () => CheckcoverageModule
      },
      {
        path:'ePA',
        data:{breadcrumb:{skip:true}},
        component:EpasummaryComponent,
        children:[
          ...ePARoutes         
        ]
      },
      
      {
        path:'resubmitePA',
        data:{breadcrumb:{skip:true}},
        component:ProviderDetailsComponent,
        children:[
          ...ePAResubmitRoutes
        ]
      },
      {path: 'searchMediactionClaims',
        data:{breadcrumb:{skip:true}},
        component: SearchMedicationClaimsComponent,
        //loadChildren is ideal way of loading children especially child routes are defined in its own module.
        //But here still children array is being used since this loadchildren not working with our custom breadcrum component.
        children:[
          ...searchMediactionClaimsRoutes
        ],
      // loadChildren: () => CheckcoverageModule
      
      },
      {
        path: 'drugadmin', 
        loadChildren: ()=>import('src/app/modules/drugfeeadmin/drugfeeadmin.module').then(module=>module.DrugfeeAdminModule)
      }
    ]
  },
  {
    path:'error',
    component: ErrorComponent
  },
  {
    path:'privacy',
    component: PrivacyComponent
  },
  {
    path: 'terms',
    component: TermsofuseComponent
  },
  {
    path: 'contactus',
    component: ContactusComponent
  },
  {
    path:'iss',
    redirectTo:'/home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
