import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { PAHistorywebrequest, MemberPAHistorywebrequest } from 'src/app/modals/pahistory/pahistorywebrequest';
import { PAHistorywebresponse, PAResponseHistory } from 'src/app/modals/pahistory/pahistorywebresponse';
import { TableCellData, TableRowData, TableHeaderCellData, DataTableType, TableClickEvent } from 'src/app/modals/datatable/datatable';
import { SavedPAResponse, ResubmitSavedPA, DrugSearchItem } from 'src/app/modals/epa/priorauthresponse';
import { QuestionsetViewmodal } from 'src/app/modals/epa/questionsetviewmodal';
import { PahistoryService } from 'src/app/services/pahistory/pahistory.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { EpaprocessorService } from 'src/app/services/epa/epaprocessor.service';
import { Infusion } from 'src/app/modals/login/loginwebresponse';
import { TimeremaininghoursPipe } from 'src/app/pipes/timeremaining/timeremaining.pipe';
import { DatetoDisplayString } from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { VIEWALL_SAVEDPA_STATUS, VIEWALL_SAVEDPA_STATUS_LABELS, APPCONSTANTS, PRESCRIBER_QUALIFIER, PRESCRIBER_QUALIFIER_LABEL } from 'src/app/constants/appconstant';
import { FilterobjectsByTextPipe } from 'src/app/pipes/filterobjects/filterobjects.pipe';
import { StringToSentenceCase } from 'src/app/pipes/stringconvertions/stringconversions.pipe';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MemberSearchItem } from 'src/app/modals/drugSearch/drugsearchwebrequest';
import { MemberSearchV5Item } from 'src/app/modals/membersearch/membersearchwebresponse';
import { MembersearchService } from 'src/app/services/membersearch/membersearch.service';
import { PrescriberSearchService } from 'src/app/services/prescriberSearch/prescriber-search.service';
import { PrescriberDetailItem, PrescriberDetailWebResponse, PrescriberSearchRootObject, PrescriberSearchItem, PrescriberAlternateAddress } from 'src/app/modals/prescriberSearch/prescriberSearchwebresponse';
import { PrescriberDetailWebRequest, PrescriberSearchWebRequest } from 'src/app/modals/prescriberSearch/prescriberSearchweberequest';
import { PrescriberSearchCriteria } from 'src/app/modals/prescriberSearch/prescriberviewmodal';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { SelectedMedicine } from 'src/app/modals/drugSearch/drugsearchwebresponse';
import { PAInitiationWebRequest } from 'src/app/modals/epa/priorauthrequest';
import { CheckCoveragePageService } from 'src/app/services/drugSearch/check-coverage-page.service';
import { MemberDetailWebResponse, MemberDetailLoadItem } from 'src/app/modals/membersearch/memberdetailwebresponse';
import { MemberDetailwebrequest } from 'src/app/modals/membersearch/memberdetailwebrequest';
import { DrugsearchService } from 'src/app/services/drugSearch/drugsearch.service';

@Component({
  selector: 'savedpahistory',
  templateUrl: './savedpahistory.component.html',
  styleUrls: ['./savedpahistory.component.css']
})
export class SavedpahistoryComponent implements OnInit {

  savedPaHeaders: TableHeaderCellData[];
  savedPaHistory: TableRowData<PAResponseHistory>[];
  private sourceData: PAResponseHistory[];
  blnLoading: boolean;
  blnServerError: boolean;
  totalPages: number;
  totalEntries: number;
  currentPage: number;
  memberdetailItem!: MemberDetailLoadItem;
  recordsPerPage: number = environment.paginationRecordsPerPage;
  private fieldsToConsiderForFilteration: string[];
  memberSearchResult!: MemberSearchV5Item | MemberSearchItem | undefined;


  private firstHeader: TableHeaderCellData = new TableHeaderCellData();
  private secondHeader: TableHeaderCellData = new TableHeaderCellData();
  private thirdHeader: TableHeaderCellData = new TableHeaderCellData();
  private forthHeader: TableHeaderCellData = new TableHeaderCellData();
  private fifthHeader: TableHeaderCellData = new TableHeaderCellData();
  private sixthHeader: TableHeaderCellData = new TableHeaderCellData();

  private _fromDate: Date;
  public get fromDate(): Date {
    return this._fromDate;
  }
  @Input()
  public set fromDate(v: Date) {
    this._fromDate = v;
  }

  private _toDate: Date;
  public get toDate(): Date {
    return this._toDate;
  }
  @Input()
  public set toDate(v: Date) {
    this._toDate = v;
  }

  @Input()
  memberId!: string;

  @Input()
  pageType!: string;

  @Input()
  searchText: string = "";

  @Input()
  filterByStatus: VIEWALL_SAVEDPA_STATUS = VIEWALL_SAVEDPA_STATUS.VIEW_ALL;

  @Input()
  maxResults!: number;

  @ViewChild("memberNameTemplate", { static: true })
  memberNameTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("drugNameTemplate", { static: true })
  drugNameTemplateRef?: TemplateRef<HTMLElement>

  @ViewChild("prescriberNameTemplate", { static: true })
  prescriberNameTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("caseIdTemplate", { static: true })
  caseIdTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("timeRemainingTemplate", { static: true })
  timeRemainingTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("timeRemainingElapsedTemplate", { static: true })
  timeRemainingElapsedTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("resumePaTemplate", { static: true })
  resumePaTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("resubmitPaTemplate", { static: true })
  resubmitPaTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("historyServerErrorTemplate", { static: true })
  historyServerErrorTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("errorPopup", { static: true })
  errorPopupTemplateRef?: TemplateRef<HTMLElement>;
  prescriberList: PrescriberSearchItem[] = [];

  constructor(private pahistory: PahistoryService, private prescriberSearchService: PrescriberSearchService, private trailclaimadjudicationHelper: CheckCoveragePageService,
    private loginProfile: ProfileService,
    private memberSearchHelper: MembersearchService,
    private timeremainingpipe: TimeremaininghoursPipe,
    private dateToDisplayStringConversion: DatetoDisplayString,
    private filterobjectsByTextPipe: FilterobjectsByTextPipe,
    private stringToSentenceCase: StringToSentenceCase, private logger: LoggerService,
    private epaServices: EpaprocessorService, private prescriberSearch: PrescriberSearchService,
    private router: Router, private modalService: NgbModal,
    private drugSearchHelper: DrugsearchService) {

    this.firstHeader.content = "Member";
    this.firstHeader.sortable = true;

    this.secondHeader.content = "Medication";

    this.thirdHeader.content = "Prescriber";

    this.forthHeader.content = "Case ID";

    this.fifthHeader.content = "Time remaining";
    this.fifthHeader.sortable = true;
    this.fifthHeader.selectedForSort = true;

    this.sixthHeader.content = "Action";

    this.savedPaHeaders = [];
    this.savedPaHeaders.push(this.firstHeader);
    this.savedPaHeaders.push(this.secondHeader);
    this.savedPaHeaders.push(this.thirdHeader);
    this.savedPaHeaders.push(this.forthHeader);
    this.savedPaHeaders.push(this.fifthHeader);
    this.savedPaHeaders.push(this.sixthHeader);

    this.savedPaHistory = [];

    //Following dates are used to fetch history from server.
    let currentDate: Date = new Date();
    //Represent 12AM of next date. Meaning, It considers complete 24h of Today.
    currentDate.setDate(currentDate.getDate() + 1);
    this._toDate = currentDate;

    //Resenting to represent today's date.
    currentDate = new Date();
    //This history will be defaulted to last 30 days from now if no dates are supplied.
    currentDate.setDate(currentDate.getDate() - 30);
    this._fromDate = currentDate;

    this.totalPages = 1;
    this.totalEntries=0;
    this.currentPage = 1;
    this.blnLoading = false;
    this.blnServerError = false;
    this.sourceData = [];
    this.fieldsToConsiderForFilteration = [];
    this.fieldsToConsiderForFilteration.push("lastName");
    this.fieldsToConsiderForFilteration.push("firstName");
    this.fieldsToConsiderForFilteration.push("drugName");
    this.fieldsToConsiderForFilteration.push("presbrLstName");
    this.fieldsToConsiderForFilteration.push("presbrFirstName");
    this.fieldsToConsiderForFilteration.push("caseId");
  }

  ngOnInit(): void {
    if (this.memberId != undefined) {
      this.memberSearchResult = this.memberSearchHelper.getSelectedMember();
    }
    this.loginProfile.loginuser.selectedInfusion.subscribe((value: Infusion) => {
      this.reload();
    });
  }

  reload(blnHardReload: boolean = true): void {
    if (blnHardReload) {
      if (this.pageType == undefined) {
        let webrequest: PAHistorywebrequest = new PAHistorywebrequest();
        webrequest.fromDate = this.dateToDisplayStringConversion.transform(this.fromDate);
        webrequest.toDate = this.dateToDisplayStringConversion.transform(this.toDate);
        webrequest.maxResults = this.maxResults;
        webrequest.infsnPhmcyNPI = this.loginProfile.loginuser.selectedInfusion.getValue().pharmacyNPI;
        webrequest.providerId = this.loginProfile.loginuser.UUID;

        this.blnLoading = true;
        this.blnServerError = false;
        this.sourceData = [];
        this.savedPaHistory = [];
        this.resetPaginationDefaults();
        this.pahistory.getSavedPAHistory(webrequest).then((response: PAHistorywebresponse) => {
          this.blnLoading = false;
          this.blnServerError = false;
          if (this.memberId != undefined) {
            this.sourceData = response.savedPaAutherizations.filter((item) => item.memberId == this.memberId);
          } else {
            this.sourceData = response.savedPaAutherizations;
          }

          this.loadHistory();
        }, (error: any) => {
          this.blnLoading = false;
          this.blnServerError = true;
          this.savedPaHistory = [];
        }).catch((reason: any) => {
          this.blnLoading = false;
          this.blnServerError = true;
          this.savedPaHistory = [];
        });
      } else if (this.pageType = "MemberSpecificResults") {
        let webrequest: MemberPAHistorywebrequest = new MemberPAHistorywebrequest();
        webrequest.fromDate = this.dateToDisplayStringConversion.transform(this.fromDate);
        webrequest.toDate = this.dateToDisplayStringConversion.transform(this.toDate);
        webrequest.maxResults = this.maxResults;
        webrequest.infsnPhmcyNPI = this.loginProfile.loginuser.selectedInfusion.getValue().pharmacyNPI;
        webrequest.providerId = this.loginProfile.loginuser.UUID;
        webrequest.memberId = this.memberSearchResult?.memberId;
        webrequest.instanceId = this.memberSearchResult?.instanceId;
        webrequest.carrierId = this.memberSearchResult?.carrierId;
        webrequest.groupId = this.memberSearchResult?.groupId;
        webrequest.accountId = this.memberSearchResult?.accountId;
        webrequest.memberDOB = this.memberSearchResult?.dateOfBirth;
        webrequest.memberFirstName = this.memberSearchResult?.firstName;
        webrequest.memberLastName = this.memberSearchResult?.lastName;
        webrequest.resultsFromPAS = true;
        this.blnLoading = true;
        this.blnServerError = false;
        this.sourceData = [];
        this.savedPaHistory = [];
        this.resetPaginationDefaults();
        this.pahistory.getMemberSavedPAHistory(webrequest).then((response: PAHistorywebresponse) => {
          this.blnLoading = false;
          this.blnServerError = false;
          if (this.memberId != undefined) {
            this.sourceData = response.savedPaAutherizations.filter((item) => item.memberId == this.memberId);
          } else {
            this.sourceData = response.savedPaAutherizations;
          }

          this.loadHistory();
        }, (error: any) => {
          this.blnLoading = false;
          this.blnServerError = true;
          this.savedPaHistory = [];
        }).catch((reason: any) => {
          this.blnLoading = false;
          this.blnServerError = true;
          this.savedPaHistory = [];
        });
      }

    } else {
      this.resetPaginationDefaults();
      this.loadHistory();
    }
  }

  private filterSourceDataByStatus(): PAResponseHistory[] {
    return this.sourceData.filter((history: PAResponseHistory) => {
      const blnSavedPaExpired: boolean = this.timeremainingpipe.transform(history.deadlineForReply) > 0 ? false : true;
      if (blnSavedPaExpired)
        history.paStatus = VIEWALL_SAVEDPA_STATUS_LABELS.get(VIEWALL_SAVEDPA_STATUS.VIEW_EXPIRED);
      else
        history.paStatus = VIEWALL_SAVEDPA_STATUS_LABELS.get(VIEWALL_SAVEDPA_STATUS.VIEW_ACTIVE);

      if (parseInt(this.filterByStatus.toString()) == VIEWALL_SAVEDPA_STATUS.VIEW_ALL)
        return true;
      if (history.paStatus != undefined && history.paStatus.length > 0)
        return VIEWALL_SAVEDPA_STATUS_LABELS.get(parseInt(this.filterByStatus.toString())) == history.paStatus
          || VIEWALL_SAVEDPA_STATUS_LABELS.get(parseInt(this.filterByStatus.toString())) == this.stringToSentenceCase.transform(history.paStatus);
      else
        return false;
    });
  }

  private resetPaginationDefaults(): void {
    this.totalPages = 1;
    this.currentPage = 1;
  }

  private readCurrentPageSavedPAHistory(): PAResponseHistory[] {

    const filteredSourceData: PAResponseHistory[] = this.filterobjectsByTextPipe.transform(this.filterSourceDataByStatus(), this.searchText, this.fieldsToConsiderForFilteration);
    this.totalEntries=filteredSourceData.length;
    if (this.maxResults == undefined) {
      const startIndex: number = (this.currentPage - 1) * this.recordsPerPage;
      const endIndex: number = (this.currentPage * this.recordsPerPage);
      this.totalPages = (filteredSourceData.length % this.recordsPerPage) != 0 ? Math.floor(filteredSourceData.length / this.recordsPerPage) + 1 : Math.floor(filteredSourceData.length / this.recordsPerPage);
      return filteredSourceData.slice(startIndex, endIndex);
    } else {
      return filteredSourceData;
    }

  }

  private loadHistory(): void {
    this.savedPaHistory = [];
    if (!this.blnServerError) {
      this.readCurrentPageSavedPAHistory().forEach((history: PAResponseHistory) => {

        const blnSavedPaExpired: boolean = this.timeremainingpipe.transform(history.deadlineForReply) > 0 ? false : true;

        const row: TableRowData<PAResponseHistory> = new TableRowData<PAResponseHistory>();

        const firstCell: TableCellData<PAResponseHistory> = new TableCellData();
        firstCell.data = history;
        firstCell.sortable = true;
        firstCell.template = this.memberNameTemplateRef;
        firstCell.clickable = false;
        row.cells.push(firstCell);

        const secondCell: TableCellData<PAResponseHistory> = new TableCellData();
        secondCell.data = history;
        secondCell.template = this.drugNameTemplateRef;
        secondCell.clickable = false;
        row.cells.push(secondCell);

        const thirdCell: TableCellData<PAResponseHistory> = new TableCellData();
        thirdCell.data = history;
        thirdCell.template = this.prescriberNameTemplateRef;
        thirdCell.clickable = false;
        row.cells.push(thirdCell);

        const fourCell: TableCellData<PAResponseHistory> = new TableCellData();
        fourCell.data = history;
        fourCell.template = this.caseIdTemplateRef;
        fourCell.clickable = false;
        row.cells.push(fourCell);

        const fifthCell: TableCellData<PAResponseHistory> = new TableCellData();
        fifthCell.data = history;
        fifthCell.sortable = true;
        fifthCell.template = (blnSavedPaExpired) ? this.timeRemainingElapsedTemplateRef : this.timeRemainingTemplateRef;
        fifthCell.clickable = false;
        row.cells.push(fifthCell);

        const sixthCell: TableCellData<PAResponseHistory> = new TableCellData();
        sixthCell.data = history;
        sixthCell.template = (blnSavedPaExpired) ? this.resubmitPaTemplateRef : this.resumePaTemplateRef;
        sixthCell.clickable = false;
        row.cells.push(sixthCell);

        this.savedPaHistory.push(row);
      });
    } else {
      const row: TableRowData<PAResponseHistory> = new TableRowData<PAResponseHistory>();
      row.disabled = true;
      row.cssClass = "norow";

      const firstCell: TableCellData<PAResponseHistory> = new TableCellData<PAResponseHistory>();
      firstCell.template = this.historyServerErrorTemplateRef;
      firstCell.clickable = false;
      firstCell.colspan = this.savedPaHeaders.length;
      row.cells.push(firstCell);
      this.savedPaHistory.push(row);
    }

  }

  onPageChange(pageNumber: any) {
    this.currentPage = pageNumber;
    this.loadHistory();
  }

  onChangeEntriesPerPage(entriesPerPage: any){
    this.recordsPerPage = entriesPerPage;
    this.currentPage = 1;
    this.loadHistory();
  }

  onSavedPASelected(clickEvent: TableClickEvent<PAResponseHistory>): void {
    this.logger.userAction("Selecting Saved PA.");
    clickEvent.cell?.data?.deadlineForReply
    if (clickEvent.cell != undefined && clickEvent.cell.data != undefined) {
      
      const blnSavedPaExpired: boolean = this.timeremainingpipe.transform(clickEvent.cell.data.deadlineForReply) > 0 ? false : true;
      if (!blnSavedPaExpired) {
        this.epaServices.resumeSavedPA(clickEvent.cell.data.paRefId, this.loginProfile.loginuser.UUID).then((response: SavedPAResponse) => {
          const epaQuestions: QuestionsetViewmodal = this.epaServices.extactQuestionSetModalFromSavedPA(response.paInitiationResponseWeb.body, response.paResumeDetails);
          this.epaServices.storeQuestionSetViewModal(epaQuestions);
          this.router.navigateByUrl("/ePA/questions");
        }, (error: any) => {
        }).catch((exception: any) => {
        });
      }
      if (blnSavedPaExpired) {
        this.epaServices.resubmitSavedPA(clickEvent.cell.data.paRefId).then((response: ResubmitSavedPA) => {
          
          sessionStorage.setItem("resubmitresponse", JSON.stringify(response));
          //console.log(JSON.parse("" + sessionStorage.getItem("resubmitresponse")));
          //console.log("res", response.initiationWebRequest.prescriber);
          sessionStorage.setItem("prescrbr", JSON.stringify(response.initiationWebRequest.prescriber));
          //console.log(JSON.parse("" + sessionStorage.getItem("prescrbr")));
          const claimresponse: SelectedMedicine = new SelectedMedicine();
          
          claimresponse.drugSearchItem = response.drugSearchWebResponse.response.drugSearchItems[0];
          claimresponse.drugSearchItem.detailedDrugName=this.drugSearchHelper.getDrugDetailedName(response.drugSearchWebResponse.response.drugSearchItems[0])
          claimresponse.selectedMember = response.detailRequest;
          claimresponse.selectedPrescriberDetail = response.initiationWebRequest.prescriber;
          claimresponse.quantytyCalculate = Number.parseInt(response.initiationWebRequest.medicationPrescribed.quantity[0].value);
          claimresponse.Days = Number.parseInt(response.initiationWebRequest.medicationPrescribed.daysSupply);

          ///////////////////////////////////
          const prescriberDetailWebRequest: PrescriberDetailWebRequest = new PrescriberDetailWebRequest();
          prescriberDetailWebRequest.prescriberId = response.initiationWebRequest.prescriber.npi;
          prescriberDetailWebRequest.instanceId = environment.instanceId;
          prescriberDetailWebRequest.prescriberIdQualifier = PRESCRIBER_QUALIFIER.NPI;

          this.prescriberSearch.getPrescriberDetail(prescriberDetailWebRequest).then((pbrResponse: PrescriberDetailWebResponse) => {
             
            if (pbrResponse.response != undefined && pbrResponse.response.prescriberDetailItem != undefined) {
              claimresponse.selectedPrescriberDetail=pbrResponse.response.prescriberDetailItem;
              claimresponse.selectedPrescriberDetail.selectedAlternativeAddress=pbrResponse.response.prescriberDetailItem.alternateAddresses.findIndex((item:PrescriberAlternateAddress)=>item.contact.fax==response.initiationWebRequest.prescriber.faxNumber);
              let memberDetailrequest: MemberDetailwebrequest = new MemberDetailwebrequest();
              memberDetailrequest.accountId = response.detailRequest.accountId;
              memberDetailrequest.carrierId = response.detailRequest.carrierId;
              memberDetailrequest.groupId = response.detailRequest.groupId;
              memberDetailrequest.instanceId = response.detailRequest.instanceId;
              memberDetailrequest.memberId = response.detailRequest.memberId;
              this.trailclaimadjudicationHelper.getMemberDetails(memberDetailrequest).then((resp: MemberDetailWebResponse) => {
                
                //console.log(resp);

                claimresponse.memberDetailResponse = resp;
                //console.log("resp", resp.memberDetailLoadItem);
                // this.memberdetailItem =  resp.memberDetailLoadItem;
                //console.log(this.memberdetailItem);
                sessionStorage.setItem("claimresponse", JSON.stringify(claimresponse));
                //console.log(JSON.parse("" + sessionStorage.getItem("claimresponse")));


                this.router.navigateByUrl("/resubmitePA");
              });

            }
          });
          ///////////////////////////////////
        });
      }
    }
  }
}
