<ng-template #dropdown let-data="data">
    <!-- style="position: relative; top: -55px; left:10px;  width: 40px;/" -->

    <div *ngIf="!data.showDetails">
        <button class="btn btn-secondary-outline" data-bs-toggle="collapse" enableAction="true" name="showDetails"
            role="button" attr.aria-label="More details for {{data.memberLastName?.toLowerCase()}} {{data.memberFirstName?.toLowerCase()}} {{data.claimGroupId}}"><i class="fa fa-chevron-right fs-6 dropdownArrowClosed" aria-hidden="true" enableAction="true"
                name="showDetails"></i>

        </button>
    </div>
    <div *ngIf="data.showDetails">
        <button class="btn btn-secondary-outline bordered bg-dark" data-bs-toggle="collapse" enableAction="true"
            name="hideDetails" role="button"><i class="fa fa-chevron-down text-white dropdownArrowOpened"
                aria-hidden="true" enableAction="true" name="hideDetails" attr.aria-label="More details for {{data.memberLastName?.toLowerCase()}} {{data.memberFirstName?.toLowerCase()}}  {{data.claimGroupId}}"></i>
        </button>
    </div>
</ng-template>

<ng-template #memberNameTemplate let-data="data">
    <p class="large" aria-labelledby="memberNameTemplate">
        <span class="fontbold">{{data.memberLastName?.toLowerCase()}}</span>
        <br />
        {{data.memberFirstName?.toLowerCase()}}
    </p>
</ng-template>
<ng-template #drugNameTemplate let-data="data">
    <p class="large" aria-labelledby="drugNameTemplate">{{data.claimGroupId}}</p>
</ng-template>
<ng-template #prescriberNameTemplate let-data="data">
    <p class="large" aria-labelledby="prescriberNameTemplate"><span
            class="fontbold">{{data.prescriberLastName?.toLowerCase()}}</span><br />{{data.prescriberFirstName?.toLowerCase()}}
    </p>
</ng-template>
<ng-template #dateTemplate let-data="data">
    <p class="large" aria-labelledby="dateTemplate">{{data.date | date: 'MM/dd/YYYY'}}</p>
</ng-template>
<ng-template #userNameTemplate let-data="data">
    <p class="large" aria-labelledby="userNameTemplate"><span
            class="fontbold">{{data.userLastName?.toLowerCase()}}</span><br />{{data.userFirstName?.toLowerCase()}}</p>
</ng-template>
<ng-template #bookmarkDeleteTemplate let-data="data">
    <a class="btn invisiblebtn" name="delete" enableAction="true" aria-labelledby="bookmarkDeleteTemplate">
        <i class="fa fa-close" style="color: black; padding-top: 0.8em;" name="delete" enableAction="true" tabindex="0"></i>
    </a>
</ng-template>

<ng-template #refreshIcon let-data="data">
    <div class="bookmark-contextmenu btn-group row pe-5 ps-5" *ngIf="data.claimStsTypId==3 || data.claimStsTypId==4" aria-labelledby="refreshIcon">
        <a class="float-end btn invisiblebtn text-center">
            <i class="fa fa-refresh fs-3 fa-spin-hover" style="color: #316BBE;padding-top: 0.4em;" name="refresh" enableAction="true"
                aria-label="refresh" aria-hidden="true" tabindex="0"
                [ngClass]="{'rotateRefresh':(data.animate)}"></i>&nbsp;&nbsp;&nbsp;&nbsp;
            <!-- <i class="fa fa-angle-right" name="resume" style="color: #316BBE;" aria-hidden="true" enableAction="true"
                aria-label="resume" tabindex="1"></i> -->
        </a>
        <div class="bookmark-success-dialogue text-center" *ngIf="this.refreshStatusTxt!='' && data.refresh">
            <p style="color: #1F4E83;" [innerHtml]="this.refreshStatusTxt"></p>
        </div>
    </div>
</ng-template>
<ng-template #selectIcon let-data="data">
        <a class="float-end btn invisiblebtn text-center" aria-labelledby="selectIcon">
            <i class="fa fa-angle-right" name="resume" style="color: #316BBE;padding-bottom: 0.4em;" tabindex="0" aria-hidden="true" enableAction="true" aria-label="resume"></i>   
        </a>
</ng-template>

<ng-template #bookmarkIconTemplate let-data="data">
    <div class="bookmark-contextmenu btn-group row p-3" *ngIf="!this.bookmarked" aria-labelledby="bookmarkIconTemplate">
        <a class="btn invisiblebtn" name="toggle" enableAction="true" alt="toggleoptions">
            <i class="fa fa-ellipsis-v" [ngClass]="{'fa-ellipsis-opened':data.showBookmarkOptions}" aria-hidden="true"
                name="toggle" enableAction="true" tabindex="0"></i>
        </a>
        <div class="bookmark-success-dialogue text-center" *ngIf="data.showAnimationAfterBookmark" role="dialog">
            <p style="color: #1F4E83;" [innerHtml]="data.bookmarkAnimationStatusTxt"></p>
        </div>
        <ul class="dropdown-menu" *ngIf="data.showBookmarkOptions">
            <li  tabindex="0">
                <a class="btn invisiblebtn" name="bookmark" enableAction="true">
                    <!-- <i class="fa fa-bookmark-o fa-fw" name="bookmark" style="color: black;" enableAction="true"></i> -->
                    <a class="btn invisiblebtn" name="bookmark" enableAction="true" attr.aria-label="{{data.memberLastName?.toLowerCase()}} {{data.memberFirstName?.toLowerCase()}} {{data.productName}} button menu">Bookmark</a>
                </a>
            </li>
            <li  tabindex="0">
                <a class="btn invisiblebtn" name="change" enableAction="true">
                    <!-- <i class="fa fa-pencil-square-o fa-fw" style="color: black;" name="change" enableAction="true"></i> -->
                    <a class="btn invisiblebtn" name="change" enableAction="true" attr.aria-label="{{data.memberLastName?.toLowerCase()}} {{data.memberFirstName?.toLowerCase()}} {{data.productName}} button menu" tabindex="0">Change</a>
                </a>
            </li>
            <li  tabindex="0">
                <a class="btn invisiblebtn" name="delete">
                    <!-- <i class="fa fa-trash-o fa-fw" style="color: black;" name="delete" enableAction="true"></i> -->
                    <a class="btn  invisiblebtn" name="delete" enableAction="true" attr.aria-label="{{data.memberLastName?.toLowerCase()}} {{data.memberFirstName?.toLowerCase()}} {{data.productName}} button menu" tabindex="0">Delete</a>
                </a>
            </li>
            <li  tabindex="0">
                <a class="btn invisiblebtn" name="resume">
                    <!-- <i class="fa fa-trash-o fa-fw" style="color: black;" name="delete" enableAction="true"></i> -->
                    <a class="btn  invisiblebtn" name="resume" enableAction="true" attr.aria-label="{{data.memberLastName?.toLowerCase()}} {{data.memberFirstName?.toLowerCase()}} {{data.productName}} button menu" tabindex="0">Resume</a>
                </a>
            </li>

        </ul>
    </div>
</ng-template>

<ng-template #secondRowTemplate let-data="data">
    <div class="mt-3 col-10 ms-2" aria-labelledby="secondRowTemplate"> 
        <h3 class="mb-3 ms-2"><strong>Drug details</strong></h3>
        <div class="container drugDetailTable p-0 m-0">
            <div class="row drugDetailRow p-4 m-0">
                <div class="col">
                    <strong>NDC</strong>
                </div>
                <div class="col">
                    <strong>Medication</strong>
                </div>
                <div class="col" *ngIf="this.showStatus">
                    <strong>Status</strong>
                </div>
                <div class="col" *ngIf="!this.showStatus">
                </div>
            </div>
            <div class="row p-3 m-0 text-dark borderbottom" *ngFor="let dataItem of data.claimDetailsHistory">
                <div class="col">
                    {{dataItem.productId}}
                </div>
                <div class="col">
                    {{dataItem.productName}}
                </div>
                <div class="col" *ngIf="this.pageType!=3 && this.pageType!=4">
                    <div class="float-end">
                        <i class="fa fa-trash-o fs-4" ria-hidden="true" name="remove"
                            (click)="removeMedicationConfirm(dataItem,false)"></i>&nbsp;&nbsp;
                        <button class="btn btn-link" data-bs-toggle="collapse" name="remove"
                            (click)="removeMedicationConfirm(dataItem,false)" role="button">Delete</button>
                    </div>
                </div>
                <div class="col" name="extra" *ngIf="(this.pageType==3 || this.pageType==4) && !this.showStatus"></div>
                <div class="col" *ngIf="this.showStatus">
                    <div *ngIf="dataItem.claimStsTypId==1">
                        <span _ngcontent-vyo-c126=""
                            class="alert alert-success text-capitalize resultsflag">Saved</span>
                    </div>
                    <div *ngIf="dataItem.claimStsTypId==2">
                        <span _ngcontent-vyo-c126=""
                            class="alert alert-success text-capitalize resultsflag">Re-executed</span>
                    </div>
                    <div *ngIf="dataItem.claimStsTypId==3">
                        <span _ngcontent-vyo-c126="" class="alert alert-success text-capitalize resultsflag">Paid</span>
                    </div>
                    <div *ngIf="dataItem.claimStsTypId==4">
                        <span _ngcontent-vyo-c126=""
                            class="alert alert-danger text-capitalize resultsflag">Reversed</span>
                    </div>
                    <div *ngIf="dataItem.claimStsTypId==5">
                        <span _ngcontent-vyo-c126=""
                            class="alert alert-success text-capitalize resultsflag">Removed</span>
                    </div>
                    <div *ngIf="dataItem.claimStsTypId==6">
                        <span _ngcontent-vyo-c126="" class="alert alert-danger text-capitalize resultsflag">Submitted
                            rejected</span>
                    </div>
                    <div *ngIf="dataItem.claimStsTypId==7">
                        <span _ngcontent-vyo-c126="" class="alert alert-danger text-capitalize resultsflag">Reversed
                            rejected</span>
                    </div>
                    <div *ngIf="dataItem.claimStsTypId==8">
                        <span _ngcontent-vyo-c126="" class="alert alert-warning text-capitalize resultsflag">Submitted
                            dublicate</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #historyServerErrorTemplate let-data="data">
    <p class="large"aria-labelledby="historyServerErrorTemplate">
        <span>We were unable to retrieve this information. Please</span>
        <button class="btn btn-link invisiblebtn" enableAction="true" name="tryagain" (click)="reload(true)">Try
            again</button>
    </p>
</ng-template>

<ng-template #bookmarkError let-modal>
    <div class="container-fluid content-card" aria-modal="true" aria-labelledby="bookmarkError" role="dialog">
        <div class="row">
            <div class="col paragraph large text-center card-content">
                <i class="fa fa-ban" aria-hidden="true"></i>
                <br />
                An error occured. Please try again later.
            </div>
        </div>
        <div class="row">
            <div class="col text-center card-footer">
                <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Close</button>
                &nbsp;&nbsp;
                <button type="button" class="btn btn-primary" (click)="retryOperation()">Try again</button>
            </div>
        </div>
    </div>
</ng-template>

<div class="bg-white content-card" [ngClass]="{'shadow-none': pageType==3}">
    <div class="card-header p-0 pe-3 ps-3 pt-2" *ngIf="!this.isHomePage">
        <div class="control float-start ps-2">
            <div class="row">
                <label class="p-0 mb-1" for="dateRange" id="dateRange">Date range</label>
                <select class="rounded-0 inp form-select dropdown responsive float-end"
                    [(ngModel)]="savedClaimsHistoryViewModal.selectedDate">
                    <option *ngFor="let option of savedClaimsHistoryViewModal.dateRange | keyvalue"
                        [innerHtml]="option.value" [value]="option.key"></option>
                </select>
            </div>
        </div>
        <div class="control reloadclaimhistory pt-4">
                <input type="text" class="form-control rounded-0 inp float-start"
                    [(ngModel)]="savedClaimsHistoryViewModal.searchText" />
                <button class="btn btn-primary rounded-pill" (click)="filterHistory()">Search</button>
        </div>
    </div>
    <div class="card-footer" *ngIf="savedClaimsHistoryViewModal.selectedDate==5">
        <div class="control float-start" *ngIf="savedClaimsHistoryViewModal.blnShowFromDate">
            <label for="dateSearch" id="dateSearch">Search by date:</label>&nbsp;
            <datefield #FromDate [validationMode]="savedClaimsHistoryViewModal.fromDateValidationMode"
                [content]="savedClaimsHistoryViewModal.fromDateValue"
                (onUpdate)="savedClaimsHistoryViewModal.onFromDateUpdate($event)"
                [emptyValid]="!savedClaimsHistoryViewModal.blnCustomDateFormSubmitted"
                [toDateComponent]="ToDateComponent" [placeHolder]="savedClaimsHistoryViewModal.dateFormatPlaceHolder">
            </datefield>
        </div>
        <div class="control float-start">
            <label for="todateSearch" id="todateSearch">To</label>&nbsp;
            <datefield #ToDate [validationMode]="savedClaimsHistoryViewModal.toDateValidationMode"
                [content]="savedClaimsHistoryViewModal.toDateValue"
                (onUpdate)="savedClaimsHistoryViewModal.onToDateUpdate($event)"
                [emptyValid]="!savedClaimsHistoryViewModal.blnCustomDateFormSubmitted"
                [placeHolder]="savedClaimsHistoryViewModal.dateFormatPlaceHolder"></datefield>
        </div>
        <div class="control float-start">
            <button class="btn btn-secondary" (click)="filterHistory()">Update</button>
        </div>
    </div>
    <div class="alert alert-danger" *ngIf="blnServerError && pageType==4">
        <div class="float-start">
            An error occured. Please try again later.
        </div>
        <button class="btn btn-danger float-end" (click)="retryOperation()">Try again</button>
    </div>
    <div class="card-content">
        <div class="text-center" *ngIf="blnLoading">
            <loader></loader>
        </div>
        <ng-container *ngIf="!blnLoading">
            <datatable [headers]="savedClaimHeaders" [rows]="savedClaimHistory"
                (onCellClicked)="onSavedClaimSelected($event)" [type]="datatableType" aria-labelledby="claimdetails">
                <!-- (onHeaderClicked)="onHeaderClick($event)" (onCellClicked)="onCellClick($event)" -->
            </datatable>
            <div class="text-center" *ngIf="totalEntries>1">
                <pagination aria-label="Saved Claims pagination"  [totalEntries]="totalEntries" [totalPages]="totalPages"
                    (onChangeEntriesPerPage)="onChangeEntriesPerPage($event)" (onPageChange)="onPageChange($event)">
                </pagination>
            </div>
            <p class="large noresults" [hidden]="savedClaimHistory.length>0">
                <span *ngIf="pageType==2">There are no saved trial claims found.</span>
                <span *ngIf="pageType==4">There are no bookmarked claims.</span>
                <span *ngIf="pageType==3">There are no submitted claims.</span>
            </p>
        </ng-container>
    </div>
</div>

<ng-template #removeMedicationConfirmPopup let-modal>
    <div class="p-3" aria-modal="true" aria-labelledby="removeMedicationConfirmPopup" role="dialog">
        <div class="row text-center"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></div>
        <div class="text-center mt-2">
            Are you sure you want to remove this medication? This action can’t be undone.
        </div>
        <div class="row border-0 mt-4 p-3 ">
            <div class="col-6 position-absolute top-80 start-50 translate-middle">
                <button class="col-5 btn btn btn-secondary" (click)="modal.dismiss()">Cancel</button>
                <button class="col-5 offset-1 btn btn btn-primary"
                    (click)="removeMedicationConfirm(this.itemToBeDeleted,true)">Proceed</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #removeTransactionConfirmPopup let-modal>
    <div class="p-3" aria-modal="true" aria-labelledby="removeTransactionConfirmPopup" role="dialog">
        <div class="row text-center"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></div>
        <div class="text-center mt-2">
            Are you sure you want to remove this entire transaction? This action can’t be undone.
        </div>
        <div class="row border-0 mt-4 p-3 ">
            <div class="col-6 position-absolute top-80 start-50 translate-middle">
                <button class="col-5 btn btn btn-secondary" (click)="modal.dismiss()">Cancel</button>
                <button class="col-5 offset-1 btn btn btn-primary" (click)="removeTransactionConfirm()">Proceed</button>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #errorPopup let-modal>
    <div class="p-3" aria-modal="true" aria-labelledby="errorPopup" role="dialog">
        <div class="row text-center">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        </div>
        <div class="text-center mt-2">
                        An error occured. Please try again.
        </div>
        <div class="row border-0 mt-4 p-3 ">
            <button class="col-2  position-absolute top-80 start-50 translate-middle btn btn btn-primary"
                (click)="modal.dismiss()">OK</button>
        </div>
    </div>
</ng-template>

<!-- Loading.... -->
<ng-template #LoadingContent let-modal>
    <div class="modal-body" aria-modal="true" aria-labelledby="LoadingContent" role="dialog" aria-hidden="true">
        <h2 class="text-center text-capitalize" tabindex="-1">Loading...</h2>
    </div>
</ng-template>

<ng-template #RefreshStatus let-modal>
    <div class="refresh-success-dialogue text-center" *ngIf="this.refreshStatusTxt!=''" aria-modal="true"
        aria-labelledby="RefreshStatus" role="dialog" aria-hidden="true">
        <p style="color: #1F4E83;" [innerHtml]="this.refreshStatusTxt"></p>
    </div>
</ng-template>