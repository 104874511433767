import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse, HttpHeaders, HttpParamsOptions } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {map} from 'rxjs/operators';

import { ProfileService } from './../profile/profile.service';
import { LogLevel, LogLevel_Label } from './../../constants/loglevel';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  level: LogLevel = LogLevel.INFO;

  publishers:LogPublisher[]=[];
  
  constructor(http: HttpClient,
    private profileService:ProfileService,
    private state: Router) { 
    this.publishers.push(new LogConsole());
    this.publishers.push(new LogWebApi(http));
  }

  login(){
    this.writeToLog("", LogLevel.Login);
  }

  userAction(actionName:string, ...optionalParams:string[]){
    this.writeToLog(actionName, LogLevel.USER_ACTION)
  }

  error(errorMessage:string, ...optionalParams:string[]){
    this.writeToLog(errorMessage, LogLevel.ERROR);
  }

  log(logMessage:string, ...optionalParams:string[]){
    this.writeToLog(logMessage, LogLevel.INFO);
  }

  private writeToLog(msg: string, level: LogLevel, params: string[]=[]) {
    if(level!=LogLevel.Off){
      let entry: LogEntry = new LogEntry();
      entry.eventOnPage = this.state.url;
      entry.eventType = LogLevel_Label.get(level)||"";
      if(this.profileService.loginuser?.UUID!=undefined)
        entry.uuid = this.profileService.loginuser?.UUID;
      else
        entry.uuid = "";
      let remarks:string[]=[msg];
      if(typeof params!=undefined)
        remarks.concat(params);
      
      entry.remarks = entry.buildLogString(remarks);
      
      this.publishers.forEach( (publisher: LogPublisher) => {
          if(publisher instanceof LogConsole && level<LogLevel.USER_ACTION){
              if(environment.production){
                if(level==LogLevel.ERROR){
                  publisher.log(entry).subscribe((response:any)=>{
                  },(error:any)=>{
                  });
                }
              } else {
                publisher.log(entry).subscribe((response:any)=>{
                },(error:any)=>{
                });
              }
          }
          if(publisher instanceof LogWebApi && level>LogLevel.INFO){
              publisher.log(entry).subscribe((response:any)=>{
              },(error:any)=>{
              });
          }
      });
    }
  }
}

class LogEntry{
   uuid:string="";
   eventOnPage:string="";
   eventType:string="";
   remarks:string="";

  buildLogString(strmessages:string[]): string {
    let ret: string = "Event details:";
    if (strmessages.length) {
        ret += this.formatParams(strmessages);
    }    
    return ret;
  }

  private formatParams(params: string[]): string {
    let ret: string = params.join(",");    
    // Is there at least one object in the array?
    if (params.some(p => typeof p == "object")) {
        ret = "";        
        // Build comma-delimited string
        for (let item of params) {
            ret += JSON.stringify(item) + ",";
        }
        ret=ret.substring(0,ret.length-1);
    }    
    return ret;
  }
}

abstract class LogPublisher {
  location: string="";
  abstract log(record: LogEntry): Observable<any>
  abstract clear(): Observable<boolean>;
}

class LogConsole extends LogPublisher {
  constructor(){
    super();
    this.location="console";
  }
  log(entry: LogEntry): Observable<boolean> {
      // Log to console
      //console.log(entry.remarks);
      return of(true);
  }
  
  clear(): Observable<boolean> {
      console.clear();
      return of(true);
  }
}

class LogWebApi extends LogPublisher {
  constructor(private http: HttpClient) {
      // Must call `super()`from derived classes
      super();
      
      // Set location
      this.location = environment.eventlogUrl;
  }
  
  // Add log entry to back end data store
  log(entry: LogEntry): Observable<Object> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.location, entry, {headers:headers});
  }
  
  // Clear all log entries from local storage
  clear(): Observable<boolean> {
      // TODO: Call Web API to clear all values
      return of(true);
  }
  
  private handleErrors(error: any): Observable<any> {
      let errors: string[] = [];
      let msg: string = "";
      
      msg = "Status: " + error.status;
      msg += " - Status Text: " + error.statusText;
      if (error.json()) {
          msg += " - Exception on posting event to server: " + error.json().exceptionMessage;
      }
      errors.push(msg);
      
      console.error('An error occurred', errors);
      return of(false);
      
  }
}