export enum PAGE_TYPE{
    SAVEDPAS=0,
    SUBMITTEDPAS=1,
    SAVEDCLAIMS=2,
    SUBMITTEDCLAIMS=3,
    BOOKMARKCLAIMS=4,
    PAIDTRIALCLAIMS=5
}
export class ViewAllRouteState{
    pageType!:PAGE_TYPE
}