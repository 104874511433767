<div class="container-fluid bg-white content-card">
    <div class="row">
        <div class="col card-header">
            <h3 id="savedMembers" class="float-start">Saved members</h3>
            <button class="btn-close float-end" aria-label="close" (click)="dismiss()">
            </button>
        </div>
    </div>
    <div class="row" *ngIf="favouriteActionError==1">
        <div class="col nopadding">
            <div class="alert" style="background-color:#fff4f4; border-bottom: 2px solid #c60f13" >
                <i class="fa fa-exclamation-circle" aria-hidden="true" style="color: #c60f13; padding-bottom: 5px;"></i>
                <label style="color: #222222;"> We were unable to retrieve this information </label>
                <a class="float-end" enableAction="true" (click)="fetchSavedMembers()" tabindex="0">Try again</a>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="favouriteActionError==2">
        <div class="col nopadding">
            <div class="alert alert-danger">
                An error occured. Please try again later.
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col p-4">
            <datatable [headers]="headers" [rows]="entries"  (onHeaderClicked)="onHeaderClicked($event)" (onCellClicked)="select($event)" aria-labelledby="savedMembers">
            </datatable>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col text-center" *ngIf="totalEntries>1">
            <pagination [totalEntries]="totalEntries" [totalPages]="totalPagesCount" (onChangeEntriesPerPage)="onChangeEntriesPerPage($event)" (onPageChange)="onPageChange($event)"></pagination>
        </div>
    </div> -->
</div>


<ng-template #memberNameTemplate let-data="data">
    <div *ngIf="data.isMedD" class="medicareInd" aria-labelledby="memberNameTemplate">Medicare</div>
    <div class="memberData" aria-labelledby="memberNameTemplate">
        <span [innerHtml]="data.lastName" class="text-capitalize fontbold"></span>
        <br/>
        <span [innerHtml]="data.firstName" class="text-capitalize"></span>
    </div>
</ng-template>

<ng-template #memberAddressTemplate let-data="data">
    <div class="memberData" aria-labelledby="memberAddressTemplate">
        {{data.address1}}&nbsp;{{data.address2}}
    </div>
</ng-template>

<ng-template #memberIdTemplate let-data="data">
    <div class="memberData" aria-labelledby="memberIdTemplate">
        {{data.memberId}}
    </div>
</ng-template>

<ng-template #memberActionTemplate let-data="data">
    <div class="memberData" aria-labelledby="memberActionTemplate">
        <a class="large underline text-danger" name="remove" style="font-weight: 700;" enableAction="true" tabindex="0">Remove</a>
    </div>
</ng-template>
<ng-template #memberSelectSymbolTemplate let-data="data">
    <a class="btn invisiblebtn btn-link" aria-labelledby="memberSelectSymbolTemplate" tabindex="0">
        <i class="fa fa-angle-right"></i>
    </a>
</ng-template>

<ng-template #noSavedMembersTemplate let-data="data">
    <p class="large" aria-labelledby="noSavedMembersTemplate">No saved members.</p>
</ng-template>