import { AfterViewInit, Component, ElementRef, OnInit, TemplateRef, ViewChild, OnDestroy, HostListener } from '@angular/core';

import { interval, Observable, Subscription, } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { NgbModal, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { TableCellData, TableClickEvent, TableHeaderCellData, TableRowData } from 'src/app/modals/datatable/datatable';
import { Router } from '@angular/router';
import { CheckMedicationCoverageService } from 'src/app/services/drugSearch/check-medication-coverage.service';
import { MembersearchService } from 'src/app/services/membersearch/membersearch.service';
import { PrescriberSearchService } from 'src/app/services/prescriberSearch/prescriber-search.service';
import { DUR_LABEL, DRUG_QUALIFIER, CLAIM_STATUS_TYPE, COVERAGE_MESSAGE_TYPE } from 'src/app/constants/appconstant';
import { Drugsearchwebrequest, Diagnosissearchwebrequest, FavoriteDrugsDetailResponse, AddFavRootObject, MemberSearchItem, RemoveFavRootObject, MedicationPrescribed, AddMedicationPrescribed, DrugCoded } from 'src/app/modals/drugSearch/drugsearchwebrequest';
import { environment } from 'src/environments/environment';
import { Drugsearchwebresponse, DiagnosisCodeSearchResponses, FavDrugStatusResponse, SmapProductInfoResponse, SpecialityProductResponse, DiagnosisCodeSearchItem, FavoriteDrugsDetail, DrugSearchItem, SpecialityProduct, SelectedMedicine } from 'src/app/modals/drugSearch/drugsearchwebresponse';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { Loginuser } from 'src/app/modals/login/loginviewmodal';
import { DrugsearchService } from 'src/app/services/drugSearch/drugsearch.service';
import { CheckCoveragePageService } from 'src/app/services/drugSearch/check-coverage-page.service';
import { Trailadjudicationwebresponse } from 'src/app/modals/drugSearch/Trailadjudicationwebresponse';
import { Trailadjudicationwebrequest, QtyCalculationOption1, QtyCalculationOption2, DurSegment } from 'src/app/modals/drugSearch/Trailadjudicationwebrequest';
import { APPCONSTANTS, USER_ID_TYPE_Label } from 'src/app/constants/appconstant';
import { USER_ROLE_Label, USER_ROLE } from 'src/app/constants/appconstant';
import { UsermenuComponent } from '../usermenu/usermenu.component';
import { MemberDetailWebResponse, Status } from 'src/app/modals/membersearch/memberdetailwebresponse';
import { MemberDetailwebrequest } from 'src/app/modals/membersearch/memberdetailwebrequest';
import { PrescriberSearchItem } from 'src/app/modals/prescriberSearch/prescriberSearchwebresponse';
import { CalculateQuantityService } from 'src/app/services/Quantity/calculate-quantity.service';
import { fade, listAnimation, slideDown } from 'src/animations/animations';
import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DateFieldResultType, DateFieldValidationMode, DateFieldEvent } from 'src/app/modals/datefield/datefield';
import { MemberSearchV5Item } from 'src/app/modals/membersearch/membersearchwebresponse';
import { throws } from 'assert';
import { ClaimUpdateWebRequest } from 'src/app/modals/claimhistory/claimhistorywebrequest';
import { ClaimhistoryService } from 'src/app/services/claimhistory/claimhistory.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { Infusion } from 'src/app/modals/login/loginwebresponse';
import { Title } from '@angular/platform-browser';
import { debug } from 'console';

@Component({
  selector: 'app-select-medication',
  templateUrl: './select-medication.component.html',
  styleUrls: ['./select-medication.component.css'],
  providers: [NgbTypeaheadConfig],
  animations: [
    listAnimation,
    fade,
    slideDown
  ]
})
export class SelectMedicationComponent implements OnInit, AfterViewInit, OnDestroy {

  // private productbyInfusionSubscription!: any;

  dropdown: DrugSearchItem[] = [];
  uniqueArr!: string[];
  qtyPageToggle: boolean = false;
  currentSelectedDrug!: DrugSearchItem[];
  productList: SpecialityProduct[] = [];
  productListByInfsn !: SpecialityProduct[];
  recentSearches: DrugSearchItem[] = new Array();
  savedSearches!: DrugSearchItem[];
  savedSearchesDrugCodes: string[] = new Array();
  submitedValidAddMedicationForm = true;
  submitedValidClaimList = true;

  searchFailed = false;
  searching = false;
  searchingDiagnosisCode = false;
  formLoading = false;

  diagnosisList!: DiagnosisCodeSearchItem[];
  diagnosisCodeList!: string[];
  diagnosisSearchValue = "";

  selectedMedicine: SelectedMedicine[] = [];
  selectedDrugItems: DrugSearchItem[] = [];
  claimGroupSize = 5;

  diagnosisCodeChecked: string | undefined = "";
  searchDiagnosisClicked = false;
  errorText = "";
  quantityCalculated = "";
  quantityCalculate!: number;
  quantityDesc = "";
  quantityEntered = "";
  drugNameNDC = "";
  drugQuestions: string = 'N';
  patienceResidence!: string;
  userIdtype!: string;
  rolename!: string;
  payOption = -1;
  searchByNDC = "";
  cacheNDC = "";
  errorPopupText: string = COVERAGE_MESSAGE_TYPE.COVERAGE_ERROR;

  // For adding delay in Check converage porgress Bar
  subscription: Subscription = new Subscription();
  counter = 1;


  // Titles 
  medStrengthTitle = "";

  // For Date Component integration
  dosValue: string = "";
  dosResult: DateFieldResultType = DateFieldResultType.EMPTY;
  dosValidationMode: DateFieldValidationMode = DateFieldValidationMode.ONBLUR;
  dateFieldName: string = "Date";
  emptyDOS = true;
  onDobUpdate(event: DateFieldEvent): void {
    this.dosValue = event.value;
    this.dosResult = event.valid;
  }
  // End of Date Component integration

  @ViewChild("errorPopup", { static: true })
  errorPopupTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("errorContent")
  content?: ElementRef<HTMLElement>;

  @ViewChild("claimSummaryProgress")
  claimSummaryProgress?: ElementRef<HTMLElement>;


  @ViewChild("LoadingContent")
  loadingContent?: ElementRef<HTMLElement>;

  @ViewChild("LimitExceeded")
  limitExceeded?: ElementRef<HTMLElement>;

  @ViewChild("recentSearches")
  recentSearch?: ElementRef<HTMLElement>;

  @ViewChild("savedSearches")
  savedSearch?: ElementRef<HTMLElement>;

  @ViewChild("diagnosisCode")
  diagnosisCode?: ElementRef<HTMLElement>;

  // Form for adding claim details
  addMedicationForm = new FormGroup({
    drugSearchModel: new FormControl('', { validators: [Validators.required, Validators.minLength(3)], updateOn: 'blur' }),
    MedStrength: new FormControl('', { validators: [Validators.required] }),
    Quantity: new FormControl('', { validators: [Validators.required, Validators.pattern('[0-9]+$'), Validators.maxLength(11), Validators.min(1)] }),
    Days: new FormControl('', { validators: [Validators.required, Validators.pattern('[0-9]+$'), Validators.maxLength(3), Validators.min(1)] }),
    DrugAdmin: new FormControl('', { validators: [Validators.required] }),
    DiagnosisCode: new FormControl('', { validators: [Validators.required, Validators.minLength(3)], updateOn: 'blur' }),
  })

  // Getters for formControls
  get drugSearchModel() {
    return this.addMedicationForm.get('drugSearchModel')
  }

  get Quantity() {
    return this.addMedicationForm.get('Quantity')
  }

  get Days() {
    return this.addMedicationForm.get('Days')
  }

  get MedStrength() {
    return this.addMedicationForm.get('MedStrength')
  }

  get DiagnosisCode() {
    return this.addMedicationForm.get('DiagnosisCode')
  }

  get DrugAdmin() {
    return this.addMedicationForm.get('DrugAdmin')
  }

  get placeOfService() {
    return this.checkCoverage.get('placeOfService') as FormControl;
  }

  checkCoverage = new FormGroup({
    placeOfService: new FormControl('', { validators: [Validators.required], updateOn: 'blur' })
  });


  //Update claim list formGroup Array Functionality
  form = this.fb.group({
    claimsList: this.fb.array([])
  });

  //Getters for Claim list formControls Array 
  get claimsList() {
    return this.form.controls["claimsList"] as FormArray;
  }

  //End of Update Functionality


  // Diagnosis Desciption Serach form
  diagnosisForm = this.fb.group({
    diagnosisDescription: new FormControl('', { validators: [Validators.required, Validators.minLength(2)], updateOn: 'blur' })
  });

  //Getters for Diagnosis Desciption Serach 
  get diagnosisDescription() {
    return this.diagnosisForm.get('diagnosisDescription');
  }
  // End Diagnosis Desciption

  constructor(private titleService: Title, private trailclaimadjudicationHelper: CheckCoveragePageService,
    public profileService: ProfileService,
    private drugSearchHelper: DrugsearchService,
    config: NgbTypeaheadConfig,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private router: Router,
    private quantityService: CalculateQuantityService,
    private memberHelper: MembersearchService,
    private prescriberSearch: PrescriberSearchService,
    private claimHistoryService: ClaimhistoryService,
    private logger: LoggerService) {
    this.titleService.setTitle("SMAP - Check Prescription Coverage - Select Medication");
    // customize default values of typeaheads used by this component tree
    config.showHint = false;
    config.editable = false;

    //Saved and Recent Searches headers for DataTable
    this.firstHeader.content = "Product ID";
    this.firstHeader.sortable = true;

    this.secondHeader.content = "Medication name";
    this.thirdHeader.content = "Action";
    this.thirdHeader.cssClass="tablesmallheader25";

    this.savedSearchHeaders = [];
    this.savedSearchHeaders.push(this.secondHeader);
    this.savedSearchHeaders.push(this.thirdHeader);

    this.savedSearchHistory = [];

    this.recentSearchHeaders = [];
    this.recentSearchHeaders.push(this.firstHeader);
    this.recentSearchHeaders.push(this.secondHeader);
    this.recentSearchHeaders.push(this.thirdHeader);

    this.recentSearchHistory = [];

    //Diagnosis keyword Search headers for DataTable
    this.diagnosisFirstHeader.content = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
    this.diagnosisFirstHeader.clickable = false;
    this.diagnosisSecondHeader.content = "Diagnosis Code";
    this.diagnosisThirdHeader.content = "Diagnosis Description";

    this.diagnosisSearchHeaders.push(this.diagnosisFirstHeader);
    this.diagnosisSearchHeaders.push(this.diagnosisSecondHeader);
    this.diagnosisSearchHeaders.push(this.diagnosisThirdHeader);

    this.diagnosisSearchHistory = [];

  }
  ngAfterViewInit(): void {

    this.modalService.dismissAll();
  }

  selectedMember!: MemberSearchItem;
  selectedPrescriber: PrescriberSearchItem | undefined;

  // Loading the NDC values on Page load
  ngOnInit(): void {
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);
    //console.log("Rerouting URL:" + sessionStorage.getItem("lastPageBeforeReload"));
    //  sessionStorage.setItem('prescribersearchresults',JSON.stringify(prescriber));

    if (sessionStorage.getItem('selectedMedicineList') != undefined) {

      let selectedMed = JSON.parse("" + sessionStorage.getItem('selectedMedicineList'));

      if (selectedMed[0].submitClaimResponseLstClaim != undefined && selectedMed[0].submitClaimResponseLstClaim.claimStsTypId != undefined) {
        //console.log("removed");
        sessionStorage.removeItem('selectedMedicineList');
      } else {
        this.selectedMedicine = JSON.parse("" + sessionStorage.getItem('selectedMedicineList'));
        //console.log('Test selectedMedicineList:');
        //console.log(this.selectedMedicine);
        this.selectedMedicine.forEach((item: SelectedMedicine) => {
          this.selectedDrugItems.push(item.drugSearchItem);
          const claimForm = this.fb.group({
            Quantity: new FormControl(item.Quantity, { validators: [Validators.required, Validators.pattern('[0-9]+$'), Validators.maxLength(7), Validators.min(1)] }),
            Days: new FormControl(item.Days, { validators: [Validators.required, Validators.pattern('[0-9]+$'), Validators.maxLength(3), Validators.min(1)] }),
            DrugAdmin: new FormControl(item.DrugAdmin, { validators: [Validators.required] }),
            DiagnosisCode: new FormControl(item.DiagnosisCode, { validators: [Validators.required, Validators.min(3)], updateOn: 'blur' })
          });
          this.claimsList.push(claimForm);
        });
      }

    }
    const member: MemberSearchV5Item | undefined = this.memberHelper.getSelectedMember();
    if (member != undefined) {
      this.selectedMember = member;
      this.selectedPrescriber = JSON.parse("" + sessionStorage.getItem('selectedPrescriber'));
      //console.log(JSON.parse("" + sessionStorage.getItem('selectedPrescriber')));
      //console.log(JSON.parse("" + sessionStorage.getItem('prescribersearchresults')));
      this.selectedPrescriber = this.prescriberSearch.readSelectedPrescriber();
      //console.log("NPI" + this.selectedPrescriber);
      this.modalService.dismissAll();
      this.searching = true;
      this.drugSearchModel?.disable();
      this.MedStrength?.disable();
      let request: MemberSearchItem = new MemberSearchItem();
      request.carrierId = this.selectedMember.carrierId;
      request.accountId = this.selectedMember.activeInd;
      request.groupId = this.selectedMember.groupId;

      this.drugSearchHelper.getProductList(request).then((response: SpecialityProductResponse) => {
        this.productList = response.smapProductInfoResponse.specialityProducts;
        this.uniqueArr = [...new Set(this.productList.map(data => data.displayName))].concat([...new Set(this.productList.map(data => data.ndc))]);
        this.searching = false;
        this.drugSearchModel?.enable();
        this.drugQuestions = response.cagValidationResponse.isCagValid;
        // console.log("questions", this.drugQuestions);
      }, (error: any) => {
        this.searching = false;
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      });

      // this.drugSearchHelper.getProductListByInfsn('117').then((data: SmapProductInfoResponse) => {
      //   //console.log("Product List By Infsn Id:" + data.specialityProducts);
      //   this.productListByInfsn = data.specialityProducts;
      // }, (error: any) => {
      //   this.modalService.dismissAll();
      //   this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      // });

      // this.productbyInfusionSubscription = this.profileService.loginuser.selectedInfusion.subscribe((selectedInfusion: Infusion) => {
        if (JSON.parse(""+sessionStorage.getItem('SelectedInfsnId')) != undefined) {
          let selectedInfusion=JSON.parse(""+sessionStorage.getItem('SelectedInfsnId'));
          this.productListByInfsn=[];
          this.drugSearchHelper.getProductListByInfsn(selectedInfusion.infsnId).then((data: SmapProductInfoResponse) => {
            // console.log("Product List By Infsn Id:" + data.specialityProducts);
            // debugger;
            this.productListByInfsn = data.specialityProducts||[];
          }, (error: any) => {
            //Error not required. Just Default selected of drug administration as per selected drug will not work in UI.
            //user still be able to select drug administration from drop down on UI.
          }).catch((reason: any) => {
            //Error not required. Just Default selected of drug administration as per selected drug will not work in UI.
            //user still be able to select drug administration from drop down on UI.
          });
        }
      // });
      //Fetching Inital Diagnosis Code
      let webrequest: Diagnosissearchwebrequest = new Diagnosissearchwebrequest();
      webrequest.diagnosisCode = 'A00';
      webrequest.diagnosisDescription = "";
      //console.log(webrequest.diagnosisCode);
      this.drugSearchHelper.diagnosisCodeSearch(webrequest).then((response: DiagnosisCodeSearchResponses) => {
        this.diagnosisList = response.diagnosisSearchResult.diagnosisCodeSearchResponse.diagnosisCodeSearchItems;
        this.diagnosisCodeList = this.diagnosisList.map((data: DiagnosisCodeSearchItem) => data.diagnosisCodeDetailedName = data.diagnosisCode.trim() + " " + data.diagnosisDescription.trim());
      }, (error: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      }).catch((reason: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      });


      //Load Saved Searches
      this.recentSearchHistory = [];
      this.reloadSavedSearches();
    }
  }

  // To update Medication Strength dropdown on selecting NDC 
  onChangeNDC(value: string = "") {
    this.searchByNDC = "";
    this.medStrengthTitle = "";
    this.drugSearchModel?.setErrors(null);
    //console.log("Selected:" + value);

    if (value != '') {
      this.drugSearchModel?.setValue(value);
    }
    this.MedStrength?.setValue("");
    if (this.drugSearchModel?.invalid) {
      this.MedStrength?.reset();
      this.MedStrength?.disable();

      this.dropdown = [];
      //console.log("Invalid Value:" + value)
    }
    else if (this.drugSearchModel?.valid == true) {
      this.cacheNDC = ""+this.drugSearchModel.value;
      //console.log("Valid Value:" + this.addMedicationForm.get('drugSearchModel')?.value)
      if (this.payOption == -1) {
        // debugger;
        this.productListByInfsn.forEach((item: SpecialityProduct) => {
          if (item.ndc == this.drugSearchModel?.value) {
            this.DrugAdmin?.setValue(""+DUR_LABEL.get(item.drgAdmin));
            //console.log("Test drgAdmin:" + item.drgAdmin + " ::" + "" + this.DrugAdmin?.value);
          }
        });
      }

      this.MedStrength?.reset();
      this.qtyPageToggle = false;
      if (this.cacheNDC != this.drugSearchModel.value) {
        this.formLoading = true;
      }
      //console.log(this.drugSearchModel);
      let webrequest: Drugsearchwebrequest = new Drugsearchwebrequest();
      var drugBrand!: any;

      drugBrand = this.productList.find(a => (a.displayName == this.addMedicationForm.get('drugSearchModel')?.value) || (a.ndc == this.addMedicationForm.get('drugSearchModel')?.value))?.brandName;

      let request: Drugsearchwebrequest = new Drugsearchwebrequest();
      request.drugName = drugBrand;
      request.instanceId = environment.instanceId;
      this.drugSearchHelper.getDrugSearchItems(request).then((response: Drugsearchwebresponse) => {
        if (response.status.respCode == '200') {
          //console.log("Item  found:" + value);
          this.dropdown = response.response.drugSearchItems;
          this.formLoading = false;
          this.MedStrength?.enable();
          this.MedStrength?.setValue("");
          if (drugBrand == undefined || this.drugSearchModel?.errors) {
            this.dropdown = [];
            this.MedStrength?.reset();
            this.MedStrength?.disable();
          } else if (this.dropdown.length == 1) {
            this.MedStrength?.disable();
            this.MedStrength?.setValue(this.dropdown[0].productId);
            this.onChangeMediactionStrenth();
          } else {
            this.dropdown.forEach((item: DrugSearchItem) => {
              if (item.productId == this.drugSearchModel?.value) {
                this.MedStrength?.disable();
                this.MedStrength?.setValue(item.productId);
                this.onChangeMediactionStrenth();
                this.searchByNDC = item.detailedDrugName;
              }
            });
          }
        } else {
          //console.log("Not  found:");
          this.drugSearchModel?.setErrors({ 'incorrect': true });
          this.formLoading = false;
          this.dropdown = [];
          this.MedStrength?.reset();
          this.MedStrength?.disable();
        }
      }, (error: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      }).catch((reason: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      });
    } else {
      // console.log("Gone to else part:" + value);
    }
    return this.dropdown;
  }

  // To update form details on selecting Medication Strength
  onChangeMediactionStrenth(productID: string = "") {
    if (productID == "") {
      productID = ""+this.addMedicationForm.get('MedStrength')?.value;
    }
    //console.log("MedStrength:" + productID);
    if (productID == "") {
      //console.log("default" + productID);
      this.qtyPageToggle = false;
      this.currentSelectedDrug = [];
    } else if (productID != "") {
      this.formLoading = true;
      this.modalService.open(this.loadingContent, { modalDialogClass: 'content' });

      let request: Drugsearchwebrequest = new Drugsearchwebrequest();
      request.ndc = productID;
      request.instanceId = environment.instanceId;
      this.drugSearchHelper.getDrugSearchItems(request).then((response: Drugsearchwebresponse) => {
        //console.log("Selected ITem:" + response);
        this.currentSelectedDrug = response.response.drugSearchItems;
        this.medStrengthTitle = this.currentSelectedDrug[0].detailedDrugName;
        this.formLoading = false;
        this.MedStrength?.enable();
        this.qtyPageToggle = true;

        // Adding unique items to recent seraches array
        if (this.recentSearches.length == 0) {
          this.recentSearches.push(this.currentSelectedDrug[0]);
        } else if (this.recentSearches.map((drugItem) => drugItem.productId).indexOf(this.currentSelectedDrug[0].productId) == -1) {
          this.recentSearches.push(this.currentSelectedDrug[0]);
        }
        // End of Adding unique items to recent seraches array

        //console.log("Recent:" + JSON.stringify(this.recentSearches.length));
        this.modalService.dismissAll();
        productID = "";
      }, (error: any) => {
        //debugger;
        this.currentSelectedDrug = this.dropdown.filter((data: DrugSearchItem) => data.productId == productID);
        this.medStrengthTitle = this.currentSelectedDrug[0].detailedDrugName;
        this.formLoading = false;
        this.MedStrength?.enable();
        this.qtyPageToggle = true;

        // Adding unique items to recent seraches array
        if (this.recentSearches.length == 0) {
          this.recentSearches.push(this.currentSelectedDrug[0]);
        } else if (this.recentSearches.map((drugItem) => drugItem.productId).indexOf(this.currentSelectedDrug[0].productId) == -1) {
          this.recentSearches.push(this.currentSelectedDrug[0]);
        }
        // End of Adding unique items to recent seraches array

        // console.log("Recent:" + JSON.stringify(this.recentSearches.length));
        this.modalService.dismissAll();
        productID = "";
      }).catch((reason: any) => {
        //debugger;
        productID = "";
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      });
      //  //console.log("Detailed Drug Name" + this.currentSelectedDrug[0].detailedDrugName);
      //console.log(this.currentSelectedDrug);
      //console.log("blank" + productID);
    }
  }

  itemSelected(event: any) {
    //console.log("Selected Event" + JSON.stringify(event));
    this.drugSearchModel?.patchValue(event.item);
    this.onChangeNDC(event.item);
  }
  // for seraching NDC and showing dropdown
  searchNDC = (text$: any) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term: string) => term.length < 3 ? []
        : this.uniqueArr.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    );

  // for seraching Diagnosis Code and showing dropdown
  diagnosisSearch = (text$: any) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term: string) => term.length < 3 ? []
        : this.diagnosisCodeList.filter(v => v.toLowerCase().startsWith(term.toLocaleLowerCase())).splice(0, 10))
    );

  //Fetcing Diagnosis Code if there is any change in the input field, minLength 3
  onChangeDiagnosisInput(event: any, value: string) {
    this.searchFailed = false;
    if (value.indexOf(' ') != -1)
      value = value.substring(0, value.indexOf(' '));

    //console.log(event);
    if (this.diagnosisSearchValue != value && value.length > 1) {
      //console.log("New :" + value + ",Old:" + this.diagnosisSearchValue);
      //console.log("changed");
      this.diagnosisSearchValue = value;
      // Value changed so hitting the API
      this.searchingDiagnosisCode = true;
      //console.log(event + "\n Value2:" + value);
      let webrequest: Diagnosissearchwebrequest = new Diagnosissearchwebrequest();
      webrequest.diagnosisCode = value;
      webrequest.diagnosisDescription = "";
      this.drugSearchHelper.diagnosisCodeSearch(webrequest).then((response: DiagnosisCodeSearchResponses) => {
        if (response.diagnosisSearchResult.message.responseCode == "200") {
          this.diagnosisList = response.diagnosisSearchResult.diagnosisCodeSearchResponse.diagnosisCodeSearchItems;
          this.diagnosisCodeList = this.diagnosisList.map((data: DiagnosisCodeSearchItem) => data.diagnosisCodeDetailedName = data.diagnosisCode.trim() + " " + data.diagnosisDescription.trim());
          this.searchingDiagnosisCode = false;
          if (this.diagnosisCodeList.length == 1) {
            this.DiagnosisCode?.patchValue(this.diagnosisCodeList[0]);
          }
        } else {
          this.searchingDiagnosisCode = false;
          //console.log("FailedTestCOde:" + this.diagnosisCodeList);
          this.searchFailed = true;
        }
        //console.log("TestCOde:" + this.diagnosisCodeList);
      }, (error: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      }).catch((reason: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      });
    } else {
      //console.log("UnChanged");
      //console.log("New :" + value + ",Old:" + this.diagnosisSearchValue);
    }
  }

  // Input Validation section and adding the claim to list
  add() {
    this.logger.userAction("Add Medication Clicked.");
    if (this.addMedicationForm.invalid) {
      this.submitedValidAddMedicationForm = false;
    } else {
      this.submitedValidAddMedicationForm = true;
      //console.log("Error:" + this.errorText);
      console.warn(this.addMedicationForm.value);
      const med: SelectedMedicine = JSON.parse(JSON.stringify(this.addMedicationForm.value));
      med.MedStrengthName = this.currentSelectedDrug[0].detailedDrugName;

      if (this.selectedMedicine.length == this.claimGroupSize) {
        this.dropdown = [];
        this.qtyPageToggle = false;
        this.searchByNDC = "";
        this.errorText = "Only 5 medications can be entered on one transaction.";
        this.modalService.open(this.content, { modalDialogClass: 'content' });
        //console.log("Error:" + this.errorText);
      } else {

        if (this.selectedMedicine.filter(claim => claim.MedStrength == med.MedStrength).length < 1) {
          //Update  Functionality
          //console.log("Ssss");
          //console.log(this.selectedMedicine.filter(claim => claim.MedStrength == med.MedStrength).length);
          //console.log(this.selectedMedicine.filter(claim => claim.MedStrength == med.MedStrength));
          const claimForm = this.fb.group({
            Quantity: new FormControl(med.Quantity, { validators: [Validators.required, Validators.pattern('[0-9]+$'), Validators.maxLength(7), Validators.min(1)] }),
            Days: new FormControl(med.Days, { validators: [Validators.required, Validators.pattern('[0-9]+$'), Validators.maxLength(3), Validators.min(1)] }),
            DrugAdmin: new FormControl(med.DrugAdmin, { validators: [Validators.required] }),
            DiagnosisCode: new FormControl(med.DiagnosisCode, { validators: [Validators.required, Validators.min(3)], updateOn: 'blur' })
          });
          this.claimsList.push(claimForm);
          //End Update Functionality

          // med.claimForm=claimForm;
          med.drugSearchItem = this.currentSelectedDrug[0];
          med.selectedMember = this.selectedMember;
          if (this.selectedPrescriber != undefined)
            med.selectedPrescriber = this.selectedPrescriber;
          med.quantytyCalculate = this.quantityCalculate;
          med.quantytyDesc = this.quantityDesc;
          med.quantityEntered = this.quantityEntered;
          // med.DiagnosisCode=this.DiagnosisCode?.value();
          //console.log("Selected diagnosisList:" + JSON.stringify(this.diagnosisList));
          med.diagnosisCodeSearchItem = (this.diagnosisList.filter((data: DiagnosisCodeSearchItem) => data.diagnosisCodeDetailedName == med.DiagnosisCode)).pop();
          this.selectedMedicine.push(med);
          this.logger.log("Medication Added: " + JSON.stringify(med));

          this.selectedDrugItems.push(this.currentSelectedDrug[0]);
          //console.log("Added selected MedList:" + this.selectedMedicine);
          //console.log("Added selected DrugItemList:" + this.selectedDrugItems);

          //console.log("Selected Medications Array:" + JSON.stringify(this.selectedMedicine));
          this.dropdown = [];
          this.MedStrength?.disable();
          this.qtyPageToggle = false;
          // this.selectedDrugItems.push()
          this.searchByNDC = "";
          //console.log("Error:" + this.errorText);
        } else {
          this.searchByNDC = "";
          this.dropdown = [];
          this.qtyPageToggle = false;
          this.errorText = "Duplicate mediaction not allowed.";
          this.modalService.open(this.content, { modalDialogClass: 'content' });
          //console.log("Error:" + this.errorText);
        }
      }
      this.resetForm();
      // this.addMedicationForm.reset();
    }
  }


  // Remove items from the claim List of having max 5 calims at time
  remove(index: number) {

    this.logger.userAction("Remove Medication Clicked.");
    this.addMedicationForm.reset();
    //console.log(index);

    if (this.selectedMedicine[index] != undefined && this.selectedMedicine[index].claimDetailId != undefined && this.selectedMedicine[index].clmgroupId != undefined) {
      const webrequest: ClaimUpdateWebRequest = new ClaimUpdateWebRequest();
      let item = this.selectedMedicine[index];
      //console.log("item", item);
      webrequest.claimDetailId = item.saveClaimResponseLstClaim.claimDetailId;
      webrequest.claimStsTypId = CLAIM_STATUS_TYPE.REMOVED;
      //console.log("claimDetailId", webrequest.claimDetailId);
      this.claimHistoryService.updateClaimStatus(webrequest).then((response: Status) => {
        //console.log("Enetered into service");
        //console.log(response);
      });
    }


    this.claimsList.removeAt(index);
    this.selectedDrugItems = this.selectedDrugItems.filter((item) => item.productId != this.selectedMedicine[index].MedStrength);
    this.selectedMedicine = this.selectedMedicine.filter(claim => claim.MedStrength != this.selectedMedicine[index].MedStrength);
    //console.log("Updated selected MedList:" + this.selectedMedicine);
    //console.log("Updated selected MedList:" + this.selectedMedicine);
    //console.log("Updated selected DrugItemList:" + this.selectedDrugItems);
  }


  //Edit claim list
  editButtonList: string[] = new Array(5).fill("Edit");
  edit(index: number) {
    this.logger.userAction("Edit Medication Clicked.");
    if (this.editButtonList[index] == "Save") {
      //console.log("Saving.....:" + (this.claimsList.at(index) as FormGroup).get('Days')?.value);
      this.selectedMedicine[index].Quantity = this.claimsList.at(index).get('Quantity')?.value;
      this.selectedMedicine[index].Days = this.claimsList.at(index).get('Days')?.value;
      this.selectedMedicine[index].DrugAdmin = this.claimsList.at(index).get('DrugAdmin')?.value;
      this.selectedMedicine[index].DiagnosisCode = this.claimsList.at(index).get('DiagnosisCode')?.value;
      this.editButtonList.fill('Edit');
    } else {
      // this.addMedicationForm.reset();
      // this.updateIndex=index;
      //console.log("Test:" + index);
      this.editButtonList[index] = "Save";
      //console.log("Not Saving.....:" + this.claimsList.at(index).get('Days')?.value);
    }
  }

  //Close button
  resetForm() {
    this.addMedicationForm.reset();
    this.qtyPageToggle = false;
    this.dropdown = [];
    this.MedStrength?.reset();
    this.MedStrength?.disable();
    this.quantityCalculated = '';
    this.submitedValidAddMedicationForm = true;
  }

  // Show Saved Searches 
  showSavedSeraches() {
    this.logger.userAction("Opening Recent Searches.");
    this.savedSearchHistory = [];
    this.reloadSavedSearches();
    this.modalService.open(this.savedSearch, { modalDialogClass: 'content', size: 'lg',backdrop: 'static', keyboard: true });
  }

  // Show Recent Searches
  showRecentSeraches() {
    this.logger.userAction("Opening Recent Searches.");
    this.recentSearchHistory = [];
    this.loadRecentSearchHistory(this.recentSearches);
    this.modalService.open(this.recentSearch, { modalDialogClass: 'content', size: 'lg',backdrop: 'static', keyboard: true });
  }

  // Show Modal for Searching Diagnosis Keyword
  showDiagnosisCode() {
    this.modalService.open(this.diagnosisCode, { modalDialogClass: 'content', size: 'lg',backdrop: 'static', keyboard: true });
  }


  // Show diagnosis keyword serach table with pagination
  searchDiagnosisKeyword() {
    this.logger.userAction("Search Diagnosis Clicked.");
    this.searchDiagnosisClicked = true;
    this.noOfPages = 0;
    this.noOfRecords = 0;
    this.recordsDisplayedCount = "";
    this.currentPage = 1;
    this.diagnosisCodeChecked = "";
    this.diagnosisKeywordResposeObject = [];
    if (this.diagnosisDescription?.value != "") {
      this.modalService.open(this.loadingContent, { modalDialogClass: 'content' });
      let webrequest: Diagnosissearchwebrequest = new Diagnosissearchwebrequest();
      webrequest.diagnosisCode = "";
      webrequest.diagnosisDescription = ""+this.diagnosisDescription?.value;
      this.logger.log("Diagnosis Search:" + JSON.stringify(webrequest));
      this.drugSearchHelper.diagnosisDescriptionSearch(webrequest).then((data: DiagnosisCodeSearchResponses) => {
        if (data.diagnosisSearchResult.message.responseCode == '200') {
          this.diagnosisSearchHistory = [];
          this.diagnosisKeywordResposeObject = data.diagnosisSearchResult.diagnosisCodeSearchResponse.diagnosisCodeSearchItems.filter((data) => data.diagnosisQualifier == '02' && data.diagnosisType == '1');

          this.noOfRecords = this.diagnosisKeywordResposeObject.length;

          this.noOfPages = Math.ceil(this.diagnosisKeywordResposeObject.length / 20);
          this.recordsDisplayedCount = "" + ((this.currentPage - 1) * 20 + 1) + " - " + (this.currentPage * 20 <= this.noOfRecords ? this.currentPage * 20 : this.noOfRecords);
          this.loadDiagnosisHistory(this.diagnosisKeywordResposeObject.slice(((this.currentPage - 1) * 20), (this.noOfRecords >= this.currentPage * 20 ? this.currentPage * 20 : this.noOfRecords)));
          this.modalService.dismissAll();
          this.modalService.open(this.diagnosisCode, { modalDialogClass: 'content', size: 'lg' });
        } else {
          this.modalService.dismissAll();
          this.modalService.open(this.diagnosisCode, { modalDialogClass: 'content', size: 'lg' });
        }
      }, (error: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      }).catch((reason: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      });
    } else {
      this.loadDiagnosisHistory([]);
    }
  }

  // Load previous page in Diagnosis Keyword Search DataTable
  loadPreviousDiagnosisKeywords() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.recordsDisplayedCount = "" + ((this.currentPage - 1) * 20 + 1) + " - " + (this.currentPage * 20 <= this.noOfRecords ? this.currentPage * 20 : this.noOfRecords);
      //console.log("Min: " + ((this.currentPage - 1) * 20) + ", Max: " + (this.noOfRecords >= this.currentPage * 20 ? this.currentPage * 20 : this.noOfRecords));
      this.loadDiagnosisHistory(this.diagnosisKeywordResposeObject.slice((this.currentPage - 1) * 20, this.noOfRecords >= this.currentPage * 20 ? this.currentPage * 20 : this.noOfRecords), this.diagnosisCodeChecked);
    }
  }

  // Load next page in Diagnosis Keyword Search DataTable
  loadNextDiagnosisKeywords() {
    if (this.currentPage < this.noOfPages) {
      this.currentPage++;
      this.recordsDisplayedCount = "" + ((this.currentPage - 1) * 20 + 1) + " - " + (this.noOfRecords >= this.currentPage * 20 ? this.currentPage * 20 : this.noOfRecords);
      //console.log("Min: " + ((this.currentPage - 1) * 20) + ", Max: " + (this.noOfRecords >= this.currentPage * 20 ? this.currentPage * 20 : this.noOfRecords));
      this.loadDiagnosisHistory(this.diagnosisKeywordResposeObject.slice((this.currentPage - 1) * 20, this.noOfRecords >= this.currentPage * 20 ? this.currentPage * 20 : this.noOfRecords), this.diagnosisCodeChecked);
    }

  }

  // Selecting a Diagnosis Keyword from the DataTable
  selectDiagnosisKeywordClicked() {
    //console.log("DiagnosisCodechecked:" + this.diagnosisCodeChecked);
    if (this.diagnosisCodeChecked != "") {
      var data = this.diagnosisKeywordResposeObject.filter((data) => data.diagnosisCode == this.diagnosisCodeChecked);
      this.diagnosisList = this.diagnosisKeywordResposeObject;
      this.DiagnosisCode?.setValue(data[0].diagnosisCode.trim() + " " + data[0].diagnosisDescription.trim());
      this.modalService.dismissAll();
    }
  }

  // Adding a drug to favourites list
  savefavourite(drugDescription: string, productId: string, index: number) {
    this.logger.userAction("Save Favourite medication.");
    //console.log("Saveing:" + drugDescription + " : " + productId + " : " + this.selectedDrugItems[index].productIdQlfr + " : " + index);
    let webrequest: AddFavRootObject = new AddFavRootObject();
    let medicationPrescribedObject: AddMedicationPrescribed = new AddMedicationPrescribed()
    let drugCodedObject: DrugCoded = new DrugCoded();
    let drugCodedObjectArr: DrugCoded[] = [];
    drugCodedObjectArr.push(drugCodedObject);
    drugCodedObject.code = productId;
    webrequest.medicationPrescribed = medicationPrescribedObject;
    webrequest.medicationPrescribed.drugDescription = drugDescription;
    webrequest.medicationPrescribed.drugCoded = drugCodedObjectArr;
    webrequest.medicationPrescribed.productIdQlfr = this.selectedDrugItems[index].productIdQlfr;
    webrequest.medicationPrescribed.drugInfoId = "0";
    webrequest.loggedPresbrId = "" + this.profileService.loginuser.prescriber.presbrId;
    webrequest.loggedUuid = "" + this.profileService.loginuser.UUID;
    this.drugSearchHelper.addFavouriteDrug(webrequest).then((data: FavDrugStatusResponse) => {
      //console.log("Saving Data:" + data.status.statusDesc + data.status.statusDesc.indexOf('Limit exceed'));
      if (data.status.statusCode == "Success" && data.status.statusDesc.indexOf('Limit exceed') < 0) {
        //console.log("Saved");
        this.savedSearchesDrugCodes.push(productId);
        this.reloadSavedSearches();
      } else if (data.status.statusDesc.indexOf('Limit exceed') >= 0) {
        this.errorText = "Your saved drug limit is ten. Please remove an existing saved drug to continue.";
        this.modalService.open(this.content, { modalDialogClass: 'content' });
      }
    }, (error: any) => {
      this.modalService.dismissAll();
      this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
    }).catch((reason: any) => {
      this.modalService.dismissAll();
      this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
    });
  }

  // Removing a drug from favourites list
  removefavourite(productID: string | undefined) {
    this.logger.userAction("Remove favourite Medication Clicked.");
    var temp: TableRowData<FavoriteDrugsDetail>[];
    temp = [];
    var drugInfoId: string | null | undefined = "";
    this.savedSearchHistory.forEach((element: TableRowData<FavoriteDrugsDetail>) => {
      var tempRow: TableRowData<FavoriteDrugsDetail> = new TableRowData<FavoriteDrugsDetail>();
      element.cells.forEach((item: TableCellData<FavoriteDrugsDetail>) => {
        //console.log(item.data?.drugCoded[0].code + '==' + productID);  //testing
        //console.log(item.data?.drugCoded[0].code == productID);  //testing

        if (item.data?.drugCoded[0].code == productID && drugInfoId == "") {
          //console.log("hello:" + item.data?.drugCoded[0].code + '==' + productID);
          //console.log("Item Data" + item.data?.drugInfoId);
          drugInfoId = item.data?.drugInfoId;

        } else {
          tempRow.cells.push(item);
        }
      });
      temp.push(tempRow);

    });
    this.savedSearchHistory = temp;
    //console.log("UnFavourite The Drug:" + drugInfoId + ":" + productID);



    let webrequest: RemoveFavRootObject = new RemoveFavRootObject();
    let medicationPrescribed: MedicationPrescribed = new MedicationPrescribed();
    medicationPrescribed.drugInfoId = drugInfoId;
    webrequest.medicationPrescribed = medicationPrescribed;
    webrequest.loggedPresbrId = "934";
    webrequest.loggedUuid = "ab8313a2-b560-4dd9-9400-9717082ae4eb";

    this.drugSearchHelper.removeFavouriteDrug(webrequest).then((data: FavDrugStatusResponse) => {
      //console.log(data);
      if (data.status.statusCode == "Success") {
        this.savedSearchesDrugCodes = this.savedSearchesDrugCodes.filter(data => data != productID);
        this.savedSearchHistory = [];
        this.reloadSavedSearches();
      }
    }, (error: any) => {
      this.modalService.dismissAll();
      this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
    }).catch((reason: any) => {
      this.modalService.dismissAll();
      this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
    });
  }

  // To set the favourite star value for the claim
  checkFavourites(medStrength: string) {
    // //console.log("Fav Index:" + this.savedSearchesDrugCodes.indexOf(medStrength) + "" + this.savedSearchesDrugCodes.length);
    return this.savedSearchesDrugCodes.indexOf(medStrength);
  }

  // Quantity calculation and description:
  calculateQuantity() {
    if (this.Quantity?.valid && this.Days?.valid) {
      let object = this.quantityService.quantityCalculate(parseInt(""+this.Quantity?.value), this.currentSelectedDrug[0]);
      this.quantityCalculate = parseFloat(object.qtycaliculated);
      this.quantityEntered = object.qtyEntered;
      this.quantityDesc = object.qtydesc;
      this.quantityCalculated = "Based on quantity entered, " + object.qtydesc + " will be submitted.";
      //console.log("quantitycalculated", object);
    } else {
      this.quantityCalculated = "";
    }
  }

  // Diagnosis Code Keyword Serach Tables and Pagination
  diagnosisSearchHeaders: TableHeaderCellData[] = [];;
  diagnosisSearchHistory: TableRowData<DiagnosisCodeSearchItem>[] = [];

  private diagnosisFirstHeader: TableHeaderCellData = new TableHeaderCellData();
  private diagnosisSecondHeader: TableHeaderCellData = new TableHeaderCellData();
  private diagnosisThirdHeader: TableHeaderCellData = new TableHeaderCellData();

  noOfPages: number = 0;
  noOfRecords: number = 0;
  recordsDisplayedCount: string = "";
  currentPage = 1;
  diagnosisKeywordResposeObject: DiagnosisCodeSearchItem[] = [];


  //Saved Search and Recent Search Tables 
  savedSearchHeaders: TableHeaderCellData[];
  savedSearchHistory: TableRowData<FavoriteDrugsDetail>[];

  recentSearchHeaders: TableHeaderCellData[];
  recentSearchHistory: TableRowData<DrugSearchItem>[];

  private firstHeader: TableHeaderCellData = new TableHeaderCellData();
  private secondHeader: TableHeaderCellData = new TableHeaderCellData();
  private thirdHeader: TableHeaderCellData = new TableHeaderCellData();

  @ViewChild("drugNameTemplate", { static: true })
  drugNameTemplateRef?: TemplateRef<any>

  @ViewChild("actionRemove", { static: true })
  actionRemove?: TemplateRef<any>

  @ViewChild("actionSelect", { static: true })
  actionSelect?: TemplateRef<any>

  @ViewChild("recentDrugNameTemplate", { static: true })
  recentDrugNameTemplate?: TemplateRef<any>

  @ViewChild("recentDrugPrdouctId", { static: true })
  recentDrugPrdouctId?: TemplateRef<any>

  @ViewChild("actionRecentSearchRemove", { static: true })
  actionRecentSearchRemove?: TemplateRef<any>

  @ViewChild("actionRecentSearchSelect", { static: true })
  actionRecentSearchSelect?: TemplateRef<any>

  @ViewChild("diagnosisCodeCheckboxTemplate", { static: true })
  diagnosisCodeCheckboxTemplate?: TemplateRef<any>

  @ViewChild("diagnosisCodeTemplate", { static: true })
  diagnosisCodeTemplate?: TemplateRef<any>

  @ViewChild("diagnosisKeywordTemplate", { static: true })
  diagnosisKeywordTemplate?: TemplateRef<any>

  // To reload savedSearches from backend and then loading the DataTable
  reloadSavedSearches() {
    if (this.profileService.loginuser.prescriber.presbrId != undefined && this.profileService.loginuser.UUID != undefined) {
      this.drugSearchHelper.getFavouriteDrugs(this.profileService.loginuser.prescriber.presbrId, this.profileService.loginuser.UUID).then((data: FavoriteDrugsDetailResponse) => {

        if (data.favoriteDrugsDetail != undefined && data.favoriteDrugsDetail.length > 0) {
          //console.log("FavSearch:" + Object.values(data.favoriteDrugsDetail));
          this.loadSavedSearchHistory(data.favoriteDrugsDetail);
        }
      }, (error: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      }).catch((reason: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      });
    }
  }

  // To load savedSearch items into DataTable
  private loadSavedSearchHistory(rawData: FavoriteDrugsDetail[]): void {

    rawData.forEach((history: FavoriteDrugsDetail) => {
      const row: TableRowData<FavoriteDrugsDetail> = new TableRowData<FavoriteDrugsDetail>();
      this.savedSearchesDrugCodes.push(history.drugCoded[0].code);
      const firstCell: TableCellData<FavoriteDrugsDetail> = new TableCellData();
      firstCell.data = history;
      firstCell.template = this.drugNameTemplateRef;
      row.cells.push(firstCell);

      const secondCell: TableCellData<FavoriteDrugsDetail> = new TableCellData();
      secondCell.data = history;
      secondCell.template = this.actionRemove;
      secondCell.clickable = false;
      row.cells.push(secondCell);

      const thirdCell: TableCellData<FavoriteDrugsDetail> = new TableCellData();
      thirdCell.data = history;
      thirdCell.template = this.actionSelect;
      row.cells.push(thirdCell);


      this.savedSearchHistory.push(row);
    });


  }

  // To load recentSearches items into DataTable 
  private loadRecentSearchHistory(rawData: DrugSearchItem[]): void {
    rawData.forEach((history: DrugSearchItem) => {
      const row: TableRowData<DrugSearchItem> = new TableRowData<DrugSearchItem>();

      const firstCell: TableCellData<DrugSearchItem> = new TableCellData();
      firstCell.data = history;
      firstCell.template = this.recentDrugPrdouctId;
      row.cells.push(firstCell);

      const secondCell: TableCellData<DrugSearchItem> = new TableCellData();
      secondCell.data = history;
      secondCell.template = this.recentDrugNameTemplate;
      row.cells.push(secondCell);

      const thirdCell: TableCellData<DrugSearchItem> = new TableCellData();
      thirdCell.data = history;
      thirdCell.template = this.actionRecentSearchRemove;
      row.cells.push(thirdCell);

      const forthCell: TableCellData<DrugSearchItem> = new TableCellData();
      forthCell.data = history;
      forthCell.template = this.actionRecentSearchSelect;
      row.cells.push(forthCell);

      this.recentSearchHistory.push(row);
    });
  }

  // To load Diagniosis Keyword Search items to DataTable
  private loadDiagnosisHistory(rawData: DiagnosisCodeSearchItem[], diagnosisCode: string = ""): void {
    this.diagnosisSearchHistory = [];
    rawData.forEach((item: DiagnosisCodeSearchItem) => {
      const row: TableRowData<DiagnosisCodeSearchItem> = new TableRowData<DiagnosisCodeSearchItem>();
      const firstCell: TableCellData<DiagnosisCodeSearchItem> = new TableCellData();
      if (item.diagnosisCode == diagnosisCode) {
        item.selected = true;
      } else {
        item.selected = false;
      }

      firstCell.data = item;
      firstCell.template = this.diagnosisCodeCheckboxTemplate;
      row.cells.push(firstCell);

      const secondCell: TableCellData<DiagnosisCodeSearchItem> = new TableCellData();
      secondCell.data = item;
      secondCell.template = this.diagnosisCodeTemplate;
      secondCell.clickable = false;
      row.cells.push(secondCell);

      const thirdCell: TableCellData<DiagnosisCodeSearchItem> = new TableCellData();
      thirdCell.data = item;
      thirdCell.template = this.diagnosisKeywordTemplate;
      row.cells.push(thirdCell);

      this.diagnosisSearchHistory.push(row);
    });


  }

  // To handling sorting event on savedSearches headers click
  onSavedHeaderClick(headerData: TableClickEvent<TableHeaderCellData>) {

  }

  //To handle select event on saved seraches items from the favourites list 
  savedSearchId: string | undefined = '';
  onSavedCellClick(cellData: TableClickEvent<FavoriteDrugsDetail>) {

    if (cellData.column == 1 || cellData.event.target.getAttribute('name')=="remove") {
      this.logger.userAction("Removing Favourite Medication");
      //console.log(cellData.cell?.data?.drugDescription);
      this.removefavourite(cellData.cell?.data?.drugCoded[0].code);
    } else {
      // For Setting the Medication Name
      this.addMedicationForm.reset();
      this.logger.userAction("Selecting Favourite Medication");
      var productID: string | undefined = cellData.cell?.data?.drugCoded[0].code;
      var drugDetails: SpecialityProduct[] = this.productList.filter(data => data.ndc == productID);
      //console.log("drugDetails:" + drugDetails);
      this.drugSearchModel?.setValue(drugDetails[0].displayName);

      // For Setting the Mediaction Strength
      // var drugBrand!: any;
      // drugBrand = this.productList.find(a => a.displayName == this.addMedicationForm.get('drugSearchModel')?.value)?.brandName;
      let request: Drugsearchwebrequest = new Drugsearchwebrequest();
      request.ndc = "" + productID;
      this.drugSearchHelper.getDrugSearchItems(request).then((response: Drugsearchwebresponse) => {
        //debugger;
        if (response.status.respCode == '200') {
          this.dropdown = response.response.drugSearchItems;
          this.formLoading = false;
          this.MedStrength?.enable();
          var drugItem: DrugSearchItem[] = this.dropdown.filter(data => data.productId == productID);
          this.MedStrength?.setValue(drugItem[0].productId);
          this.modalService.dismissAll();
          this.onChangeMediactionStrenth();
        } else {
          this.drugSearchModel?.setErrors({ 'incorrect': true });
          this.formLoading = false;
          this.dropdown = [];
        }
      }, (error: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      }).catch((reason: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      });
    }
  }

  // To handle sorting event on recentSearches headers click
  onRecentHeaderClick(headerData: TableClickEvent<TableHeaderCellData>) {

    if (headerData.column == 0) {
      if (headerData.cell?.sortAscending == false) {
        this.recentSearchHistory = [];
        this.loadRecentSearchHistory(this.recentSearches.sort((a, b) => {
          return parseInt(a.productId) - parseInt(b.productId);
        }));
      } else {
        this.recentSearchHistory = [];
        this.loadRecentSearchHistory(this.recentSearches.sort((a, b) => {
          return parseInt(b.productId) - parseInt(a.productId);
        }));
      }
    }
  }

  //To handle select event on recent seraches items 
  onRecentCellClick(cellData: TableClickEvent<DrugSearchItem>) {

    if (cellData.column == 2) {
      this.logger.userAction("Removing Recent Medication");
      //console.log(cellData.cell?.data?.productId);
      var temp: TableRowData<DrugSearchItem>[];
      temp = [];
      this.recentSearchHistory.forEach((element: TableRowData<DrugSearchItem>) => {
        var tempRow: TableRowData<DrugSearchItem> = new TableRowData<DrugSearchItem>();
        element.cells.forEach((item: TableCellData<DrugSearchItem>) => {
          if (item.data?.productId == cellData.cell?.data?.productId) {
          } else {
            tempRow.cells.push(item);
          }
        });
        temp.push(tempRow);
      });

      this.recentSearchHistory = temp;
      //console.log("Length1:" + this.recentSearches.length);
      //console.log("Length2:" + this.recentSearchHistory.length);
      var recentTemp: DrugSearchItem[] = [];
      this.recentSearches.forEach((item: DrugSearchItem) => {
        if (item.productId != cellData.cell?.data?.productId) {
          recentTemp.push(item);
        }
      });

      this.recentSearches = [];
      this.recentSearches = recentTemp;

    } else {

      // For Setting the Medication Name
      this.logger.userAction("Selecting Recent Medication");
      this.addMedicationForm.reset();
      var productID: string | undefined = cellData.cell?.data?.productId;
      var drugDetails: SpecialityProduct[] = this.productList.filter(data => data.ndc == productID);
      //console.log("drugDetails:" + drugDetails);
      this.drugSearchModel?.setValue(drugDetails[0].displayName);

      // For Setting the Mediaction Strength
      var drugBrand!: any;
      drugBrand = this.productList.find(a => a.displayName == this.addMedicationForm.get('drugSearchModel')?.value)?.brandName;
      let request: Drugsearchwebrequest = new Drugsearchwebrequest();
      request.drugName = drugBrand;
      this.drugSearchHelper.getDrugSearchItems(request).then((response: Drugsearchwebresponse) => {
        if (response.status.respCode == '200') {
          this.dropdown = response.response.drugSearchItems;
          this.formLoading = false;
          this.MedStrength?.enable();
          var drugItem: DrugSearchItem[] = this.dropdown.filter(data => data.productId == productID);
          this.MedStrength?.setValue(drugItem[0].productId);
          this.modalService.dismissAll();
          this.onChangeMediactionStrenth();
        } else {
          this.drugSearchModel?.setErrors({ 'incorrect': true });
          this.formLoading = false;
          this.dropdown = [];
        }
      }, (error: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      }).catch((reason: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      });
    }
  }

  // To handle sorting event on Dianogis keyword DataTable headers click
  onDiagnosisHeaderClick(cellData: TableClickEvent<DiagnosisCodeSearchItem>) {

  }

  //To handle select event on Diagnosis Keyword items in DataTable 
  onDiagnosisCellClick(cellData: TableClickEvent<DiagnosisCodeSearchItem>) {
    if (cellData.cell?.data?.diagnosisCode != this.diagnosisCodeChecked) {
      this.diagnosisCodeChecked = cellData.cell?.data?.diagnosisCode;
      this.loadDiagnosisHistory(this.diagnosisKeywordResposeObject.slice((this.currentPage - 1) * 20, this.noOfRecords >= this.currentPage * 20 ? this.currentPage * 20 : this.noOfRecords), cellData.cell?.data?.diagnosisCode);
    } else if (cellData.cell?.data?.diagnosisCode == this.diagnosisCodeChecked) {
      this.diagnosisCodeChecked = "";
      this.loadDiagnosisHistory(this.diagnosisKeywordResposeObject.slice((this.currentPage - 1) * 20, this.noOfRecords >= this.currentPage * 20 ? this.currentPage * 20 : this.noOfRecords));
    } else {
      this.loadDiagnosisHistory(this.diagnosisKeywordResposeObject.slice((this.currentPage - 1) * 20, this.noOfRecords >= this.currentPage * 20 ? this.currentPage * 20 : this.noOfRecords), cellData.cell?.data?.diagnosisCode);
    }
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  updateDrugAdmin(event: any) {
    // debugger;
    this.DrugAdmin?.setValue(""+this.payOption);
    // if (event.target.checked == false) {
    //   this.payOption = -1;
    // } else
     if (event.target.getAttribute('name') == 'payBoth') {
      this.payOption = 0;
      this.submitedValidClaimList = true;
      event.target.checked=true;
    } else {
      this.payOption = 1;
      this.submitedValidClaimList = true;
      event.target.checked=true;
    }
  }

  // To keep track of claimsAdjudication
  countarr = 0;
  trailAdjuducicationError = "";
  memberResponse: any;

  checkCoveragePage() {
    this.logger.userAction("Check Coverage Clicked.");
    //console.log("DOS:" + (this.dosValue == ''));
    //console.log("DOS2:" + this.dosResult);
    if (this.drugQuestions == 'Y' && this.payOption == -1) {
      this.submitedValidClaimList = false;
      window.scroll(0, 0);
    } else if (this.dosResult != DateFieldResultType.SUCCESS || this.dosValue == '') {
      this.emptyDOS = false;
    } else {

      this.modalService.open(this.claimSummaryProgress, { modalDialogClass: 'claimSummaryProgress' });
      let memberDetailrequest: MemberDetailwebrequest = new MemberDetailwebrequest();
      memberDetailrequest.accountId = this.selectedMedicine[0].selectedMember.accountId;
      memberDetailrequest.carrierId = this.selectedMedicine[0].selectedMember.carrierId;
      memberDetailrequest.groupId = this.selectedMedicine[0].selectedMember.groupId;
      memberDetailrequest.instanceId = this.selectedMedicine[0].selectedMember.instanceId;
      memberDetailrequest.memberId = this.selectedMedicine[0].selectedMember.memberId;

      this.selectedMedicine.forEach((item: SelectedMedicine) => {
        // item.memberDetailResponse = response;
        item.placeOfservice = ""+this.checkCoverage.get('placeOfService')?.value;
        item.dateOfservice = this.dosValue;
        if (this.selectedMedicine[0].selectedMember != undefined && item.selectedMember == undefined) {
          item.selectedMember = this.selectedMedicine[0].selectedMember;
        }
        // if(this.selectedMedicine[0].clmgroupId!=undefined){

        // }
        //console.log(this.selectedMedicine);
      });

      this.trailclaimadjudicationHelper.getMemberDetails(memberDetailrequest).then((response: MemberDetailWebResponse) => {
        if (response) {
          this.memberResponse = response;
        }
      }, (error: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      }).catch((reason: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      });
      this.trailclaimadjudicationHelper.checkCoverage(this.selectedMedicine).then((data: SelectedMedicine[]) => {
        if (data) {
          this.counter = 2;
          this.selectedMedicine.forEach((item: SelectedMedicine) => {
            item.memberDetailResponse = this.memberResponse;
          });
          sessionStorage.setItem("selectedMedicineList", JSON.stringify(this.selectedMedicine));

          // this.modalService.dismissAll();
          // this.router.navigateByUrl("/summary");
          // Adding delay of 2 sec before moving to summary page
          if (data[0].trailadjudicationwebresponse.status.respCode != APPCONSTANTS.API_RESPONSE_SUCCESS_CODE) {
            this.modalService.dismissAll();
            //this.errorPopupText = ""+data[0].trailadjudicationwebresponse.status.responseDescriptionCode;
            this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
          }else{
          const source = interval(2000);
          this.subscription = source.subscribe(() => {
            this.counter++;
            //console.log(this.counter);
            if (this.counter == 4) {
              //console.log(this.counter);
              this.subscription.unsubscribe();
              this.modalService.dismissAll();
              //console.log("Moving to Summary:");
              //console.log(this.selectedMedicine);
                this.router.navigateByUrl("/coverage/summary");
            }
          });
        }
        }
      }, (error: any) => {

      }).catch((error: any) => {

      });
    }
  }

  ngOnDestroy(): void {
    // this.productbyInfusionSubscription.unsubscribe();
  }

  back(event: any): void {
    this.logger.userAction("Back clicked.");
    event.preventDefault();
    this.router.navigateByUrl("/coverage/prescriber");
  }

}