import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: '[downloadserverfile]',
  templateUrl: './downloadserverfile.component.html',
  styleUrls: ['./downloadserverfile.component.css'],
  host:{
    "(click)": "downloadFile($event)"
  }
})
export class DownloadserverfileComponent implements OnInit {

  private contentType:string = "application/x-www-form-urlencoded; charset=UTF-8";

  @Input()
  Url!:string;

  @Input()
  request!:any;

  @Input()
  filename:string="sheet.xlsx";

  constructor(private http:HttpClient) { }

  ngOnInit(): void {
  }
  downloadFile(event:any){
    if(this.Url!=undefined){
      this.http.post(this.Url,this.request).subscribe((response:any)=>{
        this.handlefiledownload(response);
      },(error:any)=>{

      });
    }
  }


  private handlefiledownload(response:any):void
  {
    if(response!=undefined && response.status!=undefined && response.status.statusCode!=undefined && response.status.statusCode.toLowerCase()=='success')
    {
      var b64String = response.fileBytes;
      //[".xlsx",".csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"]
      //var contentType =scope.contentType;
      // getContentTypeOfFile('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      let blob:Blob = this.b64toBlob(b64String);
      var objectUrl = URL.createObjectURL(blob);
      var a         = document.createElement('a');
      a.href        = objectUrl; 
      a.target      = '_blank';
      a.download    = this.filename;
      document.body.appendChild(a);
      a.click();
    }
    else
    {
      
    }
  }

  private b64toBlob(base64Data:any):Blob
  {
      let byteCharacters:string,blobData:[Uint8Array],blob:Blob;

      byteCharacters = atob(base64Data);
      blobData = this.getBlobDataAtOnce(byteCharacters);
      blob = new Blob(blobData, { type: this.contentType });

      return blob;
  }
  private getBlobDataAtOnce(byteCharacters:string):[Uint8Array]{
    let byteNumbers = new Array(byteCharacters.length);

    for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);

    return [byteArray];
  }
}
