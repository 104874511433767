<!-- <div class="pagination" *ngIf="totalPages>1 && show">
    <ng-container *ngFor="let page of counter();index as i; first as isFirst; last as isLast">
        <button class="btn invisblebtn" [class.lock]="currentPageNumber==1" *ngIf="totalPages>1 && isFirst" 
            (click)="currentPageNumber>1 && currentPageNumber=currentPageNumber-1">
            <i class="goToPreviousPage fa fa-circle-thin">
                <i class="fa fa-angle-left"></i>
            </i>
        </button>
        <button class="btn invisblebtn paginationpage" [class.active]="currentPageNumber-1==i" [innerHtml]="i+1" (click)="currentPageNumber=(i+1)">
        </button>
        <button class="btn invisblebtn" [class.lock]="currentPageNumber==totalPages" *ngIf="totalPages>1 && isLast" 
            (click)="currentPageNumber<totalPages && currentPageNumber=currentPageNumber+1">
            <i class="goToNextPage fa fa-circle-thin">
                <i class="fa fa-angle-right"></i>
            </i>
        </button>
    </ng-container>
</div> -->

<nav class="row mt-3 pb-3" aria-label="pagination">
    <div class="col-6" *ngIf="totalEntries>=1 && this.router.url!='/home'">
        <p class="large noresults text-start">
            <span>
                Entries per page &nbsp;
                <button class="btn btn-primary btn-primary-link ps-3 pe-2 leftCurvedBtn" aria-label="10 Entries per page" [class.lock]="currentPageNumber==1" *ngIf="currentEntriesPerPage<=10" 
                    (click)="currentPageNumber>1">
                    10
                </button>
                <button class="btn btn-secondary-outline leftCurvedBtn" aria-label="10 Entries per page" [class.lock]="currentPageNumber==1" *ngIf="currentEntriesPerPage>10" 
                    (click)="currentEntriesPerPage=10">
                    10
                </button>
                <button class="btn btn-primary btn-primary-link ps-3 pe-3" aria-label="50 Entries per page" [class.lock]="currentPageNumber==1" *ngIf="(currentEntriesPerPage>10 && currentEntriesPerPage<=50)" 
                    (click)="currentPageNumber>1">
                    50
                </button>
                <button class="btn btn-secondary-outline" aria-label="50 Entries per page" [class.lock]="currentPageNumber==1" *ngIf="(currentEntriesPerPage<=10 || currentEntriesPerPage>50)" 
                    (click)="currentEntriesPerPage=50">
                    50
                </button>
                <button class="btn btn-primary btn-primary-link ps-2 pe-3 rightCurvedBtn" aria-label="100 Entries per page" [class.lock]="currentPageNumber==1" *ngIf="currentEntriesPerPage>50" 
                    (click)="currentPageNumber>1">
                    100
                </button>
                <button class="btn btn-secondary-outline rightCurvedBtn" aria-label="100 Entries per page" [class.lock]="currentPageNumber==1" *ngIf="currentEntriesPerPage<=50" 
                    (click)="currentEntriesPerPage=100">
                    100
                </button>
            </span>
        </p>
    </div>
    <div class="col-6" *ngIf="totalPages>1">
        <p class="large noresults text-end">
            <span>Displaying page: {{this.currentPageNumber}} of {{this.totalPages}} &nbsp; 
                <button class="btn invisblebtn" [class.lock]="currentPageNumber==1" *ngIf="totalPages>1" 
                    (click)="currentPageNumber>1 && currentPageNumber=currentPageNumber-1" aria-label="previous page">
                    <i class="goToPreviousPage fa fa-circle-thin" alt="previous page">
                        <i class="fa fa-angle-left" alt="previous page"></i>
                    </i>
                </button>
                <button class="btn invisblebtn" [class.lock]="currentPageNumber==totalPages" *ngIf="totalPages>1" 
                    (click)="currentPageNumber<totalPages && currentPageNumber=currentPageNumber+1" aria-label="next page">
                    <i class="goToNextPage fa fa-circle-thin" alt="next page">
                        <i class="fa fa-angle-right" alt="next page" tabindex="0"></i>
                    </i>
                </button>
            </span>
        </p>
    </div>

</nav>