export enum LogLevel {
    Off=0,
    INFO=1,
    ERROR=2,
    USER_ACTION=3,
    Login=4
}
export const LogLevel_Label = new Map<number, string>([
    [LogLevel.Off, 'No Logger'],
    [LogLevel.USER_ACTION, 'User action on page'],
    [LogLevel.ERROR, 'Error'],
    [LogLevel.INFO,"Info message"],
    [LogLevel.Login,"Login"]
  ]);