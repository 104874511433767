import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Drugsearchwebrequest, Diagnosissearchwebrequest, RemoveFavRootObject, AddFavRootObject } from 'src/app/modals/drugSearch/drugsearchwebrequest';
import { APPCONSTANTS } from 'src/app/constants/appconstant';
import { environment } from 'src/environments/environment';
import { Status } from 'src/app/modals/webresponsestatus';
import { BehaviorSubject } from 'rxjs';
import { Drugsearchwebresponse, FavoriteDrugsDetail, SpecialityProduct, DrugSearchItem, DiagnosisCodeSearchResponses, FavDrugStatusResponse, FavoriteDrugsDetailResponse, AdditionalField, SmapProductInfoResponse, SpecialityProductResponse } from 'src/app/modals/drugSearch/drugsearchwebresponse';
import { MemberSearchV5Item } from 'src/app/modals/membersearch/membersearchwebresponse';
import { FavoriteDrugsDetailRootObject } from 'src/app/modals/drugSearch/SpecialityProductInterfaces';
import { LoggerService } from '../logger/logger.service';


@Injectable({
  providedIn: 'root'
})
export class DrugsearchService {

  savedDrugs: BehaviorSubject<FavoriteDrugsDetail[]> = new BehaviorSubject<FavoriteDrugsDetail[]>([]);

  constructor(private http: HttpClient, private logger: LoggerService) { }

  getDrugSearchItems(ndc: Drugsearchwebrequest): Promise<Drugsearchwebresponse> {
    const promise: Promise<Drugsearchwebresponse> = new Promise<Drugsearchwebresponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.getDrugSearchDetailsUrl});
      this.http.post(environment.proxyurl, ndc,{headers:header}).subscribe((response: any) => {
        if (response.status != undefined
          && response.status.statusCode != undefined
          && (response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT ||
            response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_WARNING_TXT ||
            response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_INFO_TXT)
        ) {
          if (response.response != undefined && response.response.drugSearchItems != undefined && Array.isArray(response.response.drugSearchItems)) {
            response.response.drugSearchItems.forEach((item: DrugSearchItem) => {
              item.detailedDrugName = this.getDrugDetailedName(item);
            });
          }
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }

  getFavouriteDrugs(presbrId: string, UUID: string): Promise<FavoriteDrugsDetailResponse> {
    const promise: Promise<FavoriteDrugsDetailResponse> = new Promise<FavoriteDrugsDetailResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.getSavedSerachesListUrl.replace("{presbrId}", presbrId).replace("{UUID}", UUID)});
      this.http.post(environment.proxyurl, null,{headers:header}).subscribe((response: any) => {
        if (response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
          && response.status.dbStatusCode != undefined && response.status.dbStatusCode == APPCONSTANTS.API_RESPONSE_SUCCESS_NBR) {
          this.savedDrugs.next(response.favoriteDrugsDetail);
          resolve(response);
        } else {
          this.savedDrugs.next([]);
          resolve(response);
        }
      }, (error: any) => {
        this.savedDrugs.next([]);
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }

   getDrugDetailedName(drugSearchItem: DrugSearchItem): string {

    let otcCode: string = "";
    let packageSize: string = "";
    let packageSizeUnitOfMeasure: string = "";
    let packageDesc: string = "";
    let labelName: string = "";

    if (drugSearchItem.otcCode != undefined && (drugSearchItem.otcCode.toLowerCase() == 'p' || drugSearchItem.otcCode.toLowerCase() == 'o')) {
      otcCode = " (OTC)";
    }

    if (drugSearchItem.prodNameAbbr != undefined) {
      //removes special characters equivalent to whitespace into whitespaces.
      labelName = drugSearchItem.prodNameAbbr.replace(/\s+/g, " ").trim();
    }

    if (drugSearchItem.additionalField.length > 0) {

      drugSearchItem.additionalField.forEach((field: AdditionalField) => {
        switch (field.fieldDescription.toLowerCase()) {
          case 'packagesizeunitofmeasure':
            packageSizeUnitOfMeasure = field.fieldStringValue;
            break;
          case 'packagedesc':
            packageDesc = this.sentenceCase(field.fieldStringValue);
            break;
          case 'packagesize':
            if (field.fieldDecimalValue != undefined) {
              packageSize = parseFloat(field.fieldDecimalValue).toString();
            }
            break;
        }
      });
      if (packageDesc.trim().length > 0) {
        packageDesc += '(s)';
      } else {
        packageDesc = '';
      }
    } else {
      return '';
    }

    if (drugSearchItem.breakableIndicator != undefined && drugSearchItem.breakableIndicator.toLowerCase() == 'b') {
      return labelName + otcCode;
    }
    else {
      if (drugSearchItem.breakableIndicator != undefined && drugSearchItem.breakableIndicator.toLowerCase() == 'u') {
        if (packageSize.trim().length > 0 && packageSizeUnitOfMeasure.trim().length > 0 && packageDesc.trim().length > 0) {
          return labelName + ', ' + packageSize + ' ' + packageSizeUnitOfMeasure + ' ' + packageDesc + otcCode;
        }
        else if (packageSizeUnitOfMeasure.trim().length == 0 && packageDesc.trim().length == 0) {
          return labelName + otcCode;
        }
        else if (packageSizeUnitOfMeasure.trim().length > 0 && packageDesc.trim().length > 0 && packageSize.trim().length == 0) {
          return labelName + ', ' + packageSizeUnitOfMeasure + ' ' + packageDesc + otcCode;
        }
        else if (packageSize.trim().length > 0 && packageSizeUnitOfMeasure.trim().length > 0 && packageDesc.trim().length == 0) {
          return labelName + ', ' + packageSize + ' ' + packageSizeUnitOfMeasure + otcCode;
        }
        else if (packageDesc.trim().length > 0 && packageSizeUnitOfMeasure.trim().length == 0 && packageSize.trim().length == 0) {
          return labelName + ', ' + packageDesc + otcCode;
        }
        else if (packageDesc.trim().length > 0 && packageSize.trim().length > 0 && packageSizeUnitOfMeasure.trim().length == 0) {
          return labelName + ', ' + packageDesc + otcCode;
        }
        else if (packageSizeUnitOfMeasure.trim().length > 0 && packageSize.trim().length == 0 && packageDesc.trim().length == 0) {
          return labelName + otcCode;
        }
        else {
          return labelName + otcCode;
        }
      }
      else {
        return labelName + otcCode;
      }
    }

    return '';
  }

  private sentenceCase(str: string): string {
    if ((str === null) || (str === ''))
      return "";
    else
      str = str.toString();

    return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
  }

  getProductListByInfsn(InfsnId: string): Promise<SmapProductInfoResponse> {
    const promise: Promise<SmapProductInfoResponse> = new Promise<SmapProductInfoResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.getSmapProductsByInfusionUrl.replace("{infsId}", InfsnId)});
      this.http.post(environment.proxyurl, null,{headers:header}).subscribe((response: any) => {
        if (response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
          && response.status.dbStatusCode != undefined && response.status.dbStatusCode == APPCONSTANTS.API_RESPONSE_SUCCESS_NBR) {
          this.savedDrugs.next(response.favoriteDrugsDetail);
          resolve(response);
        } else {
          this.savedDrugs.next([]);
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.savedDrugs.next([]);
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }





  getProductList(request: MemberSearchV5Item): Promise<SpecialityProductResponse> {
    const promise: Promise<SpecialityProductResponse> = new Promise<SpecialityProductResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.getProductListUrl});
      this.http.post(environment.proxyurl, request,{headers:header}).subscribe((response: any) => {
        if (response.smapProductInfoResponse.status != undefined
          && response.smapProductInfoResponse.status.statusCode != undefined
          && (response.smapProductInfoResponse.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT ||
            response.smapProductInfoResponse.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_WARNING_TXT ||
            response.smapProductInfoResponse.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_INFO_TXT)
        ) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }



  diagnosisCodeSearch(webrequest: Diagnosissearchwebrequest): Promise<DiagnosisCodeSearchResponses> {
    const promise: Promise<DiagnosisCodeSearchResponses> = new Promise<DiagnosisCodeSearchResponses>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.diagnosisCodeSearchUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        if (response.status != undefined
          && response.status.statusCode != undefined
          && (response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT ||
            response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_WARNING_TXT ||
            response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_INFO_TXT)
        ) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }

  diagnosisDescriptionSearch(webrequest: Diagnosissearchwebrequest): Promise<DiagnosisCodeSearchResponses> {
    const promise: Promise<DiagnosisCodeSearchResponses> = new Promise<DiagnosisCodeSearchResponses>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.diagnosisDescriptionSearchUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        if (response.status != undefined
          && response.status.statusCode != undefined
          && (response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT ||
            response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_WARNING_TXT ||
            response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_INFO_TXT)
        ) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }

  public addFavouriteDrug(webrequest: AddFavRootObject): Promise<FavDrugStatusResponse> {
    const promise: Promise<FavDrugStatusResponse> = new Promise<FavDrugStatusResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.favouriteDrugUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        if (response.status != undefined
          && response.status.statusCode != undefined
          && (response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT ||
            response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_WARNING_TXT ||
            response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_INFO_TXT)
        ) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }

  public removeFavouriteDrug(webrequest: RemoveFavRootObject): Promise<FavDrugStatusResponse> {
    const promise: Promise<FavDrugStatusResponse> = new Promise<FavDrugStatusResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.unFavouriteDrugUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        if (response.status != undefined
          && response.status.statusCode != undefined
          && (response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT ||
            response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_WARNING_TXT ||
            response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_INFO_TXT)
        ) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }



}

