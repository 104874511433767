import {Status} from 'src/app/modals/webresponsestatus';

export class Prescriber {
    presbrId!:string;
    uuid!:string;
    npi!:string;
    lastName!:string;
    firstName!:string;
    addressLine1!:string;
    addressLine2!:string;
    city!:string;
    stateProvince!:string;
    postalCode!:string;
    countryCode!:string;
    primaryTelephoneNumber!:string;
    faxNumber!:string;
    roleTypeId!:string;
    approveInd!:string;
    defaultInd!:string;
    instanceId!:string;
    userType!:string;
    optumId!:string;
}

export class Infusion{
    infsnId!:string;
	pharmacyNPI!:string;
	pharmacyName!:string;
	clientId!:string;
	phmcyAddr1!:string;
	phmcyAddr2!:string;
	phmcyCty!:string;
	phmcySt!:string;
	phmcyZip!:string;
	phmcyFax!:string;
	roleTypeId!:string;
	organizationRelationId!:string;
	networkId!:string;
}

export interface Loginwebresponse {
    prescribers?:Prescriber[];
	dfltOrCurrentNPI?:string;
	npiValidOrNot?:string;
	status?:Status;
	reActivateInd?:string;
	reActivateSts?:string;

	infusionList?:Infusion[];
}
//===============================================
