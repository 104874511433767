<div class="content-card bg-white">
    <div class="card-content">
        <div class="container-fluid nopadding">
            <div class="row">
                <div class="col-12">
                    <h2 [innerHtml]="question.questionText"></h2>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-9 col-md-9 col-sm-7 col-xs-12 form-group">
                    <!-- <textarea [(ngModel)]="userAnswer"></textarea> -->
                    <form [formGroup]="freetextForm" novalidate>
                        <textarea class="form-control" formControlName="userAnswer"></textarea>
                    </form>
                    
                    <div class="text-danger" *ngIf="freetextForm.controls['userAnswer'].errors && freetextForm.controls['userAnswer'].errors['required'] && (formSubmitted || !freetextForm.controls['userAnswer'].pristine)">Please enter answer.</div>
                </div>
                <div class="col-lg-offset-0 col-lg-3 col-md-offset-0 col-md-3 col-sm-offset-0 col-sm-5 col-xs-4 col-xs-offset-4">
                    <button class="btn btn-primary rounded-pill" (click)="submit()">Continue</button>
                </div>
            </div>
        </div>
    </div>
</div>
