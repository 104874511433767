import { Component, OnInit, Input } from '@angular/core';
import { ClaimSummaryDetails } from 'src/app/modals/claimsummary/claimsummary';
import { SelectedMedicine } from 'src/app/modals/drugSearch/drugsearchwebresponse';

@Component({
  selector: 'app-member-summary',
  templateUrl: './member-summary.component.html',
  styleUrls: ['./member-summary.component.css']
})
export class MemberSummaryComponent implements OnInit {

  constructor() { }


  @Input() claimsummaryDetailsrequest!:ClaimSummaryDetails;
  @Input() claimresponse!:SelectedMedicine;
  
  ngOnInit(): void {
  }

}
