import {PRESCRIBER_QUALIFIER} from 'src/app/constants/appconstant';

export class PrescriberSearchCriteria {
    lastName: string="";
    firstName: string="";
    prescriberState: string="";
    prescriberCity: string="";
    prescriberId: string=""
    prescriberIdQualifier: PRESCRIBER_QUALIFIER= PRESCRIBER_QUALIFIER.Empty;
}

export class State{
    name!:string;
    code!:string;
}