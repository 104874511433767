<!-- progress section design-->
<div class="container-fluid shadow" style="background-color: white;">
    <div class="row" style="padding: 20px;">
        <div [ngClass]="{'col-4':this.currentState<4, 'col-3':this.currentState==4}">
            <div class="wrapper">
                <div *ngIf="this.currentState==1" class="circle-border-green">
                    <div class="number-green">1</div>
                </div>
                <div *ngIf="this.currentState>1" class="circle">
                    <div class="checkMark"></div>
                </div>
            </div>
            <span class="step-content fs-6">Select a member</span>
        </div>
        <div class="col-4" *ngIf="this.currentState<4">
            <div class="wrapper">
                <div *ngIf="this.currentState==2" class="circle-border-green">
                    <div class="number-green">2</div>
                </div>
                <div *ngIf="this.currentState<2" class="circle-border-gray">
                    <div class="number-gray">2</div>
                </div>
                <div *ngIf="this.currentState>2" class="circle">
                    <div class="checkMark"></div>
                </div>
            </div>
            <span class="step-content fs-6">Select a prescriber</span>
        </div>
        <div [ngClass]="{'col-4':this.currentState<4, 'col-3':this.currentState==4}">
            <div class="wrapper">
                <div *ngIf="this.currentState==3" class="circle-border-green">
                    <div class="number-green">3</div>
                </div>
                <div *ngIf="this.currentState<3" class="circle-border-gray">
                    <div class="number-gray">3</div>
                </div>
                <div *ngIf="this.currentState>3" class="circle">
                    <div class="checkMark"></div>
                </div>
            </div>
            <span class="step-content fs-6">Select a medication</span>
        </div>
        <div [ngClass]="{'col-4':this.currentState<4, 'col-3':this.currentState==4}" *ngIf="this.currentState==4">
            <div class="wrapper">
                <div *ngIf="this.currentState==4" class="circle-border-green">
                    <div class="number-green">3</div>
                </div>
                <div *ngIf="this.currentState<4" class="circle-border-gray">
                    <div class="number-gray">3</div>
                </div>
                <div *ngIf="this.currentState>4" class="circle">
                    <div class="checkMark"></div>
                </div>
            </div>
            <span class="step-content">Verify provider info</span>
        </div>
        <div [ngClass]="{'col-4':this.currentState<4, 'col-3':this.currentState==4}" *ngIf="this.currentState==4">
            <div class="wrapper">
                <!-- <div *ngIf="this.currentState==5" class="circle-border-green">
                    <div class="number-green">5</div>
                </div> -->
                <div *ngIf="this.currentState<5" class="circle-border-gray">
                    <div class="number-gray">4</div>
                </div>
                <div *ngIf="this.currentState>5" class="circle">
                    <div class="checkMark"></div>
                </div>
            </div>
            <span class="step-content">ePA questions</span>
        </div>
    </div>
</div>

<!-- Progress Bar component Design -->
<div class="progress container-fluid" style="height: 5px; padding: 0px; background-color: white; margin-bottom: 20px;">
    <div class="progress-bar animate-progressBar" [ngClass]="{'col-4':currentState==1,'col-8':currentState==2,'col-12':currentState==3,'col-9':currentState==4}"  role="progressbar"></div>
</div>