import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  
  @Input()
  totalPages!:number;

  @Input()
  totalEntries!:number;
  
  private _currentPageNumber : number=1;
  public get currentPageNumber() : number {
    return this._currentPageNumber;
  }
  @Input()
  public set currentPageNumber(v : number) {
    if(v<=this.totalPages){
      this._currentPageNumber = v;
      this.onPageChange.emit(v);
    }
  }

  private _currentEntriesPerPage : number=10;
  public get currentEntriesPerPage() : number {
    return this._currentEntriesPerPage;
  }
  @Input()
  public set currentEntriesPerPage(v : number) {
    if(v<=this.totalEntries){
      this._currentEntriesPerPage = v;
      this._currentPageNumber=1;
      this.onChangeEntriesPerPage.emit(v);
    }else{
      this._currentEntriesPerPage = v;
      this._currentPageNumber=1;
      this.onChangeEntriesPerPage.emit(this.totalEntries);
    }
  }
  
  @Output()
  onPageChange: EventEmitter<number>= new EventEmitter<number>();

  @Output()
  onChangeEntriesPerPage: EventEmitter<number>= new EventEmitter<number>();


  show:boolean=true;

  constructor(public router:Router) {
   }

  ngOnInit(): void {
    this.checkRequiredFields([this.totalPages]);
  }

  counter() {
    return new Array(this.totalPages);
  }

  private checkRequiredFields(inputs:any[]) {    
    inputs.forEach( (element:any)=>{
      if(element === null || element === undefined){
        this.show=false;
      }
    });
    if(!this.show){
       throw new Error("One of the madatory input property in the list [totalPages] on pagination component is missing.");
    }
  }
}
