<div class="row justify-content-center">
<div class="col-md-8 col-sm-8 col-xs-12">
  <!--<div class="col-5 offset-7">
      <ul class="list-inline float-end">
        <li (click)="showRecentPrescribers()" class="list-inline-item underline fw-bold">Recent prescribers</li>
        |
        <li (click)="showSavedPrescribers()" class="list-inline-item underline fw-bold">Saved prescribers</li>
      </ul>
    </div>
  </div> -->
  <div class=" container-fluid shadow" style="padding: 20px; background-color: white;">
    <div class="row">

      <div class="col-md-7 col-sm-7 col-xs-12">
        <h2 class="text-sm-left lh-base font-normal text-none text-decoration-none text-reset">
          <strong style="color:#002677">Step 2 of 3: Select a prescriber</strong>
        </h2>
        <p class="text-sm-left lh-base font-normal text-none text-decoration-none text-reset">
          <span style="color: #636363;">All fields required unless otherwise indicated.</span>
        </p>
      </div>
      <!-- Saved and Recent Searches Buttons  -->
      <div class="col-md-5 col-sm-5 col-xs-12">
        <ul class="list-inline float-end">
          <button (click)="showRecentPrescribers()" class="list-inline-item underline fw-bold">Recent
            prescribers</button>
          |
          <button (click)="showSavedPrescribers()" class="list-inline-item underline fw-bold">Saved prescribers</button>
        </ul>
      </div>
    </div>
  </div>
  <!-- <div class="row" *ngIf="favouriteActionError==1">
    <div class="col nopadding">
        <div class="alert alert-danger">
            We were unable to retrieve this information
            <a class="link-danger float-end" enableAction="true" (click)="showSavedPrescribers()" tabindex="0">Try again</a>
        </div>
    </div>
</div> -->


  <div class="container-fluid bg-white content-card mt-1">

    <div class="row">
      <div class="col card-header tabs-header noborder">
        <ul class="nav nav-tabs text-center">
          <li class="nav-item">
            <a class="btn invisiblebtn nav-link" [ngClass]="{'active':activeTab==1}" (click)="activeTab=1"
              aria-current="page" id="claimhistory" role="tab">
              Search by prescriber NPI
            </a>
          </li>
          <li class="nav-item">
            <a class="btn invisiblebtn nav-link" [ngClass]="{'active':activeTab==2}" (click)="activeTab=2"
              href="javascript:void(0)" id="pahistory" role="tab">
              Search by prescriber name
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- Progress Bar component Design -->
    <!-- <div class="progress row"
      style="height: 5px; background-color: white; margin-bottom: 20px;">
      <div class="col-6 progress-bar animate-progressBar" *ngIf="activeTab==1"
        role="progressbar"></div>
        <div class="col-6" *ngIf="activeTab==2"
        role="progressbar"></div>
        <div class="col-6 progress-bar animate-progressBar" *ngIf="activeTab==2"
        role="progressbar"></div>
    </div> -->


    <div class="row" *ngIf="activeTab==1">
      <div class="tab-content col">
        <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="claimhistory">
          <form [formGroup]="searchPrescriberNPIForm">
            <div class="container-fluid">
              <div class="row">
                <div class="col">
                  <label for="text" class="form-label float-left">NPI</label>
                  <div class="search">
                    <input type="text" aria-required="true" (keydown.enter)="submitNPIForm()" (focus)="onFocusNPI()" (blur)="onFocusNPI()"
                      class="form-control inp rounded-1"
                      [ngClass]="{'inpError':((NPI && NPI.invalid && NPI.dirty) || (NPI && NPI.invalid && submittedNPIForm))}"
                      formControlName="NPI">
                    <i class="fa fa-search"></i>
                  </div>
                  <div *ngIf="NPI && NPI.invalid && NPI.dirty">
                    <div class="text-danger" *ngIf=" NPI && NPI.errors?.['minlength'] && !focussedNPI">
                      <small>Please enter min 10 characters.</small>
                    </div>
                    <div class="text-danger"
                      *ngIf="NPI && NPI.errors?.['required'] && !submittedNPIForm && !focussedNPI">
                      <small>NPI is required</small>
                    </div>
                  </div>
                  <div class="text-danger" *ngIf="NPI && NPI.errors?.['required'] && submittedNPIForm && !focussedNPI">
                    <small>NPI is required</small>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="activeTab==2">
      <div class="col">
        <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="pahistory">
          <form [formGroup]="searchPrescriberNameForm">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-6">
                  <div class="mb-3 ">
                    <label for="lastname" class="form-label">Last name</label>
                    <input type="text" class="rounded-1 form-control inp" id="lastname" aria-required="true"
                      aria-describedby="emailHelp" maxlength="25"  (keydown.enter)="submitNPINameForm()"
                      [ngClass]="{'inpError':((lastName && lastName.invalid && lastName.dirty) || (lastName && lastName.invalid && submittedNPINameForm))}"
                      formControlName="lastName" (focus)="onFocusLastName()" (blur)="onFocusLastName()">
                    <div *ngIf="lastName && lastName.invalid && lastName.dirty">
                      <div class="text-danger" *ngIf="lastName && lastName.errors?.['minlength'] && !focussedLastName">
                        <small>Please enter min 2 characters.</small>
                      </div>
                      <div class="text-danger"
                        *ngIf="lastName && lastName.errors?.['required'] && !submittedNPINameForm && !focussedLastName">
                        <small>Last name is required</small>
                      </div>
                    </div>
                    <div class="text-danger"
                      *ngIf="lastName && lastName.errors?.['required'] && submittedNPINameForm && !focussedLastName">
                      <small>Last name is required</small>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="mb-3">
                    <label for="firstname" class="form-label">First name (optional)</label>
                    <input type="text" class=" rounded-1 form-control inp" id="firstName" aria-describedby="firstName"
                      name="firstName" maxlength="25" formControlName="firstName" (keydown.enter)="submitNPINameForm()">
                    <div class="text-danger" *ngIf="firstName && firstName.invalid && firstName.dirty">
                      <small>Invalid Character</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <!-- <div class="mb-3"> -->
                  <label for="city" class="form-label">City (optional)</label>
                  <input type="text" class="rounded-1 form-control inp" id="City" aria-describedby="City" name="City"
                    maxlength="7" (keydown.enter)="submitNPINameForm()">
                </div>

                <div class="col-sm-2">
                  <!-- <div class="mb-3"> -->
                  <label class="form-label">State</label>
                  <select class="rounded-1 inp form-select" id="state" aria-required="true" type="text"
                    aria-label="Default select example" name="DrugAdmin" formControlName="state"
                    [ngClass]="{'inpError':((state && state.invalid && state.dirty) || (state && state.invalid && submittedNPINameForm))}"
                    (focus)="onFocussedState()" (blur)="onFocussedState()">
                    <option selected value="">&nbsp;Select</option>
                    <option *ngFor="let item of states" value="{{item.code}}">&nbsp;{{item.code}}</option>
                  </select>
                  <div class="text-danger"
                    *ngIf="state && state.invalid && state.errors?.['required'] && submittedNPINameForm && !focussedState">
                    <small>State is required.</small>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!--  NPI Seach Buttons-->
    <div class="row" *ngIf="activeTab==1">
      <div class="col card-footer text-end">
        <button (keydown.enter)="clearNPIForm()" (click)="clearNPIForm()" class=" underline me-2">Clear</button>
        <button  class="btn btn-primary text-white shadow rounded-pill " role="button" (click)="submitNPIForm()">Search</button>
      </div>
    </div>
    <!--  NPI Seach Buttons-->
    <div class="row" *ngIf="activeTab==2">
      <div class="col card-footer text-end">
        <button type="button" (click)="clearNPINameForm()" class="underline me-2">Clear</button>
        <button class="text-white shadow rounded-pill btn btn-primary" role="button"
          (click)="submitNPINameForm()">Search</button>
      </div>
    </div>
  </div>
</div>


<div class="col-md-8 col-sm-8 col-xs-12 mb-5">
  <div class="container-fluid">
    <div *ngIf="prescriberList.length>0" class="row" style="margin-bottom: 30px; color:#424249">
      <div class="row form-label">
        <label class="display-4 col-2" style=" position: relative; top: 20%;">{{prescriberList.length}}</label>
        <div class="col-10 row">
          <label class="col-md-3 col-sm-3 col-xs-12 mt-3"> Prescriber found</label>
          <div class="col-md-9 col-sm-9 col-xs-12" style="position: relative; top: 60%; border-bottom: 1.5px solid lightgray; height: 1px;">
          </div>
          <label class="col-md-3 col-sm-3 col-xs-12 mt-3" *ngIf="prescriberList.length>10">Showing :
            {{((currentPageNumber-1)*recordsPerPage)+1}} to {{currentPageNumber * recordsPerPage}}</label>
          <label class="col-md-3 col-sm-3 col-xs-12 mt-3" *ngIf="prescriberList.length<10">Showing :
            {{((currentPageNumber-1)*recordsPerPage)+1}} to {{prescriberList.length}}</label>
        </div>
      </div>
    </div>
  </div>
  <datatable *ngIf="prescriberList.length>0" [headers]="prescriberSearchHeaders" [rows]="prescriberSearchHistory"
    [type]="datatableType" (onHeaderClicked)="onPrescriberHeaderClick($event)"
    (onCellClicked)="onPrescriberCellClick($event)" aria-labelledby="prescriberResults">
  </datatable>

  <div class="container-fluid" *ngIf="totalEntries>1">
    <div class="row">
      <div class="col text-center">
        <pagination aria-label="Prescriberresults pagination" [totalEntries]="totalEntries" [totalPages]="totalPagesCount" (onChangeEntriesPerPage)="onChangeEntriesPerPage($event)" (onPageChange)="onPageChange($event)"></pagination>
        <!-- <pagination [totalPages]="totalPagesCount" (onPageChange)="onPageChange($event)"></pagination> -->
      </div>
    </div>
  </div>

  <div class="col mt-3 nopadding noresults"
    *ngIf="totalPagesCount==0 && (submittedNPINameForm==true || submittedNPIForm==true) && (searchPrescriberNPIForm.valid || searchPrescriberNameForm.valid)">
    No search results found.
  </div>
</div>
</div>

<!-- Templates for DataTable -->
<ng-template #NameTemplate let-data="data">
  <div class="p-2" aria-labelledby="NameTemplate">
    <p class="large">
      <span class="fontbold">{{data.lastName}}</span>
      <br />
      {{data.firstName}}
    </p>
  </div>
</ng-template>
<ng-template #AddressTemplate let-data="data">
  <p class="large" aria-labelledby="AddressTemplate">
    <span class="fontbold">{{data.address.address1}}</span>
    <br />
    {{data.address.city}},{{data.address.state}}&nbsp;{{data.address.zip1}}
  </p>
</ng-template>
<ng-template #NPITemplate let-data="data">
  <p class="large" aria-labelledby="NPITemplate">{{data.identifiers[0].id}}</p>
</ng-template>

<ng-template #SNameTemplate let-data="data">
  <div class="p-2" aria-labelledby="SNameTemplate">
    <p class="large">
      <span class="fontbold">{{data.lastName}}</span>
      <br />
      {{data.firstName}}
    </p>
  </div>
</ng-template>
<ng-template #SAddressTemplate let-data="data">
  <p class="large" aria-labelledby="SAddressTemplate">
    <span class="fontbold">{{data.address}}</span>
    <br />
  </p>
</ng-template>
<ng-template #SNPITemplate let-data="data">
  <p class="large" aria-labelledby="SNPITemplate">{{data.npi}}</p>
</ng-template>


<ng-template #RNameTemplate let-data="data">
  <div class="p-2" aria-labelledby="RNameTemplate">
    <p class="large">
      <span class="fontbold">{{data.lastName}}</span>
      <br />
      {{data.firstName}}
    </p>
  </div>
</ng-template>
<ng-template #RAddressTemplate let-data="data">
  <p class="large" aria-labelledby="RAddressTemplate">
    <span class="fontbold">{{data.address.address1}}</span>
    <br />
    {{data.address.city}},{{data.address.state}}&nbsp;{{data.address.zip1}}
  </p>
</ng-template>
<ng-template #RNPITemplate let-data="data">
  <p class="large" aria-labelledby="RNPITemplate">{{data.identifiers[0].id}}</p>
</ng-template>

<ng-template #prescriberSaveTemplate let-data="data">
  <button type="button" name="favorite" class="btn invisiblebtn" enableAction="true" aria-labelledby="prescriberSaveTemplate">
    <span style="display: none;" aria-hidden>Favorite</span>
    <span name="favorite" class="notfavorite" *ngIf="!data.isFavorite" aria-label="Make your default prescriber"></span>
    <span name="favorite" class="favorite" *ngIf="data.isFavorite" aria-label="Make your default prescriber"></span>
  </button>
</ng-template>

<ng-template #actionPrescriberSearchSelect let-data="data">
  <div class="row" aria-labelledby="actionPrescriberSearchSelect">
    <!-- <div class="col-8 p-2">
      <label class="large underline mt-1" style="color: #0470c5;">Select</label>
    </div> -->
    <div class="col-4 p-2">
      <i class="fa fa-angle-right" style="font-size: 32px!important; color: #0470c5;" tabindex="0"></i>
    </div>
  </div>
</ng-template>

<ng-template #actionRemove let-data="data">
  <div class="row" aria-labelledby="actionRemove">
    <div class="col-8">
      <a class="large underline text-danger" name="Remove" style="font-weight: 700;" enableAction="true" tabindex="0">Remove</a>
    </div>
  </div>
</ng-template>

<ng-template #actionSelect let-data="data">
  <div class="row" aria-labelledby="actionSelect">
    <div class="col-4 p-2">
      <i class="fa fa-angle-right" style="font-size: 32px!important; color: #0470c5;" tabindex="0"></i>
    </div>
  </div>
</ng-template>

<!-- Loading.... -->
<ng-template #LoadingContent let-modal>
  <div class="modal-body" aria-hidden="true" role="dialog" aria-labelledby="LoadingContent" data-backdrop="static"
    data-keyboard="false">
    <h3 class="text-center text-capitalize" tabindex="-1" aria-modal="true">Searching...</h3>
  </div>
</ng-template>

<ng-template #favouriteErrorTemplate let-modal>
  <div class="container-fluid favouriteActionError" role="dialog" aria-labelledby="favouriteError">
    <div class="row">
      <div class="col text-center">
        <i class="fa fa-ban" aria-hidden="true"></i>
      </div>
    </div>
    <div class="row" [hidden]="blnFavouriteActionError">
      <div class="col paragraph large text-center" tabindex="-1" aria-modal="true">
        Your saved prescriber limit is ten. Please remove an existing saved prescriber to continue.
      </div>
    </div>
    <div class="row" [hidden]="!blnFavouriteActionError">
      <div class="col paragraph large text-center" tabindex="-1" aria-modal="true">
        An error occured. Please try again later.
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Ok</button>
      </div>
    </div>
  </div>
</ng-template>

<!-- Recent Searches Modal -->
<ng-template #recentPrescribers let-modal>
  <div class="modal-header" aria-hidden="true" role="dialog" aria-labelledby="recentPrescribers">
    <h2 class="modal-title" id="recentPrescribersList" tabindex="-1" aria-modal="true">Prescriber search history</h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <datatable [headers]="recentPrescribersHeaders" [rows]="recentPrescribersHistory"
      (onHeaderClicked)="onRecentPrescribersHeaderClick($event)" (onCellClicked)="onRecentPrescribersCellClick($event)" aria-labelledby="recentPrescribersList">
    </datatable>
  </div>
  <div class="modal-body" *ngIf="this.recentPrescribersHistory.length==0">
    <h6>{{this.message}}</h6>
  </div>
</ng-template>

<!-- Saved Searches Modal -->
<ng-template #savedPrescribers let-modal>
  <div class="modal-header" aria-hidden="true" role="dialog" aria-labelledby="savedPrescribers">
    <h2 class="modal-title" id="savedPrescribersList" tabindex="-1" aria-modal="true">Saved prescribers</h2>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click');"></button>
  </div>
  <div class="modal-body">
    <datatable [headers]="savedPrescribersHeaders" [rows]="savedPrescribersHistory"
      (onHeaderClicked)="onSavedPrescribersHeaderClick($event)" (onCellClicked)="onSavedPrescribersCellClick($event)" aria-labelledby="savedPrescribersList">
    </datatable>
  </div>
  <div class="modal-body" *ngIf="this.savedPrescribersHistory.length==0">
    <h6>{{this.savedMessage}}</h6>
  </div>
</ng-template>


<ng-template #errorPopup let-modal>
  <div class="p-3" role="dialog" aria-labelledby="errorPopup">
    <div class="row text-center"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></div>
    <div class="text-center mt-2" tabindex="-1" aria-modal="true">
                  An error occured. Please try again.</div>
    <div class="row border-0 mt-4 p-3 "><button
        class="col-2  position-absolute top-80 start-50 translate-middle btn btn btn-primary"
        (click)="modal.dismiss()">OK</button></div>
  </div>
</ng-template>