<div class="container ng-scope" style="position: relative; top: 100px;">
    <div class="row pb-5">
        <div class="col-1"></div>
        <div class="col-md-10 col-md-offset-1 col-sm-10 col-sm-offset-1 col-xs-10 col-xs-offset-1">
            <p class="heading_mega">Link Privacy Policy</p>
            <p class="heading_3">Introduction</p>
            <p class="paragraph">This policy applies to users of the website.</p>
            <br>
            <p class="paragraph">We recognize that the privacy of your personal information is important.
                The purpose of this policy is to let you know how we handle the information processed by
                this website. Portions of this statement may describe privacy practices applicable to
                specific types of information or to information provided on specific modules of the software
                application.</p>
            <br>
            <p class="paragraph">This policy does not apply to information collected through other means
                such as by telephone or in person or to information submitted, although that information may
                be protected by other privacy policies. As used in this policy, terms such as "we" or "our"
                and "Company" refer to Optum and its current and future affiliated entities.</p>
            <br>
            <p class="paragraph">This software application is intended for a United States audience. Any
                information processed by the system, including any personal information, will be transferred
                to and processed by a computer server located within the United States.</p>
            <br><br>
            <p class="heading_3">Cookies and Tracking</p>
            <p class="paragraph">The Company may use various technologies, which may include "cookie"
                technology, to gather information from our website visitors such as pages visited and how
                often they are visited, and to enable certain features on this website. "Cookies" are small
                text files that may be placed on your computer when you visit a website or click on a URL.
                Cookies may include "single-session cookies" which generally record information during only
                a single visit to a website and then are erased, and "persistent" cookies, which are
                generally stored on a computer unless or until they are deleted or are set to expire.</p>
            <br>
            <p class="paragraph">You may disable cookies and similar items by adjusting your browser
                preferences at any time; however, this may limit your ability to take advantage of all the
                features on this website. Note that we do not currently respond to web browser "Do Not
                Track" signals that provide a method to opt out of the collection of information about
                online activities over time and across third-party websites or online services because,
                among other reasons, there is no common definition of such signals and no industry-accepted
                standards for how such signals should be interpreted.</p>
            <br>
            <p class="paragraph">We may also allow third party service providers to use cookies and other
                technologies to collect information and to track browsing activity over time and across
                third party websites such as web browsers used to read our websites, which websites are
                referring traffic or linking to our websites, what IP addresses are accessing our website,
                and to deliver targeted advertisements to you. We do not control these third party
                technologies and their use is governed by the privacy policies of third parties using such
                technologies. For more information about third party advertising networks and similar
                entities that use these technologies, see http://www.aboutads.info/consumers, and to opt-out
                of such ad networks' and services' advertising practices, go to www.aboutads.info/choices.
                Once you click the link, you may choose to opt-out of such advertising from all
                participating advertising companies or only advertising provided by specific advertising
                companies.</p>
            <br>
            <p class="paragraph">We may use analytics companies to gather information and aggregate data
                from our website visitors such as which pages are visited and how often they are visited,
                and to enable certain features on our websites. Information is captured using various
                technologies and may include cookies.</p>
            <br>
            <p class="paragraph">We may use and disclose your activity information unless restricted by this
                policy or by law. Some examples of the ways we use your activity information include:</p>
            <br>
            <ul>
                <li>
                    <p class="paragraph">Customizing your experience on the website including managing and
                        recording your preferences.</p>
                </li>
                <li>
                    <p class="paragraph">Marketing, product development, and research purposes.</p>
                </li>
                <li>
                    <p class="paragraph">Tracking resources and data accessed on the website.</p>
                </li>
                <li>
                    <p class="paragraph">Developing reports regarding website usage, activity, and
                        statistics.</p>
                </li>
                <li>
                    <p class="paragraph">Assisting users experiencing website problems.</p>
                </li>
                <li>
                    <p class="paragraph">Enabling certain functions and tools on this website.</p>
                </li>
                <li>
                    <p class="paragraph">Tracking paths of visitors to this website and within this website.
                    </p>
                </li>
            </ul>
            <br>
            <p class="heading_3">Your Personal Information</p>
            <p class="paragraph">This website may require and collect limited personal information about you
                to allow for your use of the application.</p>
            <br>
            <p class="paragraph">In the event the website gives you the opportunity to provide us with
                personal information about yourself, you do not have to provide us with personal information
                if you do not want to; however, that may limit your ability to use certain functions of this
                website or to request certain services or information.</p>
            <br>
            <p class="paragraph">We may use personal information for a number of purposes such as:</p>
            <br>
            <ul>
                <li>
                    <p class="paragraph">To respond to an email or particular request from you.</p>
                </li>
                <li>
                    <p class="paragraph">To personalize the website for you.</p>
                </li>
                <li>
                    <p class="paragraph">To process an application as requested by you.</p>
                </li>
                <li>
                    <p class="paragraph">To administer surveys and promotions.</p>
                </li>
                <li>
                    <p class="paragraph">To provide you with information that we believe may be useful to
                        you, such as information about health products or services provided by us or other
                        businesses.</p>
                </li>
                <li>
                    <p class="paragraph">To perform analytics and to improve our products, websites, and
                        advertising.</p>
                </li>
                <li>
                    <p class="paragraph">To comply with applicable laws, regulations, and legal process.</p>
                </li>
                <li>
                    <p class="paragraph">To protect someone's health, safety, or welfare.</p>
                </li>
                <li>
                    <p class="paragraph">To protect our rights, the rights of affiliates or related third
                        parties, or take appropriate legal action, such as to enforce our Terms of Use.</p>
                </li>
                <li>
                    <p class="paragraph">To keep a record of our transactions and communications.</p>
                </li>
                <li>
                    <p class="paragraph">As otherwise necessary or useful for us to conduct our business, so
                        long as such use is permitted by law.</p>
                </li>
            </ul>
            <br>
            <p class="paragraph">We may use personal information to contact you through any contact
                information you provide through this website, including any email address, telephone number,
                cell phone number, text message number, or fax number. Please see the section below titled
                "Our Online Communications Practices."</p>
            <br>
            <p class="paragraph">We may also share personal information within the Company, and we may
                combine personal information that you provide us through this website with other information
                we have received from you, whether online or offline, or from other sources such as from our
                vendors. For example, if you have purchased a product or service from us, we may combine
                personal information you provide through this website with information regarding your
                receipt of the product or service.</p>
            <br>
            <p class="heading_3">Sharing Personal Information</p>
            <p class="paragraph">We will only share your personal information with third parties as outlined
                in this policy and as otherwise permitted by law.</p>
            <br>
            <p class="paragraph">We may share personal information if all or part of the Company is sold,
                merged, dissolved, acquired, or in a similar transaction.</p>
            <br>
            <p class="paragraph">We may share personal information in response to a court order, subpoena,
                search warrant, law or regulation. We may cooperate with law enforcement authorities in
                investigating and prosecuting activities that are illegal, violate our rules, or may be
                harmful to other visitors.</p>
            <br>
            <p class="paragraph">We may also share personal information with other third party companies
                that we collaborate with or hire to perform services on our behalf. For example, we may hire
                a company to help us send and manage email, and we might provide the company with your email
                address and certain other information in order for them to send you an email message on our
                behalf. Similarly, we may hire companies to host or operate some of our websites and related
                computers and software applications.</p>
            <br>
            <p class="heading_3">Website and Information Security</p>
            <p class="paragraph">We maintain reasonable administrative, technical and physical safeguards
                designed to protect the information that processed by this software application. However, no
                security system is impenetrable and we cannot guarantee the complete security of our
                web-based environment, nor can we guarantee that information will not be intercepted while
                being transmitted to us over the Internet, and we are not liable for the illegal acts of
                third parties such as criminal hackers.</p>
            <br>
            <p class="heading_3">Our Online Communication Practices</p>
            <p class="paragraph">We may send electronic newsletters, notification of account status, and
                other communications, such as marketing communications, on a periodic basis to various
                individuals and organizations. We may also send email communications regarding topics such
                as website updates. We offer you appropriate consent mechanisms, such as opt-out, for
                marketing and certain other communications. As examples, you may opt-out as provided for in
                a specific email communication or contact us as described below in the section "Contact Us."
                Please be aware that opt-outs may not apply to certain types of communications, such as
                account status, website updates, or other communications.</p>
            <br>
            <p class="heading_3">Information for Children Under 13</p>
            <p class="paragraph">We will not intentionally collect any personal information from children
                under the age of 13 through this website without receiving parental consent. If you think
                that we have collected personal information from a child under the age of 13 through this
                website, please contact us.</p>
            <br>
            <p class="heading_3">Contact Us</p>
            <p class="paragraph">To contact us regarding this policy and our related privacy practices,
                please email or write to us at: privacy@optum.com or Optum Privacy Office, MN101-E013, 11000
                Optum Circle, Eden Prairie, MN 55344. If you believe we or any company associated with us
                has misused any of your information please contact us immediately and report such misuse.
            </p>
            <br>
            <p class="heading_3">Effective Date</p>
            <p class="paragraph">The effective date of this policy is June 16, 2016.</p>
            <br>
            <p class="heading_3">Changes to this Website Privacy Policy</p>
            <p class="paragraph">We may change this policy. If we do so, such change will appear on this
                statement as it appears in our software application. We will also provide appropriate notice
                and choices to you, through normal customer communication channels, based on the scope and
                extent of changes. You may always visit this policy to learn of any updates.</p>
            <br>
            <p class="heading_3">Social Security Number Protection Policy</p>
            <p class="paragraph">Protecting personal information is important to Optum. It is our policy to
                protect the confidentiality of Social Security numbers ("SSNs") that are part of the data
                processed by our systems. We secure the confidentiality of SSNs through various means,
                including physical, technical, and administrative safeguards that are designed to protect
                against unauthorized access. It is our policy to limit access to SSNs to that which is
                lawful, and to prohibit unlawful disclosure of SSNs.</p>
        </div>
    </div>
</div>
        <section id="footer" class="d-print-none">
            <nav class="navbar navbar-expand-lg bg-light">
                <div class="container-fluid nav-footer text-center">
                    <div class="row">
                        <div class="col">
                            <ul>
                                <li aria-label="2021 Optum, Inc. All rights reserved">&#169; 2021 Optum, Inc. All rights
                                    reserved</li>
                                &nbsp;|&nbsp;
                                <li><a routerLink="/privacy" aria-label="Link to Privacy Policy">Privacy policy</a></li>
                                &nbsp;|&nbsp;
                                <li><a routerLink="/terms" aria-label="Link to Terms of Use">Terms of use</a></li>
                                &nbsp;|&nbsp;
                                <li><a routerLink="/contactus" aria-label="Link to Contact Us">Contact us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </section>
    