import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DateFieldEvent, DateFieldResultType, DateFieldValidationMode } from 'src/app/modals/datefield/datefield';
import { VIEWALL_DATERANGE_LEBELS, VIEWALL_DATERANGE} from 'src/app/constants/appconstant';
import { ViewAllRouteState, PAGE_TYPE } from 'src/app/constants/routeconstants';
import { DatefieldComponent } from 'src/app/components/datefield/datefield.component';
import { SavedclaimhistoryComponent } from 'src/app/components/savedclaimhistory/savedclaimhistory.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-smapclaims',
  templateUrl: './smapclaims.component.html',
  styleUrls: ['./smapclaims.component.css']
})
export class SmapclaimsComponent implements OnInit {

  state!:ViewAllRouteState;

  savedClaimPageType:PAGE_TYPE = PAGE_TYPE.SAVEDCLAIMS;
  bookmarkedSavedClaimPageType:PAGE_TYPE=PAGE_TYPE.BOOKMARKCLAIMS;

  @ViewChild("bookmarkmodal", {static:true})
  bookmarkmodalElementRef?:ElementRef<HTMLElement>;

  constructor(private router: Router,
    private modalService: NgbModal, private logger: LoggerService,private titleService:Title) { 
      this.titleService.setTitle("View All Saved Claims");
  }

  ngOnInit(): void {
    try{
      // debugger;
      const savedState:ViewAllRouteState = {pageType: PAGE_TYPE.SAVEDCLAIMS};
      sessionStorage.setItem("state",JSON.stringify(savedState));
      sessionStorage.setItem("lastPageBeforeReload", this.router.url);
      if(history.state.pageType!=undefined)
        this.state = history.state;
      else
        this.state=savedState;
    } catch(error){

    }
  }

  goToSelectMember(){
    //console.log("Redirecting to Select Member");
    this.logger.userAction(" Going to Select member");
    this.router.navigateByUrl("/coverage/member");
  }

  openBookmarks():void{
    this.logger.userAction("Opening Bookmarks");
    this.modalService.open(this.bookmarkmodalElementRef, { modalDialogClass: 'modalDafault', size: 'lg',backdrop: 'static', keyboard: true });
  }
}
