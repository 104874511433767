import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-progress-tabs',
  templateUrl: './progress-tabs.component.html',
  styleUrls: ['./progress-tabs.component.css']
})
export class ProgressTabsComponent implements OnInit, OnChanges {

  @Input() route = "";
  eventenabled: boolean;
  currentState: number = 1;

  constructor(private router: Router) {
    this.eventenabled = true;
      router.events.subscribe((event: Event)=>{
        
        if (event instanceof NavigationEnd) {
          if(this.eventenabled = event.urlAfterRedirects.indexOf("/coverage/member") != -1) {
            this.currentState= 1;
            //console.log(this.eventenabled, this.currentState);
          } 
          else if(this.eventenabled = event.urlAfterRedirects.indexOf("/coverage/prescriber") != -1){
            this.currentState= 2; 
            //console.log(this.eventenabled, this.currentState);
          }
         else if (this.eventenabled = event.urlAfterRedirects.indexOf("/coverage/drug") != -1) {
          this.currentState= 3; 
          //console.log(this.eventenabled, this.currentState);
            }
          else{
            this.currentState = 0;
          }
        }
      });
  }


  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
    //console.log("TEsting2");
    this.eventenabled = true;
    if (this.router.url.indexOf)
      if (this.eventenabled = this.router.url.indexOf("/coverage/member") != -1) {
        this.currentState = 1;
        //console.log(this.eventenabled, this.currentState);
      }
      else if (this.eventenabled = this.router.url.indexOf("/coverage/prescriber") != -1) {
        this.currentState = 2;
        //console.log(this.eventenabled, this.currentState);
      }
      else if (this.eventenabled = this.router.url.indexOf("/coverage/drug") != -1) {
        this.currentState = 3;
        //console.log(this.eventenabled, this.currentState);
      }else if(this.eventenabled = this.router.url.indexOf("/resubmitePA/provider") != -1){
        this.currentState = 4;
        //console.log(this.eventenabled, this.currentState);
      }
  }


}
