<div class="content-card bg-white" *ngIf="blnShow">
    <div class="card-header">
        Evaluation questions
    </div>
    <div class="card-content">
        <p class="paragraph-small">
            You must answer all of the following questions to complete the request.
            <br/>
            If you do not answer the questions, your request will be delayed or denied.
        </p>
    </div>
    <div class="card-footer">
        <div class="container-fluid nopadding">
            <div class="row">
                <div class="col-md-3 col-sm-6" >
                    <label class="form-label">Member name</label>
                    <br/>
                    <div>
                        {{patient.firstName}} {{patient.lastName}}
                    </div>
                </div>
                <div class="col-md-3 col-sm-6">
                    <label class="form-label">Medication</label>
                    <br/>
                    <div>
                        {{medication.drugNDAname}}
                    </div>
                </div>
                <div class="col-md-3 col-sm-6">
                    <label class="form-label">Case ID</label>
                    <br/>
                    <div>
                        {{paCaseId}}
                    </div>
                </div>
                <div class="col-md-3 col-sm-6">
                    <label class="form-label">PA reference ID</label>
                    <br/>
                    <div>
                        {{paRefId.completePaRefId}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>