<div class="container-fluid viewAll realclaims" *ngIf="state.pageType==2">
    <div class="row">
        <div class="col-md-8 offset-md-2 col-xs-12">
            <h1 class="fs-3">Submit a medication claim</h1>
            <br/>
            <p class="large">Submit a medication claim from a saved trial below. Trial claims must be re-executed before submitting the claim for payment. You can update medication and prescriber information by clicking the change link. You can also bookmark a claim for easy duplication in the future. Narrow your results by using the filter or search options.</p>
            <br/>
        </div>
    </div>
    <div class="row">
        <div class="col-md-8 offset-md-2 col-xs-12 p-0">
            <a class="btn underline btn-link fontbold" (click)="goToSelectMember()" (keydown.enter)="goToSelectMember()" tabindex="0">
                Check medication coverage if member is not listed
            </a>
        </div>
    </div>
    <div class="row">
        <div class="col-md-8 offset-md-2 col-xs-12">
            <h2 class="float-start">
               <span> Saved trial claims</span>
            </h2>
            <button class="underline fontbold float-end" (click)="openBookmarks()" tabindex="0">
                Bookmarked claims
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-8 offset-md-2 col-xs-12 mb-4">
            <savedclaimhistory [pageType]="savedClaimPageType"></savedclaimhistory>
        </div>
    </div>
</div>

<ng-template #bookmarkmodal let-modal>
    <div class="modal-header" aria-labelledby="bookmarkmodal" role="dialog" aria-hidden="true">
        <h2 class="modal-title fontbold" tabindex="-1" aria-modal="true">Bookmarked claims</h2>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Bookmarked claim modal closed')"></button>
    </div>
    <div class="modal-body nopadding">
        <savedclaimhistory [pageType]="bookmarkedSavedClaimPageType" [bookmarked]="true"></savedclaimhistory>
    </div>
</ng-template>