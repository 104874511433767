<div class="container-fluid col-md-8 alert alert-success shadow  mt-3 mb-3" style="position: absolute;  left: 16%;"
    role="alert" *ngIf="this.isSavedNotify==true">
    <i class="fa fa-check" aria-hidden="true" style="color: #1D781D;"></i>
    <span style="color: #222222;">Case ID: {{this.isCancelPAparam}} has been successfully saved.</span>
</div>
<div class=" container-fluid col-md-8 alert alert-success shadow  mt-3 mb-3"  style="position: absolute; left: 16%;" role="alert"
    *ngIf="this.isCancelled==true && iscancelPA!=null">
    <i class="fa fa-check" aria-hidden="true" style="color: #1D781D;"></i>
    <span style="color: #222222;">Case ID: {{this.isCancelPAparam}} has been successfully cancelled.</span>
</div>
<div class="smapbggredient">
    <div class="container-fluid smapbackground">
        <div class="row">
            <div class="col-md-8 col-xs-10">
                <h1 class="smap_title">
                    <span>Specialty Management Access Portal</span>
                </h1>
            </div>
        </div>

        <div class="row">
            <!-- <div class="col-lg-2 .d-md-none .d-sm-none"></div> -->
            <div class="col-md-6 col-sm-6 col-xs-12">
                <a class="p-0 col-12" tabindex="0" (click)="goto('coverage')" (keyup.enter)="goto('coverage')"
                    aria-label="Link to Check Medication Coverage">
                    <figure class="figure thumbnail">
                        <img src="./../../../assets/images/Icon_PillBottle_RGB.svg" class="figure-img img-fluid rounded"
                            alt="Check coverage caption" aria-hidden="true" focusable="false">
                        <figcaption class="figure-caption">
                            <h2>Check medication coverage</h2>
                            <p></p>
                            <p><span>Submit a trial claim to determine medication coverage and retrieve
                                    pricing.</span></p>
                        </figcaption>
                    </figure>
                </a>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
                <a class="p-0 col-12" tabindex="0" (click)="gotoSavedClaims()" (keyup.enter)="gotoSavedClaims()"
                    aria-label="Link to Submit a Medication Claim">
                    <figure class="figure thumbnail">
                        <img src="./../../../assets/images/Icon_Desktop_RGB.svg" class="figure-img img-fluid rounded"
                            alt="Submit a Medication Claim" aria-hidden="true" focusable="false">
                        <figcaption class="figure-caption">
                            <h2>Submit a medication claim</h2>
                            <p></p>
                            <p>Submit medication claims for billing from saved trial claim values.</p>
                        </figcaption>
                    </figure>
                </a>
            </div>
        </div>

        <div class="row  accordin-hide">
            <div class="col">
                <br />
            </div>
        </div>

        <div class="row">
            <!-- <div class="col-lg-2 .d-md-none .d-sm-none"></div> -->
            <div class="col-md-6 col-sm-6 col-xs-12">
                <a class="p-0 col-12" tabindex="0" (click)="gotoSearchMedicationClaims()" (keyup.enter)="gotoSearchMedicationClaims()"
                    aria-label="Link to Search Medication Claims">
                    <figure class="figure thumbnail">
                        <img src="./../../../assets/images/Icon_MagnifyingGlass_RGB.svg"
                            class="figure-img img-fluid rounded" alt="Search Medication Claims" aria-hidden="true"
                            focusable="false">
                        <figcaption class="figure-caption">
                            <h2>Search medication claims</h2>
                            <p></p>
                            <p>Search and view active submitted trial and paid medication claims and complete claim
                                reversals.</p>
                        </figcaption>
                    </figure>
                </a>
            </div>

            <div class="col-md-6 col-sm-6 col-xs-12">
                <a class="p-0 col-12" tabindex="0" (click)="gotoPaidClaims()" (keyup.enter)="gotoPaidClaims()"
                    aria-label="Link to Medication Claim Reversals">
                    <figure class="figure thumbnail">
                        <img src="./../../../assets/images/Icon_GoDigital_RGB.svg" class="figure-img img-fluid rounded"
                            alt="Medication Claim Reversals" aria-hidden="true" focusable="false">
                        <figcaption class="figure-caption">
                            <h2>Medication claim reversals</h2>
                            <p></p>
                            <p>View claims that have been submitted and allow claim reversals.</p>
                        </figcaption>
                    </figure>
                </a>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col">
        <br />
    </div>
</div>
<div class="container-fluid tabs-container">
    <div class="row accordin-hide">
        <!-- <div class="col-lg-2 .d-md-none .d-sm-none"></div> -->
        <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="tabs-header">
                <ul class="nav nav-tabs text-center" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link" [ngClass]="{'active':activeTab==1 || activeTab==0}" (click)="activeTab=1"
                            aria-current="page" href="javascript:void(0)" id="claimhistory" role="tab" aria-label="Submitted claims">Submitted
                            claims</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [ngClass]="{'active':activeTab==2}" (click)="activeTab=2"
                            href="javascript:void(0)" id="pahistory" role="tab" aria-label="Submitted prior authorizations">Submitted prior authorizations</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [ngClass]="{'active':activeTab==3}" (click)="activeTab=3"
                            href="javascript:void(0)" id="savedpahistory" role="tab" aria-label="Prior authorizations in progress">Prior authorizations in
                            progress</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row accordin-hide">
        <!-- <div class="col-lg-2 .d-md-none .d-sm-none"></div> -->
        <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="tab-content">
                <div class="tab-pane fade show active shadow" *ngIf="activeTab==1 || activeTab==0" role="tabpanel"
                    aria-labelledby="claimhistory">
                    <a tabindex="0" class="btn btn-secondary float-end rounded-pill" aria-label="View all claims" (click)="gotoAllSubmittedClaims()" (keyup.enter)="gotoAllSubmittedClaims()">View all
                        claims</a>
                    <h2>Submitted claims</h2>
                    <p class="large">Most recent claims are listed below. Click the "View all claims" button to view
                        a sortable list of all claims.
                        <br />If the claim status looks incorrect, click the refresh icon to update it in real time.
                    </p>
                    <savedclaimhistory [pageType]="3" [isHomePage]="true" [showStatus]="true"></savedclaimhistory>
                </div>
                <div class="tab-pane fade show active shadow" *ngIf="activeTab==2" role="tabpanel"
                    aria-labelledby="pahistory">
                    <a tabindex="0" class="btn btn-secondary float-end rounded-pill" aria-label="View all submitted PAs" (click)="gotoViewAllSubmittedPA()" (keyup.enter)="gotoViewAllSubmittedPA()">View all
                        submitted PAs</a>
                    <h2>Submitted prior authorizations</h2>
                    <p class="large">Below are the most recent submitted prior authorizations within the past 30
                        days.</p>
                    <section>
                        <submittedpahistory [maxResults]="maxHistoryCount"></submittedpahistory>
                    </section>
                </div>
                <div class="tab-pane fade show active shadow" *ngIf="activeTab==3" role="tabpanel"
                    aria-labelledby="savedpahistory">
                    <a tabindex="0" class="btn btn-secondary float-end rounded-pill" aria-label="View all PAs in progress" (click)="gotoViewAllSavedPA()" (keyup.enter)="gotoViewAllSavedPA()">View all PAs
                        in progress</a>
                    <h2>Prior authorizations in progress</h2>
                    <p class="large">Below are the most recent saved prior authorizations within the past 30 days.
                    </p>
                    <section class="pb-4">
                        <savedpahistory [maxResults]="maxHistoryCount"></savedpahistory>
                    </section>
                </div>
            </div>
        </div>
    </div>  

    <div class="shadow accordion accordin-show mt-5 pt-3" id="accordionFlushExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingOne">
            <button class="accordion-button collapsed" [ngClass]="{'active':activeTab==1}" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne" (click)="activeTab==1?activeTab=0:activeTab=1">
                Submitted claims        
            </button>
          </h2>
          <div id="flush-collapseOne" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">
                <div class="tab-pane fade show active" *ngIf="activeTab==1" role="tabpanel"
                    aria-labelledby="claimhistory">
                    <a tabindex="0" class="btn btn-secondary float-end rounded-pill" aria-label="View all claims" (click)="gotoAllSubmittedClaims()">View all
                        claims</a>
                    <h3>Submitted claims</h3>
                    <p class="large">Most recent claims are listed below. Click the "View all claims" button to view
                        a sortable list of all claims.
                        <br />If the claim status looks incorrect, click the refresh icon to update it in real time.
                    </p>
                    <section>
                    <savedclaimhistory [pageType]="3" [isHomePage]="true" [showStatus]="true"></savedclaimhistory>
                    </section>
                </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingTwo">
            <button class="accordion-button collapsed" [ngClass]="{'active':activeTab==2}" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo" (click)="activeTab==2?activeTab=0:activeTab=2">
                Submitted prior authorizations
            </button>
          </h2>
          <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">
                <div class="tab-pane fade show active" *ngIf="activeTab==2" role="tabpanel"
                    aria-labelledby="pahistory">
                    <a tabindex="0" class="btn btn-secondary float-end rounded-pill" aria-label="View all submitted PAs" (click)="gotoViewAllSubmittedPA()">View all
                        submitted PAs</a>
                    <h3>Submitted prior authorizations</h3>
                    <p class="large">Below are the most recent submitted prior authorizations within the past 30
                        days.</p>
                    <section>
                        <submittedpahistory [maxResults]="maxHistoryCount"></submittedpahistory>
                    </section>
                </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingThree">
            <button class="accordion-button collapsed" [ngClass]="{'active':activeTab==3}" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree" (click)="activeTab==3?activeTab=0:activeTab=3">
                Prior authorizations in progress
            </button>
          </h2>
          <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">
                <div class="tab-pane fade show active" *ngIf="activeTab==3" role="tabpanel"
                    aria-labelledby="savedpahistory">
                    <a tabindex="0" class="btn btn-secondary float-end rounded-pill" aria-label="View all PAs in progress" (click)="gotoViewAllSavedPA()">View all PAs
                        in progress</a>
                    <h3>Prior authorizations in progress</h3>
                    <p class="large">Below are the most recent saved prior authorizations within the past 30 days.
                    </p>
                    <section class="pb-4">
                        <savedpahistory [maxResults]="maxHistoryCount"></savedpahistory>
                    </section>
                </div>
            </div>
          </div>
        </div>
      </div>
</div>

