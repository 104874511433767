import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { LoadNextQuestionEvent, Question, UserGivenAnswer, AnswerValue } from 'src/app/modals/epa/questionsetviewmodal';
import { DateFieldEvent, DateFieldResultType, DateFieldValidationMode } from 'src/app/modals/datefield/datefield';
import { LoggerService } from 'src/app/services/logger/logger.service';

@Component({
  selector: 'datequestion',
  templateUrl: './datequestion.component.html',
  styleUrls: ['./datequestion.component.css']
})
export class DatequestionComponent implements OnInit {

  constructor(private logger: LoggerService) {
    this.dobValidationMode = DateFieldValidationMode.ONCHANGE;
    this.answerValue="";
    this.blnIsEmptyValid=false;
    this.futureMonthsAllowed=3;
    this.dateFieldName="Date field";
  }

  dobValidationMode: DateFieldValidationMode;
  dobResult: DateFieldResultType = DateFieldResultType.EMPTY;
  answerValue:string;
  blnIsEmptyValid:boolean;
  futureMonthsAllowed:number;
  dateFieldName:string;
  onDobUpdate(event: DateFieldEvent): void {
    this.answerValue = event.value;
    this.dobResult = event.valid;
  }

  @Input()
  question!:Question;

  @Output()
  nextQuestion:EventEmitter<LoadNextQuestionEvent> = new EventEmitter<LoadNextQuestionEvent>();

  ngOnInit(): void {
    if(this.question!=undefined && this.question.questionType!=undefined && this.question.questionType.date!=undefined
      && this.question.questionType.date.answer!=undefined && this.question.questionType.date.answer.prescriberProvidedAnswer!=undefined
      && this.question.questionType.date.answer.prescriberProvidedAnswer.date!=undefined){
        this.answerValue=this.question.questionType.date.answer.prescriberProvidedAnswer.date;
        this.submit(true);
    }
  }

  submit(blnOnInit:boolean=false):void{
    if(this.dobResult == DateFieldResultType.SUCCESS){
      this.logger.userAction("Submitted Date Question EPA.");
      this.question.questionType.date.answer = new UserGivenAnswer<AnswerValue>();
      this.question.questionType.date.answer.prescriberProvidedAnswer.date = this.answerValue;
      const nextQuestionEvent:LoadNextQuestionEvent = new LoadNextQuestionEvent();
      nextQuestionEvent.currentQuestionID = this.question.questionID;
      nextQuestionEvent.blnOnInit = blnOnInit;
      this.nextQuestion.emit(nextQuestionEvent);
    }
  }
}
