import {ClaimDetailsHistory} from "./claimhistorywebresponse";

export class Claimhistorywebrequest {
    presbrId?:string;
    infsnPharmacyNPI!:string;
    maxRecords!:string;
    memberId!:string;
    instanceId!:string;
    claimStsTypList!:number[];
    toDate!:string;
    fromDate!:string;
}

export class SavedClaimHistory extends ClaimDetailsHistory{
    standardDate?:string;
    showBookmarkOptions?:boolean=false;
    showAnimationAfterBookmark?:boolean=false;
    bookmarkAnimationStatusTxt?:string="";
}

export class ClaimUpdateWebRequest{
    claimStsTypId!:number;
    claimDetailId!:number;
}

export class ClaimBookmarkWebRequest extends ClaimUpdateWebRequest {
    presbrId!:string;
    isDelete!:string;
}