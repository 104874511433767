import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MemberPAHistorywebrequest } from 'src/app/modals/pahistory/pahistorywebrequest';
import { environment } from 'src/environments/environment';
import { VIEWALL_SUBMITTEDPA_STATUS, VIEWALL_SUBMITTEDPA_STATUS_LABELS } from '../constants/appconstant';
import { TableHeaderCellData, TableRowData, DataTableType, TableCellData, TableClickEvent } from '../modals/datatable/datatable';
import { Drugsearchwebrequest, MemberSearchItem } from '../modals/drugSearch/drugsearchwebrequest';
import { Drugsearchwebresponse } from '../modals/drugSearch/drugsearchwebresponse';
import { Infusion } from '../modals/login/loginwebresponse';
import { MemberSearchV5Item } from '../modals/membersearch/membersearchwebresponse';
import { PAResponseHistory, PAHistorywebresponse } from '../modals/pahistory/pahistorywebresponse';
import { DatetoDisplayString } from '../pipes/dateconversion/dateconversion.pipe';
import { FilterobjectsByTextPipe } from '../pipes/filterobjects/filterobjects.pipe';
import { StringToSentenceCase } from '../pipes/stringconvertions/stringconversions.pipe';
import { DrugsearchService } from '../services/drugSearch/drugsearch.service';
import { MembersearchService } from '../services/membersearch/membersearch.service';
import { PahistoryService } from '../services/pahistory/pahistory.service';
import { ProfileService } from '../services/profile/profile.service';

@Component({
  selector: 'membersubmittedpahistory',
  templateUrl: './member-submitted-pahistory.component.html',
  styleUrls: ['./member-submitted-pahistory.component.css']
})
export class MemberSubmittedPAHistoryComponent implements OnInit {

  submittedPaHeaders: TableHeaderCellData[];
  submittedPaHistory: TableRowData<PAResponseHistory>[];
  private sourceData: PAResponseHistory[];
  datatableType: DataTableType = DataTableType.TRANSPARENT;
  blnLoading: boolean;
  blnServerError: boolean;

  totalPages: number;
  currentPage: number;
  recordsPerPage: number = environment.paginationRecordsPerPage;
  totalEntries: number=0;

  private firstHeader: TableHeaderCellData = new TableHeaderCellData();
  private secondHeader: TableHeaderCellData = new TableHeaderCellData();
  private thirdHeader: TableHeaderCellData = new TableHeaderCellData();
  private forthHeader: TableHeaderCellData = new TableHeaderCellData();
  private fifthHeader: TableHeaderCellData = new TableHeaderCellData();
  private sixthHeader: TableHeaderCellData = new TableHeaderCellData();

  memberSearchResult!: MemberSearchV5Item | MemberSearchItem | undefined;


  @ViewChild("memberNameTemplate", { static: true })
  memberNameTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("drugNameTemplate", { static: true })
  drugNameTemplateRef?: TemplateRef<HTMLElement>

  @ViewChild("prescriberNameTemplate", { static: true })
  prescriberNameTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("caseIdTemplate", { static: true })
  caseIdTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("startDateTemplate", { static: true })
  startDateTemplate?: TemplateRef<HTMLElement>;

  @ViewChild("endDateTemplate", { static: true })
  endDateTemplate?: TemplateRef<HTMLElement>;

  @ViewChild("paCancelledTemplate", { static: true })
  paCancelledTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("paApprovedTemplate", { static: true })
  paApprovedTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("paDeniedTemplate", { static: true })
  paDeniedTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("paPendingTemplate", { static: true })
  paPendingTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("paPaidTemplate", { static: true })
  paPaidTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("paReversedTemplate", { static: true })
  paReversedTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("historyServerErrorTemplate", { static: true })
  historyServerErrorTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("errorPopup", { static: true })
  errorPopupTemplateRef?: TemplateRef<HTMLElement>;

  @Input()
  memberId!: string;

  @Input()
  searchText: string = "";
  private fieldsToConsiderForFilteration: string[];

  @Input()
  filterByStatus: VIEWALL_SUBMITTEDPA_STATUS = VIEWALL_SUBMITTEDPA_STATUS.ALL;

  private _fromDate: Date;
  public get fromDate(): Date {
    return this._fromDate;
  }
  @Input()
  public set fromDate(v: Date) {
    this._fromDate = v;
  }


  private _toDate: Date;
  public get toDate(): Date {
    return this._toDate;
  }
  @Input()
  public set toDate(v: Date) {
    this._toDate = v;
  }

  @Input()
  maxResults!: number;

  constructor(private pahistory: PahistoryService,
    private memberSearchHelper: MembersearchService,
    private loginProfile: ProfileService,
    private drugSearchHelper: DrugsearchService,
    private dateToDisplayStringConversion: DatetoDisplayString,
    private filterobjectsByTextPipe: FilterobjectsByTextPipe,
    private stringToSentenceCase: StringToSentenceCase, private modalService: NgbModal) {

    this.firstHeader.content = "Medication";
    this.firstHeader.sortable = true;

    this.secondHeader.content = "Prescriber";

    this.thirdHeader.content = "Case ID";

    this.forthHeader.content = "Start Date";
    this.forthHeader.sortable = true;
    this.forthHeader.selectedForSort = true;

    this.fifthHeader.content = "End Date";
    this.fifthHeader.sortable = true;

    this.sixthHeader.content = "Status";

    this.submittedPaHeaders = [];
    this.submittedPaHeaders.push(this.firstHeader);
    this.submittedPaHeaders.push(this.secondHeader);
    this.submittedPaHeaders.push(this.thirdHeader);
    this.submittedPaHeaders.push(this.forthHeader);
    this.submittedPaHeaders.push(this.fifthHeader);
    this.submittedPaHeaders.push(this.sixthHeader);

    this.submittedPaHistory = [];



    //Following dates are used to fetch history from server.
    let currentDate: Date = new Date();
    //Represent 12AM of next date. Meaning, It considers complete 24h of Today.
    currentDate.setDate(currentDate.getDate() + 1);
    this._toDate = currentDate;

    //Resenting to represent today's date.
    currentDate = new Date();
    //This history will be defaulted to last 30 days from now if no dates are supplied.
    currentDate.setDate(currentDate.getDate() - 30);
    this._fromDate = currentDate;

    this.totalPages = 1;
    this.currentPage = 1
    this.blnLoading = false;
    this.blnServerError = false;
    this.sourceData = [];
    this.fieldsToConsiderForFilteration = [];
    this.fieldsToConsiderForFilteration.push("lastName");
    this.fieldsToConsiderForFilteration.push("firstName");
    this.fieldsToConsiderForFilteration.push("drugName");
    this.fieldsToConsiderForFilteration.push("presbrLstName");
    this.fieldsToConsiderForFilteration.push("presbrFirstName");
    this.fieldsToConsiderForFilteration.push("caseId");
    this.fieldsToConsiderForFilteration.push("dateSubmitted");
    this.fieldsToConsiderForFilteration.push("paStatus");
  }

  ngOnInit(): void {
    this.memberSearchResult = this.memberSearchHelper.getSelectedMember();
    this.loginProfile.loginuser.selectedInfusion.subscribe((value: Infusion) => {
      this.reload();
    });


  }

  reload(blnHardReload: boolean = true): void {
    if (blnHardReload) {
      let webrequest: MemberPAHistorywebrequest = new MemberPAHistorywebrequest();
      webrequest.fromDate = this.dateToDisplayStringConversion.transform(this.fromDate);
      webrequest.toDate = this.dateToDisplayStringConversion.transform(this.toDate);
      webrequest.maxResults = this.maxResults;
      webrequest.infsnPhmcyNPI = this.loginProfile.loginuser.selectedInfusion.getValue().pharmacyNPI;
      webrequest.providerId = this.loginProfile.loginuser.UUID;
      webrequest.memberId = this.memberSearchResult?.memberId;
      webrequest.instanceId = this.memberSearchResult?.instanceId;
      webrequest.carrierId = this.memberSearchResult?.carrierId;
      webrequest.groupId = this.memberSearchResult?.groupId;
      webrequest.accountId = this.memberSearchResult?.accountId;
      webrequest.memberDOB = this.memberSearchResult?.dateOfBirth;
      webrequest.memberFirstName = this.memberSearchResult?.firstName;
      webrequest.memberLastName = this.memberSearchResult?.lastName;
      webrequest.resultsFromPAS = true;
      this.blnLoading = true;
      this.blnServerError = false;
      this.sourceData = [];
      this.resetPaginationDefaults();
      this.pahistory.getMemberSubmittedPAHistory(webrequest).then((response: PAHistorywebresponse) => {
        this.blnLoading = false;
        if (this.memberId != undefined) {
          this.sourceData = response.paResponseHistory.filter((item) => item.memberId == this.memberId);
        } else {
          this.sourceData = response.paResponseHistory;
        }
        //console.log("Member PA history:");
        //console.log(this.sourceData);

        for(let i=0;i<this.sourceData.length;i++) {

          let request: Drugsearchwebrequest = new Drugsearchwebrequest();
          request.ndc = this.sourceData[i].productId;
          request.instanceId = environment.instanceId;
          this.drugSearchHelper.getDrugSearchItems(request).then((response: Drugsearchwebresponse) => {
            //console.log("Selected ITem:"+response.response.drugSearchItems[0].detailedDrugName);
            //console.log(response);
            //console.log(this.sourceData[i]);
            this.sourceData[i].drugName =''+response.response.drugSearchItems[0].detailedDrugName;
            this.sourceData[i].firstName=''+this.memberSearchResult?.firstName;
            this.sourceData[i].lastName=''+this.memberSearchResult?.lastName;
          });
        }
        this.loadHistory();
      }, (error: any) => {
        //console.log("Member PA history Error:");
        this.submittedPaHistory = [];
        this.blnLoading = false;
        this.blnServerError = true;
        this.loadHistory();
      }).catch((reason: any) => {
        //console.log("Member PA historyCatch:");
        this.submittedPaHistory = [];
        this.blnLoading = false;
        this.blnServerError = true;
        this.loadHistory();
      });
    } else {
      this.loadHistory();
    }
  }
  private filterSourceDataByStatus(): PAResponseHistory[] {
    return this.sourceData.filter((history: PAResponseHistory) => {
      if (parseInt(this.filterByStatus.toString()) == VIEWALL_SUBMITTEDPA_STATUS.ALL)
        return true;
      if (history.paStatus != undefined && history.paStatus.length > 0)
        return VIEWALL_SUBMITTEDPA_STATUS_LABELS.get(parseInt(this.filterByStatus.toString())) == history.paStatus
          || VIEWALL_SUBMITTEDPA_STATUS_LABELS.get(parseInt(this.filterByStatus.toString())) == this.stringToSentenceCase.transform(history.paStatus);
      else
        return false;
    });
  }
  private resetPaginationDefaults(): void {
    this.currentPage = 1;
    this.totalPages = 1;
  }
  private readCurrentPagePaHistory(): PAResponseHistory[] {
    const filteredSourceData: PAResponseHistory[] = this.filterobjectsByTextPipe.transform(this.filterSourceDataByStatus(), this.searchText, this.fieldsToConsiderForFilteration);
    this.totalEntries=filteredSourceData.length;
    if (this.maxResults == undefined) {
      const startIndex: number = (this.currentPage - 1) * this.recordsPerPage;
      const endIndex: number = (this.currentPage * this.recordsPerPage);
      this.totalPages = (filteredSourceData.length % this.recordsPerPage) != 0 ? Math.floor(filteredSourceData.length / this.recordsPerPage) + 1 : Math.floor(filteredSourceData.length / this.recordsPerPage);

      return filteredSourceData.slice(startIndex, endIndex);
    } else {
      return filteredSourceData;
    }

  }
  private loadHistory(): void {
    this.submittedPaHistory = [];
    if (!this.blnServerError) {
      this.readCurrentPagePaHistory().forEach((history: PAResponseHistory) => {

        const row: TableRowData<PAResponseHistory> = new TableRowData<PAResponseHistory>();

        const firstCell: TableCellData<PAResponseHistory> = new TableCellData();
        firstCell.data = history;
        firstCell.sortable = true;
        firstCell.template = this.drugNameTemplateRef;
        row.cells.push(firstCell);

        const secondCell: TableCellData<PAResponseHistory> = new TableCellData();
        secondCell.data = history;
        secondCell.template = this.prescriberNameTemplateRef;
        row.cells.push(secondCell);

        const thirdCell: TableCellData<PAResponseHistory> = new TableCellData();
        thirdCell.data = history;
        thirdCell.template = this.caseIdTemplateRef;
        row.cells.push(thirdCell);

        const fourCell: TableCellData<PAResponseHistory> = new TableCellData();
        fourCell.data = history;
        fourCell.template = this.startDateTemplate;
        row.cells.push(fourCell);

        const fifthCell: TableCellData<PAResponseHistory> = new TableCellData();
        fifthCell.data = history;
        fifthCell.sortable = true;
        fifthCell.template = this.endDateTemplate;
        row.cells.push(fifthCell);

        const sixthCell: TableCellData<PAResponseHistory> = new TableCellData();
        sixthCell.data = history;
        switch (history.paStatus?.toLowerCase()) {
          case "cancelled":
          case "closed":
            sixthCell.template = this.paCancelledTemplateRef;
            break;
          case "approved":
            sixthCell.template = this.paApprovedTemplateRef;
            break;
          case "denied":
            sixthCell.template = this.paDeniedTemplateRef;
            break;
          case "resolved-denied":
            sixthCell.template = this.paDeniedTemplateRef;
            break;
          case "pending":
            sixthCell.template = this.paPaidTemplateRef;
            break;
          case "paid":
            sixthCell.template = this.paPaidTemplateRef;
            break;
          case "reversed":
            sixthCell.template = this.paReversedTemplateRef;
            break;
        }
        row.cells.push(sixthCell);

        this.submittedPaHistory.push(row);
      });
    } else {
      this.datatableType = DataTableType.TRANSPARENT;
      const row: TableRowData<PAResponseHistory> = new TableRowData<PAResponseHistory>();
      row.disabled = true;
      row.cssClass = "norow";

      const firstCell: TableCellData<PAResponseHistory> = new TableCellData<PAResponseHistory>();
      firstCell.template = this.historyServerErrorTemplateRef;
      firstCell.clickable = false;
      firstCell.colspan = this.submittedPaHeaders.length;
      row.cells.push(firstCell);
      this.submittedPaHistory.push(row);
    }

    //console.log("TableData");
    //console.log(this.submittedPaHistory);


  }
  onHeaderClick(headerData: TableClickEvent<TableHeaderCellData>) {
  }
  onCellClick(clickEvent: TableClickEvent<PAResponseHistory>) {
    if (clickEvent.event.target.getAttribute("name") == "tryagain") {
      this.reload();
    }
  }
  onPageChange(pageNumber: any) {
    this.currentPage = pageNumber;
    this.loadHistory();
  }

  onChangeEntriesPerPage(entriesPerPage: any){
    this.recordsPerPage = entriesPerPage;
    this.currentPage = 1;
    this.loadHistory();
  }
}




