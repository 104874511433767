<div class="container-fluid viewAll savedHistory mt-3">
    <div class="row">
        <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10">
            <h1 name="title" class="fs-3">Search for a Member</h1>
        </div>
    </div>
    <div class="row">
        <div class="offset-md-2 col-md-6 offset-xs-1 col-xs-8 mt-3">
            <p name="description" class="large">Enter the required fields to select a member.</p>
        </div>
    </div>
    <div class="row">
        <!-- <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10 mt-3"> -->
            <app-findmember [pageType]="pageType"></app-findmember>
        <!-- </div> -->
    </div>
    <div class="row">
        <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10 text-start backToHome">
            <i class="fa fa-angle-left"></i>
            &nbsp;
            <a class="btn btn-link backToHome" (click)="goto('home')" (keydown.enter)="goto('home')" tabindex="0">
                Back to SMAP homepage
            </a>
        </div>
    </div>
</div>