import { Component, OnInit, Input, Output, EventEmitter, HostListener} from '@angular/core';
import { KEY_CODE } from 'src/app/constants/appconstant';
import { TableHeaderCellData, TableRowData, TableClickEvent, TableCellData, DataTableType, TableRowClickEvent } from 'src/app/modals/datatable/datatable';
import { TablerowComponent } from './tablerow/tablerow.component';

@Component({
  selector: 'datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.css']
})
export class DatatableComponent implements OnInit {

  @Input()
  cssClass:string="";

  @Input()
  headers:TableHeaderCellData[]=[];

  @Input()
  rows:TableRowData<any>[]=[];

  @Input()
  rowIndex!:number;

  @Input()
  type:DataTableType = DataTableType.TRANSPARENT;

  @Output()
  onHeaderClicked:EventEmitter<TableClickEvent<any>>=new EventEmitter<TableClickEvent<any>>();

  @Output()
  onCellClicked:EventEmitter<TableClickEvent<any>>=new EventEmitter<TableClickEvent<any>>();


  onHeaderClick(_event:TableClickEvent<TableHeaderCellData>){
    this.onHeaderClicked.emit(_event);
  }

  onCellClick(_event:TableClickEvent<TableCellData<any>>){
    this.onCellClicked.emit(_event);
  }

  onRowClick(ev: KeyboardEvent,row:TableRowData<any>){
     if (ev.key === "Enter"){
      this.onCellClicked.emit({cell: row.cells[0],event:ev,column:0, row:this.rowIndex});
     }
  }

  getTableClass():string{
    let cssCls:string =this.cssClass;
    switch(this.type){
      case 1:
        cssCls += ' highlight_rows';
        break;
      case 2:
        cssCls += ' table-striped';
        break;
      case 3:
        cssCls += ' transparent collapsable';
        break;
      default:
        cssCls += ' transparent';
    }
    return cssCls;
  }
  constructor() { 
    
  }

  ngOnInit(): void {
  }

}