import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { MemberPAHistorywebrequest, PAHistorywebrequest } from './../../modals/pahistory/pahistorywebrequest';
import { MemberPAHistorywebresponse, PAHistorywebresponse } from './../../modals/pahistory/pahistorywebresponse';
import { APPCONSTANTS } from 'src/app/constants/appconstant';
import { environment } from 'src/environments/environment';
import { LoggerService } from '../logger/logger.service';

@Injectable({
  providedIn: 'root'
})
export class PahistoryService {

  constructor(private http: HttpClient, private logger: LoggerService) { }

  getSubmittedPAHistory(webrequest: PAHistorywebrequest): Promise<PAHistorywebresponse> {
    const promise: Promise<PAHistorywebresponse> = new Promise<PAHistorywebresponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.submittedPAHistoryUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        if (response.status != undefined
          && response.status.statusCode != undefined
          && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
          && response.status.dbStatusCode != undefined
          && response.status.dbStatusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
        ) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }

  getMemberSubmittedPAHistory(webrequest: MemberPAHistorywebrequest): Promise<MemberPAHistorywebresponse> {
    const promise: Promise<MemberPAHistorywebresponse> = new Promise<MemberPAHistorywebresponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.submittedMemberPAHistoryUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        if (response.status != undefined
          && response.status.statusCode != undefined
          && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }
  getSavedPAHistory(webrequest: PAHistorywebrequest): Promise<PAHistorywebresponse> {
    const promise: Promise<PAHistorywebresponse> = new Promise<PAHistorywebresponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.savedPAHistoryUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        //this.http.get("./assets/translation_en.json").subscribe((response:any)=>{
        if (response.status != undefined
          && response.status.statusCode != undefined
          && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
          && response.status.dbStatusCode != undefined
          && response.status.dbStatusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
        ) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }

  getMemberSavedPAHistory(webrequest: MemberPAHistorywebrequest): Promise<MemberPAHistorywebresponse> {
    const promise: Promise<MemberPAHistorywebresponse> = new Promise<MemberPAHistorywebresponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.savedMemberPAHistoryUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        //this.http.get("./assets/translation_en.json").subscribe((response:any)=>{
        if (response.status != undefined
          && response.status.statusCode != undefined
          && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
          && response.status.dbStatusCode != undefined
          && response.status.dbStatusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
        ) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }
}
