import { Component, OnInit, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MembersearchService } from 'src/app/services/membersearch/membersearch.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { FavouriteMember, FavouriteMembersWebResponse} from 'src/app/modals/membersearch/membersearchwebresponse';
import { TableCellData,TableClickEvent, TableHeaderCellData, TableRowData } from 'src/app/modals/datatable/datatable';
import { environment } from 'src/environments/environment';
import { Status } from 'src/app/modals/webresponsestatus';
import { APPCONSTANTS } from 'src/app/constants/appconstant';
import { LoggerService } from 'src/app/services/logger/logger.service';

enum FavouriteActionErrorType{
  NO_ERROR=0,
  RETRIEVE_ERROR=1,
  UNFAVOURITE_ERROR=2
}

@Component({
  selector: 'favouritemembers',
  templateUrl: './favouritemembers.component.html',
  styleUrls: ['./favouritemembers.component.css']
})
export class FavouritemembersComponent implements OnInit {

  private currentPageNumber!:number;
  recordsPerPage:number = environment.paginationRecordsPerPage;
  totalPagesCount!:number;  
  totalEntries: number=0;

  
  private _members !: FavouriteMember[];
  public get members() : FavouriteMember[] {
    return this._members;
  }
  public set members(v : FavouriteMember[]) {
    this._members = v;
    this.currentPageNumber=1;
    this.generateSavedMembers();
  }
  
  favouriteActionError:FavouriteActionErrorType=FavouriteActionErrorType.NO_ERROR;

  headers!:TableHeaderCellData[];
  entries!:TableRowData<FavouriteMember>[];
  firstHeader!:TableHeaderCellData;
  secondHeader!:TableHeaderCellData;
  thirdHeader!:TableHeaderCellData;
  forthHeader!:TableHeaderCellData;
  fifthHeader!:TableHeaderCellData;

  @ViewChild("memberNameTemplate", {static:true})
  memberNameTemplateRef!:TemplateRef<HTMLElement>;

  @ViewChild("memberAddressTemplate", {static:true})
  memberAddressTemplateRef!:TemplateRef<HTMLElement>;

  @ViewChild("memberIdTemplate", {static:true})
  memberIdTemplateRef!:TemplateRef<HTMLElement>;

  @ViewChild("memberActionTemplate", {static:true})
  memberActionTemplateRef!:TemplateRef<HTMLElement>;

  @ViewChild("memberSelectSymbolTemplate", {static:true})
  memberSelectSymbolTemplateRef!:TemplateRef<HTMLElement>;

  @ViewChild("noSavedMembersTemplate", {static:true})
  noSavedMembersTemplateRef!:TemplateRef<HTMLElement>;

  @Output()
  onMemberSelected:EventEmitter<TableClickEvent<FavouriteMember>> = new EventEmitter<TableClickEvent<FavouriteMember>>();

  constructor(private memberSearchHelper:MembersearchService,
    private profileService:ProfileService,
    private modalService: NgbModal, private logger: LoggerService) {

    this.headers=[];

    this.firstHeader = new TableHeaderCellData();
    this.firstHeader.content="Member";
    this.firstHeader.sortable=true;
    this.firstHeader.sortAscending=true;
    this.firstHeader.selectedForSort=true;
    this.headers.push(this.firstHeader);

    this.secondHeader = new TableHeaderCellData();
    this.secondHeader.content="Address";
    this.headers.push(this.secondHeader);

    this.thirdHeader = new TableHeaderCellData();
    this.thirdHeader.content = "Member ID";
    this.headers.push(this.thirdHeader);

    this.forthHeader = new TableHeaderCellData();
    this.forthHeader.content="Actions";
    this.headers.push(this.forthHeader);

    this.fifthHeader = new TableHeaderCellData();
    this.fifthHeader.content=" ";
    this.fifthHeader.cssClass="tablesmallheader25";
    this.headers.push(this.fifthHeader);
  }

  fetchSavedMembers():void{
    if(this.profileService.loginuser.prescriber.presbrId!=undefined){
      this.favouriteActionError = FavouriteActionErrorType.NO_ERROR;
      this.memberSearchHelper.getFavouriteMembers(this.profileService.loginuser.prescriber.presbrId).then((response:FavouriteMembersWebResponse)=>{
        if(response.memberSearchFavoriteList!=undefined){
          this.firstHeader.sortAscending=true;
          this.members = response.memberSearchFavoriteList;
        } else {
          this.favouriteActionError=FavouriteActionErrorType.RETRIEVE_ERROR;
        }
      },(error:any)=>{
          this.favouriteActionError=FavouriteActionErrorType.RETRIEVE_ERROR;
      }).catch((reason:any)=>{
          this.favouriteActionError=FavouriteActionErrorType.RETRIEVE_ERROR;
      });
    }
  }
  ngOnInit(): void {
    this.fetchSavedMembers();
  }
  dismiss():void{
    this.modalService.dismissAll("saved members closed.");
  }
  private sortMembers():void{
    this.members.sort((member1:FavouriteMember,member2:FavouriteMember)=>{
      if(this.firstHeader.sortAscending)
        return (member1.lastName.toLowerCase()+" "+member1.firstName.toLowerCase()).localeCompare(member2.lastName.toLowerCase()+" "+member2.firstName.toLowerCase());
      else
        return (member2.lastName.toLowerCase()+" "+member2.firstName.toLowerCase()).localeCompare(member1.lastName.toLowerCase()+" "+member1.firstName.toLowerCase());
    });
  }
  private getCurrentPageMembers():FavouriteMember[]{
    this.totalEntries=this.members.length;
    if(this.members.length<=this.recordsPerPage){
      return this.members;
    }
    else {
      const startIndex:number = (this.currentPageNumber-1) * this.recordsPerPage;
      const endIndex:number = (this.currentPageNumber * this.recordsPerPage);
      return this.members.slice(startIndex, endIndex);
    }
  }
  private updateTotalPagesCount():void{
    this.totalPagesCount=(this.members.length%this.recordsPerPage)!=0?Math.floor(this.members.length/this.recordsPerPage)+1: Math.floor(this.members.length/this.recordsPerPage);
  }

  generateSavedMembers():void{
    this.entries=[];
    if(this.members.length>0){
      this.sortMembers();
      this.updateTotalPagesCount();
      this.getCurrentPageMembers().forEach((member:FavouriteMember)=>{
        const row:TableRowData<FavouriteMember> = new TableRowData<FavouriteMember>();
        
        const firstCell:TableCellData<FavouriteMember> = new TableCellData<FavouriteMember>();
        firstCell.data=member;
        firstCell.sortable=true;
        firstCell.template = this.memberNameTemplateRef;
        row.cells.push(firstCell);
 
        const secondCell:TableCellData<FavouriteMember> = new TableCellData<FavouriteMember>();
        secondCell.data=member;
        secondCell.template = this.memberAddressTemplateRef;
        row.cells.push(secondCell);
 
        const thirdCell:TableCellData<FavouriteMember> = new TableCellData<FavouriteMember>();
        thirdCell.data=member;
        thirdCell.template=this.memberIdTemplateRef;
        row.cells.push(thirdCell);
 
        const forthCell:TableCellData<FavouriteMember> = new TableCellData<FavouriteMember>();
        forthCell.clickable=false;
        forthCell.data=member;
        forthCell.template=this.memberActionTemplateRef;
        row.cells.push(forthCell);
 
        const fifthCell:TableCellData<FavouriteMember> = new TableCellData<FavouriteMember>();
        fifthCell.data=member;
        fifthCell.template=this.memberSelectSymbolTemplateRef;
        row.cells.push(fifthCell);
        this.entries.push(row);
     });
    } else {
        const row:TableRowData<FavouriteMember> = new TableRowData<FavouriteMember>();    
        row.cssClass="norow";    
        const firstCell:TableCellData<FavouriteMember> = new TableCellData<FavouriteMember>();
        firstCell.data= {lastName:"",firstName:"",mbrSearchId:0,memberId:"",zipCode:"",dateOfBirth:"",address1:"",address2:""};
        firstCell.colspan=this.headers.length;
        firstCell.clickable=false;
        firstCell.sortable=true;
        firstCell.template = this.noSavedMembersTemplateRef;
        row.cells.push(firstCell);
        this.entries.push(row);
    }
  }

  onPageChange(pageNumber:any){
    this.currentPageNumber=pageNumber;
    this.generateSavedMembers();
  }
  onChangeEntriesPerPage(entriesPerPage: any){
    this.recordsPerPage = entriesPerPage;
    this.generateSavedMembers();
  }
  onHeaderClicked(headerClickEvent:TableClickEvent<FavouriteMember>):void{
    if(headerClickEvent.cell!=undefined){
      this.generateSavedMembers();
    }
  }
  private unfavourite(member:FavouriteMember):void{
    this.memberSearchHelper.removeFavouriteMember(member.mbrSearchId).then((response:Status)=>{
      if(response.statusCode!=undefined && response.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
      && response.dbStatusCode!=undefined && response.dbStatusCode.toLowerCase()==APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE){
        this.fetchSavedMembers();
      } else {
        this.favouriteActionError = FavouriteActionErrorType.UNFAVOURITE_ERROR;
      }
    }, (error:any)=>{
      this.favouriteActionError = FavouriteActionErrorType.UNFAVOURITE_ERROR;
    })
  }
  select(event:TableClickEvent<FavouriteMember>):void{
    
    if(event.column==3 || event.event.target.getAttribute('name')=="remove"){
      //remove member from favourites list
      if(event.cell!=undefined && event.cell.data!=undefined)
      {
        this.logger.userAction("Remove Favourtie Member")
        this.unfavourite(event.cell?.data)
      }
    } else {
      this.logger.userAction("Favourtie Member Selected: "+event.cell?.data)
      this.onMemberSelected.emit(event);
    }
  }
}
