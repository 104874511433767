<div class="container-fluid viewAll realclaims">
    <div class="row" style="padding-top:20px;">
        <div class="col-md-8 offset-md-2 col-xs-12">
            <h1 class="fs-3"> Medication claim reversals </h1>
            <br/>
            <p class="large" style="padding-top: 20px; padding-bottom: 20px;">Click on a claim below to view its details. Narrow your results by using the filter or search options. Paid claims can be reversed up to 180 days from the date that it was submitted. If the claim status looks incorrect, click the refresh icon to update it in real time.</p>
            <br/>
        </div>
    </div>
    <div class="row" style="padding-bottom:20px;">
        <div class="col-md-8 offset-md-2 col-xs-12">
            <h2 class="float-start">
                <span>All paid claims</span>
            </h2>
        </div>
    </div>
    <div class="row">
        <div class="col-md-8 offset-md-2 col-xs-12">
             <savedclaimhistory [pageType]="paidClaimPageType"></savedclaimhistory> 
        </div>
    </div>

</div>