<div class="content-card bg-white">
    <div class="card-content">
        <div class="container-fluid nopadding">
            <div class="row">
                <div class="col-12">
                    <h2 [innerHtml]="question.questionText"></h2>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-9 col-md-9 col-sm-7 col-xs-12 form-group">
                    <form [formGroup]="numericForm" novalidate>
                        <input type="text" class="form-control inp rounded-0" formControlName="userAnswer"/>
                    </form>
                    <div class="text-danger" *ngIf="numericForm.controls['userAnswer'].errors">
                        <span *ngIf="numericForm.controls['userAnswer'].errors['pattern']">Invalid character.</span>
                        <span *ngIf="numericForm.controls['userAnswer'].errors['required'] && (formSubmitted || !numericForm.controls['userAnswer'].pristine)">Please enter answer.</span>
                    </div>
                </div>
                <div class="col-lg-offset-0 col-lg-3 col-md-offset-0 col-md-3 col-sm-offset-0 col-sm-5 col-xs-4 col-xs-offset-4">
                    <button class="btn btn-primary rounded-pill" (click)="submit()">Continue</button>
                </div>
            </div>
        </div>
    </div>
</div>
