<div class="container-fluid viewAll savedHistory mt-3" *ngIf="state.pageType==3">
    <div class="row">
        <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10">
            <h1 name="title" class="fs-3">{{displayText}}</h1>
        </div>
    </div>
    <div class="row">
        <div class="offset-md-2 col-md-6 offset-xs-1 col-xs-8 mt-3">
            <p name="description" class="large">Click on a claim below to view its details. Narrow your results by using the
                filter or search options.
                <br />If the claim status looks incorrect, click the refresh icon to update it in real time.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10 mt-3">
            <savedclaimhistory class="offset-md-2 col-md-8" [pageType]="3" [showStatus]="true" [memberId]="this.memberId"></savedclaimhistory>
        </div>
    </div>
    <div class="row">
        <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10 text-start backToHome mt-3">
            <i class="fa fa-angle-left"></i>
            &nbsp;
            <a class="btn btn-link backToHome" (click)="goto('home')" (keydown.enter)="goto('home')" tabindex="0">
                Back to SMAP homepage
            </a>
        </div>
    </div>
</div>