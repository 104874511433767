import { AdditionalField } from './drugsearchwebresponse';
import { LocalMessages } from './Trailadjudicationwebresponse';
import { Status } from 'src/app/modals/webresponsestatus';
import { CLAIM_STATUS_TYPE } from 'src/app/constants/appconstant';

export interface SearchOutputMetaData {
    externalCorrelationId: string;
    internalCorrelationId: string;
    consumingAppId: string;
    respCode: string;
    respMessage: string[];
    responseCode:string;
    responseMessage:string[];
}

export class ResponsePricing {
    ingredientCost!: string;
    dispensingFee!: string;
    flatTax!: string;
    percentSalesTaxAmount!: string;
    incentiveFee!: string;
    other!: string;
    professionalServiceFee!: string;
    patientPayAmount!: string;
    adjustment!: string;
    cob!: string;
    ucw!: string;
    totalAmountDue!: string;
    copay!: string;
    copayFlat!: string;
    copayPercent!: string;
    deductible!: string;
    overCap!: string;
    productSelection!: string;
    processorFee!: string;
    networkSelection!: string;
    coverageGap!: string;
    healthPlan!: string;
    tax!: string;
}

export interface DiagnosisCode {
    diagnosisCode: string;
    diagnosisCodeQualifier: string;
}

export interface DurReasons{
     durCodeCounter:string;
	 durReason:string;
	 durReasonDesc:string;
	 clinicalSigCode:string;
	 clinicalSigCodeDesc:string;
	 otherPharmInd:string;
	 otherPharmIndDesc:string;
	previousDteFill:string;
	previousQuantityFill:string;
	databaseInd:string;
    databaseIndDesc:string;
	otherPresInd:string;
	otherPresIndDesc:string;
	durFreeText:string;
    durResponse:string;
	durResponseDesc:string;
	durAddlText:string;
    additionalField:AdditionalField[];
    prfsnlSrvcCd:string;
    rsltSrvcCd:string;
    durMsgId:number;
    levelOfEffort:string;
}

export interface RejectCodes{
     rejectCodeSeq:string;
	 rejectCode:string;
	 rejectCodeDesc:string;
	 additionalField:AdditionalField[];
}
export class RealClaimResponse {
    transactionResponseStatus!: string;
    uniqueClaimId!: string;
    claimSequenceNumber!: string;
    groupId!: string;
    planId!: string;
    rxServiceReferenceQualifier!:string
    rxServiceReferenceNo!: string;
    planEffectiveDate!: string;
    networkReimbursementId!:string;
    drugStatus!: string;
    rejectCodeCount!: string;
    durReasons!:DurReasons[];
    rejectCodes!:RejectCodes[];
    message!: string;
    additionalMessage!:string;
    localMessages!:LocalMessages[];
    patientPaid!: number;
    salesTaxAmount!: number;
    copayCoinsuranceAmount!: number;
    deductibleAmount!: number;
    productSelectionAmount!: number;
    exceedBenefitMaxAmount!: number;
    totalAmountPaid!: number;
    planPaidAmount!: number;
    remainingDeductibleAmount!: number;
    remainingBenefitAmount!: number;
    accumDeductibleAmount!: number;
    paidWithWaiver!: string;
    paidWithFacts!: string;
    autoPayRejection!: string;
    nonPreferredBrand!: string;
    gracePeriod!: string;
    contingentTherapy!: string;
    quantityLimits!: string;
    homeDelivery!: string;
    responsePricing!: ResponsePricing;
    transitionBenefits!:TransitionBenefits;
    diagnosisCodeCount!: string;
    diagnosisCode!: DiagnosisCode[];
}

export interface TransitionBenefits{
    rejectedSequence:string;
	rejectedReason:string;
	rejectedPlanEdit:string;
}

export class SubmitClaimResponse {
    searchOutputMetaData!: SearchOutputMetaData;
    realClaimResponse!: RealClaimResponse;
    status!: Status;
}

export class SubmitClaimResponseDetail {
    submitClaimResponse?: SubmitClaimResponse;
    claimStsTypId!: CLAIM_STATUS_TYPE;
    claimDtlId!: number;
    status!: Status;
}

export interface SubmitMultipleClaimResponse {
    responses: SubmitClaimResponseDetail[];
    status: Status;
}