import { CLAIM_STATUS_TYPE, PersonGender } from 'src/app/constants/appconstant';
import { Status } from 'src/app/modals/webresponsestatus';
import { LstClaim } from 'src/app/modals/drugSearch/SaveTrailClaimRequest';
import { DurSegment } from 'src/app/modals/drugSearch/Trailadjudicationwebrequest';
import { InfusionFacility } from 'src/app/modals/drugSearch/SaveTrailClaimResponse';

export interface Claimhistorywebresponse {
    claimDetailsHistory:ClaimDetailsHistory[];
    bookmarkClaims:ClaimDetailsHistory[];
    status:Status;
    bookmarkId:string;
}

export class ClaimDetailsHistory{
    claimDetailId!:number;
    claimGroupId!:string;
    claimDetailsHistory:ClaimDetailsHistory[]=[];
    bookmarkId!:number;
    memberId!:string;
    memberLastName!:string;
    memberFirstName!:string;
    productName!:string;
    infusionFacilityId!:string;
    infusionFacilityName!:string;
    prescriberFirstName!:string;
    prescriberLastName!:string;
    userLastName!:string;
    userFirstName!:string;
    date!:string;
    claimStsTypId!:CLAIM_STATUS_TYPE;
    claimStatusMessage!:string;
    showDetails:boolean=false;
    animate:boolean=false;
    refresh:boolean=false;
}


export class ClaimDetialsHistoryGroup{
    claimGroupId:string="";
    claimDetailsHistory:ClaimDetailsHistory[]=[];
}

export class LstClaimHistory extends LstClaim{
    prescriberName!:string;
    bin!:string;
    pcn!:string;
    groupId!:string;
    serviceProviderIdQualifier!:string;
    serviceProviderId!:string;
    datePrescriptionWritten!:string;
    mbrId!:string;
    instanceId!:string;
    firstName!:string;
    lastName!:string;
    dateOfBirth!:string;
    gender!:PersonGender;
    cagCarrierId!:string;
    cagAccountId!:string;
    cagGroupId!:string;
    nbrOfDaySpl!:string;
    submittedIngredientCost!:string;
    usualAndCustomaryCharge!:string;
    userId!:string;
    personCode!:string;
    relationshipCode!:string;
    quantityDispensed!:string;
    infusionFacility!: InfusionFacility;
    claimStsTypId!:CLAIM_STATUS_TYPE;
    durSegment!:DurSegment[];
    message!:string;
    status!:Status;
    rxServiceReferenceNo!: string;
    billingInformation!:BillingInformation;
}

export class BillingInformation{
    billingInfoId!: number;
	ingredientCost!: string;
	dispensingFee!:string ;
	patientPayAmount!:string ;
	totalAmountDue!: string ;
	rxClaimNbr!:string ;
}


export class ClaimTransactionHistoryResponse{
    responses!:LstClaimHistory[];
    clmgroupId!:string;
    status!:Status;
}