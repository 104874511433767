import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoadNextQuestionEvent, BooleanCode, Question, UserGivenAnswer, AnswerValue, QuestionChoice } from 'src/app/modals/epa/questionsetviewmodal';
import { LoggerService } from 'src/app/services/logger/logger.service';

@Component({
  selector: 'selectquestion',
  templateUrl: './selectquestion.component.html',
  styleUrls: ['./selectquestion.component.css']
})
export class SelectquestionComponent implements OnInit {

  blnSelectMultiple:boolean;
  blnAcknowledgement:boolean;

  constructor(private logger:LoggerService) {
    this.blnSelectMultiple=false;
    this.selectedCheckboxChoices=[];
    this.blnAcknowledgement=true;
    this.blnSelectMultipleDropdown=false;
  }

  @Input()
  question!:Question;

  @Output()
  nextQuestion:EventEmitter<LoadNextQuestionEvent> = new EventEmitter<LoadNextQuestionEvent>();
  
  private _selectedRadioOption !: string;
  public get selectedRadioOption() : string {
    return this._selectedRadioOption;
  }
  public set selectedRadioOption(v : string) {
    if(v!=undefined && v.length>0){
      const previousState = this._selectedRadioOption;
      this._selectedRadioOption = v;
      this.storeAnswerSelection();
      const nextQuestionEvent:LoadNextQuestionEvent = new LoadNextQuestionEvent();
      nextQuestionEvent.currentQuestionID = this.question.questionID;
      nextQuestionEvent.blnOnInit = (previousState==undefined)?true:false;
      this.nextQuestion.emit(nextQuestionEvent);
    }
  }

  selectedCheckboxChoices:Array<AnswerValue>;
  blnSelectMultipleDropdown:boolean;
  
  private storeAnswerSelection():void{
    //User has changed the selection of choice on this question.
    //Selected option should be written back to input question data so that parent component is aware of the change made on this question.
    if(this.question!=undefined && this.question.questionType!=undefined
      && this.question.questionType.select!=undefined && Array.isArray(this.question.questionType.select.choice)){
        if(this.question.questionType.select.selectMultiple.toLowerCase() == BooleanCode.No.toLowerCase()){
          const selectedChoiceIndex:number = this.question.questionType.select.choice.findIndex((choice:QuestionChoice)=>{
            return choice.choiceID == this.selectedRadioOption;
          });
  
          if(selectedChoiceIndex!=-1){
            const selectedChoice:QuestionChoice = this.question.questionType.select.choice[selectedChoiceIndex];
            const answerObj:UserGivenAnswer<AnswerValue> = new UserGivenAnswer();
            answerObj.prescriberProvidedAnswer = new AnswerValue();
            answerObj.prescriberProvidedAnswer.choiceID = selectedChoice.choiceID;
            answerObj.prescriberProvidedAnswer.choiceText = selectedChoice.choiceText;
            this.question.questionType.select.answer=new Array<UserGivenAnswer<AnswerValue>>();
            this.question.questionType.select.answer.push(answerObj);
          }
        } else {
          this.question.questionType.select.answer=new Array<UserGivenAnswer<AnswerValue>>();
          this.selectedCheckboxChoices.forEach((answerObj:AnswerValue)=>{
              const userGivenNewAnswer:UserGivenAnswer<AnswerValue> = new UserGivenAnswer<AnswerValue>();
              userGivenNewAnswer.prescriberProvidedAnswer=answerObj;
              if(Array.isArray(this.question.questionType.select.answer))
                this.question.questionType.select.answer.push(userGivenNewAnswer);
          });
        }
    }
  }

  ngOnInit(): void {
    //Below logic restores the user answer on this question during page refresh or resume PA(if this prior auth is already a saved one).
    if(this.question!=undefined && this.question.questionType!=undefined
    && this.question.questionType.select!=undefined){
        this.blnAcknowledgement = this.question.questionText.toLowerCase().indexOf("i certify")==0?true:false;

        if(this.question.questionType.select.selectMultiple!=undefined && this.question.questionType.select.selectMultiple.toLowerCase() ==BooleanCode.Yes.toLowerCase()){
          this.blnSelectMultiple=true;
        } else {
          this.blnSelectMultiple=false;
        }

        if(Array.isArray(this.question.questionType.select.choice)
        && this.question.questionType.select.answer!=undefined && this.question.questionType.select.answer.length>0){
          //const answerObj:UserGivenAnswer<AnswerValue> = this.question.questionType.select.answer[0];
          this.question.questionType.select.answer.forEach((answerObj:UserGivenAnswer<AnswerValue>)=>{
            if(answerObj!=undefined && answerObj.prescriberProvidedAnswer!=undefined){
              this.question.questionType.select.choice.forEach((choice:QuestionChoice)=>{
                if(choice.choiceID == answerObj.prescriberProvidedAnswer.choiceID){
                   if(this.question.questionType.select.selectMultiple.toLowerCase()==BooleanCode.No.toLowerCase()){
                    this.selectedRadioOption=choice.choiceID;
                   } else {
                     this.selectedCheckboxChoices.push(answerObj.prescriberProvidedAnswer);
                   }
                }
              });
            }
          });
          if(this.question.questionType.select.selectMultiple.toLowerCase() == BooleanCode.Yes.toLowerCase()){
            //This submit is required only for the select multiple question. 
            //In case of single select question, it is automatically triggered as and when the property selectedRadioOptionm is updated with answer.
            this.submit(true);
          }
        }
    }
  }

  submit(blnOnInit:boolean=false):void{
    if((this.selectedRadioOption!=undefined && this.selectedRadioOption.length>0)
    || this.selectedCheckboxChoices.length>0){
      this.storeAnswerSelection();
      this.logger.log("Submiting Select Question.");
      const nextQuestionEvent:LoadNextQuestionEvent = new LoadNextQuestionEvent();
      nextQuestionEvent.blnOnInit = blnOnInit;
      nextQuestionEvent.currentQuestionID = this.question.questionID;
      this.nextQuestion.emit(nextQuestionEvent);
    }
  }
  toggleRadioOption(choiceID:string):void{
    this._selectedRadioOption = "";//Don't remove this. This differentiate 'blnOnInit'
    this.selectedRadioOption=choiceID;
  }
  toggleChoiceByID(choiceID:string){
    const choiceSelected:QuestionChoice | undefined = this.question.questionType.select.choice.find((choice:QuestionChoice)=>choice.choiceID==choiceID);
    if(choiceSelected!=undefined)
      this.toggleChoice(choiceSelected);
  }
  toggleChoice(choice:QuestionChoice):void{
    const choiceAnswerIndex:number = this.selectedCheckboxChoices.findIndex((item:AnswerValue)=>{
      return item.choiceID==choice.choiceID;
    });
    if(choiceAnswerIndex==-1){
      const answerChoice:AnswerValue = new AnswerValue();
      answerChoice.choiceID = choice.choiceID;
      answerChoice.choiceText = choice.choiceText;
      this.selectedCheckboxChoices.push(answerChoice);
    } else {
      this.selectedCheckboxChoices.splice(choiceAnswerIndex,1);
      if(this.selectedCheckboxChoices.length==0){
        this.storeAnswerSelection();
        //All choices are disselected. Remove next appearing questions if there are any.
        const nextQuestionEvent:LoadNextQuestionEvent = new LoadNextQuestionEvent();
        nextQuestionEvent.blnOnInit=false;
        nextQuestionEvent.currentQuestionID = this.question.questionID;
        this.nextQuestion.emit(nextQuestionEvent);
      }
    }
  }
  isChoiceSelected(choice:QuestionChoice):boolean{
    return this.selectedCheckboxChoices.findIndex((item)=>item.choiceID==choice.choiceID)!=-1?true:false;
  }
  toggleMultipleSelectDropdown(event:any):void{
    if(event.target.getAttribute('name')=='toggle'){
      this.blnSelectMultipleDropdown=!this.blnSelectMultipleDropdown;
    } else{
      this.blnSelectMultipleDropdown=false;
    }
  }
}
