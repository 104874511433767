<div class="container-fluid" [ngClass]="{'nopadding':!blnProgressBar}">
    <div class="row">
        <div class="col">
            <div class="row justify-content-center" *ngIf="blnProgressBar">
                <div class="col-md-8 col-sm-8 col-xs-12 timelinetitle">
                    <h1><strong>Check medication coverage</strong></h1>
                </div>
            </div>
            <div class="row justify-content-center" *ngIf="blnProgressBar">
                <div class="col-md-8 col-sm-8 col-xs-12 timeline">
                    <!-- Progress bar  -->
                    <app-progress-tabs></app-progress-tabs>
                </div>
            </div>
            <div class="row justify-content-center" [ngClass]="{'nopadding':!blnProgressBar}">
                <div [ngClass]="{'timelinestepholder':blnProgressBar}">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>