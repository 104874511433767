<div class="row justify-content-center">
    <div class="col-md-8 col-sm-8 col-xs-12">
        <div class="container-fluid noresultsinfo" *ngIf="memberResultType==1">
            <div class="row">
                <div class="col nopadding">
                    <div class="alert alert-info shadow" role="alert">
                        <i class="fa fa-info-circle" aria-hidden="true" style="color: #002677;"></i>
                        <span style="color: #222222;">Info: No search results found</span>
                        <i class="fa fa-remove" aria-label="Close" (click)="memberResultType=0"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid servererror" *ngIf="memberResultType==2 || memberResultType==3">
            <div class="row">
                <div class="col nopadding">
                    <div class="alert alert-info shadow" role="alert">
                        <i class="fa fa-info-circle" aria-hidden="true" style="color: #002677;"></i>
                        <span style="color: #222222;">Info:We're unable to complete your request at this time. Please
                            try again later.</span>
                        <i class="fa fa-remove" aria-label="Close" (click)="memberResultType=0"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid timeout" *ngIf="memberResultType==4">
            <div class="row alert alert-info shadow" role="alert">
                <div class="col-10 nopadding">
                    <i class="fa fa-info-circle" aria-hidden="true" style="color: #002677;"></i>
                    <span style="color: #222222;">Info:We were unable to retrieve this information. Refresh the page to
                        try again.</span>
                </div>
                <div class="col-2 text-right nopadding">
                    <div class="row">
                        <button class="col-10 btn btn-primary rounded-pill" (click)="findMember()">Refresh page</button>
                        <i class="col-2 mt-2 fa fa-remove" aria-label="Close" (click)="memberResultType=0"></i>
                    </div>
                </div>
            </div>
        </div>



        <form name="membersearchform" *ngIf="blnSearchPanelOpened" [formGroup]="membersearchform"
             (ngSubmit)="findMember()">
            <div class="container-fluid bg-white content-card">
                <div class="row">
                    <div class="row card-header m-0">
                        <div class="col-md-8 col-sm-8 col-xs-12 p-0">
                            <div class="row">
                                <h2 class=" float-start"><strong>Step 1 of 3: Select a member</strong></h2>
                                <p>
                                    <span class="fs-7">All fields required unless otherwise indicated.</span>
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 p-0">
                            <button class="btn btn-link underline float-end clearAll"
                                *ngIf="this.blnSearchedMember.lastName!=undefined || this.blnSearchedMember.zipCode!=undefined || this.blnSearchedMember.dateOfBirth!=undefined"
                                (click)="clearAll($event)">Clear All</button>
                            <span class="row">
                                <button type="button" class="col btn underline btn-link fw-bold"
                                    (click)="openRecentMembers($event)">Recent
                                    searches</button>
                                <span class="mt-2 col-1">|</span>
                                <button type="button" class="col btn underline btn-link fw-bold" (click)="openSavedMembers($event)">Saved
                                    searches</button>
                            </span>
                        </div>
                    </div>

                </div>
                <div class="row" *ngIf="blnInSuffientSearchCriteria">
                    <div class="col nopadding">
                        <div class="alert alert-error">
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <label style="color: #222222;"> Enter all required fields. </label>
                        </div>
                    </div>
                </div>
                <div class="row p-3">
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="form-group">
                            <label for="Lastname" class="form-label required">Member last name</label>
                            <input (keydown.enter)="findMember()" type="text" maxlength="25" name="Lastname" formControlName="Lastname"
                                class="rounded-1 form-control inp" aria-required="true" (focus)="blnLastNameFocus=true"
                                (blur)="blnLastNameFocus=false"
                                [ngClass]="{'inpError': (blnFormSubmitted && !membersearchform.controls['Lastname'].valid) || 
                        (!blnFormSubmitted && !membersearchform.controls['Lastname'].pristine && membersearchform.controls['Lastname'].errors && 
                        (membersearchform.controls['Lastname'].errors['pattern'] || (!blnLastNameFocus && membersearchform.controls['Lastname'].errors['minlength']) ))}" />
                        </div>
                        <div class="text-danger">
                            <ng-container
                                *ngIf="membersearchform.controls['Lastname'] && membersearchform.controls['Lastname'].errors && !membersearchform.controls['Lastname'].errors['pattern'] && 
                        membersearchform.controls['Lastname'].errors['minlength'] && (blnFormSubmitted || !blnLastNameFocus)">
                                Please enter atleast two characters.
                            </ng-container>
                            <ng-container
                                *ngIf="membersearchform.controls['Lastname'] && membersearchform.controls['Lastname'].errors && membersearchform.controls['Lastname'].errors['pattern']">
                                Invalid character.
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="form-group">
                            <label for="Firstname" class="form-label optional">Member first name <label
                                    class="required">(Optional)</label></label>
                            <input type="text" maxlength="25" name="Firstname" formControlName="Firstname"
                                class="rounded-1 form-control inp" (keydown.enter)="findMember()"
                                [ngClass]="{'inpError': (!membersearchform.controls['Firstname'].valid && !membersearchform.controls['Firstname'].pristine)}" />
                        </div>
                        <div class="text-danger">
                            <ng-container
                                *ngIf="membersearchform.controls['Firstname'] && membersearchform.controls['Firstname'].errors && membersearchform.controls['Firstname'].errors['pattern']">
                                Invalid character
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="row p-3">
                    <div class="col-md-4 col-sm-4 col-xs-12">
                        <div class="form-group">
                            <label for="DateofBirth" class="form-label required">Date of birth (mm/dd/yyyy)</label>
                            <datefield [validationMode]="dobValidationMode" [content]="dobValue" (keydown.enter)="findMember()"
                                (onUpdate)="onDobUpdate($event)" [emptyValid]="!blnFormSubmitted">
                            </datefield>
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-2 col-xs-12">
                        <div class="form-group">
                            <label for="inputZip" class="form-label required">ZIP code</label>
                            <input type="text" maxlength="5" name="inputZip" formControlName="inputZip" (keydown.enter)="findMember()"
                                class="rounded-1 form-control inp" aria-required="true" (focus)="blnZipCodeFocus=true"
                                (blur)="blnZipCodeFocus=false"
                                [ngClass]="{'inpError': (blnFormSubmitted && !membersearchform.controls['inputZip'].valid) || 
                        (!membersearchform.controls['inputZip'].pristine && membersearchform.controls['inputZip'].errors && 
                        (membersearchform.controls['inputZip'].errors['pattern'] || (!blnZipCodeFocus && membersearchform.controls['inputZip'].errors['minlength'])))}" />
                        </div>
                        <div class="text-danger">
                            <ng-container
                                *ngIf="membersearchform.controls['inputZip'] && membersearchform.controls['inputZip'].errors && membersearchform.controls['inputZip'].errors['pattern']">
                                Invalid character
                            </ng-container>
                            <ng-container
                                *ngIf="membersearchform.controls['inputZip'] && membersearchform.controls['inputZip'].errors && !membersearchform.controls['inputZip'].errors['pattern'] && 
                        membersearchform.controls['inputZip'].errors['minlength'] && (blnFormSubmitted || !blnZipCodeFocus)">
                                Please enter a valid ZIP code.
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="form-group">
                            <label for="MemberId" class="form-label optional">Member ID <label
                                    class="required">(Optional)</label></label>
                            <input type="text" maxlength="18" class="rounded-1 form-control inp" (keydown.enter)="findMember()"
                                formControlName="MemberId" name="MemberId" (focus)="blnMemberIdFocus=true"
                                (blur)="blnMemberIdFocus=false" [ngClass]="{'inpError': membersearchform.controls['MemberId'].errors && !membersearchform.controls['MemberId'].pristine && ( (!blnMemberIdFocus && membersearchform.controls['MemberId'].errors['minlength']) || 
                        membersearchform.controls['MemberId'].errors['pattern'])}" />
                        </div>
                        <div class="text-danger">
                            <ng-container
                                *ngIf="!blnMemberIdFocus && membersearchform.controls['MemberId'].errors && membersearchform.controls['MemberId'].errors['minlength']">
                                Please enter at least 6 characters.
                            </ng-container>
                            <ng-container
                                *ngIf="membersearchform.controls['MemberId'].errors && !membersearchform.controls['MemberId'].pristine && membersearchform.controls['MemberId'].errors['pattern']">
                                Invalid character.
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col card-footer">
                        <button type="submit" class="btn btn-primary float-end fontbold rounded-pill" (keydown.enter)="findMember()" (click)="findMember()">
                            <span *ngIf="!blnValidFormSubmitted" (keydown.enter)="findMember()">Search</span>
                            <span *ngIf="blnValidFormSubmitted" (keydown.enter)="findMember()">Update</span>
                        </button>
                        <a class="btn underline btn-link float-end fontbold" tabindex="0" *ngIf="!blnValidFormSubmitted"
                            (click)="back($event)" (keyup.enter)="back($event)">Back</a>
                        <a class="btn underline sbtn-link float-end fontbold" tabindex="0" *ngIf="blnValidFormSubmitted"
                            (click)="close()" (keyup.enter)="close()">Close</a>
                    </div>
                </div>

            </div>
        </form>

        <div class="container-fluid bg-white closedsearchcriteria content-card" *ngIf="!blnSearchPanelOpened">
            <div class="row">
                <div class="col-md-2 col-sm-3 col-xs-4">
                    You searched:
                </div>
                <div class="col-md-9 col-sm-7 col-xs-8">
                    [
                    LN:&nbsp;{{getLastName()}}
                    <span *ngIf="getFirstName().length>0">&nbsp;/&nbsp;FN:&nbsp;{{getFirstName()}}&nbsp;</span>
                    /&nbsp;DOB:&nbsp;{{dobValue}}
                    /&nbsp;ZIP:&nbsp;{{getZipcode()}}&nbsp;
                    <span *ngIf="getMemberId().length>0">/&nbsp;ID:&nbsp;{{getMemberId()}}&nbsp;</span>
                    ]
                </div>
                <div class="col-md-1 col-sm-2 col-xs-12">
                    <a class="btn underline btn-link invisiblebtn btn-link fontbold" tabindex="0"
                        (click)="blnSearchPanelOpened=true" (keyup.enter)="blnSearchPanelOpened=true">Edit</a>
                </div>
            </div>
        </div>
        <br />
        <div class="container-fluid" *ngIf="blnShowResultsPannel">
            <!-- <div class="container-fluid"> -->
            <div class="row">
                <div class="col nopadding" *ngIf="memberResults.length>0">
                    <memberresult [members]="memberResults" [pageType]="pageType"></memberresult>
                </div>
                <div class="col nopadding noresults" *ngIf="memberResults.length==0">
                    No search results found.
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #savedmembersmodaltemplate let-modal>
    <favouritemembers (onMemberSelected)="findFavouriteMember($event)" aria-labelledby="savedmembersmodaltemplate"></favouritemembers>
</ng-template>

<ng-template #recentMembers let-modal>
    <div class="modal-header" aria-hidden="true" aria-modal="true" aria-labelledby="recentMembers" role="dialog">
      <h2 id="recentMembers" class="modal-title" tabindex="-1">Search history</h2>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>

    <div class="modal-body">
      <datatable [headers]="recentMembersHeaders" [rows]="recentMembersHistory"
        (onHeaderClicked)="onRecentMembersHeaderClick($event)" (onCellClicked)="onRecentMembersCellClick($event)" aria-labelledby="recentMemberss">
      </datatable>
    </div>
    <div class="modal-body" *ngIf="this.recentMembersHistory.length==0">
        <h6>{{this.message}}</h6>
      </div>
  </ng-template>

  <ng-template #NameTemplate let-data="data">
    <div class="p-2" aria-labelledby="NameTemplate">
      <p class="large">
        <span class="fontbold">{{data.lastName}}</span>
        <br />
        {{data.firstName}}
      </p>
    </div>
  </ng-template>
  <ng-template #AddressTemplate let-data="data">
    <p class="large" aria-labelledby="AddressTemplate">
      <span class="fontbold">{{data.address.address1}}</span>
      <br />
      {{data.address.city}},{{data.address.state}}&nbsp;{{data.address.zip1}}
    </p>
  </ng-template>
  <ng-template #DateofBirthTemplate let-data="data">
    <p class="large" aria-labelledby="DateofBirthTemplate">{{data.dateOfBirth}}</p>
  </ng-template>
  <ng-template #MemberIdTemplate let-data="data">
    <p class="large" aria-labelledby="MemberIdTemplate">{{data.memberId}}</p>
  </ng-template>
  
  
<!-- Loading.... -->
<ng-template #LoadingContent let-modal>
    <div class="modal-body" aria-hidden="true" aria-modal="true" aria-labelledby="LoadingContent" role="dialog"
        title="LoadingContent">
        <h2 class="text-center text-capitalize" tabindex="-1">{{modalContent}}</h2>
    </div>
</ng-template>