<div class="media container-fluid nopadding">
    <div class="row">
        <div class="col-lg-1 col-md-2 col-sm-2 col-2 text-center">
            <h1 id="memberResultsCount" tabindex="-1">{{members.length}}</h1>
        </div>
        <div id="memberResultsTxt" class="col-lg-4 col-md-10 col-sm-10 col-10 text-start">
            <span class="float-start">Member<ng-container *ngIf="members.length>1">s</ng-container> found</span>
            <hr/>
        </div>
        <div class="col-lg-1 col-md-2 col-sm-1 col-2 text-center">
            <span>Show:</span>
        </div>
        <div class=" col-md-3 col-5 col-sm-4 col-lg-2 text-center">
            <label (click)="activeMembersCount!=0 && filterStatus=1" class="radioInput" tabindex="2" [class.disabled]="activeMembersCount==0">
                <input type="radio" name="filtermembers" value="1" [checked]="filterStatus===1"/>
                <i class="fa fa-circle-o" *ngIf="filterStatus!==1"></i>
                <i class="fa fa-dot-circle-o" *ngIf="filterStatus===1" style="color: #002677;"></i>
                <span class="text">&nbsp;Active</span>&nbsp;<span>(&nbsp;{{activeMembersCount}}&nbsp;)</span>
            </label>
        </div>
        <div class=" col-md-4 col-5 col-sm-4 col-lg-2 text-center">
            <label (click)="inactiveMembersCount!=0 && filterStatus=2" class="radioInput" tabindex="2" [class.disabled]="inactiveMembersCount==0">
                <input type="radio" name="filtermembers" value="2" [checked]="filterStatus===2"/>
                <i class="fa fa-circle-o" *ngIf="filterStatus!==2" ></i>
                <i class="fa fa-dot-circle-o" *ngIf="filterStatus===2" style="color: #002677;"></i>
                <span class="text">&nbsp;Inactive</span>&nbsp;<span>(&nbsp;{{inactiveMembersCount}}&nbsp;)</span>
            </label>
        </div>
        <div class=" col-md-3 col-4 col-sm-3 col-lg-2 text-center">
            <label (click)="filterStatus=3" class="radioInput" tabindex="2">
                <input type="radio" name="filtermembers" value="3" [checked]="filterStatus===3"/>
                <i class="fa fa-circle-o" *ngIf="filterStatus!==3"></i>
                <i class="fa fa-dot-circle-o" *ngIf="filterStatus===3" style="color: #002677;"></i>
                <span class="text">&nbsp;All</span>&nbsp;<span>(&nbsp;{{members.length}}&nbsp;)</span>
            </label>
        </div>
    </div>
</div>

<datatable [headers]="headers" [rows]="results" [type]="datatableType"
    (onHeaderClicked)="onHeaderClicked($event)" (onCellClicked)="onCellClicked($event)" aria-labelledby="memberResults">
</datatable>
<div class="container-fluid">
    <div class="row" *ngIf="totalEntries>1">
        <div class="col text-center">
            <!-- <pagination [totalPages]="totalPagesCount" (onPageChange)="onPageChange($event)"></pagination> -->
            <pagination aria-label="MemberResults pagination" [totalEntries]="totalEntries" [totalPages]="totalPagesCount" (onChangeEntriesPerPage)="onChangeEntriesPerPage($event)" (onPageChange)="onPageChange($event)"></pagination>
        </div>
    </div>
</div>
<ng-template #memberNameTemplate let-data="data">
    <div *ngIf="data.isMedD" class="medicareInd" aria-labelledby="memberNameTemplate">Medicare</div>
    <div class="memberData" aria-labelledby="memberNameTemplate">
        <span [innerHtml]="data.lastName" class="text-capitalize fontbold"></span>
        <br/>
        <span [innerHtml]="data.firstName" class="text-capitalize"></span>
    </div>
</ng-template>

<ng-template #memberAddressTemplate let-data="data">
    <div class="memberData" aria-labelledby="memberAddressTemplate">
        {{data.address.address1}}&nbsp;{{data.address.address2}}&nbsp;{{data.address.address3}}
    </div>
</ng-template>

<ng-template #memberIdTemplate let-data="data">
    <div class="memberData" aria-labelledby="memberIdTemplate">
        {{data.memberId}}
    </div>
</ng-template>

<ng-template #memberGroupIdTemplate let-data="data">
    <div class="memberData" aria-labelledby="memberGroupIdTemplate">
        {{data.groupId}}
    </div>
</ng-template>

<ng-template #memberEligibilityTemplate let-data="data">
    <div class="memberData" aria-labelledby="memberEligibilityTemplate">
        <span class="alert alert-success text-capitalize resultsflag" *ngIf="data.activeInd.toLowerCase()=='a'">active</span> 
        <span class="alert alert-danger text-capitalize resultsflag" *ngIf="data.activeInd.toLowerCase()=='i'">inactive</span>
    </div>
</ng-template>


<ng-template #memberSaveTemplate let-data="data">
    <button type="button" name="favorite" class="btn invisiblebtn" enableAction="true" aria-labelledby="memberSaveTemplate">
        <span style="display: none;" aria-hidden>Favorite</span>
        <span name="favorite" *ngIf="!data.isFavorite" class="notfavorite" [attr.enableAction]="data.activeInd.toLowerCase()=='a'" [ngStyle]="{'cursor':(data.activeInd.toLowerCase()=='i')?'not-allowed':'pointer'}" aria-disabled="true"></span>
        <span name="favorite" *ngIf="data.isFavorite" class="favorite" aria-label="Make your default member" [attr.enableAction]="data.activeInd.toLowerCase()=='a'" [ngStyle]="{'cursor':(data.activeInd.toLowerCase()=='i')?'not-allowed':'pointer'}"></span>
    </button>
</ng-template>

<ng-template #memberSelectSymbolTemplate let-data="data">
    <a class="btn invisiblebtn" *ngIf="data.activeInd.toLowerCase()=='a'" tabindex="0" aria-labelledby="memberSelectSymbolTemplate">
        <i class="fa fa-angle-right"></i>
    </a>
</ng-template>

<ng-template #favouriteErrorTemplate let-modal>
    <div class="container-fluid favouriteActionError" title="favouriteError" role=dialog aria-labelledby="favouriteError">
        <div class="row">
            <div class="col text-center">
                <i class="fa fa-ban" aria-hidden="true"></i>
            </div>
        </div>
        <div class="row" [hidden]="blnFavouriteActionError">
            <div class="col paragraph large text-center" tabindex="-1" aria-modal="true" >
                Your saved member limit is ten. Please remove an existing saved member to continue.
            </div>
        </div>
        <div class="row" [hidden]="!blnFavouriteActionError">
            <div class="col paragraph large text-center" tabindex="-1" aria-modal="true">
                An error occured. Please try again later.
            </div>
        </div>
        <div class="row">
            <div class="col text-center">
                <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Ok</button>
            </div>
        </div>
    </div>
</ng-template>