import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { FormGroup, Validators, FormBuilder} from '@angular/forms';

import { LoadNextQuestionEvent, Question, UserGivenAnswer }  from 'src/app/modals/epa/questionsetviewmodal';
import { LoggerService } from 'src/app/services/logger/logger.service';

@Component({
  selector: 'numericquestion',
  templateUrl: './numericquestion.component.html',
  styleUrls: ['./numericquestion.component.css']
})
export class NumericquestionComponent implements OnInit {

  numericForm: FormGroup;
  numericPattern:RegExp;
  formSubmitted:boolean;

  constructor(private fb:FormBuilder, private logger: LoggerService) {
    this.formSubmitted=false;
    this.numericPattern = new RegExp("^([0-9]+)$");
    this.numericForm = this.fb.group({
      userAnswer: ["", Validators.compose([Validators.required, Validators.pattern(this.numericPattern)])]
    });
  }

  @Output()
  nextQuestion:EventEmitter<LoadNextQuestionEvent> = new EventEmitter<LoadNextQuestionEvent>();

  @Input()
  question!:Question;
  
  ngOnInit(): void {
    if(this.question!=undefined && this.question.questionType!=undefined && this.question.questionType.numeric!=undefined
      && this.question.questionType.numeric.answer!=undefined && this.question.questionType.numeric.answer.prescriberProvidedAnswer!=undefined){
        this.numericForm.controls['userAnswer'].setValue(this.question.questionType.numeric.answer.prescriberProvidedAnswer);
        this.submit(true);
    }
  }
  submit(blnOnInit:boolean=false):void{
    this.formSubmitted=!blnOnInit;
    if(this.numericForm.valid){
      this.logger.log("Submiting Numeric Question.");
      this.question.questionType.numeric.answer = new UserGivenAnswer<string>();
      this.question.questionType.numeric.answer.prescriberProvidedAnswer = this.numericForm.controls['userAnswer'].value;
      const nextQuestionEvent:LoadNextQuestionEvent = new LoadNextQuestionEvent();
      nextQuestionEvent.currentQuestionID = this.question.questionID;
      nextQuestionEvent.blnOnInit = blnOnInit;
      this.nextQuestion.emit(nextQuestionEvent);
    }
  }
}
