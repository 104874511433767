import { trigger, state, style, transition, animate, query, stagger, keyframes } from "@angular/animations";

export let fade= trigger('fade',[
      state('void',style({opacity:0})),
      transition('void<=>*',[
        animate(300)
      ]),
      // transition('*=>void',[
      //   animate(200)
      // ])
    ])
    export let listAnimation= trigger('listAnimation',[
        transition('*=>*',[
            query(':enter',style({opacity: 0}), {optional:true}),
    
            query(':enter',stagger('300ms',[
                animate('1s ease-in', keyframes([
                    style({opacity: 0, transform:'translateY(-75px)', offset:0}),
                    style({opacity: .5, transform:'translateY(35px)', offset:0.3}),
                    style({opacity: 1, transform:'translateY(0px)', offset:1})
                ]))
            ]), {optional:true}),
    
            query(':leave',stagger('300ms',[
                animate('1s ease-in', keyframes([
                    style({opacity: 1, transform:'translateY(0px)', offset:0}),
                    style({opacity: .5, transform:'translateY(35px)', offset:0.3}),
                    style({opacity: 0, transform:'translateY(-75px)', offset:1}),
                ]))
            ]), {optional:true})
        ])
    ])    
    
    
    export let listAnimFade= trigger('listAnimFade', [
            transition('* <=> *', [
              query(':enter',
                [style({ opacity: 0 }), stagger('60ms', animate('600ms ease-out', style({ opacity: 1 })))],
                { optional: true }
              ),
      
              query(':leave',
                animate('200ms', style({ opacity: 0 })),
                { optional: true}
              )
            ])
          ])
    
    export let explainerAnim= trigger('explainerAnim',[
        transition(':enter',[
            query('.col-8',style({opacity: 0, transform:'translateX(-40px)'})),
            query('.col-8',stagger('300ms',[
                animate('800ms 1.2s ease-out',style({opacity : 1, transform:'translateX(0px)'}))
            ]))
    
        ])
    ])      
    
    export let slideDown= trigger('slideDown',[
      state('void',style({opacity:0})),
      transition('void=>*',[
        animate(1000)
      ]),
      transition(':leave',[
        animate('1s ease-in', keyframes([
          style({opacity: 1, transform:'translateY(0px)', offset:0}),
          style({opacity: .5, transform:'translateY(35px)', offset:0.3}),
          style({opacity: 0, transform:'translateY(75px)', offset:1})
      ]))
      ])
      
    ])      