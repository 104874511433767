import { CLAIM_STATUS_TYPE } from 'src/app/constants/appconstant';

export interface Status {
    statusCode: string;
    statusDesc: string;
    dbStatusCode: string;
    respCode: string;
    respMessage:string;
	responseDescriptionCode:string;
	dbStatusDesc:string;
	errorMessage:string;
	customErrorCode:string;
}

export interface Status2 {
    statusCode: string;
    statusDesc: string;
    dbStatusCode: string;
    respCode: string;
    respMessage:string;
	responseDescriptionCode:string;
	dbStatusDesc:string;
	errorMessage:string;
	customErrorCode:string;
}
export interface InfusionFacility{
    infsnId: number;
    pharmacyNPI: string;
    pharmacyName: string;
    clientId: string;
    phmcyAddr1: string;
    phmcyAddr2: string;
    phmcyCty: string;
    phmcySt: string;
    phmcyZip: string;
    phmcyFax: string;
    roleTypeId: string;
    networkId: string;
    roles: string[];
    organizationRelationId: string;
    status: Status
}

export interface DurSegment{
     durMsgId: number;
	 reasonSrvcCd : string;
	 prfsnlSrvcCd: string;
	 rsltSrvcCd: string;
	 levelOfEffortHrs: string;
	 freeText: string;
	 additionalText: string;
	 mainMsg: string;
	 durMessage: string;
	 durMsg: string;
	 response:string;
	 levelOfEffort: string;
}

export interface BillingInformation{

    billingInfoId: number;
	ingredientCost: string;
	 dispensingFee: string;
	 patientPayAmount: string;
	 totalAmountDue: string;
	 rxClaimNbr: string;
}

export class Response {
    infusionFacility!:InfusionFacility;
    productServiceId!: string;
    dateOfService!: string;
    claimDetailId!: number;
    claimGroupId!: string;
    claimStsTypId!: CLAIM_STATUS_TYPE;
    //claimsHistorySts!: string;
    billingInformation!: BillingInformation;
    claimDate!:string;
    status!: Status;
    durSegment!:DurSegment[];
}

export interface SaveTrailClaimResponse {
    status: Status;
    clmgroupId: string;
    responses: Response[];
}
