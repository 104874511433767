<div class="content-card bg-white">
    <div class="card-content">
        <div class="container-fluid nopadding">
            <div class="row">
                <div class="col-12">
                    <h2 [innerHtml]="question.questionText"></h2>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 form-group">
                    <label class="form-label">Date (mm/dd/yyyy)</label>
                    <datefield [validationMode]="dobValidationMode" [content]="answerValue" 
                    [emptyValid]="blnIsEmptyValid" [futureMonthsAllowed]="futureMonthsAllowed" 
                    (onUpdate)="onDobUpdate($event)" [dateFieldName]="dateFieldName"></datefield>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-xs-4 align-self-end">
                    <button class="btn btn-primary rounded-pill" [disabled]="answerValue.length==0" (click)="submit()">Continue</button>
                </div>
            </div>
        </div>
    </div>
</div>
