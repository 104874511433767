import { Status } from 'src/app/modals/webresponsestatus';
import { Trailadjudicationwebrequest } from 'src/app/modals/drugSearch/Trailadjudicationwebrequest';

export class QtyCalculationOption1 {
    submittedIngredientCost!: string;
    quantityDispensed!: number;
    additionalField!: any[];
}

export class QtyCalculationOption2 {
    dailyQuantity!: string;
    dosageForm!: string;
    dispensingUnit!: string;
    packageSize!: string;
    awp!: string;
    additionalField!: any[];
}
export class DURSegmentClaimSubmit{
    codeCounter!:number;
    reasonCode!:string;
    professionalCode!:string;
    resultCode!:string;
    levelOfEffort!:string;
}

export class SubmitClaimRequest extends Trailadjudicationwebrequest {
    claimDetailId!: number;
    claimDt!: string;
    subConsumingAppId!: string;
    externalCorrelationId: string="";
    sbmClarificationCodeCount: string="";
    vendorCertId: string="";
    durClaimSubmitSegment:DURSegmentClaimSubmit[]=[];
}

export class SubmitMultipleClaimRequest{
    requests!: SubmitClaimRequest[];
}