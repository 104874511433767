import { Trailadjudicationwebrequest } from './Trailadjudicationwebrequest';
import { Trailadjudicationwebresponse } from './Trailadjudicationwebresponse';
import { PRESCRIBER_QUALIFIER } from 'src/app/constants/appconstant';

export class LstClaim {
    trailClaimResponse!: Trailadjudicationwebresponse;
    trailClaimRequest!: Trailadjudicationwebrequest;

        productServiceIdQualifier!: string;
        productServiceId!: string;
        drugName!: string;
        drgQty!: number;
        gpi!: string;
        productNameExt!: string;
        packageSize!: string;
        breakableIndicator!: string;
        prescriberLastName!: string;
        prescriberFirstName!: string;
        prescriberIdQualifier!: PRESCRIBER_QUALIFIER;
        prescriberNpiId!: string;
        presbrId!: string;
        infsnId!: number;
        prescriberInstanceId!: string;
        zipCode!: string;
        lob!: string;
        middleName!: string;
        dateOfService!: string;
        claimDate!: string;
        claimDetailId!: number;
    
}

export class SaveTrailClaimRequest {
    lstClaims: LstClaim[] = [];
    claimGroupId!:string;
}