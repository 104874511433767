import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';

import { ProgressTabsComponent } from 'src/app/components/progress-tabs/progress-tabs.component';
import { FindmemberComponent } from 'src/app/components/findmember/findmember.component';
import { MemberresultComponent } from 'src/app/components/memberresult/memberresult.component';
import { SelectMedicationComponent } from 'src/app/components/select-medication/select-medication.component';
import { SelectPrescriberComponent } from 'src/app/components/select-prescriber/select-prescriber.component';
import { EditproviderComponent } from 'src/app/components/editprovider/editprovider.component';
//import { ClaimSummaryComponent } from 'src/app/components/claim-summary/claim-summary.component';
import { ClaimsummaryComponent } from 'src/app/components/claimsummary/claimsummary.component';
import { MemberSummaryComponent } from 'src/app/components/member-summary/member-summary.component';
import { PaybleClaimSummaryComponent } from 'src/app/components/payble-claim-summary/payble-claim-summary.component';
import { CheckcoverageComponent } from 'src/app/components/routecontent/checkcoverage/checkcoverage.component';

import { SharedModule } from 'src/app/modules/shared/shared.module';
import { FavouritesModule } from 'src/app/modules/favourites/favourites.module';

export const coverageRoutes: Routes = [
  {
    path:'', 
    redirectTo:'/coverage/member',
    pathMatch:'full'
  },
  {path:'member', component: FindmemberComponent, data:{breadcrumb:{label:'Check Coverage: Select member', step:1}},pathMatch:'full'},
  {path:'prescriber', component:SelectPrescriberComponent, data:{breadcrumb:{label:'Select prescriber', step:2}}, pathMatch:'full'},
  {path:'drug', component:SelectMedicationComponent, data:{breadcrumb:{label:'Select medication', step:3}}, pathMatch:'full'},
  {path:'summary', component:ClaimsummaryComponent, data:{breadcrumb:{label:'Summary', step:4}}, pathMatch:'full'}
];

@NgModule({
  declarations: [
    ProgressTabsComponent,
    FindmemberComponent,
    MemberresultComponent,
    SelectMedicationComponent,
    SelectPrescriberComponent,
    EditproviderComponent,    
    //ClaimSummaryComponent,
    ClaimsummaryComponent,
    MemberSummaryComponent,
    PaybleClaimSummaryComponent,
    CheckcoverageComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    FavouritesModule,
    NgbModule,
    RouterModule.forChild(coverageRoutes)
  ],
  exports: [
    ProgressTabsComponent,
    FindmemberComponent,
    MemberresultComponent,
    SelectPrescriberComponent
  ]
})
export class CheckcoverageModule { }
