import { RejectCodes } from './submitMultipleClaimResponse';
import { CLAIM_STATUS_TYPE } from 'src/app/constants/appconstant';

export class SearchOutputMetaData {
	externalCorrelationId!:string;
	internalCorrelationId!:string;
	consumingAppId!:string;
	subConsumingAppId!:string;
	respCode!:string;
	responseCode!:string;
	respMessage!:string[];
    responseMessage!:string[];
}

export class RealClaimReversalResponse{
   transactionReponseStatus!:string;
   groupId!:string;
   planId!:string;
   planEffectiveDate!:string;
   networkReimbursementId!:string;
   rejectCodeCount!:string;
   rejectCodes!:RejectCodes[];
   message!:string;
   additionalMessage!:string;

}

export class ClaimReverseResponse {
     searchOutputMetaData!: SearchOutputMetaData;
	 realClaimReversalResponse!: RealClaimReversalResponse;
	 status!: Status;
}

export class Status {
    statusCode!: string;
    statusDesc!: string;
    respCode!: string;
    respMessage!: string;
    responseDescriptionCode!: string;
    dbStatusCode!: string;
    dbStatusDesc!:string;
	errorMessage!:string;
	customErrorCode!:string;
}

export class ReverseClaimResponse {
    claimReverseResponse!: ClaimReverseResponse;
    claimStsTypId!: CLAIM_STATUS_TYPE;
    claimDtlId!: number;
    status!: Status;
}
