import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder} from '@angular/forms';

import { LoadNextQuestionEvent, Question, UserGivenAnswer } from 'src/app/modals/epa/questionsetviewmodal';
import { LoggerService } from 'src/app/services/logger/logger.service';

@Component({
  selector: 'freetextquestion',
  templateUrl: './freetextquestion.component.html',
  styleUrls: ['./freetextquestion.component.css']
})
export class FreetextquestionComponent implements OnInit {

  freetextForm: FormGroup;
  formSubmitted:boolean;

  constructor(private fb:FormBuilder, private logger:LoggerService) {
    this.formSubmitted=false;
    this.freetextForm = this.fb.group({
      userAnswer: ['', Validators.required]
    });
  }
  
  @Output()
  nextQuestion:EventEmitter<LoadNextQuestionEvent> = new EventEmitter<LoadNextQuestionEvent>();

  @Input()
  question!:Question;

  ngOnInit(): void {
    if(this.question!=undefined && this.question.questionType!=undefined && this.question.questionType.freeText!=undefined
      && this.question.questionType.freeText.answer!=undefined && this.question.questionType.freeText.answer.prescriberProvidedAnswer!=undefined){
        this.freetextForm.controls['userAnswer'].setValue(this.question.questionType.freeText.answer.prescriberProvidedAnswer);
        this.submit(true);
    }
  }

  submit(blnOnInit:boolean=false):void{
    this.formSubmitted=!blnOnInit;
    if(this.freetextForm.valid){
      this.logger.log("Submiting Free Text Question.");
      this.question.questionType.freeText.answer = new UserGivenAnswer<string>();
      this.question.questionType.freeText.answer.prescriberProvidedAnswer = this.freetextForm.controls['userAnswer'].value;
      const nextQuestionEvent:LoadNextQuestionEvent = new LoadNextQuestionEvent();
      nextQuestionEvent.blnOnInit = blnOnInit;
      nextQuestionEvent.currentQuestionID = this.question.questionID;
      this.nextQuestion.emit(nextQuestionEvent);
    }
  }

}
