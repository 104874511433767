<div class="container-fluid">
  <div class="row">
    <div class="col">
      <div class="container-fluid">
        <div class="col-8 offset-2 timelinetitle">
          <h1>Initiate prior authorization</h1>
          <p class="text-end fs-6"> PA reference ID:
            {{this.webrequest.paReferenceID.paRefPrefix+this.webrequest.paReferenceID.paRefID}} </p>
        </div>
      </div>
      <div class="container-fluid">
        <div class="col-8 offset-2">
          <!-- Progress bar  -->
          <app-progress-tabs></app-progress-tabs>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="container bg-white" style="width: 68%; padding-bottom: 20px; padding-top: 20px;">
    <div class="row">
      <div class="col-md">
        <label class="text-muted text-dark text-center">Select a Member</label>
      </div>
      <div class="col-md">
        <label class="text-muted text-dark text-center">Select a Medication</label>
      </div>
      <div class="col-md">
        <label class="text-muted text-dark text-center">Verify Provider info</label>
      </div>
      <div class="col-md">
        <label class="text-muted text-dark text-center">ePA Questions</label>
      </div>
    </div>
  </div>
 -->
  <div class="container-fluid">
    <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10">
      <h3>Use previously selected prescriber</h3>
    </div>

    <div class="col-8 offset-2 mt-3 border-bottom border-2 p-2">
      <div class="row">
        <div class="col-md ms-2">
          <label class="text-dark " style="color: #444444;">Name</label>
        </div>
        <div class="col-md ms-2">
          <label class="text-dark " style="color: #444444;">Address</label>
        </div>
        <div class="col-md ms-2">
          <label class="text-dark" style="color: #444444;">NPI</label>
        </div>
        <div class="col-md">
          <label class="text-dark" style="color: #444444;">&nbsp;</label>
        </div>
      </div>
    </div>

    <div class="col-8 offset-2 bg-white mt-2 closedsearchcriteria content-card">
      <div class="row p-3">
        <div class="col-md p-2">
          <label class="text-dark" style="color: #222222;"><strong>{{this.sessionPaDetails.firstName}}</strong> <br />
            {{this.sessionPaDetails.lastName}}
          </label>
        </div>
        <div class="col-md p-2">
          <label
            class="text-dark" style="color: #222222;">{{this.sessionPaDetails.addressLine1}}<br />{{this.sessionPaDetails.city}},&nbsp;{{this.sessionPaDetails.stateProvince}}&nbsp;{{this.sessionPaDetails.postalCode}}</label>
        </div>
        <div class="col-md  p-2">
          <label class="text-dark" style="color: #222222;">{{this.sessionPaDetails.npi}}</label>
        </div>
        <div class="col-md ">
          <i class="fa fa-angle-right position-relative top-50 start-100 translate-middle" (click)="initiatePA()" (keyup.enter)="initiatePA()" tabindex="0"></i>
        </div>
        &nbsp;
      </div>
    </div>
    <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10 mt-5">
        <p class="fs-4 fw-lighter text-capitalize">Search for a new prescriber</p>
    </div>
    <div class="container-fluid pb-3 mt-0">
      <app-select-prescriber (intiateEPA)="prescriberChange($event)"></app-select-prescriber>
    </div>
  </div>
</div>

<ng-template #paInitiationTemplate let-modal>
  <div class="content-card bg-white" aria-labelledby="paInitiationTemplate">
    <div class="card-content painitiation" *ngIf="epaState<5">
      <div class="text-center">
        <loader [loaderText]="loaderText" [cssClass]="loaderClass"></loader>
      </div>
      <div class="step" [ngClass]="{'active':epaState>0}">
        <i class="fa fa-check" aria-hidden="true"></i>
        <span class="paragraph large inputFieldLabel">Verifying information.</span>
      </div>
      <div class="step" [ngClass]="{'active':epaState>1}">
        <i class="fa fa-check" aria-hidden="true"> </i>
        <span class="paragraph large inputFieldLabel">Submitting PA request form.</span>
      </div>
      <div class="step" [ngClass]="{'active':epaState>2}">
        <i class="fa fa-check" aria-hidden="true"> </i>
        <span class="paragraph large inputFieldLabel">Retrieving PA evaluation questions.</span>
      </div>
      <div class="step" [ngClass]="{'active':epaState>3}">
        <i class="fa fa-check" aria-hidden="true"> </i>
        <span class="paragraph large inputFieldLabel">Questions received.</span>
      </div>
      <div class="painitiationfooter text-center">
        <!-- <span class="paragraph" style="line-height: normal;">This process may take up to 3 minutes.</span> -->
        <span class="paragraph large inputFieldLabel">Thank you and we've received your request. Your question set will
          typically be returned in less than 1 minute.</span>
      </div>
    </div>
    <div class="card-content text-center timeline" *ngIf="epaState==5">
      <i class="fa fa-check-circle"></i>
      <br />
      <span x-ms-format-detection="none" class="paragraph large inputFieldLabel">
        The questions have been received. You have...
      </span>
      <br />
      <div class="text-center">
        <div class="timerblock">
          <div class="inputFieldLabel">{{epaHoursRemaining}}</div>
          <div>Hours</div>
        </div>
        <div class="timerblock">
          <div class="inputFieldLabel">{{epaMinutesRemaining}}</div>
          <div>Minutes</div>
        </div>
        <div class="timerblock">
          <div class="inputFieldLabel">{{epaSecondsRemaining}}</div>
          <div>Seconds</div>
        </div>
      </div>
      <br />
      <span x-ms-format-detection="none" class="paragraph large inputFieldLabel">
        ...to complete the questions before this request expires.
      </span>
      <br /><br />
    </div>
    <div class="card-footer text-center" *ngIf="epaState==5">
      <button class="btn btn-primary rounded-pill" (click)="goToEPAQuestions()">View PA questions</button>
    </div>
  </div>
</ng-template>

<ng-template #paInitiationFaxFormTemplate let-modal>
  <div class="content-card bg-white" aria-labelledby="paInitiationFaxFormTemplate"> 
    <div class="card-content text-center">
      <i class="fa fa-ban"></i>
      <br />
      <span x-ms-format-detection="none" class="paragraph large inputFieldLabel" [innerHtml]="errorMessage"></span>
      <br /><br />
      <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Close</button>
      &nbsp;&nbsp;
      <button type="button" class="btn btn-primary rounded-pill" (click)="openfaxforms()">View fax forms</button>
    </div>
  </div>
</ng-template>

<ng-template #paInitiationErrorTemplate let-modal>
  <div class="content-card bg-white" aria-labelledby="paInitiationErrorTemplate">
    <div class="card-content text-center">
      <i class="fa fa-ban"></i>
      <br />
      <span x-ms-format-detection="none" class="paragraph large inputFieldLabel" [innerHtml]="errorMessage"></span>
      <br /><br />
      <button type="button" class="btn btn-secondary" (click)="modal.dismiss();goToHome()">Ok</button>
    </div>
  </div>
</ng-template>