import { Component, OnInit, OnChanges, Input, SimpleChanges, ElementRef, ViewChild, TemplateRef } from '@angular/core';
import { ClaimTransactionHistoryResponse } from 'src/app/modals/claimhistory/claimhistorywebresponse';
import { PrescriberSearchService } from 'src/app/services/prescriberSearch/prescriber-search.service';
import { NavigationEnd, Router } from '@angular/router';
import { PARefIDWebResponse, EpaInitiationAckWebResponse, ResubmitSavedPA, EpaInitiationResponse, PAReferenceID } from 'src/app/modals/epa/priorauthresponse';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EpaprocessorService } from 'src/app/services/epa/epaprocessor.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { EpaRunnerInitiationEvent, PAInitiationWebRequest } from 'src/app/modals/epa/priorauthrequest';
import { EPA_STATUS, EPA_TRANSACTIon_STATUS, PRESCRIBER_QUALIFIER } from 'src/app/constants/appconstant';
import { SelectedMedicine } from 'src/app/modals/drugSearch/drugsearchwebresponse';
import { Prescriber, QuestionsetViewmodal } from 'src/app/modals/epa/questionsetviewmodal';
import {Subscription, timer} from 'rxjs';
import { environment } from 'src/environments/environment';
import { PrescriberDetailItem, PrescriberDetailWebResponse, PrescriberSearchItem } from 'src/app/modals/prescriberSearch/prescriberSearchwebresponse';
import { PrescriberDetailWebRequest } from 'src/app/modals/prescriberSearch/prescriberSearchweberequest';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-provider-details',
  templateUrl: './provider-details.component.html',
  styleUrls: ['./provider-details.component.css']
})
export class ProviderDetailsComponent implements OnInit , OnChanges {
  epaState:EPA_STATUS = EPA_STATUS.PA_NOT_STARTED;
  loaderText:string="";
  loaderClass:string="bigloader nopadding";

  errorMessage:string="";
  epaTimerSubscription!:Subscription;
  epaHoursRemaining!:number;
  epaMinutesRemaining!:number;
  epaSecondsRemaining!:number;

  @Input() route="";
  //eventenabled: boolean;
  currentState: number=1;
  claimresponse!: SelectedMedicine;
  webrequest!:ResubmitSavedPA;

  sessionPaDetails!:any;
  result!: any;
  private subscription!:any;

  constructor(private router:Router,private modalService: NgbModal,
    private epaProcessor: EpaprocessorService,
    private profileService: ProfileService,
    private logger: LoggerService,
    private prescriberSearch: PrescriberSearchService,private titleService:Title) {
      
    this.titleService.setTitle("SMAP - PA Initiation - Verify Provider");
      
   }
   @ViewChild("paInitiationTemplate")
   private paInitiationTemplateRef!:TemplateRef<HTMLElement>;

   @ViewChild("paInitiationFaxFormTemplate")
  private paInitiationFaxFormTemplateRef!:TemplateRef<HTMLElement>;

  @ViewChild("paInitiationErrorTemplate")
  private paInitiationErrorTemplateRef!:TemplateRef<HTMLElement>;
 
  
   @ViewChild("errorModalTemplate")
   errorModalTemplateRef!:ElementRef<HTMLElement>;
   

  ngOnChanges(changes: SimpleChanges): void {
    //console.log("TEsting");
  }
  ngOnInit(): void {
    //console.log("TEsting2");
    //console.log((""+sessionStorage.getItem("prescrbr")));
    
    this.sessionPaDetails = JSON.parse(""+sessionStorage.getItem("prescrbr"));
    //console.log(this.sessionPaDetails);
    //console.log(JSON.parse("" + sessionStorage.getItem('claimresponse')));
    this.claimresponse = JSON.parse("" + sessionStorage.getItem('claimresponse'));
    //console.log(JSON.parse("" + sessionStorage.getItem('resubmitresponse')));
    this.webrequest = JSON.parse("" + sessionStorage.getItem('resubmitresponse'));
    
    sessionStorage.setItem("pageType","resubmitEPA");
    this.epaProcessor.epaSubscription.subscribe
    this.subscription = this.epaProcessor.epaSubscription.subscribe((value:EpaRunnerInitiationEvent)=>{
      if(value.status == EPA_STATUS.PA_INITIATION 
        && value.claimresponse!=undefined && value.paRefId!=undefined
        && this.paInitiationTemplateRef!=undefined){
        this.epaState = value.status;

        }
      });
  }

   initiatePA():void{
    this.logger.userAction("Initiating PA.");
    
    const webrequest:PAInitiationWebRequest= this.epaProcessor.extractPAInitiationRequestFromSelectMadicine(this.claimresponse);
      webrequest.infsnInfoId = this.profileService.loginuser.selectedInfusion.getValue().infsnId;
        webrequest.pAReferenceID = ""+this.webrequest.paReferenceID.paRefID;
      this.modalService.open(this.paInitiationTemplateRef,{size:'md'});


      this.epaProcessor.submitPAInitiationRequest(webrequest).then((response:EpaInitiationAckWebResponse)=>{
      
      //console.log("firstcall"+response);
      this.epaState = EPA_STATUS.PA_INITIATION_SUCCESS;
      //Below timeout is for initial delay before start pooling the DB for Questionset availability.
      setTimeout(()=>{
        this.startPoolingQuestionSet(this.webrequest);
      },1000);
    },(error:any)=>this.handleAPIError(error)).catch((error:any)=>this.handleAPIException(error));
}
     
prescriberChange(item:PrescriberSearchItem|undefined){
  if(item!=undefined){
    const prescriberDetailWebRequest: PrescriberDetailWebRequest = new PrescriberDetailWebRequest();
          prescriberDetailWebRequest.prescriberId = item.identifiers[0].id;
          prescriberDetailWebRequest.instanceId = environment.instanceId;
          prescriberDetailWebRequest.prescriberIdQualifier = PRESCRIBER_QUALIFIER.NPI;

          this.prescriberSearch.getPrescriberDetail(prescriberDetailWebRequest).then((pbrResponse: PrescriberDetailWebResponse) => {
             
            if (pbrResponse.response != undefined && pbrResponse.response.prescriberDetailItem != undefined) {
              this.claimresponse.selectedPrescriberDetail=pbrResponse.response.prescriberDetailItem;
              this.claimresponse.selectedPrescriberDetail.selectedAlternativeAddress=0;
              //console.log(item);
              //console.log(pbrResponse);
              this.initiatePA();
            }
          });
              
  }
}

    



  private handleAPIException(error:any):void{
    this.epaState = EPA_STATUS.PA_NOT_STARTED;
    if(typeof error == 'object' &&  (error.status==-1 || error.status==0)){
      //errorhtmlmodal:
      this.errorMessage="The system cannot process your request at this time.<br/><br/>You must submit the prior authorization by fax <br/> or by contacting OptumRx at 800-711-4555.";
      this.modalService.open(this.paInitiationFaxFormTemplateRef,{size:'md'});
    } else {
      //errorhtmlmodal:
      this.errorMessage="An error occurred. Please call customer service at<br/>1-866-842-3278.";
      this.modalService.open(this.paInitiationErrorTemplateRef, {size:'md'});
    }
  }
  private startPoolingQuestionSet(paRef:ResubmitSavedPA):void{
    this.epaState = EPA_STATUS.PA_QUESTIONNARIE_CHECK;
    this.epaProcessor.poolPAInitiationResponse(""+paRef.paReferenceID.paRefID).then((response:EpaInitiationResponse)=>{
      this.epaState = EPA_STATUS.PA_QUESTIONNARIE_RETRIVED;
      const epaQuestions: QuestionsetViewmodal = this.epaProcessor.extactQuestionSetModalFromSavedPA(response.paInitiationResponseWeb.body);
      epaQuestions.paReferenceID.completePaRefId = paRef.paReferenceID.paRefPrefix+paRef.paReferenceID.paRefID;
      this.epaProcessor.storeQuestionSetViewModal(epaQuestions);
      const $timer:any = timer(0,1000);
      this.epaTimerSubscription = $timer.subscribe((value:any)=>{
        this.showEpaTimeline(epaQuestions.deadlineForReply);
      });
    },(error:any)=>this.handleAPIError(error)).catch((error:any)=>this.handleAPIException(error));
  }

  private showEpaTimeline(deadlineForReply:number):void{
    this.epaState = EPA_STATUS.PA_QUESTIONNARIE_TIMELINE;
    const deadLine: Date = new Date(deadlineForReply);
    //Removed 1 minute grace period to submit the questionset.
    deadLine.setMinutes(deadLine.getMinutes()-1);
    const today: Date = new Date();
    
    if(deadLine instanceof Date && !isNaN(deadLine.valueOf())){
      //valid date.
      let differenceinmilliseconds:number= deadLine.getTime() - today.getTime();
      
      this.epaHoursRemaining = parseInt((differenceinmilliseconds/(1000*60*60)).toString());
      differenceinmilliseconds = differenceinmilliseconds - (this.epaHoursRemaining * 60 * 60 * 1000);

      this.epaMinutesRemaining = parseInt((differenceinmilliseconds/(1000*60)).toString());
      differenceinmilliseconds = differenceinmilliseconds - (this.epaMinutesRemaining * 60 * 1000);

      this.epaSecondsRemaining = parseInt((differenceinmilliseconds/1000).toString());
    }

  }


  private handleAPIError(error:any):void{
    this.epaState = EPA_STATUS.PA_NOT_STARTED;
    this.modalService.dismissAll();
    if(typeof error == 'object'){
      if(typeof error=="object" && (error.status==-1 || error.status==0)){
        //errorhtmlmodal:
        this.errorMessage="The system cannot process your request at this time.<br/><br/>You must submit the prior authorization by fax <br/> or by contacting OptumRx at 800-711-4555.";
        this.modalService.open(this.paInitiationFaxFormTemplateRef,{size:'md'});
      } else {
        const message:string=(error.status.respMessage!=undefined && error.status.respMessage.length>0)?error.status.respMessage:error.status.statusDesc;
        if(typeof error.status =='object' && error.status.statusCode!=undefined 
        && (error.status.statusCode.toLowerCase()=='bx' || error.status.statusCode.toLowerCase()=='co')){
          //connectionerrormodal:faxMsg
          this.errorMessage=message;
          this.modalService.open(this.paInitiationFaxFormTemplateRef,{size:'md'});
        } else {
          if(message.indexOf(EPA_TRANSACTIon_STATUS.PlanNotSupported)!=-1 && message.indexOf(EPA_TRANSACTIon_STATUS.ContactRx)!=-1){
            //connectionerrormodal: 
            this.errorMessage="Electronic prior authorization cannot be initiated for this <br/> member because the member's plan does not support it.<br/><br/>You must submit the prior authorization by fax <br/> or by contacting OptumRx at 800-711-4555.";
            this.modalService.open(this.paInitiationFaxFormTemplateRef,{size:'md'});
          } else {
            //errormodal:message
            this.errorMessage=message;
            this.modalService.open(this.paInitiationErrorTemplateRef, {size:'md'});
          }
        }
      }
    } else {
      //errormodal:message
      if(error == EPA_TRANSACTIon_STATUS.INTERNAL_SERVER_ERROR){
        //errorhtmlmodal:
        this.errorMessage="An error occurred. Please call customer service at<br/>1-866-842-3278.";
        this.modalService.open(this.paInitiationErrorTemplateRef, {size:'md'});
      } else {
        if(error == EPA_TRANSACTIon_STATUS.POOLING_TIME_EXCEEDED){
          this.errorMessage="The system cannot process your request at this time.<br/><br/>You must submit the prior authorization by fax <br/> or by contacting OptumRx at 800-711-4555.";
          this.modalService.open(this.paInitiationFaxFormTemplateRef,{size:'md'});
        } else {
          //errormodal: show error
          this.errorMessage=error;
          this.modalService.open(this.paInitiationErrorTemplateRef, {size:'md'});
        }
      }
    }
  }

  goToEPAQuestions():void{
    if(this.epaState == EPA_STATUS.PA_QUESTIONNARIE_TIMELINE){
      this.logger.userAction("View EPA Question Clicked");
      this.modalService.dismissAll();
      this.router.navigateByUrl("/ePA/questions");
    }
  }

  openfaxforms():void{
    this.logger.userAction("View Fax Forms clicked.");
    window.open(environment.faxForm, "_blank");
  }

  goToHome():void{
    this.logger.userAction("Routing to Home Page");
    this.router.navigateByUrl("/home");
  }
  ngOnDestroy():void{
    if(this.epaTimerSubscription!=undefined){
      this.epaTimerSubscription.unsubscribe();
    }
    this.epaProcessor.cancelAllSubscriptions();
    this.subscription.unsubscribe();
  }
}

