import {Infusion} from './loginwebresponse';


export class Loginwebrequest {
    uuid?:string;
	linkUuid?:string;
	optumId?:string;
	lastName?:string;
	firstName?:string;
}
//============================================================
export class Orgwebrequest{
	linkUuid?:string;
	uuid?:string;
	presbrId?:string;
	optumId?:string;
	infusionFacility?:Infusion;
}