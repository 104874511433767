<input type="text" class="form-control rounded-1 inp" aria-required="true" [(ngModel)]="content" #name="ngModel"
[ngClass]="{'inpError': (valid!=0 && !name.pristine) || (validationMode==0 && valid!=0 && !emptyValid) || (validationMode==1 && valid!=0 && !emptyValid && !name.pristine)}" (blur)="onFocusOut($event)" 
(keyup)="onChange($event)"
name="datepicker" [hidden]="!show" maxlength="10" required pattern="datepattern" [attr.placeholder]="placeHolder"/>

<div class="text-danger text-start" *ngIf="valid!=0 && valid!=6" [ngSwitch]="valid">
    <ng-container *ngSwitchCase="1">Invalid character.</ng-container>
    <ng-container *ngSwitchCase="2">{{dateFieldName}} is invalid.</ng-container>
    <ng-container *ngSwitchCase="3">{{dateFieldName}} is invalid.</ng-container>
    <ng-container *ngSwitchCase="4">{{dateFieldName}} cannot be in the future.</ng-container>
    <ng-container *ngSwitchCase="5">Future date must be within {{futureDaysAllowed}} days.</ng-container>
    <ng-container *ngSwitchCase="8">From date cannot be later than end date.</ng-container>
    <ng-container *ngSwitchCase="9">Future date must be within {{futureMonthsAllowed}} months.</ng-container>
</div>