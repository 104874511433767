import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { QuestionsetViewmodal, BooleanCode, LoadNextQuestionEvent, Question, UserGivenAnswer, QuestionChoice, AnswerValue, QuestionAttachment, QuestionType } from 'src/app/modals/epa/questionsetviewmodal';

import { EpaprocessorService } from 'src/app/services/epa/epaprocessor.service';

@Component({
  selector: 'questionset',
  templateUrl: './questionset.component.html',
  styleUrls: ['./questionset.component.css']
})
export class QuestionsetComponent implements OnInit {

  private questionsetviewmodal!:QuestionsetViewmodal;
  private lastQuestionID!:string;

  @Input()
  disabled!:boolean;

  @Output()
  onUpdate:EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onCompleted:EventEmitter<boolean> = new EventEmitter<boolean>();

  blnShow!:boolean;
  questionsLoaded:Question[];
  answeredQuestionIDs:string[];

  constructor(private epaServices:EpaprocessorService) { 
    this.disabled=false;
    this.questionsLoaded=[];
    this.answeredQuestionIDs=[];
    this.updateQuestionSetViewModalFromSession();
  }
  private updateQuestionSetViewModalFromSession():void{
    
    const questionsetviewmodalsession:QuestionsetViewmodal|null = this.epaServices.getQuestionSetViewModal();
    if(questionsetviewmodalsession!=null && questionsetviewmodalsession.questions.length>0)
    {
      this.questionsetviewmodal = questionsetviewmodalsession;
      this.blnShow=true;
    } else {
      this.blnShow=false;
    }
  }

  private storeAnsweredQuestionsToSession():void{
    
    if(!this.disabled){
      //Update the answers of all questions on screen into the source data in the browser session.
      const questionsetviewmodalsession:QuestionsetViewmodal|null = this.epaServices.getQuestionSetViewModal();
      if(questionsetviewmodalsession!=null && questionsetviewmodalsession.questions.length>0){
          questionsetviewmodalsession.questions.forEach((sessionquestion:Question)=>{
          const questionOnScreen:Question|undefined = this.questionsLoaded.find((question:Question)=>question.questionID==sessionquestion.questionID);
          if(questionOnScreen!=undefined){
              //Updating answers of all previouse questions into the browser session.
              if(sessionquestion.questionType.select!=undefined && Array.isArray(questionOnScreen.questionType.select.answer) && questionOnScreen.questionType.select.answer.length>0)
                sessionquestion.questionType.select.answer = questionOnScreen.questionType.select.answer;
              if(sessionquestion.questionType.numeric!=undefined 
                && questionOnScreen.questionType.numeric!=undefined && questionOnScreen.questionType.numeric.answer!=undefined)
                sessionquestion.questionType.numeric.answer=questionOnScreen.questionType.numeric.answer;
              if(sessionquestion.questionType.freeText!=undefined 
                && questionOnScreen.questionType.freeText != undefined && questionOnScreen.questionType.freeText.answer!=undefined)
                sessionquestion.questionType.freeText.answer=questionOnScreen.questionType.freeText.answer;
              if(sessionquestion.questionType.date!=undefined 
                && questionOnScreen.questionType.date != undefined && questionOnScreen.questionType.date.answer!=undefined)
                sessionquestion.questionType.date.answer=questionOnScreen.questionType.date.answer;
          } else {
              //Clearing the answers of all next questions into the browser session.
              if(sessionquestion.questionType.select!=undefined)
              sessionquestion.questionType.select.answer = [];
              if(sessionquestion.questionType.numeric!=undefined)
                sessionquestion.questionType.numeric.answer=new UserGivenAnswer<string>();
              if(sessionquestion.questionType.freeText!=undefined)
                sessionquestion.questionType.freeText.answer=new UserGivenAnswer<string>();
              if(sessionquestion.questionType.date!=undefined)
                sessionquestion.questionType.date.answer=new UserGivenAnswer<AnswerValue>();
          }
        });
        this.epaServices.storeQuestionSetViewModal(questionsetviewmodalsession);
      }
    }
  }

  private removeAllNextQuestionsOnScreen(answeredQuestionIDIndex:number):void{
    
    const blnLastQuestionOnScreen:boolean = (answeredQuestionIDIndex== (this.answeredQuestionIDs.length-1))?true:false;
    
    if(!blnLastQuestionOnScreen){
      this.answeredQuestionIDs.splice(answeredQuestionIDIndex+1,this.answeredQuestionIDs.length);
    }
    
    const newlastQuestionIndex:number = this.questionsLoaded.findIndex((item:Question)=>{
      return item.questionID == this.answeredQuestionIDs[this.answeredQuestionIDs.length-1];
    });

    if(newlastQuestionIndex< (this.questionsLoaded.length-1))
      this.questionsLoaded.splice(newlastQuestionIndex+1, this.questionsLoaded.length);
  }

  private getNextQuestionID(currentQuestionIndex:number):string{
    
    const currentQuestion:Question = this.questionsetviewmodal.questions[currentQuestionIndex];
    //When current question is select type of question.
    if(currentQuestion.questionType.select!=undefined && currentQuestion.questionType.select.answer!=undefined
      && Array.isArray(currentQuestion.questionType.select.answer) && currentQuestion.questionType.select.answer.length>0){
        if(currentQuestion.questionType.select.selectMultiple == BooleanCode.No){
          if(currentQuestion.questionType.select.answer[0].prescriberProvidedAnswer!=undefined
            && currentQuestion.questionType.select.answer[0].prescriberProvidedAnswer.choiceID != undefined){
              const answeredChoiceIndex:number = currentQuestion.questionType.select.choice.findIndex((item:QuestionChoice)=>{
                if(Array.isArray(currentQuestion.questionType.select.answer) && currentQuestion.questionType.select.answer.length>0)
                  return item.choiceID == currentQuestion.questionType.select.answer[0].prescriberProvidedAnswer.choiceID;
                else
                  return false;
              });
              if(answeredChoiceIndex!=-1){
                return currentQuestion.questionType.select.choice[answeredChoiceIndex].nextQuestionID;
              }
            }
        } else {
          return currentQuestion.defaultNextQuestionID;
        }
    } else {
      if(currentQuestion.questionType.freeText!=undefined && currentQuestion.questionType.freeText.answer!=undefined
        && currentQuestion.questionType.freeText.answer.prescriberProvidedAnswer!=undefined
        && currentQuestion.questionType.freeText.answer.prescriberProvidedAnswer.length>0
        && currentQuestion.defaultNextQuestionID!=undefined && currentQuestion.defaultNextQuestionID.length>0){
          return currentQuestion.defaultNextQuestionID;
      }
    }
    return "";
  }

  private pushNextQuestion(currentQuestionID:string):void{
    
    const currentQuestionIndex:number = this.questionsetviewmodal.questions.findIndex((item:Question)=>item.questionID == currentQuestionID);
    if(currentQuestionIndex!=-1){
      //Get next question ID as per PA initiation response and current question answer.
      let nextQuestionID:string= this.getNextQuestionID(currentQuestionIndex);
      //Continue to load next question untill no questions found or we reached end of the questionnarie as per PA initiation response.
      if(nextQuestionID.length>0 && nextQuestionID.toLowerCase()!="end"){
        /*//Below conditions is required to handle Resume saved PA.
        //last question ID usually represents the id of last question in the saved PA. 
        //We stop loading next questions (No push operation in else block) after last saved question though we are not reached end of the question set as per PA initiation response.
        if(currentQuestionID!=this.lastQuestionID){
          const nextQuestionIndex:number = this.questionsetviewmodal.questions.findIndex((item:Question)=>item.questionID == nextQuestionID);
          if(nextQuestionIndex!=-1){
            setTimeout(()=>{
              this.questionsLoaded.push(this.questionsetviewmodal.questions[nextQuestionIndex]);
            },50);
          }
        } else {
          //Not pusing next question into the list during resume PA since screen is already loaded with enough questions as per previous saved PA version.
          //We are completed with resume PA, Now we are overwriting last question id to 'end' to enable pushing next questions again when user start answering those.
          this.lastQuestionID = "end";
        }*/
        const nextQuestionIndex:number = this.questionsetviewmodal.questions.findIndex((item:Question)=>item.questionID == nextQuestionID);
        if(nextQuestionIndex!=-1){
          setTimeout(()=>{
            this.questionsLoaded.push(this.questionsetviewmodal.questions[nextQuestionIndex]);
          },50);
        }
      } else {
        const attachmentQuestion:Question = new Question();
        attachmentQuestion.questionID="end";
        attachmentQuestion.questionType = new QuestionType();
        attachmentQuestion.questionType.attachment = new QuestionAttachment();
        setTimeout(()=>{
          this.questionsLoaded.push(attachmentQuestion);
        },50);
      } 
    }
  }

  ngOnInit(): void {
    if(this.blnShow){
      this.questionsLoaded.push(this.questionsetviewmodal.questions[0]);
      
      if(this.questionsetviewmodal.paAttachment!=undefined && this.questionsetviewmodal.paAttachment.saveIndicator!=undefined){
        if(this.questionsetviewmodal.paAttachment.saveIndicator.toLowerCase()=='y' || this.questionsetviewmodal.paAttachment.saveIndicator.toLowerCase()=='n'){
          //Last question ID usually represent 'end' that allows to show attachment question when all questions are answered as per decision tree.
          this.lastQuestionID="end";  
        } else {
          //Otherwise, it represents last question that was answered by the user when this PA is saved last time.
          this.lastQuestionID = this.questionsetviewmodal.paAttachment.saveIndicator;
        }
      } else{
        //Last question ID usually represent 'end' that allows to show attachment question when all questions are answered as per decision tree.
        this.lastQuestionID="end";
      }
    }
  }


  loadNextQuestion(event:LoadNextQuestionEvent):void{
    
    //END represents the current question is attachment type.
    if(event.currentQuestionID!=undefined && event.currentQuestionID.toLowerCase()!="end"){
        //There are still few questions left to be answered.
        const answeredQuestionIDIndex:number = this.answeredQuestionIDs.findIndex((questionID:string)=>questionID==event.currentQuestionID);
        if(answeredQuestionIDIndex!=-1){
          //This is not a last question being displayed on the screen. User had changed his answer for one of previous question.
          //Hence before we load next appropriate question, we have to clear all next question and there answers.
          this.removeAllNextQuestionsOnScreen(answeredQuestionIDIndex);
          this.epaServices.storeQuestionIDsAnswered(this.answeredQuestionIDs);
          if(!event.blnOnInit)
          {
            this.storeAnsweredQuestionsToSession();
            this.onUpdate.emit();
          }
          this.updateQuestionSetViewModalFromSession();
          this.pushNextQuestion(event.currentQuestionID);
          this.onCompleted.emit(false);
        } else {
          //User had submitted answer for the current question freshly just now.
          this.answeredQuestionIDs.push(event.currentQuestionID);
          this.epaServices.storeQuestionIDsAnswered(this.answeredQuestionIDs);
          if(!event.blnOnInit)
          {
            this.storeAnsweredQuestionsToSession();
            this.onUpdate.emit();
          }
          this.updateQuestionSetViewModalFromSession();
          this.pushNextQuestion(event.currentQuestionID);
          this.onCompleted.emit(false);
        }
    } else {
      //All questions within the given question set are already answered.
      //Push attachment question ID into the answered list only once no matter how many times attachment is changed.
      if(event.currentQuestionID!=undefined && this.answeredQuestionIDs.indexOf("end")==-1){
        //Note: Give question set doesnot contain attachment question. 
        //Hence event.currentQuestionID = END represents that current question answered is attachment question.
        this.answeredQuestionIDs.push(event.currentQuestionID.toLowerCase());
      }
      //Work to store attachment question answer
      this.epaServices.storeQuestionIDsAnswered(this.answeredQuestionIDs);
      if(!event.blnOnInit){
        this.storeAnsweredQuestionsToSession();
        this.onUpdate.emit();
      }
      //Always have to submit the attachment question as soon as it is loaded so that it will be added to the watch list of auto save operation to considers current attachment status.
      this.onCompleted.emit(true);
    }
  }

}
