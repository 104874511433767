import { LocalMessages } from "../drugSearch/Trailadjudicationwebresponse";
import { CLAIM_STATUS_TYPE } from 'src/app/constants/appconstant';
import { DurSegment } from 'src/app/modals/drugSearch/Trailadjudicationwebrequest';

export enum BANNER_TYPE{
    NONE=0,
    RED=1,
    YELLOW=2,
    GREEN=3,
    BLACK=4
}

export class ClaimSummaryDetails{
    DateofService:string="";
    QuantityEntered:string="";
    DaysSupply:number=0;
    QuantityCalculated:number=0;
    QuantityDesc:string="";
    ProviderTotalAmountDue:string="";
    PatientPayAmount:string=""; 
    MedicationName:string="";
    ProductID:string="";
    DiagnosisCode:string="";
    PlaceofService:string="";
    ContractedNetworkProvider:string="";
    ContractedNetworkProviderAddress:string="";
    ApprovedClaimIngredientCost:string="";
    ApprovedProfessionalFee:string="";  
    Name: string="";
    MemberID: string="";
    Address: string="";
    DateOfBirth:string="";
    Phone:string="";
    Gender:string="";
    BinPCNGroup:string="";
    bannerType:BANNER_TYPE=BANNER_TYPE.NONE;
    bannerMessage:string="";
    requiresPA:boolean=false;
    initiatePALnk:boolean=false;
    drugStatus:string="";
    brandPreferred:boolean=false;
    localMessages:LocalMessages[]=[];
    dateOfLastTrail:string="";
    //dateOfClaimSubmittion:string="";
    claimGroupId:string="";
    claimSubmitCustomErrorMessage="";
    //claimSubmitted:boolean=false;
    //claimSubmitFailed=false;
    //claimReversed:boolean=false;
    //dateOfReversal:string="";
    rxServiceReferenceNo:string="";
    uniqueClaimId:string="";
    claimStsTypId:CLAIM_STATUS_TYPE=CLAIM_STATUS_TYPE.UN_SAVED;
    durReject:ClaimSummaryDurReject=new ClaimSummaryDurReject();
}

export class ClaimSummaryDurReject{
    isDurReject:boolean=false;
    //isDurCodesValid:boolean=false;
    durMessages:string[]=[];
    durAllRejects:DurSegment[]=[];
    durSoftRejects:DurSegment[]=[];
    durInvalidSoftRejects:DurSegment[]=[];
  }