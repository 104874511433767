import { Component, OnInit } from '@angular/core';

import { EpaprocessorService } from 'src/app/services/epa/epaprocessor.service';

import { QuestionsetViewmodal, MedicationViewModal, Patient } from 'src/app/modals/epa/questionsetviewmodal';
import {PAReferenceID} from 'src/app/modals/epa/priorauthresponse';

@Component({
  selector: 'epadetails',
  templateUrl: './epadetails.component.html',
  styleUrls: ['./epadetails.component.css']
})
export class EpadetailsComponent implements OnInit {

  private ePADetails!:QuestionsetViewmodal;

  patient!:Patient;
  medication!:MedicationViewModal;
  paCaseId!:string;
  paRefId!:PAReferenceID;
  blnShow!:boolean;
  constructor(private epaServices:EpaprocessorService) { 
    const ePASessionDetails: QuestionsetViewmodal | null = this.epaServices.getQuestionSetViewModal();
    if(ePASessionDetails!=null){
      this.blnShow=true;
      this.ePADetails = ePASessionDetails;
      this.patient = this.ePADetails.patient;
      this.medication = this.ePADetails.medication;
      this.paCaseId=this.ePADetails.pacaseID;
      this.paRefId = this.ePADetails.paReferenceID;
    } else {
      this.blnShow=false;
    }
  }

  ngOnInit(): void {

  }

}
