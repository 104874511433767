<section id="header" class="d-print-none">
    <nav class="navbar navbar-default navbar-fixed-top">
<div class="container-fluid bg-white p-1">
    <div class="row  p-4 pt-3 pb-2">
        <img src="./../../../assets/images/optum-logo-ora-rgb.svg" role="img" aria-labelledby="mysvgtitle" class="optum-img" style="width:100px; height:22px;">
    </div>
    <div class="row ps-4">
        <p>
        <h3 style="font-weight: 700;">Specialty Management Access Portal</h3>
     </div>
</div>
    </nav>
</section>


<section id="header" class="d-print-none mt-3 pt-3 " style="z-index: 999;">
    <nav class="navbar navbar-default border-bottom border-top p-0">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-1 col-sm-2 user_menu">
                    <app-usermenu></app-usermenu>
                    <!-- <a class="navbar-brand" href="javascript:void(0)">
                        <img ng-if="blnsmapuser" id="logo_id" alt="" class="img logo_img" src="./../../../assets/images/menu.png">
                    </a> -->
                </div>
                <div class="col-md-7 col-sm-6">
                    <breadcrumb></breadcrumb>
                </div>
                <div class="col-xs-8 col-md-7 col-sm-8 col-10 text-end profile">
                    <app-infusionlist></app-infusionlist>
                </div>
            </div>
        </div>
    </nav>    
</section>
<section id="content" style="z-index: 1;">
    <router-outlet></router-outlet>
</section>
<section id="footer" class="d-print-none">
    <nav class="navbar navbar-expand-lg bg-light" aria-label="footer">
        <div class="container-fluid nav-footer text-center">
          <div class="row">
              <div class="col">
                  <ul>
                  <li  aria-label="2021 Optum, Inc. All rights reserved">&#169; 2021 Optum, Inc. All rights reserved</li>
                  &nbsp;<span aria-hidden="true">|</span>&nbsp;
                  <li><a routerLink="/privacy" aria-label="Link to Privacy Policy" (click)="goToPrivacyLink('/rxlinkapp-msepa/privacy')">Privacy policy</a></li>
                  &nbsp;<span aria-hidden="true">|</span>&nbsp;
                  <li><a routerLink="/terms" aria-label="Link to Terms of Use" (click)="goToTermsLink('/rxlinkapp-msepa/terms')">Terms of use</a></li>
                  &nbsp;<span aria-hidden="true">|</span>&nbsp;
                  <li><a routerLink="/contactus" aria-label="Link to Contact Us" (click)="goToContactusLink('/rxlinkapp-msepa/contactus')">Contact us</a></li>
                  </ul>
              </div>
          </div>
        </div>
      </nav>
</section>