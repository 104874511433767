<div class="content-card bg-white">
    <div class="card-content">


        <ng-container *ngIf="blnSelectMultiple">

            <ng-container *ngIf="question.questionType.select.choice.length<=4">
                <p [innerHtml]="question.questionText" *ngIf="blnAcknowledgement"></p>
                <h2 [innerHtml]="question.questionText" *ngIf="!blnAcknowledgement"></h2>
                <ng-container *ngFor="let choice of question.questionType.select.choice">
                    <label class="form-control" [class.selected]="isChoiceSelected(choice)" tabindex="-1">
                        <input type="checkbox" [attr.name]="question.questionID" 
                        (change)="toggleChoice(choice)"/>
                        <span [innerHtml]="choice.choiceText"></span>
                    </label>
                </ng-container>                
                <div class="text-end">
                    <button class="btn btn-primary rounded-pill" [disabled]="selectedCheckboxChoices.length==0" (click)="submit()">Continue</button>
                </div>
            </ng-container>

            <ng-container *ngIf="question.questionType.select.choice.length>4">
                <h2 [innerHtml]="question.questionText"></h2>
                <label class="form-label multiSelectLabel">Select all that apply</label>
                <div class="multiSelect">
                    <div class="multiSelectDropDown" name="toggle" tabindex="-1" (click)="toggleMultipleSelectDropdown($event)">
                        <ng-container *ngIf="selectedCheckboxChoices.length==0">
                            <div class="multiselectplaceholder" name="toggle">
                                <label>Select an answer</label>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="selectedCheckboxChoices.length!=0">
                            <div *ngFor="let choice of selectedCheckboxChoices" class="selectedDropdownItem" name="notoggle">
                                <label [innerHtml]="choice.choiceText"></label>
                                <i class="fa fa-close" (click)="toggleChoiceByID(choice.choiceID)"></i>
                            </div>
                        </ng-container>
                        <i class="fa fa-caret-down" aria-hidden="true" name="toggle"></i>
                    </div>
                    <div class="multiSelectDropDownList bg-white" *ngIf="blnSelectMultipleDropdown">
                        <div *ngFor="let choice of question.questionType.select.choice" [hidden]="isChoiceSelected(choice)" (click)="toggleMultipleSelectDropdown($event)">
                            <div class="fontbold" (click)="toggleChoiceByID(choice.choiceID)">
                                <span [innerHtml]="choice.choiceText"></span>
                            </div>
                        </div>  
                    </div>
                </div>
                <br/>
                <div class="text-end">
                    <button class="btn btn-primary rounded-pill" [disabled]="selectedCheckboxChoices.length==0" (click)="submit()">Continue</button>
                </div>
            </ng-container>
        </ng-container>



        <ng-container *ngIf="!blnSelectMultiple">

            <ng-container *ngIf="question.questionType.select.choice.length<=4">
                <!--This is usually acknowledgement type of question-->
                <p [innerHtml]="question.questionText" *ngIf="blnAcknowledgement"></p>
                <h2 [innerHtml]="question.questionText" *ngIf="!blnAcknowledgement"></h2>
                <ng-container *ngFor="let choice of question.questionType.select.choice">
                    <label class="form-control" [class.selected]="selectedRadioOption == choice.choiceID" tabindex="-1">
                        <input type="radio" [attr.name]="question.questionID" 
                        (change)="toggleRadioOption(choice.choiceID)"/>
                        <span [innerHtml]="choice.choiceText"></span>
                    </label>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="question.questionType.select.choice.length>4">
                <h2 [innerHtml]="question.questionText"></h2>
                <label class="form-label singleSelectLabel">Select an answer</label>
                <select class="singleSelect" [(ngModel)]="selectedRadioOption">
                    <option value="" disabled>Choose answer</option>
                    <option *ngFor="let choice of question.questionType.select.choice" [ngValue]="choice.choiceID">{{choice.choiceText}}</option>
                </select>
            </ng-container>
            
        </ng-container>
        
    </div>
</div>
