import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Loginuser } from 'src/app/modals/login/loginviewmodal';
import { ProfileService } from 'src/app/services/profile/profile.service';
import {Router} from '@angular/router';
import { LoggerService } from 'src/app/services/logger/logger.service';

import { SubmittedpahistoryComponent } from 'src/app/components/submittedpahistory/submittedpahistory.component';
import { SavedpahistoryComponent } from 'src/app/components/savedpahistory/savedpahistory.component';

import { PAGE_TYPE } from 'src/app/constants/routeconstants';
import { ViewAllRouteState } from 'src/app/constants/routeconstants';
import {Title} from "@angular/platform-browser";
import { SavedPARequest, EPACancellationWebRequest } from 'src/app/modals/epa/priorauthrequest';
import { SavedPAResponse, EpaCancellationWebResponse } from 'src/app/modals/epa/priorauthresponse';
import { QuestionsetViewmodal } from 'src/app/modals/epa/questionsetviewmodal';
import { KEY_CODE } from 'src/app/constants/appconstant';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  maxHistoryCount:number=10;
  isSavedNotify!:boolean;
  isCancelled!:boolean;
  isSavedParam!:SavedPARequest;
  isCancelPAparam!:string;
  iscancelPA!:EpaCancellationWebResponse;

  private _activeTab : number=1;
  public get activeTab() : number {
    return this._activeTab;
  }
  public set activeTab(v : number) {
    this._activeTab = v;
    switch(v){
      case 1:
        this.loggerService.log("Home page - Submitted claims history tab selected.");
        break;
      case 2:
        this.loggerService.log("Home page - Submitted prior authorization history tab selected.");
        // setTimeout(()=>{
        //   this.submittedHistoryComponent?.reload();
        // },100);
        break;
      case 3:
        this.loggerService.log("Home page - Saved prior authorization history tab selected.");
        break;
    }
  }
  

  constructor(protected router:Router, private loggerService:LoggerService, private titleService:Title) {
    this.isSavedParam = JSON.parse(""+sessionStorage.getItem("savelater"));
    this.isCancelPAparam = JSON.parse(""+sessionStorage.getItem("paCaseID"));
    this.iscancelPA = JSON.parse(""+sessionStorage.getItem("iscancelPA"));
    console.log(this.iscancelPA);
     
    if(this.isSavedParam!=undefined){
      this.isSavedNotify = true;
    }
    else if(this.isCancelPAparam!=undefined||this.isCancelPAparam!=null){
      this.isCancelled = true;
    }
    
    setTimeout(()=>{
      sessionStorage.removeItem("savelater");
      sessionStorage.removeItem("paCaseID");
      this.isSavedNotify = false;
      this.isCancelled = false;
      // this.epaSavedResponse =JSON.parse(""+sessionStorage.getItem("savelater"));
    },7000);

    if(sessionStorage.getItem('ActiveTab')!=undefined)
      this.activeTab=parseInt(""+sessionStorage.getItem('ActiveTab'));
      if(sessionStorage.getItem('SelectedInfsnId')==undefined){
        sessionStorage.clear();
      }else{
        let selectedInfusion= ""+sessionStorage.getItem('SelectedInfsnId');
        sessionStorage.clear();
        sessionStorage.setItem('SelectedInfsnId',selectedInfusion);
      }
    var v=sessionStorage.getItem("lastPageBeforeReload");
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);
    this.titleService.setTitle("SMAP - Home");
    // sessionStorage.removeItem('blnSearchedMember');
    // sessionStorage.removeItem('memberResults');
    // sessionStorage.removeItem('prescriberList');
    // sessionStorage.removeItem('searchPrescriberNameForm');
    // sessionStorage.removeItem('prescriberNPI');
    // sessionStorage.removeItem('memberdetails');
  }

  goto(routeUrl:string){
    this.loggerService.userAction("Going to check coverage.");
    this.router.navigateByUrl("/"+routeUrl);
  }

  gotoViewAllSavedPA(){
    this.loggerService.userAction("Going to view All Save PA.");
    const savedState:ViewAllRouteState = {pageType:PAGE_TYPE.SAVEDPAS};
    this.router.navigateByUrl("/viewall",{ state:  savedState });
    this.titleService.setTitle("SMAP - View All PAs in Progress");
    sessionStorage.setItem('ActiveTab',""+3);

  }

  gotoViewAllSubmittedPA(){
    this.loggerService.userAction("Going to view All Submitted PA.");
    const submittedState:ViewAllRouteState = { pageType: PAGE_TYPE.SUBMITTEDPAS };
    this.router.navigateByUrl("/viewall",{ state: submittedState});
    this.titleService.setTitle("SMAP - View All Submitted PAs");
    sessionStorage.setItem('ActiveTab',""+2);

  }

  gotoSavedClaims(){
    this.loggerService.userAction("Going to view Saved Claims.");
    const savedState:ViewAllRouteState = {pageType: PAGE_TYPE.SAVEDCLAIMS};
    this.router.navigateByUrl("/smapclaims", {state: savedState});
  }

  gotoAllSubmittedClaims(){
    this.loggerService.userAction("Going to view All Submitted Claims.");
    const savedState:ViewAllRouteState = {pageType:PAGE_TYPE.SUBMITTEDCLAIMS};
    this.router.navigateByUrl("/AllSubmittedClaims",{ state:  savedState });
    this.titleService.setTitle(" View All Submitted Claims");
    sessionStorage.setItem('ActiveTab',""+1);
  }

  gotoSearchMedicationClaims(){
    this.loggerService.userAction("Going to Search Medication Claims.");
    const savedState:ViewAllRouteState = {pageType:PAGE_TYPE.SUBMITTEDCLAIMS};
    this.router.navigateByUrl("/searchMediactionClaims",{ state:  savedState });
  }

  gotoPaidClaims(){
    this.loggerService.userAction("Going to paid Claims.");
    const paidState:ViewAllRouteState = {pageType:PAGE_TYPE.SUBMITTEDCLAIMS};
    this.router.navigateByUrl("/claimreversal",{ state:  paidState });
  }
  ngOnInit(): void {
    this.loggerService.userAction("User landed on home screen.");
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    const ClickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    });

    if(event.keyCode == KEY_CODE.SPACE_BAR){
      event.target?.dispatchEvent(ClickEvent);
    }

    if (event.keyCode == KEY_CODE.RIGHT_ARROW) {
      window.scroll(0, 800);
      if(this.activeTab<3)
        this.activeTab=this.activeTab+1;
      else
        this.activeTab=1;
    }

    if (event.keyCode == KEY_CODE.LEFT_ARROW) {
      window.scroll(0, 800);
      if(this.activeTab>1)
        this.activeTab=this.activeTab-1;
      else
        this.activeTab=3;
    }
  }

}
