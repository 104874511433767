<div class="container-fluid nopadding">
    <div class="container-fluid maindiv" style="height: 325px;">
        <div class="row">
            <div class="col-md-12 text-center" style="padding:95px 0 85px 0;">
                <div class="smapcontactus" style="display:inline-block;">
                    <p class="heading_mega" style="color: #ffffff;">Contact Specialty Management Access Portal</p>
                    <p class="paragraph" style="color: #ffffff; word-wrap: break-word;line-height: 24px;">
                        If you have any questions, please contact us with questions using the information below.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid" style="position: relative; top:-125px;">
        <div class="row">
            <div class="col-md-12 text-center" style="padding:0px;">
                <div class="container-fluid nopadding smapcontactus">
                    <div class="row">
                        <div class="col-sm-12 col-md-4 position-relative" style="padding:10px 5px 0 12px;">
                            <div x-ms-format-detection="none" class="content-card" style="min-height: 279px;">
                                <p class="heading_3" style="font-weight: 700; font-size: 16px;">Optum Pharmacy Help Desk</p>
                                <p class="paragraph_small">
                                    For questions related to prior authorizations and pharmacy claim submission through
                                    the Specialty Management Access
                                    Portal contact the OptumRx Pharmacy Help Desk.
                                </p>
                                <ul style="list-style: none;padding-left: 0px;padding-top: 15px;margin-bottom: 0px;">
                                    <li>
                                        <p class="paragraph" style="color: #444444;">
                                            <i x-ms-format-detection="none" class="fa fa-phone" aria-hidden="true">
                                            </i>
                                            &nbsp; 1-800-797-9791
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 position-relative" style="padding:10px 5px 0 12px;left:10px;">
                            <div x-ms-format-detection="none" class="content-card" style="min-height: 279px;">
                                <p class="heading_3" style="font-weight: 700;font-size: 16px;">Optum Support Help Desk</p>
                                <p class="paragraph_small">For Optum ID questions, please contact the Optum Support Help
                                    Desk.</p>
                                <ul
                                    style="list-style: none; padding-left: 0px;padding-top: 15px; margin-bottom: 0px;margin-top: 75px;">
                                    <li>
                                        <p class="paragraph" style="color: #444444;">
                                            <i x-ms-format-detection="none" class="fa fa-phone" aria-hidden="true">
                                            </i>
                                            &nbsp; 855-819-5909
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 position-relative" style="padding-top:10px;left:20px;">
                            <div x-ms-format-detection="none" class="content-card" style="min-height: 279px;">
                                <p class="heading_3" style="font-weight: 700;font-size: 16px;">Link Support Help Desk</p>
                                <p class="paragraph_small" style="margin-bottom: 31px;">For questions related to Link,
                                    please contact the Link Support Help Desk.</p>
                                <ul
                                    style="list-style: none;padding-left: 0px;padding-top: 15px; margin-bottom: 0px;margin-top: 75px;">
                                    <li>
                                        <p class="paragraph ng-binding" style="color: #444444;">
                                            <i x-ms-format-detection="none" class="fa fa-phone" aria-hidden="true">
                                            </i>
                                            &nbsp; 855-349-1375
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 25px;">
            <div class="col-md-12 text-center" style="padding: 0px;">
                <div class="container-fluid content-card smapcontactus">
                    <div class="row">
                        <div class="col-md-12">
                            <p class="heading_3" style="margin-bottom: 15px;font-weight: 700;font-size: 16px;">Link and Optum Pharmacy Help Desk
                                Information</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-md-5 row">
                            <p class="paragraph" style="color: #444444; white-space: nowrap;">Hours of Operation
                                (Central Time)</p>
                            <div class="col-sm-6 col-md-6 nopadding position-relative" style="width:37%;top:-40px;left:10px;">
                                <p class="label_cu label_hours">
                                    <span style="color: #626F7D;">Monday - Friday</span>
                                </p>
                                <p class="label_cu label_hours">
                                    <span style="color: #626F7D;">Saturday</span>
                                </p>
                                <p class="label_cu label_hours">
                                    <span style="color: #626F7D;">Sunday</span>
                                </p>
                            </div>
                            <div class="col-sm-6 col-md-6 position-relative" style="top:-40px;">
                                <p class="label_cu label_hours">
                                    <span>6:00 a.m. - 10:00 p.m.</span>
                                </p>
                                <p class="label_cu label_hours">
                                    <span>6:00 a.m. - 6:00 p.m.</span>
                                </p>
                                <p class="label_cu label_hours">
                                    <span>9:00 a.m. - 6:00 p.m.</span>
                                </p>
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-4">
                            <p class="paragraph" style="color: #444444;">Observed Holidays</p>
                            <ul style="padding-left: 15px;">
                                <li>
                                    <p class="paragraph" style="color: #444444;">New Year's Day</p>
                                </li>
                                <li>
                                    <p class="paragraph" style="color: #444444;">Martin Luther King, Jr. Day</p>
                                </li>
                                <li>
                                    <p class="paragraph" style="color: #444444;">Memorial Day</p>
                                </li>
                                <li>
                                    <p class="paragraph" style="color: #444444;">Independence Day</p>
                                </li>
                                <li>
                                    <p class="paragraph" style="color: #444444;">Labor Day</p>
                                </li>
                            </ul>
                        </div>
                        <div class="col-sm-3 col-md-3">
                            <ul style="padding-left: 0px;margin-top: 35px;">
                                <li>
                                    <p class="paragraph" style="color: #444444;">Thanksgiving Day</p>
                                </li>
                                <li>
                                    <p class="paragraph" style="color: #444444;">Day after Thanksgiving</p>
                                </li>
                                <li>
                                    <p class="paragraph" style="color: #444444;">Christmas Day</p>
                                </li>
                            </ul>
                        </div>
                    </div>


                </div>
            </div>


        </div>


    </div>
</div>
<section id="footer" class="d-print-none">
    <nav class="navbar navbar-expand-lg bg-light">
        <div class="container-fluid nav-footer text-center">
            <div class="row">
                <div class="col">
                    <ul>
                        <li aria-label="2021 Optum, Inc. All rights reserved" style="list-style: outside none none;display: inline-block;">&#169; 2021 Optum, Inc. All rights
                            reserved</li>
                        &nbsp;|&nbsp;
                        <li style="list-style: outside none none;display: inline-block;"><a aria-label="Link to Privacy Policy">Privacy policy</a></li>
                        &nbsp;|&nbsp;
                        <li style="list-style: outside none none;display: inline-block;"><a routerLink="/terms" aria-label="Link to Terms of Use">Terms of use</a></li>
                        &nbsp;|&nbsp;
                        <li style="list-style: outside none none;display: inline-block;"><a routerLink="/contactus" aria-label="Link to Contact Us">Contact us</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</section>