import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import {Router} from '@angular/router';

import { Loginuser } from '../../modals/login/loginviewmodal';
import  {APPCONSTANTS} from './../../constants/appconstant';
import { Loginwebrequest, Orgwebrequest } from 'src/app/modals/login/loginwebrequest';
import { Loginwebresponse, Infusion, Prescriber } from 'src/app/modals/login/loginwebresponse';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { Status } from 'src/app/modals/drugSearch/reverseclaimResponse';


@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  
 errorStatus!:Loginwebresponse;

  private _appstatus : APPCONSTANTS;
  public get appstatus() : APPCONSTANTS {
    return this._appstatus;
  }

  public set appstatus(status:APPCONSTANTS){
    this._appstatus=status;
    if(this._appstatus!=APPCONSTANTS.APP_INITIALIZED){
      this.router.navigateByUrl("/error",{state:this.errorStatus});
    }
  }

  private _loginuser : Loginuser;
  public get loginuser() : Loginuser {
    return this._loginuser;
  }
  
  constructor(public http:HttpClient, public keycloakService: KeycloakService, 
    public jwtHelper: JwtHelperService,
    private router: Router) { 
    this._loginuser = new Loginuser();
    this._appstatus = APPCONSTANTS.APP_NOT_INITIALIZED;
  }

  loadOrganization(infusionlist:Infusion[], infusion:Infusion):Promise<APPCONSTANTS>{
      let promise:Promise<APPCONSTANTS> = new Promise<APPCONSTANTS>((resolve,reject)=>{
        if(infusionlist!=undefined){
          let orgwebrequest:Orgwebrequest = new Orgwebrequest();
          orgwebrequest.uuid = this._loginuser.UUID;
          orgwebrequest.linkUuid = this._loginuser.linkUuid;
          //either this._value.ohid or this._value.prescriber.
          //optumId should be fine. ohid available in SSO token where as optumId comes from DB.
          orgwebrequest.optumId = this._loginuser.ohid;
          orgwebrequest.presbrId = this._loginuser.prescriber.presbrId;
          orgwebrequest.infusionFacility = infusion;
          let header=new HttpHeaders({"endpoint":environment.orgUrl});
          this.http.post(environment.proxyurl,orgwebrequest,{headers:header}).subscribe((orgwebresponse:Loginwebresponse)=>{
              if(orgwebresponse!=undefined && orgwebresponse.status!=undefined 
                && orgwebresponse.status.statusCode!=undefined
                && orgwebresponse.status.statusCode.toLowerCase()==APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
                && orgwebresponse.infusionList!=undefined
                && orgwebresponse.infusionList.length>0){

                  this._loginuser.infusionList.forEach(item=>{
                      if(orgwebresponse!=undefined && orgwebresponse.infusionList!=undefined
                        && item.pharmacyNPI == orgwebresponse.infusionList[0].pharmacyNPI){
                          orgwebresponse.infusionList[0].organizationRelationId = item.organizationRelationId;
                          orgwebresponse.infusionList[0].roleTypeId = item.roleTypeId;
                      }
                  });
                  this._loginuser.selectedInfusion.next(orgwebresponse.infusionList[0]);
                  resolve(APPCONSTANTS.APP_INITIALIZED);
                } else {
                  resolve(APPCONSTANTS.APP_INITIALIZATION_ERROR);
                }
          },(orgApiError)=>{
            resolve(APPCONSTANTS.APP_INITIALIZATION_ERROR);
          });
        }

      });
      return promise;
  }

  loadProfile():Promise<Boolean> {
    return new Promise<Boolean>((resolve,reject)=>{
      this._loginuser = new Loginuser();
      this.keycloakService.getToken().then(token=>{
        try{
          let decodedToken = this.jwtHelper.decodeToken(token);
          let loginwebrequest:Loginwebrequest = new Loginwebrequest();
          loginwebrequest.uuid = decodedToken["optum-id"];
          loginwebrequest.linkUuid = decodedToken["sub"];
          loginwebrequest.optumId = decodedToken["preferred_username"];
          loginwebrequest.lastName = decodedToken["family_name"];
          loginwebrequest.firstName = decodedToken["firstName"];
         // //console.log("optumid", loginwebrequest.optumId);
         let header=new HttpHeaders({"endpoint":environment.loginUrl});
          this.http.post(environment.proxyurl,loginwebrequest,{headers:header}).subscribe((loginresponse:Loginwebresponse)=>{
            if(loginresponse.status!=undefined
              && loginresponse.status.statusCode!=undefined
              && loginresponse.status.statusCode.toLowerCase()==APPCONSTANTS.API_RESPONSE_SUCCESS_TXT){ 
                // this.errorStatus = loginresponse;
                if(loginresponse.prescribers!=undefined && loginresponse.prescribers[0].approveInd=='Y'){
                  console.log("active");
                    if(loginresponse.reActivateInd!=undefined && loginresponse.reActivateInd.toLowerCase()=="y"){
                         //No provision of this scenario in SMAP until now. This may become valid once smap self registration is enabled.
                    } else {
                        if(loginresponse.npiValidOrNot!=undefined && loginresponse.npiValidOrNot=="0"){
                            this._loginuser.email=decodedToken["email"];
                            this._loginuser.ohid=decodedToken["preferred_username"];
                            this._loginuser.UUID=decodedToken["optum-id"];
                            this._loginuser.linkUuid=decodedToken["sub"];
                            if(loginresponse.prescribers!=undefined){
                              let userlist:Prescriber[]= loginresponse.prescribers.filter(item=>item.uuid==this._loginuser.UUID);
                              if(userlist.length>0){
                                this._loginuser.prescriber=userlist[0];
                                // if(this._value.prescriber.approveId==undefined || this._value.prescriber.approveId.toLowerCase()=='n'){
                                //   this._appstatus=APPCONSTANTS.APP_INITIALIZATION_ERROR;
                                //   resolve(true);
                                // } else {
                                  
                                // }
        
                                userlist = loginresponse.prescribers.filter(item=>item.uuid!=this._loginuser.UUID);
                                this._loginuser.defaultPrescriber=(userlist.length>0)?userlist[0]:this._loginuser.prescriber;
        
                                if(loginresponse.infusionList!=undefined && loginresponse.infusionList.length>0){
                                      this._loginuser.infusionList=loginresponse.infusionList;                      
                                      if(loginresponse.infusionList.length>1){
                                        this._loginuser.selectedInfusion.next(loginresponse.infusionList[0]);
                                        //Though first infusion is selected by default, UI will show a pop asking to choice infusion facility.
                                        //Organization details API call will happen after selecting a infusion from that popup.
                                        this.errorStatus = loginresponse;
                                        this._appstatus=APPCONSTANTS.APP_INITIALIZED;
                                        resolve(true);
                                      } else {
                                        //Organizations details API call will happen directly on only one available organizarion/infusion during user login. 
                                        //No need of explicit popup.
                                        this.loadOrganization(loginresponse.infusionList, loginresponse.infusionList[0]).then(response=>{
                                            this._appstatus=response;
                                            resolve(true);
                                        },error=>{
                                            this.errorStatus = loginresponse;
                                            this._appstatus=APPCONSTANTS.APP_INITIALIZATION_ERROR;
                                            resolve(true);
                                        });
                                      }
                                }
                              }
                            } else {
                              /** testing logi
                               * nresponse.status.customErrorCode="5" **/;
                              this.errorStatus = loginresponse;
                              sessionStorage.setItem("state",JSON.stringify(this.errorStatus));
                              this._appstatus=APPCONSTANTS.APP_INITIALIZATION_ERROR;
                              resolve(true);
                            }
                        } else {
                          /* If further want to code below scenario's. 
                            In PCMS, Prescriber details service will be verified before user login to check 
                            if the NPI is correct and exists in RxClaims 
                            (NPI is the NPI through with user is registered in case of prescriber. It is default prescriber NPI in case of user with admin role.)  
                            1---PrescriberDetails service getting 404 respCode
                            2---PrescriberDetail service failed
                            3--Default or Current NPI is null or empty
                          */
                          this.errorStatus = loginresponse;
                          this._appstatus=APPCONSTANTS.APP_INITIALIZATION_ERROR
                          resolve(true);
                        }
                      }
                     }
                     else{
                      // debugger;
                      console.log("inactive");
                      this._appstatus=APPCONSTANTS.NOT_APPROVED;
                      resolve(true);
                      sessionStorage.setItem("state",JSON.stringify(APPCONSTANTS.NOT_APPROVED));
                      this.errorStatus=loginresponse;
                      this.router.navigateByUrl("/error",{state:this.errorStatus});                      /******error component code****** */
                    }

                 } else {
                  //  debugger;
                  if(loginresponse.status!=undefined && loginresponse.status.customErrorCode!= undefined){
                    this.errorStatus = loginresponse;
                    sessionStorage.setItem("state",JSON.stringify(this.errorStatus));

                    switch(parseInt(loginresponse.status.customErrorCode)){
                      case 2:
                        this._appstatus=APPCONSTANTS.APP_LOGIN_MULTIPLE_ROLES;
                        resolve(true);
                        break;
                      case 4:
                        this._appstatus=APPCONSTANTS.APP_LOGIN_ORG_MISSING;
                        resolve(true);
                        break;
                      case 5:
                        this._appstatus=APPCONSTANTS.APP_LOGIN_ROLES_MISSING;
                        resolve(true);
                        break;
                      case 6:
                        this._appstatus=APPCONSTANTS.APP_LOGIN_USER_INACTIVE;
                        resolve(true);
                        break;
                      default:
                        this._appstatus=APPCONSTANTS.APP_INITIALIZATION_ERROR;
                        resolve(true);
                        break;
                    }
                  } else {
                    this.errorStatus = loginresponse;
                    this._appstatus=APPCONSTANTS.APP_INITIALIZATION_ERROR;
                    resolve(true);
                  }
              }
          },
          (loginApiError)=>{
              console.error("Login API failed:"+loginApiError);
              this._appstatus=APPCONSTANTS.APP_INITIALIZATION_ERROR;
              resolve(true);
          });
        }catch(decodeError){
          console.error("SSO Token tamperred.");
          this._appstatus=APPCONSTANTS.APP_INITIALIZATION_ERROR;
          resolve(true);
        }
      });
    });
  }
  logout():void{
    sessionStorage.clear();
    this.keycloakService.logout(environment.sso_redirectUri);
  }
  getUserRole():string{
    if(Array.isArray(this.loginuser.infusionList) && this.loginuser.infusionList.length>0)
      return this.loginuser.selectedInfusion.getValue().roleTypeId;
    else
      return this.loginuser.defaultPrescriber.roleTypeId;
  }
}
