export class PAHistorywebrequest {
    fromDate?: string;
    toDate?: string;
    maxResults?: number;
    infsnPhmcyNPI?: string;
    providerNPI?: string;
    providerId?: string;
}



export class MemberPAHistorywebrequest {
    fromDate!: String;
    toDate!: String;
    maxDays!: number;
    isCustomDateSelected!: boolean;
    providerId!: String;
    instanceId!: String |undefined;
    carrierId!: String|undefined;
    groupId!: String|undefined;
    accountId!: String|undefined;
    providerNPI!: String;
    memberId!: String|undefined;
    memberDOB!: String|undefined;
    memberLastName!: String|undefined;
    memberFirstName!: String|undefined;
    resultsFromPAS!: boolean;
    infsnPhmcyNPI!: String;
    maxResults!: number;
}