import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PAGE_TYPE, ViewAllRouteState } from 'src/app/constants/routeconstants';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { MembersearchService } from 'src/app/services/membersearch/membersearch.service';

@Component({
  selector: 'app-view-all-submitted-claims',
  templateUrl: './view-all-submitted-claims.component.html',
  styleUrls: ['./view-all-submitted-claims.component.css']
})
export class ViewAllSubmittedClaimsComponent implements OnInit {
  public href!: string;
  displayText!:string;
  state!:ViewAllRouteState;
  memberId!:any;
  memberSearchResult:any;

  constructor(private router:Router,private memberSearchHelper: MembersearchService,private logger: LoggerService) {
   }

  ngOnInit(): void {
    try{
      const savedState:ViewAllRouteState = {pageType:PAGE_TYPE.SUBMITTEDCLAIMS};
      sessionStorage.setItem("state",JSON.stringify(savedState));
      sessionStorage.setItem("lastPageBeforeReload", this.router.url);
      this.state = history.state;

      if(this.memberSearchHelper.getSelectedMember()!=undefined){
        this.memberId=this.memberSearchHelper.getSelectedMember()?.memberId;
      }
    } catch(error){

    }
    this.href = this.router.url;
    if(this.href.includes('/searchMediactionClaims/'))
     this.displayText = "View all claims";
     else
     this.displayText = "View all submitted claims";
  }

  goto(routerUrl:string){
    this.logger.userAction("Goto home clicked.");
    this.router.navigateByUrl("/"+ routerUrl);
  }

}
