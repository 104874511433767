import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'searchMember',
  templateUrl: './search-member.component.html',
  styleUrls: ['./search-member.component.css']
})
export class SearchMemberComponent implements OnInit {
  pageType:string="SearchMedicationClaims";
  constructor(private router:Router, private logger: LoggerService,private titleService:Title) { 
    
  }

  ngOnInit(): void {
    this.titleService.setTitle("SMAP - Search for a member");
  }

  goto(routerUrl:string){    
    this.logger.userAction("Goto home clicked.");
    this.router.navigateByUrl("/"+ routerUrl);
  }

}
