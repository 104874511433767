import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes, RouterEvent } from '@angular/router';

import { EpasummaryComponent } from 'src/app/components/routecontent/epasummary/epasummary.component';
import { EpadetailsComponent } from 'src/app/components/epa/epadetails/epadetails.component';
import { QuestionsetComponent } from 'src/app/components/epa/questionset/questionset.component';
import { SelectquestionComponent } from 'src/app/components/epa/selectquestion/selectquestion.component';
import { NumericquestionComponent } from 'src/app/components/epa/numericquestion/numericquestion.component';
import { FreetextquestionComponent } from 'src/app/components/epa/freetextquestion/freetextquestion.component';
import { DatequestionComponent } from 'src/app/components/epa/datequestion/datequestion.component';
import { AttachmentquestionComponent } from 'src/app/components/epa/attachmentquestion/attachmentquestion.component';

import { SharedModule } from 'src/app/modules/shared/shared.module';
import { SelectPrescriberComponent } from 'src/app/components/select-prescriber/select-prescriber.component';

export const ePARoutes: Routes = [
  {
    path:'', 
    redirectTo:'/ePA/questions',
    pathMatch:'full'
  },
  {path:'member', redirectTo:'/ePA/questions', data:{breadcrumb:{label:'Check Coverage: Select Member', step:1}},pathMatch:'full'},
  {path:'prescriber', redirectTo:'/ePA/questions', data:{breadcrumb:{label:'Select Prescriber', step:2}}, pathMatch:'full'},
  {path:'drug', redirectTo:'/ePA/questions', data:{breadcrumb:{label:'Select Medication', step:3}}, pathMatch:'full'},
  //Allthough EPA module is not a part of coverage module, it will be loaded through coverage module from coverage summary page. 
  //But breadcrums still have to be represented as if it is the part of coverage module.
  //Hence above dummy routes are created for the sake of breadcrumb.
  {path:'questions', component: EpasummaryComponent, data:{breadcrumb:{label:'ePA Questions', step:4}}, pathMatch:'full'},
];

export const ePAResubmitRoutes: Routes = [
  {
    path:'', 
    redirectTo:'/ePA/ePAsubmit',
    pathMatch:'full'
  },
  {path:'member', redirectTo:'/ePA/ePAsubmit', data:{breadcrumb:{label:'Check Coverage: Select Member', step:1}},pathMatch:'full'},
  {path:'drug', redirectTo:'/ePA/ePAsubmit', data:{breadcrumb:{label:'Select Medication', step:2}}, pathMatch:'full'},
  {path:'provider', component: SelectPrescriberComponent, data:{breadcrumb:{label:'Verify Provider Info', step:3}}, pathMatch:'full'},
  //Allthough EPA module is not a part of coverage module, it will be loaded through coverage module from coverage summary page. 
  //But breadcrums still have to be represented as if it is the part of coverage module.
  //Hence above dummy routes are created for the sake of breadcrumb.
  {path:'questions', component: EpasummaryComponent, data:{breadcrumb:{label:'ePA Questions', step:4}}, pathMatch:'full'},
];

/*************Notes*********** */
// At any point if the same ePA module needs to be loaded from any other module then 
//same process has to be repeated with new Routes constant and different url's like above.
//After that, include this new routes constant in the app routing file like how it is handled for ePARoutes.
/*************Notes*********** */

@NgModule({
  declarations: [
    EpasummaryComponent,
    EpadetailsComponent,
    QuestionsetComponent,
    SelectquestionComponent,
    NumericquestionComponent,
    DatequestionComponent,
    FreetextquestionComponent,
    AttachmentquestionComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(ePARoutes),
    RouterModule.forChild(ePAResubmitRoutes)
  ]
})
export class EpaModule { }
