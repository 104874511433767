import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DatatableComponent} from './../../components/datatable/datatable.component';
import { TableheaderComponent} from './../../components/datatable/tableheader/tableheader.component';
import { TablerowComponent } from './../../components/datatable/tablerow/tablerow.component';
import { TablecellComponent } from './../../components/datatable/tablecell/tablecell.component';


@NgModule({
  declarations: [
    TablecellComponent, 
    TablerowComponent, 
    TableheaderComponent, 
    DatatableComponent
  ],
  imports: [
    CommonModule
  ],
  exports:[
    TablecellComponent, 
    TablerowComponent, 
    TableheaderComponent, 
    DatatableComponent
  ]
})
export class DatatableModule { }
