import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { EpasummaryComponent } from 'src/app/components/routecontent/epasummary/epasummary.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectPrescriberComponent } from 'src/app/components/select-prescriber/select-prescriber.component';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { SavedpahistoryComponent } from 'src/app/components/savedpahistory/savedpahistory.component';
import { ProgressTabsComponent } from 'src/app/components/progress-tabs/progress-tabs.component';
import { EpadetailsComponent } from 'src/app/components/epa/epadetails/epadetails.component';
import { QuestionsetComponent } from 'src/app/components/epa/questionset/questionset.component';
import { SelectquestionComponent } from 'src/app/components/epa/selectquestion/selectquestion.component';
import { NumericquestionComponent } from 'src/app/components/epa/numericquestion/numericquestion.component';
import { DatequestionComponent } from 'src/app/components/epa/datequestion/datequestion.component';
import { FreetextquestionComponent } from 'src/app/components/epa/freetextquestion/freetextquestion.component';
import { AttachmentquestionComponent } from 'src/app/components/epa/attachmentquestion/attachmentquestion.component';
import { ProviderDetailsComponent } from 'src/app/components/provider-details/provider-details.component';

export const ePAResubmitRoutes: Routes = [
  {
    path:'', 
    redirectTo:'/resubmitePA/provider',
    pathMatch:'full'
  },
  {path:'member', redirectTo:'/home', data:{breadcrumb:{label:'PA Initiation: Select Member', step:1}},pathMatch:'full'},
  {path:'drug', redirectTo:'/home', data:{breadcrumb:{label:'Select Medication', step:2}}, pathMatch:'full'},
  //Allthough EPA module is not a part of coverage module, it will be loaded through coverage module from coverage summary page. 
  //But breadcrums still have to be represented as if it is the part of coverage module.
  //Hence above dummy routes are created for the sake of breadcrumb.
  {path:'provider', component: SelectPrescriberComponent, data:{breadcrumb:{label:'Verify Provider', step:3}}, pathMatch:'full'},
  {path:'questions', component: EpasummaryComponent, data:{breadcrumb:{label:'ePA Questions', step:4}}, pathMatch:'full'},
];


@NgModule({
  declarations: [  
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(ePAResubmitRoutes)
  ]
 // exports: [ProgressTabsComponent]
})
export class ResubmitModule { }
