import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import { ProfileService } from 'src/app/services/profile/profile.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { Loginuser } from 'src/app/modals/login/loginviewmodal';
import {USER_ROLE_Label, USER_ROLE} from 'src/app/constants/appconstant';
import { ViewAllRouteState, PAGE_TYPE } from 'src/app/constants/routeconstants';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-usermenu',
  templateUrl: './usermenu.component.html',
  styleUrls: ['./usermenu.component.css']
})
export class UsermenuComponent implements OnInit {

  
  private _blnMenuOpened : boolean=false;
  public get blnMenuOpened() : boolean {
    return this._blnMenuOpened;
  }
  public set blnMenuOpened(menuState : boolean) {
    this._blnMenuOpened = menuState;
    if(menuState){
      this.logger.userAction("Global menu opened.")
    }
  }
  
  loginUser:Loginuser;
  blnDrugFeeTable:boolean;
  
  @ViewChild("userdetailmodal")
  userDetailModalElmtRef?:ElementRef<HTMLElement>;

  
  constructor(private profileService:ProfileService, 
    private modalService:NgbModal,
    private logger:LoggerService,
    protected router:Router,
    private titleService:Title) { 
    this.blnMenuOpened=false;
    this.loginUser = profileService.loginuser;    
    this.blnDrugFeeTable=false;
  }
  ngOnInit(): void {
    this.profileService.loginuser.selectedInfusion.subscribe((value:any)=>{
      if(this.loginUser.selectedInfusion.getValue()!=undefined && 
        this.loginUser.selectedInfusion.getValue().roleTypeId == USER_ROLE.SMAPSUPERADMIN.toString()){
        this.blnDrugFeeTable=true;
      } else {
        this.blnDrugFeeTable=false;
      }
    });
  }

  @HostListener('document:keydown', ['$event']) 
  onKeydownHandler(event: KeyboardEvent) {
    if (this.blnMenuOpened &&  event.keyCode === 27) {
        this.blnMenuOpened=false;
    }
  }

  openUserDetails():void{
    this.blnMenuOpened=false;
    this.logger.userAction("Login user profile modal is opened from global menu.");
    this.modalService.open(this.userDetailModalElmtRef,{modalDialogClass:'userDetailModal', size:'lg'});
  }

  getRoleName(roleTypeId?:string):string{
    if(roleTypeId==undefined){
      return "";
    }
    const rolename:string|undefined=USER_ROLE_Label.get(parseInt(roleTypeId));
    if(rolename!=undefined){
      return rolename;
      
    }
    return "";
  }

  logout():void{
    this.profileService.logout();
  }

  gotoSavedClaims(){
    this.logger.userAction("Going to view Saved Claims.");
    const savedState:ViewAllRouteState = {pageType: PAGE_TYPE.SAVEDCLAIMS};
    this.router.navigateByUrl("/smapclaims", {state: savedState});
  }

}
