import { APP_INITIALIZER, NgModule} from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';


import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import {JwtModule} from '@auth0/angular-jwt';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {environment} from 'src/environments/environment';
import { initializeKeycloak, initializeProfile } from 'src/app/utility/keycloak-initializer';
import { AppRoutingModule } from 'src/app/app-routing.module';
import {HomeModule} from 'src/app/modules/home/home.module';
import { CheckcoverageModule } from 'src/app/modules/checkcoverage/checkcoverage.module';
import { RecentsearchModule } from 'src/app/modules/recentsearch/recentsearch.module';
import { FavouritesModule } from 'src/app/modules/favourites/favourites.module';
import { EpaModule } from 'src/app/modules/epa/epa.module';

import { AppComponent } from 'src/app/app.component';
import { LayoutComponent } from 'src/app/components/layout/layout.component';
import { InfusionlistComponent } from 'src/app/components/infusionlist/infusionlist.component';
import { PrivacyComponent } from 'src/app/components/privacy/privacy.component';
import { TermsofuseComponent } from 'src/app/components/termsofuse/termsofuse.component';
import { ContactusComponent } from 'src/app/components/contactus/contactus.component';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ErrorComponent } from 'src/app/components/error/error.component';
import { UsermenuComponent } from 'src/app/components/usermenu/usermenu.component';
import  { BreadcrumbComponent } from 'src/app/components/breadcrumb/breadcrumb.component';
import { SharedModule } from './modules/shared/shared.module';
import { PaDetailsComponent } from './components/pa-details/pa-details.component';
import { ResubmitModule } from './modules/resubmit/resubmit.module';
import { ProviderDetailsComponent } from './components/provider-details/provider-details.component';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    InfusionlistComponent,
    PrivacyComponent,
    TermsofuseComponent,
    ContactusComponent,
    ErrorComponent,
    UsermenuComponent,
    BreadcrumbComponent,
    PaDetailsComponent,
    ProviderDetailsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    AppRoutingModule,
    KeycloakAngularModule,
    HomeModule,
    CheckcoverageModule,
    RecentsearchModule,
    FavouritesModule,
    EpaModule,
    ResubmitModule,
    BrowserAnimationsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter:  () => localStorage.getItem('access_token')
      }
    })
  ],
  providers: [DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService,ProfileService],
    },
    {
      provide: APP_BASE_HREF, 
      useValue: environment.sso_contextpath
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
