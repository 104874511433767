import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HistoryModule } from 'src/app/modules/history/history.module';
import {HomeComponent} from 'src/app/components/routecontent/home/home.component';

@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    HistoryModule
  ]
})
export class HomeModule { }
