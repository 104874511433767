import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { SubmittedpahistoryComponent } from 'src/app/components/submittedpahistory/submittedpahistory.component';
import { SavedpahistoryComponent } from 'src/app/components/savedpahistory/savedpahistory.component';
import { ViewaallComponent } from 'src/app/components/routecontent/viewaall/viewaall.component';

import { SavedclaimhistoryComponent } from 'src/app/components/savedclaimhistory/savedclaimhistory.component';
import { SmapclaimsComponent } from 'src/app/components/routecontent/smapclaims/smapclaims.component';

import { SharedModule} from 'src/app/modules/shared/shared.module';
import { ViewAllSubmittedClaimsComponent } from 'src/app/components/view-all-submitted-claims/view-all-submitted-claims.component';
import { SearchMedicationClaimsComponent } from 'src/app/components/search-medication-claims/search-medication-claims.component';
import { CheckcoverageModule } from '../checkcoverage/checkcoverage.module';
import { PaidClaimsComponent } from 'src/app/components/paid-claims/paid-claims.component';
import { MemberDetailComponent } from 'src/app/components/member-detail/member-detail.component';
import { MemberSubmittedPAHistoryComponent } from 'src/app/member-submitted-pahistory/member-submitted-pahistory.component';
import { RouterModule, Routes } from '@angular/router';
import { SearchMemberComponent } from './search-member/search-member.component';

export const searchMediactionClaimsRoutes: Routes = [
  {
    path:'', 
    redirectTo:'/searchMediactionClaims/searchMember',
    pathMatch:'full'
  },
  {path:'searchMember', component: SearchMemberComponent, data:{breadcrumb:{label:'Member Search', step:1}},pathMatch:'full'},
  {path: 'memberDetail', component:MemberDetailComponent, data:{breadcrumb:{label:'Member Detail', step:2}}, pathMatch:'full'},
  {path:'AllSubmittedClaims', component:ViewAllSubmittedClaimsComponent, data:{breadcrumb:{label:'View All', step:3}}, pathMatch:'full'},
  {path:'viewall', component:ViewaallComponent, data:{breadcrumb:{label:'View All', step:3}}, pathMatch:'full'}
];

@NgModule({
  declarations: [ 
    SubmittedpahistoryComponent,
    SavedpahistoryComponent,
    ViewaallComponent,
    SavedclaimhistoryComponent,
    SmapclaimsComponent,
    ViewAllSubmittedClaimsComponent,
    SearchMedicationClaimsComponent,
    PaidClaimsComponent,
    MemberDetailComponent,
    MemberSubmittedPAHistoryComponent,
    SearchMemberComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    CheckcoverageModule,
    RouterModule.forChild(searchMediactionClaimsRoutes)
  ],
  exports:[
    SubmittedpahistoryComponent, 
    SavedpahistoryComponent,
    SavedclaimhistoryComponent,
    MemberSubmittedPAHistoryComponent
  ]
})
export class HistoryModule { }
