<!-- <div [innerHtml]="status"></div> -->

<div class="notauthorized">
    <nav class="navbar-default navbar-fixed-top rxlink_navbar">
		<div class="container-fluid bg-white">
			<div class="row">
				<div class="col-xs-12 col-sm-12 col-md-12 text-center nav-bar-title" style="padding-bottom:20px;">
					<div class="img center">
                        <img id="errorpageLink.PNG" class="img" style="padding-top: 10px;"
                        src="./../../../assets/images/errorpageLink.png" aria-hidden="true" focusable="false">
					</div>
				</div>
			</div>
        </div>
    </nav>

    <div class="container-fluid" style="position: relative;top:165px;"> 
        <div class="row" style="text-align: center;" *ngIf=  "'2,4,5,6,7'.indexOf(this.errorStatus.status?.customErrorCode)==-1 && this.errorStatus!='NOT APPROVED'">
            <div class="col-sm-6" style="text-align: right; padding-right: 25px;">
                <img class="img" src="./../../../assets/images/errorpageimage.png" style="position: relative;top:0px;width: 320px;height: 320px;" aria-hidden="true" focusable="false">
            </div>
            <div id="genericerror" class="col-sm-6" style="text-align: left;">
                <h1 id='header_txt'>We are experiencing <br/>
                technical difficulties</h1>
                
                <p class="paragraph" style="padding-top: 30px;padding-bottom: 20px;">
                    <span id='info_txt'> Please refresh the page in case it's a temporary<br/>
                    glitch, try again later or call 1-866-842-3278 if you <br/>
                    need immediate assistance. 
                    </span>
                </p>
                <label href="" id="tryagain" class="btn btn-primary primary" (click)="tryAgain()"style="width: 125px;height: 44px; padding: 10px;font-family:'Frutiger-Bold',Helvetica, Arial, sans-serif;border-radius:2px;">Try again</label>

            </div>
        </div>

        <div class="row" style="text-align: center;" *ngIf="this.profileService.errorStatus.status?.customErrorCode=='6' || this.profileService.errorStatus.status?.customErrorCode=='7'">
            <div class="col-sm-6" style="text-align: right; padding-right: 25px;">
                <img class="img" src="./../../../assets/images/errorpageimage.png" style="position: relative;top:0px;width: 320px;height: 320px;" aria-hidden="true" focusable="false">
            </div>
            <div id="genericerror" class="col-sm-6" style="text-align: left;">
                <h1 id='header_txt'>User is inactive</h1>
                
                <p class="paragraph" style="padding-top: 30px;padding-bottom: 20px;">
                    <span id='info_txt'> Please contact super admin(s) <br/> from respective infusion facility to change the status.</span>
                </p>
            </div>
        </div>

        <div class="row" style="text-align: center;" *ngIf="this.errorStatus.status?.customErrorCode=='4'">
            <div class="col-sm-6" style="text-align: right; padding-right: 25px;">
                <img class="img" src="./../../../assets/images/errorpageimage.png" style="position: relative;top:0px;width: 320px;height: 320px;" aria-hidden="true" focusable="false">
            </div>
            <div id="genericerror" class="col-sm-6" style="text-align: left;">
                <h1 id='header_txt'>User is not setup with<br/> an infusion facility location</h1>
                
                <p class="paragraph" style="padding-top: 30px;padding-bottom: 20px;">
                    <span id='info_txt'>Please contact super admin(s) <br/> from respective infusion facility for setup.</span>
                </p>
            </div>
        </div>

        <div class="row" style="text-align: center;" *ngIf="this.errorStatus.status?.customErrorCode=='5'">
            <div class="col-sm-6" style="text-align: right; padding-right: 25px;">
                <img class="img" src="./../../../assets/images/errorpageimage.png" style="position: relative;top:0px;width: 320px;height: 320px;" aria-hidden="true" focusable="false">
            </div>
            <div id="genericerror" class="col-sm-6" style="text-align: left;">
                <h1 id='header_txt'>No role setup detected for infusion location(s) - <br/>
                    <span *ngFor="let infusion of this.errorStatus.infusionList;let last = last" >{{infusion.pharmacyName}}
                    <span *ngIf="!last">,</span>
                    <span *ngIf="last">.</span>
                </span></h1>
                
                <p class="paragraph" style="padding-top: 30px;padding-bottom: 20px;">
                    <span id='info_txt'>Please contact super admin(s) <br/> from respective infusion facility listed. <br/> Only 1 role for a given infusion facility location can be assigned. </span>
                </p>
            </div>
        </div>

        <div class="row" style="text-align: center;" *ngIf="this.errorStatus.status?.customErrorCode=='2'">
            <div class="col-sm-6" style="text-align: right; padding-right: 25px;">
                <img class="img" src="./../../../assets/images/errorpageimage.png" style="position: relative;top:0px;width: 320px;height: 320px;" aria-hidden="true" focusable="false">
            </div>
            <div id="genericerror" class="col-sm-6" style="text-align: left;">
                <h1 id='header_txt'>Multiple roles setup detected for infusion location(s) -<br/>
                    <span *ngFor="let infusion of this.errorStatus.infusionList;let last = last" >{{infusion.pharmacyName}}
                        <span *ngIf="last">.</span>
                        <span *ngIf="!last">,</span>
                    </span></h1>
                
                <p class="paragraph" style="padding-top: 30px;padding-bottom: 20px;">
                    <span id='info_txt'>Please contact super admin(s) from respective infusion facility listed.<br/> Only 1 role for a given infusion facility location can be assigned.</span>
                </p>
            </div>
        </div>



        <div class="row" style="text-align: center;" *ngIf="this.errorStatus=='NOT APPROVED'">
            <div class="col-sm-6" style="text-align: right; padding-right: 25px;">
                <img class="img" src="./../../../assets/images/errorpageimage.png" style="position: relative;top:0px;width: 320px;height: 320px;" aria-hidden="true" focusable="false">
            </div>
            <div id="genericerror" class="col-sm-6" style="text-align: left;">
                <h1 id='header_txt'>User Deactivated due to inactivity for more than 90 days, Please follow below steps to reactivate the user:</h1>
                

                <p class="paragraph" style="padding-top: 30px;padding-bottom: 20px;"></p>
                   
                      <p> 1.	Go to service now (https://optum.service-now.com/) </p> 
                      <p> 2.	Raise an incident of Priority-5 to ‘precheckmyscript-group’ assignment group.</p>
                      <p> 3.	Type: Service Request, Category: Needs Service performed </p>
                      <p> 4.	Add short description as “Re-activate SMAP user : "OHID”</p>
                      <p> 5.	Add following details in the Description: Email, OHID/username, first name and last name</p>
                      <p>  6.	Save the incident. </p>
                   
                
            </div>
        </div>

    </div>
</div>
