import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadNextQuestionEvent, Question, QuestionsetViewmodal } from 'src/app/modals/epa/questionsetviewmodal';
import { SavedPAAttachment } from 'src/app/modals/epa/priorauthresponse';
import { APPCONSTANTS } from 'src/app/constants/appconstant';

import { EpaprocessorService } from 'src/app/services/epa/epaprocessor.service';
import { LoggerService } from 'src/app/services/logger/logger.service';

@Component({
  selector: 'attachmentquestion',
  templateUrl: './attachmentquestion.component.html',
  styleUrls: ['./attachmentquestion.component.css']
})
export class AttachmentquestionComponent implements OnInit {
  
  private paRefId!:string;

  constructor(private epaServices: EpaprocessorService,
    private modalService:NgbModal, private logger: LoggerService) {
    this.errorMessage="";
  }

  questionsetviewmodal!:QuestionsetViewmodal;
  paAttachment:SavedPAAttachment|undefined;

  maxFileSize:number = APPCONSTANTS.ATTACHMENT_SIZE;
  fileTypesAllowed:string = APPCONSTANTS.ATTACHMENT_TYPES;

  @Output()
  nextQuestion:EventEmitter<LoadNextQuestionEvent> = new EventEmitter<LoadNextQuestionEvent>();

  @Input()
  question!:Question;

  progress!:number;
  errorMessage!:string;  
  currentFile : File | undefined;

  @ViewChild("uploaderelement")
  uploaderElement?: ElementRef<HTMLElement>;

  @ViewChild("FileTypeError")
  fileTypeErrorElmRef?: ElementRef<HTMLElement>;

  @ViewChild("FileSizeExceeded")
  fileSizeExceededElmRef?: ElementRef<HTMLElement>;

  @ViewChild("UploadProgress")
  uploadProgressElmtRef?: ElementRef<HTMLElement>;

  @ViewChild("UploadError")
  uploadErrorElmtRef?:ElementRef<HTMLElement>;

  uploadFile(event:any):void{
    this.deleteUploadedFile();
    this.logger.userAction("Uploading Attachments in EPA");
    const selectedFiles:FileList = event.target.files;
    if(selectedFiles!=undefined && selectedFiles.item!=undefined && selectedFiles.item(0)!=undefined){
      const file:File|null= selectedFiles.item(0);
      if(file!=null && this.isFileValid(file) 
      && this.uploadProgressElmtRef!=undefined
      && this.uploadErrorElmtRef!=undefined){
        this.currentFile = file;
        this.transferFile();
      }
    }
    //reset file upload element after capturing the file bytes.
    event.target.value="";
  }

  browseAnotherFile():void{
    if(this.uploaderElement!=undefined){
      this.uploaderElement.nativeElement.click();
    }
  }

  getFileSize(file?:File):string{
    if(file!=undefined)
      return (file.size/1048576).toFixed(2);
    else
    {
      if(this.paAttachment!=undefined)
        return (this.paAttachment.fileSize/1048576).toFixed(2);
      else
        return "0";
    } 
  }

  deleteUploadedFile():void{
    this.logger.log("Deleting Uploading Attachments");
    this.currentFile=undefined;
    this.progress=0;
    this.errorMessage="";
    this.paAttachment=undefined;
    this.updateQuestionSetViewModalFromSession(false);
    if(this.questionsetviewmodal!=undefined){
      this.questionsetviewmodal.paAttachment.isDeleted=true;
      this.epaServices.storeQuestionSetViewModal(this.questionsetviewmodal);
    }
  }

  ngOnInit(): void {
    if(this.question!=undefined){
      this.updateQuestionSetViewModalFromSession();
      if(this.questionsetviewmodal!=undefined){
        this.questionsetviewmodal.paAttachment.isDeleted=(this.paAttachment!=undefined)?false:true;
        this.epaServices.storeQuestionSetViewModal(this.questionsetviewmodal);
      }
      //Always have to submit the attachment question as soon as it is loaded so that it will be added to the watch list of auto save operation to considers current attachment status.
      this.nextQuestion.emit({blnOnInit:true,currentQuestionID:this.question.questionID});
    }
  }

  private updateQuestionSetViewModalFromSession(blnReloadAttachmentDetails:boolean=true):void{
    const questionsetviewmodalsession:QuestionsetViewmodal|null = this.epaServices.getQuestionSetViewModal();
    if(questionsetviewmodalsession!=null && questionsetviewmodalsession.questions.length>0)
    {
      this.questionsetviewmodal = questionsetviewmodalsession;
      this.paRefId = this.questionsetviewmodal.paReferenceID.paRefID.toString();
      if(blnReloadAttachmentDetails && this.questionsetviewmodal.paAttachment!=undefined 
        && this.questionsetviewmodal.paAttachment.fileName!=undefined
        && this.questionsetviewmodal.paAttachment.fileName.length>0
        && this.questionsetviewmodal.paAttachment.fileSize!=undefined
        && this.questionsetviewmodal.paAttachment.fileSize.toString().length>0){
          this.paAttachment = new SavedPAAttachment();
          this.paAttachment.fileName = this.questionsetviewmodal.paAttachment.fileName;
          this.paAttachment.fileType = this.questionsetviewmodal.paAttachment.fileType;
          this.paAttachment.fileSize = this.questionsetviewmodal.paAttachment.fileSize;
        }
    }
  }

  private isFileValid(file:File):boolean{
    const fileType:string = file.type;
    const fileSize:string = this.getFileSize(file);
    if(this.fileTypesAllowed.indexOf(fileType)==-1){
      this.modalService.open(this.fileTypeErrorElmRef,{size:'md'});
      return false;
    }
    else{
      if(this.maxFileSize < parseFloat(fileSize)){
        this.modalService.open(this.fileSizeExceededElmRef,{size:'md'});
        return false;
      }
    }
    return true;
  }

  private transferFile():void{
      if(this.currentFile!=undefined) {
        this.progress=1;
        this.modalService.open(this.uploadProgressElmtRef,{size:"md"});
        this.epaServices.uploadAttachmentForPA(this.paRefId,this.currentFile).subscribe({
          next : (event:any) =>{
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round(100 * event.loaded / event.total);
            } else if (event instanceof HttpResponse) {
              if(event.status.toString()==APPCONSTANTS.API_RESPONSE_SUCCESS_CODE
              && event.body!=undefined && event.body.statusCode!=undefined 
              && event.body.statusCode.toLowerCase()==APPCONSTANTS.API_RESPONSE_SUCCESS_TXT){
                this.progress=0;
                this.errorMessage = event.body.statusDesc;
                this.modalService.dismissAll(); 
                if(this.currentFile!=undefined){
                  this.paAttachment = new SavedPAAttachment();
                  //file type is part of file name itself when uploading a file.
                  this.paAttachment.fileName = this.currentFile.name;
                  this.paAttachment.fileType="";
                  this.paAttachment.fileSize = this.currentFile.size;

                  this.questionsetviewmodal.paAttachment.fileName=this.currentFile.name;
                  this.questionsetviewmodal.paAttachment.fileSize=this.currentFile.size;
                  this.questionsetviewmodal.paAttachment.fileType=this.currentFile.type;
                  this.questionsetviewmodal.paAttachment.isDeleted=false;
                  this.epaServices.storeQuestionSetViewModal(this.questionsetviewmodal);
                }
              } else {
                this.deleteUploadedFile();
                this.modalService.dismissAll();
                if(event.status==-1){
                  this.errorMessage = event.statusText;
                } else {
                  this.errorMessage = "An error occurred while trying to upload the file.<br/>Please try again.";
                }
                this.modalService.open(this.uploadErrorElmtRef,{size:'md'});
              }
            }
          },
          error : (err:any)=>{
            this.deleteUploadedFile();
            this.modalService.dismissAll();
            if (err.error && err.error.message) {
              this.errorMessage = err.error.message;
            } else {
              this.errorMessage = 'An error occurred while trying to upload the file.<br/>Please try again.';
            }
            this.modalService.open(this.uploadErrorElmtRef,{size:'md'});
          }
        });
      }
  }
}
