import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FavouritemembersComponent } from 'src/app/components/favouritemembers/favouritemembers.component';

import { SharedModule } from 'src/app/modules/shared/shared.module';

@NgModule({
  declarations: [
    FavouritemembersComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports:[
    FavouritemembersComponent
  ]
})
export class FavouritesModule { }
