<!-- Desciption Div -->
<div class="position-absolute col" style="height: 215px;  width: 100%; background-color:rgba(217, 246, 250, 1);">
</div>
<div class="row justify-content-center position-absolute mb-5" @explainerAnim>
    <div class="col-md-8 col-sm-8 col-xs-12 row timelinetitle mb-5">
        <h1 class="timeline h1">Medication Coverage Results</h1>
        <ul style="margin-left: 0em;">
            <li style="list-style: outside none none;">
                <span style="color: #636363;">Member:</span>
                <span class="label"> {{claimsummaryDetailsrequest.Name}} </span>
            </li>
        <br>
            <li style="list-style: outside none none;">
                <span style="color: #636363;">Contracted Network Provider:</span>
                <span class="label">{{this.profileService.loginuser.selectedInfusion.getValue().pharmacyName}}</span>
            </li>
        </ul>
    </div>

    <div class="mt-2 col-md-8 col-sm-8 col-xs-12 row timelinetitle shadow descriptionSection">
        <div class="row">
            <div class="col-md-7 col-sm-7 col-xs-12">
                <h2 class="h3" style="color: #002677;">Medication pricing</h2>
                <p *ngIf="this.claimResponseList.length>0 && this.claimResponseList[0].clmgroupId!=undefined">Transaction ID:
                   <label class="label"> {{this.claimResponseList[0].clmgroupId}} </label></p>
                

            </div>
            <div class="col-md-5 col-sm-5 col-xs-12"
                *ngIf="this.claimResponseList.length>0 && this.claimsummaryDetailsrequest.claimStsTypId<2">
                <button [disabled]="this.claimResponseList[0].trailadjudicationwebresponse.status.respCode!='200'" class="btn btn-primary float-end fontbold rounded-pill" (click)="validateSaveOperation()">Save trial details
                </button>
            </div>

            <div class="col-md-5 col-sm-5 col-xs-12"
                *ngIf="this.claimResponseList.length>0 && this.claimsummaryDetailsrequest.claimStsTypId==2">
                <button class="btn btn-secondary fontbold mt-1 col-md-5 col-sm-5 offset-sm-1 offset-md-1 col-xs-6 rounded-pill"
                    (click)="this.reexecuteClaim(this.dosValue,true)">Re-execute Trial
                </button>

                <button [disabled]="claimResponseList[0].trailadjudicationwebresponse.status.respCode!='200'" class="btn btn-primary float-end fontbold mt-1 col-5 offset-1 rounded-pill" (click)="submitClaims()">Submit
                    claim
                </button>

            </div>
            <div class="col-md-7 col-sm-6 col-xs-12 ">
                <div *ngIf="updatingDOS==0">
                    <label class="text-muted fs-6">Date of service:
                        <label
                            class="text-dark"><strong>{{claimsummaryDetailsrequest.DateofService}}</strong>&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;</label>
                    </label>
                    <!-- <button *ngIf="claimsummaryDetailsrequest.claimSubmitted==false" class="ml-3 btn invisiblebtn btn-link underline"
                        (click)="changeDOS()"><strong>Change</strong></button> -->
                    <button *ngIf="this.claimsummaryDetailsrequest.claimStsTypId<3" class="ml-3 btn underline btn-link"
                        (click)="changeDOS()"><strong>Change</strong></button>
                </div>

                <div class="row" *ngIf="updatingDOS==1">
                    <label class="text-muted col-md-3 col-sm-3 col-xs-6 col-6 mt-2 fs-6">Date of service:</label>
                    <div class="col-md-4 col-sm-4 col-xs-6 col-6 mt-2 row">
                        <datefield class="h-75" [validationMode]="dosValidationMode" [content]="dosValue"
                            (onUpdate)="onDobUpdate($event)" [emptyValid]="emptyDOS" [dateFieldName]="dateFieldName">
                        </datefield>
                         <!-- <div class="text-danger" *ngIf="this.dosResult==1 || this.dosResult==2 || this.dosResult==3">
                            <small>Invalid Date</small> 
                        </div> 
                        <div class="text-danger" *ngIf="this.dosResult==4 || this.dosResult==5"><small>Future date not
                                allowed</small></div> -->

                    </div>

                    <button class="ml-3 btn underline btn-link text-danger col-md-2 col-sm-2 col-xs-6 col-6"
                        (click)="cancelDOS()"><strong>Cancel</strong></button>
                    <button *ngIf="this.claimResponseList[0].clmgroupId==undefined"
                        class="ml-3 btn underline btn-link col-md-2 col-sm-2 col-xs-6 col-6"
                        (click)="upadateOrReexecute()"><strong>Update</strong></button>
                    <button *ngIf="this.claimResponseList[0].clmgroupId!=undefined"
                        class="ml-3 btn underline btn-link col-md-3 col-sm-3 col-xs-6 col-6"
                        (click)="upadateOrReexecute(true)"><strong>Re-execute</strong></button>
                </div>
            </div>
            <div class="col-md-5 col-sm-6 col-xs-12 text-md-end text-xs-start text-sm-end" *ngIf="(this.claimResponseList.length>0 && this.claimsummaryDetailsrequest.claimStsTypId==2) && (claimsummaryDetailsrequest.claimStsTypId<3)">
                <label class="mt-2 fs-6 text-end text-muted">Date of last trial:
                    <label
                        class="text-dark"><strong>{{this.claimsummaryDetailsrequest.dateOfLastTrail}}</strong></label>
                </label>
            </div>
            <div class="col-md-5 col-sm-6 col-xs-12 text-md-end text-xs-start text-sm-end"
                *ngIf="claimsummaryDetailsrequest.claimStsTypId==3 || claimsummaryDetailsrequest.claimStsTypId==6 || claimsummaryDetailsrequest.claimStsTypId==8 || claimsummaryDetailsrequest.claimStsTypId==4 || claimsummaryDetailsrequest.claimStsTypId==7">
                    <label class="fs-6 text-muted">Date of claim submission:
                        <label class="text-dark"><strong>{{claimsummaryDetailsrequest.dateOfLastTrail}}</strong></label>
                    </label>
            </div>
        </div>
    </div>

    <div *ngFor="let item of claimsummaryDetailsrequestList;index as i">
        <app-payble-claim-summary class="row justify-content-center nopadding" *ngIf="true" [claimsummaryDetailsrequest]="claimsummaryDetailsrequestList[i]"
            [claimresponse]="claimResponseList[i]" (removeevent)="removeMedication($event)"
            (reverseClaim)="reverseClaimsConfrmModel($event)" (updateevent)="loadClaimSummary()">
        </app-payble-claim-summary>
    </div>

    <!-- <app-prior-auth-claim-summary></app-prior-auth-claim-summary>
    <app-limit-exceeded-claim-summary></app-limit-exceeded-claim-summary> -->

    <app-member-summary class="row justify-content-center nopadding" [claimsummaryDetailsrequest]="claimsummaryDetailsrequest">
    </app-member-summary>

    <!-- <div class="col-8 offset-2 mt-5 mb-5" *ngIf="!this.claimsummaryDetailsrequest.claimSubmitted"> -->
    <div *ngIf="this.claimsummaryDetailsrequest.claimStsTypId<3" class="col-md-8 col-sm-8 col-xs-12 mt-5 mb-5">
        <button class="btn btn-secondary float-end rounded-pill" type="submit" role="button"
            (click)="goToSelectMember()">Search for another member</button>
        <a class="btn underline btn-link fontbold float-end" (click)="goToSelectMedication()" (keydown.enter)="goToSelectMedication()" tabindex="0" style="cursor: pointer;">Check another medication for this
            member</a>
    </div>

    <div class="col-md-8 col-sm-8 col-xs-12 mt-5 mb-5 pt-3">
        <div class="pt-3 pb-5 mt-5 mb-5 border-3 border-top">
            <p class="fw-light">Prices are estimates. Prices on the medication(s) listed may vary depending on the days
                of supply, directions for use or the manufacturers’ prices at this time and at the time of the
                transaction. Please note: Not all manufacturers for specified medications may be priced with this tool.
                Please refer to the member’s covered medications list (formulary) for more information.</p>
            <p class="fw-light">Medications that are listed as preferred alternatives may not be covered by the member’s
                benefits plan. Please refer to the member’s covered medications list (formulary) to determine coverage.
            </p>
        </div>
    </div>
</div>
<!--  -->


<!-- Confirmation Modal -->
<ng-template #confirmModal let-modal>
    <div class="p-3" aria-modal="true" role="dialog" aria-labelledby="confirmModal">
        <div class="row text-center"><i class="fa fa-ban" aria-hidden="true"></i></div>
        <div class="text-center mt-2">
            Are you sure you want to remove this medication?
        </div>
        <div class="row border-0 mt-4 p-3 ">
            <div class="col-7 position-absolute top-80 start-50 translate-middle">
                <button class="col-5 btn btn-secondary" (click)="dismissAll()">Cancel</button>
                <button class=" col-5 offset-1 btn btn-secondary" (click)="removeMedication()">Proceed</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #claimSummaryProgress let-modal>
    <div style="height:200px" aria-modal="true" role="dialog" aria-labelledby="claimSummaryProgress">
        <div class="modal-body mt-2">
            <div class="p-3">
                <div *ngIf="this.counter==10" class="row text-center"><i class="fa fa-ban  text-danger fs-1"
                        aria-hidden="true"></i></div>
                <div @slideDown *ngIf="this.counter==10" class="text-center mt-3 fs-6">
                    {{this.trailAdjuducicationError}}</div>
                <div *ngIf="this.counter==10" @slideDown class="modal-footer border-0 mt-5">
                    <button class="btn btn-secondary position-absolute top-80 start-50 translate-middle"
                        (click)="dismissModal()">ok</button>
                </div>
                <div *ngIf="this.counter==3" class="circle">
                    <div class="checkMark"></div>
                </div>
                <div *ngIf="this.counter<3" class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div @slideDown *ngIf="this.counter==3" class="text-center fs-6">Check coverage complete</div>

                <div @slideDown *ngIf="this.counter==2" class="text-center fs-6">Retrieving results...</div>

                <div @slideDown *ngIf="this.counter==1" class="text-center fs-6">Analyzing information…</div>

            </div>
        </div>

    </div>

</ng-template>

<!-- Confirmation Modal -->
<!-- <ng-template #drugConfirmModal let-modal>
    <div class="p-3">
        <div class="row text-center"><i class="fa fa-ban" aria-hidden="true"></i></div>
        <div class="text-center mt-2">
            Would you like to retain the current claim list ?
        </div>
        <div class="row border-0 mt-4 p-3 ">
            <div class="col-7 position-absolute top-80 start-50 translate-middle">
                <button class="col-5 btn btn-secondary" (click)="dismissAll()">Yes</button>
                <button class=" col-5 offset-1 btn btn-secondary"
                    (click)="dismissAll()">No</button>
            </div>
        </div>
    </div>
</ng-template> -->

<ng-template #overwriteConfirmModal let-modal>
    <div class="p-3" aria-modal="true" role="dialog" aria-labelledby="overwriteConfirmModal">
        <div class="row text-center"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></div>
        <div class="text-center mt-2">
            Another trial claim for the same member and drug exists.<br>
            Do you want to overwrite?
        </div>
        <div class="row border-0 mt-4 p-3 ">
            <div class="col-4 position-absolute top-80 start-50 translate-middle">
                <button class="col-5 btn btn btn-primary" (click)="dismissAll()">No</button>
                <button class="col-5 offset-1 btn btn btn-primary" (click)="saveTrailClaimDetails()">Yes</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #updateReexecuteConfirmModal let-modal>
    <div class="p-3" aria-modal="true" role="dialog" aria-labelledby="updateReexecuteConfirmModal">
        <div class="row text-center"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></div>
        <div class="text-center mt-2">
            All drugs in the transaction will process again.<br>Results may vary depending on if there were plan
            changes.
        </div>
        <div class="row border-0 mt-4 p-3 ">
            <div class="col-4 ">
                <button class="col-2 position-absolute top-80 start-50 translate-middle btn btn btn-primary"
                    (click)="changeDOS()">ok</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #saveClaimConfirmModal let-modal>
    <div class="p-3" aria-modal="true" role="dialog" aria-hidden="true" aria-labelledby="saveClaimConfirmModal">
        <div class="circle mt-3">
            <div class="checkMark"></div>
        </div>
        <div class="text-center mt-2">
            Trial claim details saved succesfully.
        </div>
        <div class="row border-0 mt-4 p-3 ">
            <button class="col-2 btn btn btn-primary position-absolute top-80 start-50 translate-middle"
                (click)="dismissAll()">ok</button>
        </div>
    </div>
</ng-template>

<ng-template #rexecuteClaimConfirmModal let-modal>
    <div class="p-3" aria-modal="true" role="dialog" aria-hidden="true" aria-labelledby="rexecuteClaimConfirmModal">
        <div class="circle mt-3">
            <div class="checkMark"></div>
        </div>
        <div class="text-center mt-2">
            Re-executed trial successfully.
        </div>
        <div class="row border-0 mt-4 p-3 ">
            <button class="col-2 btn btn btn-primary position-absolute top-80 start-50 translate-middle"
                (click)="dismissAll()">ok</button>
        </div>
    </div>
</ng-template>


<ng-template #submitClaimSuccessModal let-modal>
    <div class="p-3" aria-modal="true" role="dialog" aria-hidden="true" aria-labelledby="submitClaimSuccessModal">
        <div class="circle mt-3">
            <div class="checkMark"></div>
        </div>
        <div class="text-center mt-2">
            The claim has been submitted.
        </div>
        <div class="row border-0 mt-4 p-3 ">
            <button class="col-2 btn btn btn-primary position-absolute top-80 start-50 translate-middle"
                (click)="dismissAll()">ok</button>
        </div>
    </div>
</ng-template>

<ng-template #submitClaimErrorModal let-modal>
    <div class="content-card text-center" aria-modal="true" aria-hidden="true" role="dialog" aria-labelledby="submitClaimErrorModal">
        <i class="fa fa-ban"></i>
        <br /><br />
        <span>{{submitClaimErrorText}}</span>
        <br /><br />
        <button class="btn btn-primary" (click)="dismissAll()">Ok</button>
        <br /><br />
    </div>
</ng-template>

<ng-template #errorModal let-modal>
    <div class="content-card text-center" aria-modal="true" aria-hidden="true" role="dialog" aria-labelledby="errorModal">
        <i class="fa fa-ban"></i>
        <br /><br />
        <span>An error occurred. Please try again.</span>
        <br /><br />
        <button class="btn btn-primary" (click)="dismissAll()">Ok</button>
        <br /><br />
    </div>
</ng-template>

<ng-template #durSoftsUnAnsweredModal let-modal>
    <div class="content-card text-center" aria-modal="true" role="dialog" aria-hidden="true" aria-labelledby="durSoftsUnAnsweredModal">
        <i class="fa fa-ban"></i>
        <br /><br />
        <div style="margin:0px 10px;">A professional service code and result of service code must be entered to override the drug utilization review error before the trial claim can be executed.</div>
        <br /><br />
        <button class="btn btn-primary" (click)="dismissAll()">Ok</button>
        <br /><br />
    </div>
</ng-template>

<ng-template #saveErrorModal let-modal>
    <div class="content-card text-center" aria-modal="true" aria-hidden="true" role="dialog" aria-labelledby="saveErrorModal">
        <i class="fa fa-ban"></i>
        <br /><br />
        <span *ngIf="saveErrorText.length==0">An error occurred while saving. Please save or re-execute trial
            again.</span>
        <span *ngIf="saveErrorText.length>0" [innerHtml]="saveErrorText"></span>
        <br /><br />
        <button class="btn btn-primary" (click)="dismissAll()">Ok</button>
        <br /><br />
    </div>
</ng-template>

<ng-template #reverseClaimSuccessModal let-modal>
    <div class="p-3" aria-modal="true" role="dialog" aria-hidden="true" aria-labelledby="reverseClaimSuccessModal">
        <div class="circle mt-3">
            <div class="checkMark"></div>
        </div>
        <div class="text-center mt-2">
            The claim has been successfully reversed.
        </div>
        <div class="row border-0 mt-4 p-3 ">
            <button class="col-2 btn btn btn-primary position-absolute top-80 start-50 translate-middle"
                (click)="dismissAll()">ok</button>
        </div>
    </div>
</ng-template>


<ng-template #submitClaimNotAllowedModal let-modal>
    <div class="p-3" aria-modal="true" role="dialog" aria-labelledby="submitClaimNotAllowedModal">
        <div class="row text-center"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></div>
        <div class="text-center mt-2">
            All the drugs in the transaction must be payable before they can be submitted as real claims.  Consider
            adjusting the quantity or the days supply on the rejected claims, obtaining a prior authorization approval
            if required, or removing the rejected claims from the transaction.
        </div>
        <div class="row border-0 mt-4 p-3 ">
            <button class="col-2 btn btn btn-primary position-absolute top-80 start-50 translate-middle"
                (click)="dismissAll()">ok</button>
        </div>
    </div>
</ng-template>

<ng-template #submitClaimOnOldTrailErrolModal let-modal>
    <div class="modal-body text-center" aria-modal="true" aria-hidden="true" role="dialog" aria-labelledby="submitClaimOnOldTrailErrolModal">
        <i class="fa fa-exclamation-triangle"></i>
        <br /><br />
        <p class="large">
            A trial claim must executed before a real claim can be submitted.
        </p>
        <br /><br />
        <button class="btn btn-primary" (click)="modal.dismiss()">Ok</button>
    </div>
</ng-template>

<ng-template #reverseClaimRejected let-modal>
    <div class="p-3" aria-modal="true" role="dialog" aria-labelledby="reverseClaimRejected">
        <div class="row text-center"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></div>
        <div class="text-center mt-2">
            {{reversClaimRejectmessage}}
        </div>
        <div class="row border-0 mt-4 p-3 ">
            <button class="col-2 btn btn btn-primary position-absolute top-80 start-50 translate-middle"
                (click)="dismissAll()">ok</button>
        </div>
    </div>
</ng-template>

<ng-template #reverseClaimConfirmModal let-modal>
    <div class="p-3" aria-modal="true" role="dialog" aria-labelledby="reverseClaimConfirmModal">
        <div class="row text-center"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></div>
        <div class="text-center mt-2">
            Are you sure you want to reverse this claim? <br>
            This action can't be undone.
        </div>
        <div class="row border-0 mt-4 p-3 ">
            <div class="col-4 position-absolute top-80 start-50 translate-middle">
                <button class="col-5 btn btn btn-primary" (click)="dismissAll()">No</button>
                <button class="col-5 offset-1 btn btn btn-primary" (click)="reverseClaim()">Yes</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #reverseErrorModal let-modal>
    <div class="content-card text-center" aria-modal="true" aria-hidden="true" role="dialog" aria-labelledby="reverseErrorModal">
        <i class="fa fa-ban"></i>
        <br /><br />
        <span *ngIf="this.reverseErrorText.length==0">An error occurred while reversing. Please reverse claim again.</span>
        <span *ngIf="this.reverseErrorText.length>0" [innerHtml]="reverseErrorText"></span>
        <br /><br />
        <button class="btn btn-primary" (click)="dismissAll()">Ok</button>
        <br /><br />
    </div>
</ng-template>

<ng-template #memberTermedConfirmModal let-modal>
    <div class="p-3" aria-modal="true" role="dialog" aria-labelledby="memberTermedConfirmModal" >
        <div class="row text-center"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></div>
        <div class="text-center mt-2">
            The member’s coverage has termed.  Do you want to submit a claim anyway? 
        </div>
        <div class="row border-0 mt-4 p-3 ">
            <div class="col-5 position-absolute top-80 start-50 translate-middle">
                <button class="col-5 btn btn btn-primary" (click)="dismissAll()">Cancel</button>
                <button class="col-5 offset-1 btn btn btn-primary" (click)="this.submitClaims(true)">Proceed</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #memberTermedExpiredModal let-modal>
    <div class="p-3" aria-modal="true" role="dialog" aria-labelledby="memberTermedExpiredModal">
    <div class="row text-center"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></div>
        <div class="text-center mt-2">
            The member's coverage has termed more than 30 days ago. A real claim can't be submitted after 30 days of
            expired coverage.
        </div>
    
    <div class="row border-0 mt-4 p-3 ">
        <div class="row border-0 mt-4 p-3 ">
            <button class="col-2 btn btn btn-primary position-absolute top-80 start-50 translate-middle"
                (click)="dismissAll()">ok</button>
        </div>
    </div>
    </div>
</ng-template>


<!-- Loading.... -->
<ng-template #LoadingContent let-modal>
    <div class="modal-body" aria-modal="true" role="dialog" aria-labelledby="LoadingContent" aria-hidden="true">
        <h2 class="text-center text-capitalize" tabindex="-1">{{modalContent}}</h2>
    </div>
</ng-template>