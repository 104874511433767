import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ProfileService } from 'src/app/services/profile/profile.service';
import { Infusion } from 'src/app/modals/login/loginwebresponse';
import { APPCONSTANTS } from 'src/app/constants/appconstant';
import { LoggerService } from 'src/app/services/logger/logger.service';

@Component({
  selector: 'app-infusionlist',
  templateUrl: './infusionlist.component.html',
  styleUrls: ['./infusionlist.component.css']
})
export class InfusionlistComponent implements OnInit, AfterViewInit {
  // keycloakService: any;
  username?: string;
  ohid?: string;
  blnswitchenabled: boolean;


  selectedInfusionOrgId?: string;
  private storedInfusionOrgId?: string;
  infusionList: Infusion[];
  infusionName:string='';

  @ViewChild("infusionselectionmodal")
  infusionSelectionModalElmtRef?: ElementRef<HTMLElement>;

  @ViewChild("infusionchangeconfirmation")
  infusionChangeConfirmationModalElmtRef?: ElementRef<HTMLElement>;

  constructor(public profileService: ProfileService, private modalService: NgbModal, router: Router,private logger: LoggerService) {
    this.username = profileService.loginuser.prescriber.firstName + " " + profileService.loginuser.prescriber.lastName;
    this.ohid = profileService.loginuser.ohid;
    this.infusionList = this.profileService.loginuser.infusionList;
    this.blnswitchenabled = true;
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.blnswitchenabled = event.urlAfterRedirects.indexOf("home") != -1 ? true : false;
      }
    });
  }
  
  ngAfterViewInit():void{
    if(this.profileService.loginuser.infusionList.length>1){
      if(sessionStorage.getItem('SelectedInfsnId')==undefined){
        this.profileService.loginuser.selectedInfusion.next(this.infusionList[0]);
        this.modalService.open(this.infusionSelectionModalElmtRef,{modalDialogClass:'infusionSelectionModal', size:'sm'});
      }else{

        this.profileService.loginuser.selectedInfusion.next(JSON.parse(""+sessionStorage.getItem('SelectedInfsnId')));
      }
    }else{
      if(sessionStorage.getItem('SelectedInfsnId')==undefined){
        this.logger.login();
        sessionStorage.setItem('SelectedInfsnId',JSON.stringify(this.profileService.loginuser.selectedInfusion?.getValue()));
      }
    } 
    setTimeout(()=>{
      this.selectedInfusionOrgId = this.profileService.loginuser.selectedInfusion?.getValue().organizationRelationId;
      this.storedInfusionOrgId=this.selectedInfusionOrgId;
    },20);
  }

  setSelectedInfusionFacility():void{
    this.logger.userAction("Setting the selected Infusion Facility.");  
    let selectedInfusion = this.infusionList.filter(item=>{return item.organizationRelationId==this.selectedInfusionOrgId;})[0];
    sessionStorage.setItem('SelectedInfsnId',JSON.stringify(selectedInfusion));

    this.profileService.loadOrganization(this.infusionList,selectedInfusion).then(response=>{
      if(response == APPCONSTANTS.APP_INITIALIZED){
          this.storedInfusionOrgId=this.selectedInfusionOrgId;
          this.profileService.appstatus=response;
         // console.log("Test Infusion:"+JSON.stringify(this.profileService.loginuser.selectedInfusion.getValue().pharmacyName));
          // sessionStorage.setItem('InfsnId',JSON.stringify(this.profileService.loginuser.selectedInfusion.getValue().infsnId));
          this.modalService.dismissAll();
      } else {
        this.profileService.appstatus=response;
      }
    },error=>{
      //console.log(error);
      this.selectedInfusionOrgId = this.storedInfusionOrgId;
    });
  }
  infusionChangeConfirmation(event: any): void {
    this.infusionName=this.infusionList.filter(item=>{return item.organizationRelationId==this.selectedInfusionOrgId;})[0].pharmacyName;
    this.modalService.open(this.infusionChangeConfirmationModalElmtRef, { modalDialogClass: 'infusionSelectionModal', size: 'sm' });
  }
  discardInfusionChange(): void {
    this.logger.userAction("Discard Infusion Facility Change.");
    this.selectedInfusionOrgId = this.storedInfusionOrgId;
    this.modalService.dismissAll();
  }
  ngOnInit(): void {
  }
}
