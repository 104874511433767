import { Component, OnInit } from '@angular/core';
import { Router, Routes, Route, ChildActivationEnd, NavigationStart, Event, UrlSegment} from '@angular/router';

import { Breadcrumbitem } from 'src/app/modals/breadcrumb/breadcrumbitem';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  items:Breadcrumbitem[]=[];

  constructor(private router:Router) {
    router.events.subscribe((event: Event)=>{
      if(event instanceof NavigationStart){
        this.items=[];
      }
      if(event instanceof ChildActivationEnd){
        if(event!=null && event.snapshot!=null && event.snapshot.firstChild!=null){
          const itemData: any = event.snapshot.firstChild.data;
          if(itemData!=undefined && itemData.breadcrumb!=undefined && itemData.breadcrumb.skip!=true){


            const breadcrumbitem:Breadcrumbitem = new Breadcrumbitem();
            breadcrumbitem.label = itemData.breadcrumb.label;
            breadcrumbitem.skip = itemData.breadcrumb.skip;
            breadcrumbitem.step = itemData.breadcrumb.step;
            breadcrumbitem.url = "/"+event.snapshot.firstChild.routeConfig?.path;
  
            const urls: UrlSegment[]= event.snapshot.url;
  
            
  
            if(event.snapshot.routeConfig!=null && event.snapshot.routeConfig 
              && event.snapshot.routeConfig.children!=undefined && event.snapshot.routeConfig.children.length>0){
              if(urls.length>0 && urls[0].path == event.snapshot.routeConfig.path){
                let filteredRoutes:Routes = event.snapshot.routeConfig.children.filter((route:Route)=>{
                  const routeData:any = route.data;
                  return routeData!=undefined && routeData.breadcrumb!=undefined 
                  && !routeData.breadcrumb.skip
                  && routeData.breadcrumb.step!=undefined;
                });
  
                if(filteredRoutes.length>0){
                  filteredRoutes=filteredRoutes.sort((route1:Route, route2:Route)=>{
                    const routeData1:any = route1.data;
                    const routeData2:any = route2.data;
                    if(routeData1!=undefined && routeData2!=undefined){
                      return routeData1.breadcrumb.step<routeData2.breadcrumb.step?-1:1;
                    } else{
                      return 0;
                    }
                  });
  
                  for(let index=0;index<filteredRoutes.length;index++){
                    const routeData:any = filteredRoutes[index].data;
                    const breadcrumbstep:Breadcrumbitem = new Breadcrumbitem();
                    breadcrumbstep.label = routeData.breadcrumb.label;
                    breadcrumbstep.skip = routeData.breadcrumb.skip;
                    breadcrumbstep.step = routeData.breadcrumb.step;
                    breadcrumbstep.url = "/"+event.snapshot.routeConfig.path+"/"+filteredRoutes[index].path;
                    this.items.push(breadcrumbstep);
                    if(routeData.breadcrumb.step==breadcrumbitem.step){
                      break;
                    }
                  }
                } else {
                  this.items.unshift(breadcrumbitem);
                }
              } else {
                this.items.unshift(breadcrumbitem);
              }
            } else {
              this.items.unshift(breadcrumbitem);
            }
          }
        }
      }
    });
  }

  ngOnInit(): void {
  }

}
