import {SavedPAAttachment, PAReferenceID, PAInitiationCommunicationNumbers} from 'src/app/modals/epa/priorauthresponse';

export enum QuestionSetOperation{
    NEW_QUESTION_LOADED=0,
    AUTOSAVE=1,
    SAVE_FOR_LATER=2,
    SUBMIT_PA_REQUEST=3
}

export class QuestionsetViewmodal {
    patient!:Patient;
    medication!:MedicationViewModal;
    prescriber!:Prescriber;

    pacaseID!:string;
    deadlineForReply!:number;
    questionSetHeader!:QuestionSetHeader;
    questions!:Question[];
    paAttachment!:SavedPAAttachment;
    paReferenceID!:PAReferenceID;

    benefitsCoordination!:BenefitsCoordination[];
}

export class MedicationViewModal{
    drugNDAname!:string;
    daysupply!:string;
    
    drugNDC!:string;
    drugQualifier!:string;

    quantity!:string;
    quantityQualifier!:string;
    quantityCode!:string;
}
export class LoadNextQuestionEvent{
    currentQuestionID!:string;
    blnOnInit!:boolean;
}
/*********************************Common**************************** */
export class Patient{
    patientAccountNumber:string="";//Nothing but memberId;
    lastName:string="";
    firstName:string="";
    gender!:PatientGender;
    dateOfBirth:string="";
    addressLine1:string="";
    city:string="";
    stateProvince:string="";
    postalCode:string="";
    countryCode:string="";
    instanceId:string="";
    carrierId:string="";
    groupId:string="";
    accountId:string="";

    public get memberId() : string {
        return this.patientAccountNumber;
    }
    public set memberId(v : string) {
        this.patientAccountNumber = v;
    }
    
}

export class BenefitsCoordination{
    payerIdentification:string="";
    cardholderID:string="";
    pBMMemberID:string="";
}
export class Prescriber{
    npi:string="";
    lastName:string="";
    firstName:string="";
    addressLine1:string="";
    city:string="";
    stateProvince:string="";
    postalCode:string="";
    countryCode:string="";
    primaryTelephoneNumber:string="";
    faxNumber:string="";
    uuid:string="";
    presbrId:string="";
}

/****************QuestionSet Common across all 4 parts of EPA transactions */
export class QuestionType{
    select!:QuestionSelect;
    freeText!:QuestionFreeText;
    date!:QuestionDate;
    numeric!:QuestionNumeric;
    attachment!:QuestionAttachment;
}
export class QuestionSet{
    header!:QuestionSetHeader;
    question!:Question[];
}
export class Question{
    questionID:string="";
    sequenceNumber:string="";
    questionText:string="";
    codedReference:any[]=[];
    questionType!:QuestionType;
    defaultNextQuestionID:string="";
}
export interface QuestionSetHeader{
    questionSetID:string;
    questionSetTitle:string;
    questionSetDescription:string;
    questionSetContactCommunicationNumber:PAInitiationCommunicationNumbers;
    attachementRequired:BooleanCode;
}
export interface QuestionChoice{
    choiceID:string;
    sequenceNumber:string;
    choiceText:string;
    additionalFreeTextIndicator:string;
    codedReference:any[];
    nextQuestionID:string;
}
interface QuestionSelect{
    selectMultiple:BooleanCode;
    choice:QuestionChoice[];
    answer: UserGivenAnswer<AnswerValue>[] | undefined;
}
interface QuestionFreeText{
    isFreeText:BooleanCode; 
    answer: UserGivenAnswer<string> | undefined;
}
interface QuestionDate{
    isDateTimeRequired:BooleanCode;
    answer: UserGivenAnswer<AnswerValue> | undefined;
}
interface QuestionNumeric{
    isNumeric:BooleanCode;
    answer: UserGivenAnswer<string> | undefined;
}

export class QuestionAttachment extends SavedPAAttachment{

}


export class UserGivenAnswer<T>{
    prescriberProvidedAnswer!:T;
}

export class AnswerValue{
    /**************For Select type of question**************** */
    choiceID!:string;
    additionalFreeText!:string;//resume PA response have this field.
    choiceText!:string;//Saved PA request would have this field.

    /***************For Free text type of question*********** */
    isFreeText!:string;
    /***************For Date type of question*************** */
    date!:string
}
/************************************END************************************ */

/**************************Enums**********************************/

export enum BooleanCode{
    Yes="Y",
    No="N"
}
export enum PatientGender{
    Male="M",
    Female="F",
    Unknown="U"
}

export const PATIENT_GENDER = new Map<string,PatientGender>([
    ["M",PatientGender.Male],
    ["F",PatientGender.Female],
    ["U",PatientGender.Unknown]
]);