<table [ngClass]="getTableClass()">
    <thead>
        <tr th [cells]="headers" (onCellClicked)="onHeaderClick($event)">
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let row of rows;index as i">
            <tr tabindex="0" tr [row]="row" [rowIndex]="i" [class.active]="!row.disabled" [class.inactive]="row.disabled" [ngClass]="row.cssClass"
                (onCellClicked)="onCellClick($event)" (keyup)="onRowClick($event,row)"  *ngIf="!row.disabled && row.blnShow">
            </tr>
            <tr tr [row]="row" [rowIndex]="i" [class.active]="!row.disabled" [class.inactive]="row.disabled" [ngClass]="row.cssClass"
                (onCellClicked)="onCellClick($event)"  *ngIf="row.disabled && row.blnShow">
            </tr>
        </ng-container>
    </tbody>
</table>