import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeremainingdesc'
})
export class TimeremainingDescPipe implements PipeTransform {

  transform(startTime: string | number, ...args: any[]): string {    
    try {

      let startDate:Date;
      const numericReg:RegExp = new RegExp("^([0-9]+)$");
      if(typeof startTime == 'string' && numericReg.test(startTime.toString()))
        startDate = new Date(parseInt(startTime));
      else
        startDate =new Date(startTime);

      let today:Date = new Date();
      let timeremainingvalue:string="";
      let differenceInMilliSec:number = startDate.getTime() - today.getTime();
      let differenceInHours:number = parseFloat((differenceInMilliSec/36e5).toFixed(2)); //Return difference in hours.
      
      if(differenceInHours>1){
        let minutesRemaining:number = differenceInHours - (parseInt(differenceInHours.toString()));
        minutesRemaining = Math.floor((minutesRemaining*60));
        
        if(parseInt(differenceInHours.toString())==1)
          timeremainingvalue = parseInt(differenceInHours.toString())+" hr ";
        else
          timeremainingvalue = parseInt(differenceInHours.toString())+" hrs ";

        if(minutesRemaining==1)
          timeremainingvalue += minutesRemaining+" min";
        else
          if(minutesRemaining>0)
            timeremainingvalue += minutesRemaining+" mins";
        
        return timeremainingvalue;
        
      } else {
        const minutesRemaining:number =  Math.floor((60 * differenceInHours)); //in min
        if(minutesRemaining==1)
          timeremainingvalue = minutesRemaining+" minute";
        else
          timeremainingvalue = minutesRemaining+" minutes";
      }

      return timeremainingvalue;

    } catch(e){
      return "time remaining error";
    }
  }

}



@Pipe({
  name: 'timeremaininghours'
})
export class TimeremaininghoursPipe implements PipeTransform {

  transform(startTime: string | number, ...args: string[]): number {    
    try {
      let startDate:Date;

      const numericReg:RegExp = new RegExp("^([0-9]+)$");
      if(typeof startTime == 'string' && numericReg.test(startTime.toString()))
        startDate = new Date(parseInt(startTime));
      else
        startDate =new Date(startTime);

      let today:Date = (args!=undefined && args.length>0)? new Date(parseInt(args[0])) : new Date();
      let timeremainingvalue:string="";
      let differenceInMilliSec:number = startDate.getTime() - today.getTime();
      let differenceInHours:number = parseFloat((differenceInMilliSec/36e5).toFixed(2)); //Return difference in hours.
      

      return differenceInHours;

    } catch(e){
      return 0;
    }
  }

}

@Pipe({
  name: 'timeremainingminutes'
})
export class TimeremainingminutesPipe implements PipeTransform {

  transform(startTime: string | number, ...args: string[]): number {    
    try {
      const hoursPipe:TimeremaininghoursPipe = new TimeremaininghoursPipe();
      let differenceInHours:number = hoursPipe.transform(startTime, ...args);
      return Math.floor(60 * differenceInHours);
    } catch(e){
      return 0;
    }
  }

}
