<!-- For QA automation purpose
<div class="row" id="targetDrugRejectCode" style="display:none;">
    <h1 codeintargetdrug="{{targetrejectcode}}" aria-hidden="true">{{targetrejectcode}}</h1>
  </div>

For QA automation purpose
<div class="row" id="targetDrugTransitionRejectCode" style="display:none;">
    <h1 transitioncodeintargetdrug="{{targetTransitionRejectCode}}" aria-hidden="true">{{targetTransitionRejectCode}}</h1>
  </div> -->


<!-- Payble Claim  -->
<div class="mt-3 col-md-8 col-sm-8 col-xs-12 nopadding text-light">
    <!-- Green Payble Claim -->
    <div class="p-2 bg-success" *ngIf="claimsummaryDetailsrequest.bannerType==3">
        <!-- <i aria-hidden="true" class="fa fa-check h3 ps-2"
            style="position: relative; top: 2px; margin-right: 20px;"></i>
        <label class="fs-6">{{claimsummaryDetailsrequest.bannerMessage}}</label> -->
        <div class="row p2">
            <i aria-hidden="true" class="fa fa-check h3 m-2 ms-4 col-md-1 col-sm-1 col-xs-2 col-2 bannerIcon text-light"></i>
            <label class="fs-6 col-9 mt-2 bannerText">{{claimsummaryDetailsrequest.bannerMessage}}</label>
        </div>
    </div>

    <!--Yellow Prior authorization is required on this medication.  -->
    <div class="p-2 bg-warning text-dark" *ngIf="claimsummaryDetailsrequest.bannerType==2">
        <div class="row p2">
            <i aria-hidden="true" class="fa fa-exclamation-triangle h3 m-2 ms-4 col-md-1 col-sm-1 col-xs-2 col-2 bannerIcon"></i>
            <label class="fs-6 col-9 mt-2 bannerText">{{claimsummaryDetailsrequest.bannerMessage}}</label>
        </div>
    </div>

    <!--Red The medication has exceeded the plan limits allowed  -->
    <div class="p-2 bg-danger text-light" *ngIf="claimsummaryDetailsrequest.bannerType==1">
        <div class="row p2">
            <i aria-hidden="true" class="fa fa-exclamation-circle h3 m-2 ms-4 col-md-1 col-sm-1 col-xs-2 col-2 bannerIcon text-light"></i>
            <label class="fs-6 col-9 mt-2 bannerText">{{claimsummaryDetailsrequest.bannerMessage}}</label>
        </div>
    </div>

    <div class="p-2 text-light bg-custom" *ngIf="claimsummaryDetailsrequest.bannerType==4">
        <div class="row p2">
            <i aria-hidden="true" class="fa fa-check h3 m-2 ms-4  col-md-1 col-sm-1 col-xs-2 col-2 bannerIcon text-light"></i>
            <label class="fs-6 col-9 mt-2 bannerText">{{claimsummaryDetailsrequest.bannerMessage}}</label>
        </div>
    </div>
    <div [hidden]="!claimsummaryDetailsrequest.durReject.isDurReject || claimsummaryDetailsrequest.durReject.durSoftRejects.length>0" [ngClass]="{'bg-danger':claimsummaryDetailsrequest.bannerType==1, 'bg-warning':claimsummaryDetailsrequest.bannerType==2, 'bg-success':claimsummaryDetailsrequest.bannerType==3}">
        <div *ngFor="let msg of claimsummaryDetailsrequest.durReject.durMessages" class="text-dark fs-6" [innerHtml]="msg"></div>
    </div>
    <div *ngIf="claimsummaryDetailsrequest.durReject.durSoftRejects.length>0" class="text-dark fs-6" [ngClass]="{'bg-danger':claimsummaryDetailsrequest.bannerType==1, 'bg-warning':claimsummaryDetailsrequest.bannerType==2, 'bg-success':claimsummaryDetailsrequest.bannerType==3}">
        <div style="display: inline-block;vertical-align: top;">
            <i style="visibility: hidden;" aria-hidden="true" class="fa fa-exclamation-triangle h3 ps-4 col-md-1 col-sm-1 col-xs-2 col-2 bannerIcon"></i>
        </div>
        <div style="display: inline-block;vertical-align: top;">
            <div *ngFor="let softReject of claimsummaryDetailsrequest.durReject.durSoftRejects; first as isFirst" class="container-fluid dursoftcontainer">
                <div class="row" *ngIf="softReject.durMessage.length>0">
                    <div class="col">
                        <span [innerHtml]="softReject.durMessage"></span>
                    </div>
                </div>
                <div [hidden]="!isFirst" class="row">
                    <div class="col">
                        Please choose Professional and Result of Service Codes to override and proceed with the trial.
                    </div>
                </div>
                <div [hidden]="isFirst" class="row">
                    <div class="col">Another DUR message goes here.</div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Professional service code</label>
                        <select [(ngModel)]="softReject.prfsnlSrvcCd" class="rounded-0 inp form-select">
                            <option value="">Select a code</option>
                            <option *ngFor="let code of durProfessionalCodes" [value]="code" [innerHtml]="code|durprfsnlSrvcCd"></option>
                        </select>
                    </div>
                    <div class="col">
                        <label>Result of service code</label>
                        <select [(ngModel)]="softReject.rsltSrvcCd" class="rounded-0 inp form-select">
                            <option value="">Select a code</option>
                            <option *ngFor="let code of durResultCodes" [value]="code" [innerHtml]="code|durrsltSrvcCd"></option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="p-2 bg-white container-fluid durinvalidcontainer" *ngIf="claimsummaryDetailsrequest.durReject.isDurReject">
        <div class="row p2" *ngFor="let invalidReject of claimsummaryDetailsrequest.durReject.durInvalidSoftRejects; first as isFirst">
            <div class="col-12 durtitle" *ngIf="isFirst">
                <div style="border-bottom: 1px solid lightgrey;padding-top:10px; padding-bottom: 10px;">
                    <h3>DUR override codes that will be added to this claim</h3>
                </div>
            </div>
            <div class="col-12" style="border-bottom: 1px solid lightgrey" [hidden]="invalidReject.prfsnlSrvcCd.length==0 || invalidReject.rsltSrvcCd.length==0">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <label class="text-muted">Professional service code</label>
                            <br/>
                            <div [innerHtml]="invalidReject.prfsnlSrvcCd | durprfsnlSrvcCd" class="text-dark fs-5"></div>
                        </div>
                        <div class="col">
                            <label class="text-muted">Result of service code</label>
                            <br/>
                            <div [innerHtml]="invalidReject.rsltSrvcCd | durrsltSrvcCd" class="text-dark fs-5"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid shadow p-3 mb-4"
        [ngClass]="{'lightGreenBG':claimsummaryDetailsrequest.bannerType==3, 'lightYellowBG':claimsummaryDetailsrequest.bannerType==2,'lightRedBG':claimsummaryDetailsrequest.bannerType==1, 'lightCream':claimsummaryDetailsrequest.bannerType==4}">
        <div>
            <p class="fs-6 text-danger">{{claimsummaryDetailsrequest.claimSubmitCustomErrorMessage}}</p>
        </div>
        <div class="row ps-2">
            <div class="col-md-7 col-sm-7 col-xs-12 pt-3 pe-5">
                <div style="border-bottom: 1px solid lightgrey;">
                    <div class="row">
                        <h3 class="mb-2 col-8"><strong>{{claimsummaryDetailsrequest.MedicationName}}</strong></h3>
                        <div class="col-4 row">

                            <label *ngIf="claimsummaryDetailsrequest.brandPreferred"
                                class="mb-1 p-2 row rounded-pill text-center"
                                style="color: #000; background-color: lightblue;">
                                <span class="col-12">Brand</span>
                                <span class="col-12">preferred</span>
                            </label>

                            <label *ngIf="claimsummaryDetailsrequest.requiresPA" class="mb-1"
                                style="color: #846732;"><span class="text-center  ms-2">PA
                                    required<i aria-hidden="true" class="fa fa-exclamation-triangle ps-2"
                                        style="position: relative; top: 4px; margin-right: 5px;"></i></span></label>
                        </div>
                    </div>
                </div>
                <p class="mt-2 fs-6 text-secondary fw-light"><span>
                        Quantity calculated: {{claimsummaryDetailsrequest.QuantityDesc}}
                    </span></p>
            </div>

            <div class="col-md-5 col-sm-5 col-xs-12 row">
                <div class="col-4">
                    <p class="fs-6 text-secondary fw-light">Provider total amount due</p>
                    <p class="fs-4 text-dark">{{claimsummaryDetailsrequest.ProviderTotalAmountDue}}</p>
                </div>
                <div class="col-4">
                    <p class="fs-6 text-secondary fw-light">Patient pay amount</p>
                    <p class="fs-4 text-dark">{{claimsummaryDetailsrequest.PatientPayAmount}}</p>
                </div>
                <div class="col-4" *ngIf="claimsummaryDetailsrequest.initiatePALnk">
                    <button class="btn btn-link fs-6 ms-3 mt-3 fw-bold" (click)="editProvider()">Initiate PA</button>.
                    <eparunner></eparunner>
                </div>
                <!-- *ngIf="this.claimsummaryDetailsrequest.claimSubmitted" -->
                <div class="col-4" *ngIf="claimsummaryDetailsrequest.claimStsTypId==3">
                <!-- <div class="col-4"> -->
                    <button class="btn btn-primary fs-6  fw-bold rounded-pill"
                        (click)="reverseClaim.emit(claimsummaryDetailsrequest.ProductID)">Reverse claim</button>
                </div>

                <!-- <div class="col-4" *ngIf="this.claimsummaryDetailsrequest.claimSubmitFailed"> -->
                <div class="col-4" *ngIf="claimsummaryDetailsrequest.claimStsTypId==6 || claimsummaryDetailsrequest.claimStsTypId==8">
                    <button class="btn btn-primary fs-6  fw-bold rounded-pill"
                        (click)="removeevent.emit(claimsummaryDetailsrequest.ProductID)">Remove</button>
                </div>

                <!-- <button class="btn btn-primary float-end fontbold mt-1 col-5 offset-1" (click)="submitClaims()">Submit Claim
                </button> -->
            </div>
        </div>

        <div class="rounded border p-3 mb-2" *ngIf="claimsummaryDetailsrequest.localMessages.length>0">
            <span class="text-secondary fs-6"><strong>Additional information:</strong></span>
            <ul class="text-secondary fw-light mb-0 ps-3" *ngFor="let item of claimsummaryDetailsrequest.localMessages">
                <li>{{item.localMessageCodeText}}</li>
            </ul>
        </div>
        <div class="row p-3">
            <div class="col-md-6 col-sm-6 col-xs-12 p-0">
                <!-- <label class="fs-6 text-end text-muted" *ngIf="this.claimsummaryDetailsrequest.dateOfReversal!=undefined">Date of Claim Reversal: -->
                    <label class="fs-6 text-end text-muted" *ngIf="claimsummaryDetailsrequest.claimStsTypId==4">Date of claim reversal:
                        {{claimsummaryDetailsrequest.dateOfLastTrail}}
                    <!-- <label class="text-muted">{{this.claimsummaryDetailsrequest.dateOfReversal}}</label> -->
                    </label>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
                <!-- <button *ngIf="claimsummaryDetailsrequest.claimSubmitted!=true"
                    class="col-6 btn btn-link text-danger"
                    (click)="removeevent.emit(claimsummaryDetailsrequest.ProductID)">Remove medication</button> -->
                <button class="col-6 btn btn-link text-danger" *ngIf="claimsummaryDetailsrequest.claimStsTypId==0 || claimsummaryDetailsrequest.claimStsTypId==1 
                || claimsummaryDetailsrequest.claimStsTypId==2 || claimsummaryDetailsrequest.claimStsTypId==6 || claimsummaryDetailsrequest.claimStsTypId==8"
                (click)="removeevent.emit(claimsummaryDetailsrequest.ProductID)">Remove medication</button>
                <!-- <button class="col-6 btn btn-link float-end"
                    [ngClass]="{'offset-6': (claimsummaryDetailsrequest.claimSubmitted==true)}"
                    data-bs-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false"
                    aria-controls="multiCollapseExample1"
                    (click)="changeShowFullDetailsBtnLabel()">{{showFullDetailsBtnTitle}}</button> -->
                <button class="col-6 btn btn-link float-end"
                [ngClass]="{'offset-6': (claimsummaryDetailsrequest.claimStsTypId==3)}"
                data-bs-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false"
                aria-controls="multiCollapseExample1"
                (click)="blnShowFullMedicationDetails=!blnShowFullMedicationDetails">
                <span *ngIf="!blnShowFullMedicationDetails">Show full medication details</span>
                <span *ngIf="blnShowFullMedicationDetails">Hide full medication details</span>
                </button>
            </div>
        </div>

        <!--Start Collasable Div-->
        <div class=" multi-collapse" *ngIf="blnShowFullMedicationDetails">
            <div class="card card-body rounded-0 border-0 p-0">
                <div class="p-3 bg-white">
                    <h4 class="mt-3 mb-3">{{claimsummaryDetailsrequest.MedicationName}} Medication
                        information</h4>
                    <div *ngIf="blnChgQtyDOS==0" class="rounded border col-md-6 col-sm-12 col-xs-12 mt-3">
                        <div class="row">
                            <p class="col-md-4 col-sm-4 col-xs-6 col-6 mt-3 text-center"><span>Quantity entered:
                                    <strong>{{claimsummaryDetailsrequest.QuantityEntered}}</strong></span></p>
                            <p class="col-md-4 col-sm-4 col-xs-6 col-6 mt-3 text-center"><span>Days supply:
                                    <strong>{{claimsummaryDetailsrequest.DaysSupply}}</strong></span></p>
                            <button class="col-md-4 col-sm-4 col-xs-12 btn btn-link float-end" *ngIf="claimsummaryDetailsrequest.claimStsTypId<3 || claimsummaryDetailsrequest.claimStsTypId==6 || claimsummaryDetailsrequest.claimStsTypId==8" 
                            (click)="chgQtyDOS()">Change</button>
                        </div>
                    </div>
                    <div *ngIf="blnChgQtyDOS==1" class="rounded border col-12 mt-3">
                        <form [formGroup]="changeQTYDOSForm">
                            <div class="row ">
                                <div class="col-md-4 col-sm-4 col-xs-6 col-6 row">
                                    <label for="quantity" class="col-6 text-dark mt-2 text-center"><span>Quantity entered: </span></label>
                                    <div class="col-6 mt-2">
                                        <input type="text"
                                            [ngClass]="{'inpError': (!Quantity?.valid && !Quantity?.pristine)}"
                                            class=" rounded-1 form-control inp h-75 " maxlength="11"
                                            formControlName="Quantity" aria-required="true">
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-3 col-xs-6 col-6 row">
                                    <label for="days" class="col-7 text-dark mt-2 text-center"><span>Days supply: </span></label>
                                    <div class="col-5 mt-2">
                                        <input type="text" [ngClass]="{'inpError': (!Days?.valid && !Days?.pristine)}"
                                            class=" rounded-1 form-control inp h-75" maxlength="3"
                                            formControlName="Days" aria-required="true">
                                    </div>
                                </div>
                                <!-- <p class="col-3 mt-3 text-center"><span>Days Supply: <strong>{{claimsummaryDetailsrequest.DaysSupply}}</strong></span></p> -->
                                <button class="col-md-1 col-sm-1 col-xs-6 col-6  btn btn-link float-end text-danger"
                                    (click)="cancelQtyDOS()">Cancel</button>
                                <button *ngIf="claimresponse.clmgroupId==undefined"
                                    class="col-md-1 col-sm-1 col-xs-6 col-6  btn btn-link float-end" (click)="upadateOrReexecute()">Update</button>
                                <button *ngIf="claimresponse.clmgroupId!=undefined"
                                    class="col-md-2 col-sm-2 col-xs-6 col-6  btn btn-link float-end"
                                    (click)="upadateOrReexecute(true)">Re-execute</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="bg-light border-3 border-top p-3">
                    <div class="row">
                        <div class="col-md-6 col-sm-12 col-xs-12 p-3">
                            <div class="row">
                                <label class="text-muted">Medication name</label>
                                <label class="fs-6 text-dark">{{claimsummaryDetailsrequest.MedicationName}}</label>
                            </div>
                            <div class="row     mt-3">
                                <div class="col-6 row text-muted">
                                    <label>Quantity entered</label>
                                    <label class="text-dark">{{claimsummaryDetailsrequest.QuantityEntered}}</label>
                                </div>
                                <div class="col-6 row text-muted">
                                    <label>Quantity calculated</label>
                                    <label class="text-dark">{{claimsummaryDetailsrequest.QuantityDesc}} </label>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <label class="text-muted">Diagnosis code</label>
                                <label class="fs-6 text-dark">{{claimsummaryDetailsrequest.DiagnosisCode}}</label>
                            </div>
                            <div class="row mt-3">
                                <label class="text-muted">Contracted Network Provider</label>
                                <label
                                    class="fs-6 text-dark">{{claimsummaryDetailsrequest.ContractedNetworkProvider}}</label>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 col-xs-12 p-3">
                            <div class="row">
                                <div class="col-6 row text-muted">
                                    <label>Product ID</label>
                                    <label class="text-dark">{{claimsummaryDetailsrequest.ProductID | ndcdisplayformat}}</label>
                                </div>
                                <div class="col-6 row text-muted">
                                    <label>Date of service</label>
                                    <label class="text-dark">{{claimsummaryDetailsrequest.DateofService}}</label>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <label class="text-muted">Days of supply</label>
                                <label class="fs-6 text-dark">{{claimsummaryDetailsrequest.DaysSupply}}</label>
                            </div>
                            <div class="row mt-3">
                                <label class="text-muted">Place of service</label>
                                <label class="fs-6 text-dark">{{claimsummaryDetailsrequest.PlaceofService}}</label>
                            </div>
                            <div class="row mt-3">
                                <label class="text-muted">Contracted Network Provider address</label>
                                <label
                                    class="fs-6 text-dark">{{claimsummaryDetailsrequest.ContractedNetworkProviderAddress}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card card-body rounded-0 border-0 p-0 mt-3">
                <div class="p-3 bg-white">
                    <h4 class="mt-3 mb-3">
                        {{claimsummaryDetailsrequest.MedicationName.substring(0,claimsummaryDetailsrequest.MedicationName.indexOf('
                        '))}} Billing
                        information</h4>
                </div>
                <div class="m-0 p-3 bg-light border-3 border-top">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12 row p-3">
                            <label class="text-muted">Approved claim ingredient cost</label>
                            <label
                                class="fs-6 text-dark">{{claimsummaryDetailsrequest.ApprovedClaimIngredientCost}}</label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12 row p-3">
                            <label class="text-muted">Provider total amount due</label>
                            <label class="fs-6 text-dark">{{claimsummaryDetailsrequest.ProviderTotalAmountDue}}</label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12 row p-3">
                            <label class="text-muted">Approved professional fee <br><span class="fs-6 text-dark" style="font-size: 13px !important;">(Drug Administration and Disposable Supply Fees)</span></label>
                            <label class="fs-6 text-dark">{{claimsummaryDetailsrequest.ApprovedProfessionalFee}}</label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12 row p-3">
                            <label class="text-muted">Patient pay amount</label>
                            <label class="fs-6 text-dark">{{claimsummaryDetailsrequest.PatientPayAmount}}</label>
                        </div>
                        <div class="col-6 row p-3" *ngIf="claimsummaryDetailsrequest.rxServiceReferenceNo!=''">
                            <label class="text-muted">RX-service reference No</label>
                            <label class="fs-6 text-dark">{{claimsummaryDetailsrequest.rxServiceReferenceNo}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Collapasble Div -->
    </div>
</div>
<!--End Payble Claim -->

<ng-template #claimSummaryProgress let-modal>
    <div style="height:200px" aria-modal="true" role="dialog" aria-labelledby="claimSummaryProgress">
        <div class="modal-body mt-2">
            <div class="p-3">
                <div *ngIf="counter==10" class="row text-center"><i class="fa fa-ban  text-danger fs-1"
                        aria-hidden="true"></i></div>
                <div @slideDown *ngIf="counter==10" class="text-center mt-3 fs-6">
                    {{trailAdjuducicationError}}</div>
                <div *ngIf="counter==10" @slideDown class="modal-footer border-0 mt-5">
                    <button class="btn btn-secondary position-absolute top-80 start-50 translate-middle"
                        (click)="dismissAll()">ok</button>
                </div>
                <div *ngIf="counter==3" class="circle">
                    <div class="checkMark"></div>
                </div>
                <div *ngIf="counter<3" class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div @slideDown *ngIf="counter==3" class="text-center fs-6">Check coverage complete</div>

                <div @slideDown *ngIf="counter==2" class="text-center fs-6">Retrieving results...</div>

                <div @slideDown *ngIf="counter==1" class="text-center fs-6">Analyzing information…</div>

            </div>
        </div>

    </div>

</ng-template>

<ng-template #editProviderTemplate let-modal>
    <div class="content-card editprovider" aria-modal="true" aria-hidden="true" role="dialog" aria-labelledby="editProvider">
        <div class="card-header text-start">
            <h2 tabindex="-1">Verify provider information</h2>
        </div>
        <div class="alert alert-danger text-end" *ngIf="editProviderStatus==2">
            <div class="float-start servererror">
                <i class="fa fa-ban"></i>An error occurred.
            </div>
            <button class="btn btn-danger" (click)="editProviderStatus=0">Refresh page</button>
        </div>
        <div class="alert alert-danger text-start" *ngIf="editProviderStatus==3">
            All fields are required.
        </div>
        <div class="card-content">
            <editprovider *ngIf="editProviderStatus!=2" [prescriberDemographs]="claimresponse.selectedPrescriberDetail" (isValid)="isEditProviderValid($event)"></editprovider>
        </div>
        <div class="card-footer text-end">
            <button class="btn underline btn-link" (click)="modal.dismiss()">Cancel</button>
            <button class="btn btn-primary fontbold rounded-pill" [ngClass]="{'disabled':editProviderStatus!=0}"
                (click)="modal.dismiss();initiatePA()">Submit</button>
        </div>
    </div>
</ng-template>

<ng-template #errorModalTemplate let-modal>
    <div class="content-card errorModal" aria-modal="true" aria-hidden="true" role="dialog" aria-labelledby="errorModal">
        <div class="card-content text-center">
            <i class="fa fa-ban"></i>
            <br /><br />
            <div [innerHtml]="errorMessage"></div>
            <br />
            <button class="btn btn-primary" (click)="modal.dismiss()">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #overwriteConfirmModal let-modal>
    <div class="p-3" aria-modal="true" role="dialog" aria-labelledby="editProvider">
        <div class="row text-center"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></div>
        <div class="text-center mt-2">
            All drugs in the transaction will process again.<br>Results may vary depending on if there were plan
            changes.
        </div>
        <div class="row border-0 mt-4 p-3 ">
            <div class="col-4 ">
                <button class="col-2 position-absolute top-80 start-50 translate-middle btn btn btn-primary"
                    (click)="chgQtyDOS()">ok</button>
            </div>
        </div>
    </div>
</ng-template>