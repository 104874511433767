import {PRESCRIBER_QUALIFIER} from 'src/app/constants/appconstant';

export class PrescriberSearchWebRequest {
    lastName: string='';
    firstName: string='';
    prescriberState: string='';
    prescriberCity: string='';
    prescriberId: string='';
    prescriberIdQualifier:PRESCRIBER_QUALIFIER = PRESCRIBER_QUALIFIER.Empty;
}

export class PrescriberDetailWebRequest{
    instanceId:string="";
    prescriberId:string="";
    prescriberIdQualifier:PRESCRIBER_QUALIFIER = PRESCRIBER_QUALIFIER.Empty;
}

/**************************Prescriber Favorite*******************/

export class PrescriberFavoriteWebRequest {
    lastName:string="";
	firstName:string="";
    address:string="";
    npi: string = "";
    presbrId: string="";
    instcId: string="";
    uuid: string="";
}

