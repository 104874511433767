import { TemplateRef } from '@angular/core';

export class TableHeaderCellData{
    cssClass:string="";
    template?:TemplateRef<any>;
    colspan:number=1;
    rowspan:number=1;
    content:string="";

    sortable:boolean=false;
    sortAscending:boolean=true;
    selectedForSort:boolean=false;
    clickable:boolean = true;
}

export class TableCellData<T> extends TableHeaderCellData{
    data?:T;
}

export class TableClickEvent<T>{
    cell?:TableCellData<T>;
    event:any;
    column?:number;
    row!:number;
}

export class KeyboardEvent<T>{
    cell?:TableCellData<T>;
    event:any;
    row!:number;
}


export class TableRowClickEvent<T>{
    event:any;
    row!:number;
}

export class TableRowData<T> {
    cells:TableCellData<T>[]=[];
    disabled:boolean=false;
    cssClass:string="";
    blnShow:boolean=true;
}

export enum DataTableType{
    TRANSPARENT=0,
    HIGHLIGHT_ROWS=1,
    TABLE_STRIPPED=2,
    TRANSPARENT_COLLAPSABLE=3
}