import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ndcdisplayformat'
})
export class NdcdisplayformatPipe implements PipeTransform {

  transform(ndc: string, ...args: string[]): string {
    let formattedNdc:string="";
    if(ndc!=undefined && ndc.length==11){
      ndc = ndc.replace(/-/g,"");
      formattedNdc = ndc.substring(0,5)+"-"+ndc.substring(5,9)+"-"+ndc.substring(9,11);
    }
    return formattedNdc;
  }

}
