import { Component, OnInit } from '@angular/core';
import { ViewAllRouteState, PAGE_TYPE } from 'src/app/constants/routeconstants';
import { Router } from '@angular/router';
import { SavedclaimhistoryComponent } from 'src/app/components/savedclaimhistory/savedclaimhistory.component';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-paid-claims',
  templateUrl: './paid-claims.component.html',
  styleUrls: ['./paid-claims.component.css']
})
export class PaidClaimsComponent implements OnInit {

  state!:ViewAllRouteState;
  paidClaimPageType:PAGE_TYPE = PAGE_TYPE.SUBMITTEDCLAIMS;
  constructor(private router:Router,private titleService:Title) { 
    this.titleService.setTitle("View All Paid Claims (Reverse a claim)");
  }

  ngOnInit(): void {
    try{
      this.state = history.state;
    } catch(error){

    }
  }

  goto(routerUrl:string){
    this.router.navigateByUrl("/"+ routerUrl);
  }
}
