import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DatatableComponent} from 'src/app/components/datatable/datatable.component';
import { TableheaderComponent} from 'src/app/components/datatable/tableheader/tableheader.component';
import { TablerowComponent } from 'src/app/components/datatable/tablerow/tablerow.component';
import { TablecellComponent } from 'src/app/components/datatable/tablecell/tablecell.component';
import { DatefieldComponent } from 'src/app/components/datefield/datefield.component';
import { EparunnerComponent } from 'src/app/components/epa/eparunner/eparunner.component';
import { TimeremaininghoursPipe , TimeremainingDescPipe, TimeremainingminutesPipe} from "src/app/pipes/timeremaining/timeremaining.pipe";
import { DrugnamesortPipe } from 'src/app/pipes/drugnamesort/drugnamesort.pipe';
import { StandardDateToServertime, DatetoDisplayString, ServertimeToStandardDate, TimeStampToStandardDate, TimestamptoServerTime, ServertimeToDisplayString } from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { DurdescriptionPipe, DurProfessionalServiceCodePipe, DurResultOfServiceCodePipe } from 'src/app/pipes/durdescription/durdescription.pipe';
import { FilterobjectsByTextPipe } from 'src/app/pipes/filterobjects/filterobjects.pipe';
import { StringToSentenceCase, FormatPhoneOrFax } from 'src/app/pipes/stringconvertions/stringconversions.pipe';
import { NdcdisplayformatPipe } from 'src/app/pipes/ndcdisplayformat/ndcdisplayformat.pipe';
import { PaginationComponent } from 'src/app/components/pagination/pagination.component';
import { LoaderComponent } from 'src/app/components/loader/loader.component';
import { DatatableModule } from 'src/app/modules/datatable/datatable.module';

import { DownloadserverfileComponent } from 'src/app/components/downloadserverfile/downloadserverfile.component';

@NgModule({
  declarations: [
    TimeremainingDescPipe,
    TimeremaininghoursPipe,
    TimeremainingminutesPipe,
    DrugnamesortPipe,
    DurdescriptionPipe,
    DurProfessionalServiceCodePipe,
    DurResultOfServiceCodePipe,
    StandardDateToServertime,
    ServertimeToStandardDate,
    ServertimeToDisplayString,
    DatetoDisplayString,
    TimeStampToStandardDate,
    TimestamptoServerTime,
    FilterobjectsByTextPipe,
    StringToSentenceCase,
    NdcdisplayformatPipe,
    FormatPhoneOrFax,
    DatefieldComponent,
    PaginationComponent,
    DownloadserverfileComponent,
    LoaderComponent,
    EparunnerComponent
  ],
  imports: [
    CommonModule,
    DatatableModule,
    FormsModule, 
    ReactiveFormsModule,
    NgbModule
  ],
  exports: [
    TimeremainingDescPipe,
    TimeremaininghoursPipe,
    TimeremainingminutesPipe,
    DrugnamesortPipe,
    DurdescriptionPipe,
    DurProfessionalServiceCodePipe,
    DurResultOfServiceCodePipe,
    StandardDateToServertime,
    ServertimeToDisplayString,
    DatetoDisplayString,
    ServertimeToStandardDate,
    TimeStampToStandardDate,
    TimestamptoServerTime,
    FilterobjectsByTextPipe,
    NdcdisplayformatPipe,
    StringToSentenceCase,
    FormatPhoneOrFax,
    DatatableComponent,
    TableheaderComponent,
    TablerowComponent,
    TablecellComponent,
    DatefieldComponent,
    PaginationComponent,
    DownloadserverfileComponent,
    LoaderComponent,
    EparunnerComponent
  ],
  providers:[
    TimeremaininghoursPipe,
    TimeremainingDescPipe,
    TimeremainingminutesPipe,
    DurdescriptionPipe,
    DurProfessionalServiceCodePipe,
    DurResultOfServiceCodePipe,
    StandardDateToServertime,
    ServertimeToDisplayString,
    DatetoDisplayString,
    ServertimeToStandardDate,
    TimeStampToStandardDate,
    TimestamptoServerTime,
    DrugnamesortPipe,
    FilterobjectsByTextPipe,
    StringToSentenceCase,
    FormatPhoneOrFax
  ]
})
export class SharedModule { }
