import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringtosentencecase'
})
export class StringToSentenceCase implements PipeTransform {

  transform(value: string | undefined, ...args: unknown[]): string {
    if ((value===null) || (value==='') || (value==undefined) )  
  		       return "";  
  		  else  
  		   value = value.toString();  
  		  
  		 return value.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});  
  }

}

@Pipe({
  name: 'formatphoneorfax'
})
export class FormatPhoneOrFax implements PipeTransform {
  transform(value: string,mode:number=0, ...args: unknown[]): string {
    if(value !=undefined && value.toString().length==10){
      if(mode==0)
        return value.substring(0,3)+"-"+value.substring(3,6)+"-"+value.substring(6,10);
      else
        return "("+value.toString().substring(0,3)+") "+value.toString().substring(3,6)+"-"+value.toString().substring(6,10);
    } else {
      return "- - -";
    }
  }
}