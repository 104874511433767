import { Status } from '../webresponsestatus';
import { MemberSearchItem } from './drugsearchwebrequest';
import { PrescriberDetailItem, PrescriberSearchItem } from '../prescriberSearch/prescriberSearchwebresponse';
import { MemberDetailWebResponse } from '../membersearch/memberdetailwebresponse';
import { Trailadjudicationwebresponse } from './Trailadjudicationwebresponse';
import { Trailadjudicationwebrequest } from './Trailadjudicationwebrequest';
import { DRUG_QUALIFIER } from 'src/app/constants/appconstant';
import { Response } from './SaveTrailClaimResponse';
import { SubmitClaimResponseDetail } from './submitMultipleClaimResponse';
import { ReverseClaimResponse } from './reverseclaimResponse';
import { MemberSearchV5Item } from '../membersearch/membersearchwebresponse';
import {ClaimSummaryDurReject} from 'src/app/modals/claimsummary/claimsummary';

export interface Drugsearchwebresponse {
    response: DrugResponse;
    status: Status;
}
export interface DrugResponse {
    maxRowExceededInd: boolean;
    genericSubstitutionPerformed: boolean;
    drugSearchItems: DrugSearchItem[];
    ndcDrugsList: string[];
}

export interface DrugSearchItem {
    prodNameAbbr: string;
    productNameExt: string;
    genericName: string;
    gpi: string;
    productIdQlfr: DRUG_QUALIFIER;
    productId: string;
    strength: string;
    strengthUnitOfMeasure: string;
    dosageFormCode: string;
    dosageFormDesc: string;
    multiSourceCode: string;
    unitDoseUnitUse: string;
    productStatus: string;
    otcCode: string;
    breakableIndicator: string;
    additionalField: AdditionalField[];
    detailedDrugName:string;

}

export interface AdditionalField {
    fieldDescription: string;
    fieldStringValue: string;
    fieldDecimalValue: string;
    fieldIntegerValue: string;
}


export interface DrugCoded {
    code: string;
}

export interface FavoriteDrugsDetail {
    drugDescription: string;
    drugCoded: DrugCoded[];
    productIdQlfr: string;
    drugInfoId: string;
}

export interface FavoriteDrugsDetailResponse {
    favoriteDrugsDetail: FavoriteDrugsDetail[];
    loggedPresbrId: string;
    loggedUuid: string;
    status: Status;
}
export interface FavDrugStatus {
    statusCode: string;
    statusDesc: string;
    dbStatusCode: string;
}

export interface FavDrugStatusResponse {
    drugInfoId: string;
    status: FavDrugStatus;
}

export interface SpecialityProductResponse {
    smapProductInfoResponse: SmapProductInfoResponse;
    cagValidationResponse: CagValidationResponse;
}

export interface SmapProductInfoResponse {
    specialityProducts: SpecialityProduct[];
    records: number;
    status: SmapProductInfoStatus;
}

export interface SpecialityProduct {
    prdctInfoId: number;
    ndc: string;
    displayName: string;
    genericName: string;
    brandName: string;
    drgLableNm: string;
    drgAdmin: number;
    jCode: string;
}

export interface CagValidationResponse {
    isCagValid: string;
    status: CagValidationStatus2;
}

export interface CagValidationStatus2 {
    statusCode: string;
    dbStatusCode: string;
}

export interface SmapProductInfoStatus {
    statusCode: string;
    dbStatusCode: string;
}

//diagnosis responses
export interface DiagnosisCodeMessage {
    responseCode: string;
    responseMessage: string;
}

export class DiagnosisCodeSearchItem {
    diagnosisCode!: string;
    diagnosisDescription!: string;
    diagnosisQualifier!: string;
    diagnosisType!: string;
    additionalField!: any[];
    diagnosisCodeDetailedName!: string;
    private _selected!: boolean;
    public get selected(): boolean {
        return this._selected;
    }
    public set selected(value: boolean) {
        this._selected = value;
    }
}

export interface DiagnosisCodeSearchResponse {
    maxRowExceededInd: boolean;
    diagnosisCodeSearchItems: DiagnosisCodeSearchItem[];
    correlationId: string;
}

export interface DiagnosisSearchResult {
    message: DiagnosisCodeMessage;
    diagnosisCodeSearchResponse: DiagnosisCodeSearchResponse;
}

export interface DiagnosisCodeSearchStatus {
    statusCode: string;
    statusDesc: string;
}

export interface DiagnosisCodeSearchResponses {
    diagnosisSearchResult: DiagnosisSearchResult;
    status: DiagnosisCodeSearchStatus;
}

export class SelectedMedicine{
    memberDetailResponse!:MemberDetailWebResponse;
    drugSearchModel!: string;
    MedStrength!: string;
    Quantity!: string;
    quantytyCalculate!: number;
    quantytyDesc!: string;   
    quantityEntered!:string;
    Days!: number;
    DrugAdmin!: string;
    DiagnosisCode!: string;
    MedStrengthName!: string;
    drugSearchItem!: DrugSearchItem;
    drugSearchItemOnTrail!:DrugSearchItem;
    dateOfservice!:string;
    placeOfservice!:string;
    diagnosisCodeSearchItem!: DiagnosisCodeSearchItem | undefined;
    selectedMember!:MemberSearchItem;
    selectedPrescriber!: PrescriberSearchItem;
    selectedPrescriberDetail!: PrescriberDetailItem;
    trailadjudicationwebresponse!:Trailadjudicationwebresponse;
    trailadjudicationwebrequest!:Trailadjudicationwebrequest;
    clmgroupId!:string;
    claimDetailId:number=0;
    dateOfLastTrial!:string;
    blnSavedAfterTrail:boolean=false;
    //dateOfClaimSubmission!:string;
    //dateOfReversal!:string;
    //submitClaimStatus!:string;

    //saveClaimRequestLstClaim!:LstClaim;
    saveClaimResponseLstClaim!:Response;

    //saveClaimCompleteRequest!:SaveTrailClaimRequest;
    //saveClaimCompleteResponse!:SaveTrailClaimResponse;
    
    //submitClaimRequestLstClaim!:SubmitClaimRequest;
    submitClaimResponseLstClaim?:SubmitClaimResponseDetail;

    //submitClaimCompleteRequest!:SubmitMultipleClaimRequest;
    //submitClaimCompleteResponse!: SubmitMultipleClaimResponse;

    //reverseClaimRequest!:ReverseClaimRequest;
    reverseClaimResponseLstClaim?:ReverseClaimResponse;
    durReject!:ClaimSummaryDurReject;
    }