import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Claimhistorywebrequest, ClaimBookmarkWebRequest, ClaimUpdateWebRequest } from 'src/app/modals/claimhistory/claimhistorywebrequest';
import { Claimhistorywebresponse, ClaimTransactionHistoryResponse } from 'src/app/modals/claimhistory/claimhistorywebresponse';
import { Status } from 'src/app/modals/webresponsestatus';
import { environment } from 'src/environments/environment';
import { APPCONSTANTS } from 'src/app/constants/appconstant';
import { RefreshResponse } from 'src/app/modals/claimhistory/claimrefreshwebresponse';
import { RefreshRequest } from 'src/app/modals/claimhistory/claimrefreshwebrequest';
import { LoggerService } from '../logger/logger.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimhistoryService {

  constructor(private http:HttpClient, private logger: LoggerService) { }

  getSavedClaimHistory(webrequest: Claimhistorywebrequest):Promise<Claimhistorywebresponse>{
      const promise: Promise<Claimhistorywebresponse> = new Promise<Claimhistorywebresponse>((resolve:any,reject:any)=>{
        let header=new HttpHeaders({"endpoint":environment.getClaimHistoryByStatusesUrl});
        this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response:any)=>{
          if(response.status!=undefined 
            && response.status.statusCode!=undefined
            && response.status.statusCode.toLowerCase()==APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
            && response.status.dbStatusCode!=undefined
            && (response.status.dbStatusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE ||
            response.status.dbStatusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_DB_EMPTY_SAVEDCLAIMS_CODE)){
              resolve(response);
            } else {
              this.logger.log(response);
          reject(response);
            }
        },(error:any)=>{
            this.logger.error(error);
          reject(error);
        });
      });
      return promise;
  }

  getBookmarkedClaims(webrequest: Claimhistorywebrequest):Promise<Claimhistorywebresponse>{
    const promise: Promise<Claimhistorywebresponse> = new Promise<Claimhistorywebresponse>((resolve:any,reject:any)=>{
      let header=new HttpHeaders({"endpoint":environment.getBookmarkedClaimsUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response:any)=>{
        if(response.status!=undefined 
          && response.status.statusCode!=undefined
          && response.status.statusCode.toLowerCase()==APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
          && response.status.dbStatusCode!=undefined
          && (response.status.dbStatusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE ||
          response.status.dbStatusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_DB_EMPTY_SAVEDCLAIMS_CODE)){
            resolve(response);
          } else {
            this.logger.log(response);
          reject(response);
          }
      },(error:any)=>{
          this.logger.error(error);
          reject(error);
      });
    });
    return promise;
}

  addOrRemoveClaimBookmark(webrequest:ClaimBookmarkWebRequest):Promise<Claimhistorywebresponse>{
    const promise: Promise<Claimhistorywebresponse> = new Promise<Claimhistorywebresponse>((resolve:any, reject:any)=>{
      let header=new HttpHeaders({"endpoint":environment.addOrRemoveClaimBookmarkUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response:any)=>{
        if(response.status!=undefined && response.status.statusCode!=undefined && response.status.statusCode.toLowerCase()==APPCONSTANTS.API_RESPONSE_SUCCESS_TXT){
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      },(error:any)=>{
        this.logger.error(error);
          reject(error);
      });
    });
    return promise;
  }

  updateClaimStatus(webrequest:ClaimUpdateWebRequest): Promise<Status>{
    const promise: Promise<Status> = new Promise<Status>((resolve:any, reject:any)=>{
      let header=new HttpHeaders({"endpoint":environment.updateClaimStatus});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response:any)=>{
        
        if(response!=undefined && response.statusCode!=undefined 
          && response.statusCode.toLowerCase()==APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
          && response.dbStatusCode != undefined
          && response.dbStatusCode == APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE){
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      },(error:any)=>{
        this.logger.error(error);
          reject(error);
      });
    });
    return promise;
  }

  getSmapClaimTransactionByGrpId(claimGrpId:string):Promise<ClaimTransactionHistoryResponse>{
    const promise: Promise<ClaimTransactionHistoryResponse> = new Promise<ClaimTransactionHistoryResponse>((resolve:any, reject:any)=>{
      let header=new HttpHeaders({"endpoint":environment.getSmapClaimTransactionByGroupId.replace("{claimGrpId}",claimGrpId)});
      this.http.post(environment.proxyurl, null,{headers:header}).subscribe((response:any)=>{
          if(response!=undefined && response.status != undefined && response.status.statusCode!=undefined
            && response.status.statusCode.toLowerCase()==APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
            && response.status.dbStatusCode != undefined && response.status.dbStatusCode == APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE){
              resolve(response);
            } else {
              this.logger.log(response);
          reject(response);
            }
        },(error:any)=>{
            this.logger.error(error);
          reject(error);
        });
    });
    return promise;
  }

getStatusAfterRefresh(webrequest:RefreshRequest):Promise<any>{
  
  const promise: Promise<any> = new Promise<any>((resolve:any, reject:any)=>{
    let header=new HttpHeaders({"endpoint":environment.refreshUrl});
      this.http.post(environment.proxyurl,webrequest,{headers:header}).subscribe((response:any)=>{
        if(response!=undefined){
            resolve(response);
          } else {
            this.logger.log(response);
          reject(response);
          }
      },(error:any)=>{
          this.logger.error(error);
          reject(error);
      });
  });
  return promise;
}



}
